import { useFormik } from "formik"
import * as Yup from "yup"

import Spinner from "../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    title: Yup.string().required('Title is required').min(5, 'Title must be at least 5 characters').max(255, 'Title must be at most 255 characters'),
    sequence: Yup.number().min(1).max(999).nullable().label('Sequence')
})

const CourseSectionForm = ({ data = null, onSubmit, submitLoading }) => {
    const formik = useFormik({
        initialValues: {
            title: data?.title,
            sequence: data?.sequence
        },
        validationSchema: schema,
        onSubmit: values => onSubmit(values)
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-8">
                <label className="form-label">Section Title</label>
                <input type="text" name="title" className="form-control" placeholder="Section Title" onChange={formik.handleChange} value={formik.values.title} />
                {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
            </div>
            <div className="form-group mb-8">
                <label className="form-label">Sequence</label>
                <input type="number" name="sequence" className="form-control" placeholder="Sequence" min={1} max={999} onChange={formik.handleChange} value={formik.values.sequence} />
                {formik.errors.sequence ? <small className="form-text text-danger">{formik.errors.sequence}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                {data ? 'Update' : 'Create'} Section {submitLoading && <Spinner size="sm" />}
            </button>
        </form>
    )
}

export default CourseSectionForm