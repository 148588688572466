import { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { getTasks } from "../../../../models/courses/tasks"
import TaskFilter from "./TaskFilter"
import Table from "../../../../components/Table/Table"
import { taskColumns, columnAliases } from "./TaskTable/_columns"
import GeneralPagination from "../../../../components/Pagination/GeneralPagination"
import TaskDetailModal from "./TaskDetailModal"

export default function TasksComponent({ schedule = null }) {
    const tasksQueryKey = 'course-tasks'
    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState(schedule ? {
        schedule: {
            label: schedule.name,
            value: schedule.id
        }
    } : {})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({
        id: 'updated_at',
        direction: 'DESC'
    })
    const [showTaskDetailModal, setShowTaskDetailModal] = useState(false)
    const [taskDetailData, setTaskDetailData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery([tasksQueryKey], () => getTasks({
        courseId: filters?.course?.value,
        scheduleId: filters?.schedule?.value,
        lessonId: filters?.lesson?.value,
        user: filters?.user,
        taskType: filters?.taskType?.value,
        assessmentStatus: filters?.assessmentStatus?.value,
        page,
        rows: pageSize,
        sortBy
    }))

    useEffect(() => {
        queryClient.fetchQuery([tasksQueryKey])
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <div className='card'>
                <div className='card-header p-6 d-block'>
                    <div className='d-flex justify-content-between'>
                        <button
                            type='button'
                            className={
                                "btn btn-sm me-3 " + (showFilter ? "btn-primary" : "btn-light-primary")
                            }
                            onClick={() => setShowFilter((item) => !item)}
                        >
                            <i className='fa fa-filter'></i> Filter
                        </button>
                    </div>
                    <TaskFilter
                        scheduleId={schedule?.id}
                        defaultValues={{
                            schedule: schedule ? {
                                label: schedule?.name,
                                value: schedule?.id
                            } : null
                        }}
                        show={showFilter}
                        queryClient={queryClient}
                        onSubmit={(values) => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className='card-body p-6'>
                    <Table
                        isClickableRow
                        columns={taskColumns}
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        defaultSortBy={{
                            id: 'updated_at',
                            desc: true
                        }}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        onRowClick={row => {
                            setShowTaskDetailModal(true)
                            setTaskDetailData(row)
                        }}
                    />
                    
                    {isSuccess && <GeneralPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>
            <TaskDetailModal
                show={showTaskDetailModal}
                onHide={() => {
                    setShowTaskDetailModal(false)
                    setTaskDetailData({})
                }}
                queryKey={tasksQueryKey}
                task={taskDetailData}
            />
        </>
    )
}
