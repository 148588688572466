import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"

import PageError from "../../../../components/Error/PageError"
import PageSpinner from "../../../../components/Spinner/PageSpinner"
import Spinner from "../../../../components/Spinner/Spinner"

import { formatDate } from "../../../../helpers/common"
import { getDummyCertificates, generateDummyCertificate } from "../../../../models/courses/courses"


const DummyCertificateModal = ({ course, show, onHide }) => {
    const queryKey = `course-${course.id}-dummy-certificates`

    const [shouldRefetch, setShouldRefetch] = useState(false)

    const queryClient = useQueryClient()

    const { isLoading, data: { data: certificates } = {}, isError, error } = useQuery([queryKey], () => getDummyCertificates(course.id), {
        refetchInterval: shouldRefetch ? 3000 : false
    })
    const { isLoading: generateIsLoading, mutate: mutateGenerate } = useMutation(certificateId => generateDummyCertificate(course.id, certificateId), {
        onSuccess: () => queryClient.invalidateQueries([queryKey]),
        onError: error => toast.error(error?.response?.data?.message)
    })

    const hideModal = () => {
        queryClient.invalidateQueries([queryKey])
        onHide()
    }

    useEffect(() => {
        if (certificates && certificates.filter(certificate => certificate.status === 'in_progress').length > 0) setShouldRefetch(true)
        else setShouldRefetch(false)
    }, [certificates])

    return (
        <Modal show={show} onHide={() => hideModal()} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Dummy Certificates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className="mb-1">{course?.title}</h4>
                <p className="text-muted mb-5">{course?.main_mentor?.name}</p>
                {isLoading ? <PageSpinner height={100} /> : isError ? <PageError height={100}>{error.message}</PageError> : certificates.map((certificate, i) => (
                    <div key={i} className="border rounded p-6 my-3">
                        <h5>Certificate {certificate.certificate.name}</h5>
                        {(certificate.created_at || certificate.updated_at) && <p className="text-muted mb-0">Last generated: {formatDate(certificate.updated_at || certificate.created_at, true)}</p>}
                        {(certificate.status === 'failed' && certificate.message) && (
                            <p className="text-danger mt-1 mb-0">
                                <i className="fa fa-warning text-danger"></i> {certificate.message}
                            </p>
                        )}
                        
                        <div className="d-flex gap-3 mt-5">
                            {certificate.user_certificate_id && certificate.status === 'success' ? (
                                <>
                                    <div className="flex-fill">
                                        <a href={`${certificate.file_pdf_url}?v=${Date.now()}`} className="btn btn-primary w-100" target="_blank">Download</a>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-light-primary w-100" onClick={() => mutateGenerate(certificate.certificate_id)}><i className="fa fa-refresh"></i></button>
                                    </div>
                                </>
                            ) : (
                                <button type="button" className="btn btn-light-primary w-100" disabled={certificate.status === 'in_progress' || generateIsLoading} onClick={() => mutateGenerate(certificate.certificate_id)}>
                                    Generate {(certificate.status === 'in_progress' || generateIsLoading) && <Spinner size="sm" color="primary" className="ms-2" />}
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    )
}

export default DummyCertificateModal