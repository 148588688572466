import { useFormik } from "formik"
import * as Yup from "yup"
import clsx from "clsx"

import Spinner from "../../../../../components/Spinner/Spinner"
import BasicSection from "./BasicSection"


const schema = Yup.object().shape({
    title: Yup.string().required().min(5).max(255).label('Title')
})

const Item = ({ active, children, onClick }) => (
    <div className={clsx("position-relative border border-gray-400 rounded px-3 py-4 cursor-pointer", active ? 'bg-light-primary' : 'bg-hover-light-primary')} onClick={onClick}>
        {active ? <div className="position-absolute w-20px h-20px bg-primary rounded-circle d-flex justify-content-center align-items-center top-0 end-0" style={{
            marginTop: -5,
            marginRight: -5
        }}><i className="fa fa-check text-white"></i></div> : null}
        <p className="text-gray-700 fw-semibold mb-0 fs-6 text-center">{children}</p>
    </div>
)

const PopupQuizTrueOrFalse = ({ lesson, popupQuiz, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            title: popupQuiz ? popupQuiz.title : '',
            position: popupQuiz ? popupQuiz.position : 'end',
            description: popupQuiz?.description ? popupQuiz.description : '',
            position_duration: popupQuiz?.position_duration ? popupQuiz.position_duration : lesson.duration,
            true_or_false: popupQuiz?.attributes ? popupQuiz.attributes : 'true'
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.type = 'true_or_false'
            if (data.position !== 'custom') delete data.position_duration

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <BasicSection formik={formik} lesson={lesson} />
            <div className="form-group mb-8">
                <label className="form-label required">True or False?</label>
                <div className="row">
                    <div className="col-6">
                        <Item active={formik.values.true_or_false === 'true'} onClick={() => formik.setFieldValue('true_or_false', 'true')}>True</Item>
                    </div>
                    <div className="col-6">
                        <Item active={formik.values.true_or_false === 'false'} onClick={() => formik.setFieldValue('true_or_false', 'false')}>False</Item>
                    </div>
                </div>
                {formik.errors.true_or_false ? <small className="form-text text-danger">{formik.errors.true_or_false}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                {!popupQuiz ? 'Create' : 'Edit'} Popup Quiz {isSubmitLoading ? <Spinner size="sm" /> : null}
            </button>
        </form>
    )
}

export default PopupQuizTrueOrFalse