import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getRevenueChart({ view = 'voucher', courseId = null, pubYear = null, pubMonth = null, pubDate = null }) {
    return axios.get(`${API_URL}/revenues/chart`, {
        params: {
            view,
            course_id: courseId,
            pub_year: pubYear,
            pub_month: pubMonth,
            pub_date: pubDate
        }
    })
}

export function getRevenueTable({ courseId = null, pubYear = null, pubMonth = null, pubDate = null }) {
    return axios.get(`${API_URL}/revenues/table`, {
        params: {
            course_id: courseId,
            pub_year: pubYear,
            pub_month: pubMonth,
            pub_date: pubDate
        }
    })
}

export function getTotalCourse() {
    return axios.get(`${API_URL}/statistic/course`)
}

export function getTotalUser({ startPubDate = null, endPubDate = null }) {
    return axios.get(`${API_URL}/statistic/user`, {
        params: {
            start_pub_date: startPubDate,
            end_pub_date: endPubDate
        }
    })
}

export function getUserChart({ startPubDate = null, endPubDate = null }) {
    return axios.get(`${API_URL}/statistic/user/chart`, {
        params: {
            start_pub_date: startPubDate,
            end_pub_date: endPubDate
        }
    })
}

export function getTotalEnrol() {
    return axios.get(`${API_URL}/statistic/enrol`)
}

export function getTotalSales({ startPubDate = null, endPubDate = null }) {
    return axios.get(`${API_URL}/statistic/sales`, {
        params: {
            start_pub_date: startPubDate,
            end_pub_date: endPubDate
        }
    })
}

export function getSalesChart({ key = 'pub_date', startPubDate = null, endPubDate = null, startPubMonth = null, endPubMonth = null }) {
    return axios.get(`${API_URL}/statistic/sales/chart`, {
        params: {
            key,
            start_pub_date: startPubDate,
            end_pub_date: endPubDate,
            start_pub_month: startPubMonth,
            end_pub_month: endPubMonth
        }
    })
}

export function getTotalRedemption({ pubYear = null }) {
    return axios.get(`${API_URL}/statistic/reports/redemption`, {
        params: {
            pub_year: pubYear
        }
    })
}

export function getTotalCompletion({ pubYear = null }) {
    return axios.get(`${API_URL}/statistic/reports/completion`, {
        params: {
            pub_year: pubYear
        }
    })
}