import AsyncSelect from 'react-select/async'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Field } from 'formik'

import { getCategories } from '../../../../../models/courses/categories'
import { getUsers } from '../../../../../models/users'


const BasicSection = ({ user, formik, queryClient }) => {
    const searchCategory = async search => queryClient.fetchQuery('all-categories', () => getCategories(true, search)).then(res => res.data.map(category => ({
        label: category.name,
        value: category.id,
    })))

    const searchMentor = async search => queryClient.fetchQuery('mentors', () => getUsers({ isMentor: true, name: search })).then(res => res.data.map(mentor => ({
        label: mentor.name,
        value: mentor.id,
    })))

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-8">
                <h3 className="mb-0">Basic</h3>
                <div className="form-check form-switch form-check-custom form-check-solid">
                    <label className="form-check-label fw-semibold me-3">
                        Prakerja Course
                    </label>
                    <Field name="is_prakerja" className="form-check-input" type="checkbox" onChange={e => formik.setFieldValue('is_prakerja', e.target.checked)} />
                </div>
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Course Type</label>
                <select name="type" className="form-select" onChange={formik.handleChange} value={formik.values.type}>
                    <option value="video">Video</option>
                    <option value="webinar">Webinar</option>
                    <option value="offline">Offline</option>
                </select>
                {formik.errors.type ? <small className="form-text text-danger">{formik.errors.type}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Course Title</label>
                <input type="text" name="title" className="form-control" placeholder="Course Title" onChange={formik.handleChange} value={formik.values.title} />
                {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Category</label>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={searchCategory}
                    name="category_id"
                    onChange={option => formik.setFieldValue('category_id', option)}
                    value={formik.values.category_id}
                />
                {formik.errors.category_id ? <small className="form-text text-danger">{formik.errors.category_id}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Main Mentor</label>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={searchMentor}
                    name="mentor_id"
                    onChange={option => formik.setFieldValue('mentor_id', option)}
                    value={formik.values.mentor_id}
                    isDisabled={user.role_id === 'user'}
                />
                {formik.errors.mentor_id ? <small className="form-text text-danger">{formik.errors.mentor_id}</small> : null}
            </div>
            {['admin', 'officer'].includes(user.role_id) ? (
                <div className="form-group mb-4">
                    <label className="form-label">Other Mentor</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        isMulti
                        loadOptions={searchMentor}
                        name="other_mentor_ids"
                        onChange={option => formik.setFieldValue('other_mentor_ids', option)}
                        value={formik.values.other_mentor_ids}
                    />
                    {formik.errors.other_mentor_ids ? <small className="form-text text-danger">{formik.errors.other_mentor_ids}</small> : null}
                </div>
            ) : null}
            <div className="form-group mb-4">
                <label className="form-label">Short Description</label>
                <textarea rows={4} name="short_description" className="form-control" placeholder="Short Description" onChange={formik.handleChange} value={formik.values.short_description} />
                {formik.errors.short_description ? <small className="form-text text-danger">{formik.errors.short_description}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Description</label>
                <ReactQuill theme='snow' name="description" value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                {formik.errors.description ? <small className="form-text text-danger">{formik.errors.description}</small> : null}
            </div>
            <div className="form-group mb-0">
                <label className="form-label">Level</label>
                <select name="level" className="form-select" onChange={formik.handleChange} value={formik.values.level}>
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advanced</option>
                </select>
                {formik.errors.level ? <small className="form-text text-danger">{formik.errors.level}</small> : null}
            </div>
        </>
    )
}

export default BasicSection