const PageError = ({ height = null, children }) => (
    <div style={{
        height: height ? height : 0
    }}>
        <div className="h-100 d-flex justify-content-center align-items-center">
            {children}
        </div>
    </div>
)

export default PageError