export default function UserCell({ user, onPhoneNumberClick }) {
    return (
        <>
            <p className="fw-semibold mb-0">{user.name}</p>
            <p className="text-muted mb-0">{user.email}</p>
            {user.phone_number && (
                <p className="mb-0 text-success cursor-pointer" onClick={() => onPhoneNumberClick(user)}>
                    <i className="fab fa-whatsapp text-success"></i> {user.phone_number}
                </p>
            )}
        </>
    )
}
