import { useState } from 'react'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core"
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from "@dnd-kit/sortable"

import './ContentSection.scss'
import HeadingBlock from './ContentSection/HeadingBlock'
import ImageBlock from './ContentSection/ImageBlock/ImageBlock'
import ParagraphBlock from './ContentSection/ParagraphBlock'
import ImageDetailModal from './ContentSection/ImageBlock/ImageDetailModal'
import EmbedTweetBlock from './ContentSection/EmbedTweetBlock'
import TitleBlock from './ContentSection/TitleBlock'


const ContentSection = ({ blocks, onAddBlock, onRemoveBlock, onChangeBlock, onChangeBlockValue, onCollectiveDeleteFile, isUpdateMode = false }) => {
    const [showImageDetailModal, setShowImageDetailModal] = useState(false)
    const [imageDetailData, setImageDetailData] = useState({})

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const handleDragEnd = event => {
        const { active, over } = event
    
        if (active.id !== over.id) {
            onChangeBlock(blocks => {
                const oldIndex = blocks.findIndex(block => block.id === active.id)
                const newIndex = blocks.findIndex(block => block.id === over.id)

                return arrayMove(blocks, oldIndex, newIndex)
            })
        }
    }

    return (
        <>
            <div className="d-flex flex-column gap-3">
                <TitleBlock
                    blockId={blocks[0].id}
                    value={blocks[0].value}
                    onChange={data => onChangeBlockValue(blocks[0].id, {
                        text: data
                    })}
                />
                <ImageBlock
                    blockId={blocks[1].id}
                    value={blocks[1].value}
                    onChange={data => onChangeBlockValue(blocks[1].id, data)}
                    onShowImageDetailModal={() => {
                        setShowImageDetailModal(true)
                        setImageDetailData({
                            blockId: blocks[1].id
                        })
                    }}
                    onCollectiveDeleteImage={imageId => onCollectiveDeleteFile(imageId)}
                    isThumbnail
                    isUpdateMode={isUpdateMode}
                />
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext items={blocks.slice(2)} strategy={verticalListSortingStrategy}>
                        {blocks.slice(2).map(block => {
                            if (block.type === 'heading') return (
                                <HeadingBlock
                                    key={block.id}
                                    blockId={block.id}
                                    value={block.value}
                                    onChange={data => onChangeBlockValue(block.id, {
                                        text: data
                                    })}
                                    onAttributeChange={data => onChangeBlockValue(block.id, {
                                        ...block.value,
                                        attributes: data 
                                    })}
                                    onRemove={() => onRemoveBlock(block.id)}
                                />
                            )
                            else if (block.type === 'paragraph') return (
                                <ParagraphBlock
                                    key={block.id}
                                    blockId={block.id}
                                    value={block.value}
                                    onChange={data => onChangeBlockValue(block.id, {
                                        text: data
                                    })}
                                    onRemove={() => onRemoveBlock(block.id)}
                                />
                            )
                            else if (block.type === 'image') return (
                                <ImageBlock
                                    key={block.id}
                                    blockId={block.id}
                                    value={block.value}
                                    onChange={data => onChangeBlockValue(block.id, data)}
                                    onRemove={() => onRemoveBlock(block.id)}
                                    onShowImageDetailModal={() => {
                                        setShowImageDetailModal(true)
                                        setImageDetailData({
                                            blockId: block.id
                                        })
                                    }}
                                    onCollectiveDeleteImage={imageId => onCollectiveDeleteFile(imageId)}
                                    isUpdateMode={isUpdateMode}
                                />
                            )
                            else if (block.type === 'embed_tweet') return (
                                <EmbedTweetBlock
                                    key={block.id}
                                    blockId={block.id}
                                    value={block.value}
                                    onChange={data => onChangeBlockValue(block.id, data)}
                                    onRemove={() => onRemoveBlock(block.id)}
                                />
                            )
                        })}
                    </SortableContext>
                </DndContext>
                <div className="text-center p-3 pb-0">
                    <p className="fs-6 text-muted mb-2">Add block</p>
                    <div className="d-flex justify-content-center gap-2">
                        <button type="button" className="btn btn-sm btn-light-primary" onClick={() => onAddBlock('heading')}>Heading</button>
                        <button type="button" className="btn btn-sm btn-light-primary" onClick={() => onAddBlock('paragraph')}>Paragraph</button>
                        <button type="button" className="btn btn-sm btn-light-primary" onClick={() => onAddBlock('image')}>Image</button>
                        <button type="button" className="btn btn-sm btn-light-primary" onClick={() => onAddBlock('embed_tweet')}>Tweet</button>
                    </div>
                </div>
            </div>

            <ImageDetailModal
                show={showImageDetailModal}
                getData={() => {
                    if (!showImageDetailModal) return null

                    const index = blocks.findIndex(block => block.id === imageDetailData.blockId)
                    return blocks[index].value
                }}
                onHide={() => {
                    setShowImageDetailModal(false)
                    setImageDetailData({})
                }}
                onSubmit={data => {
                    const index = blocks.findIndex(block => block.id === imageDetailData.blockId)

                    onChangeBlockValue(imageDetailData.blockId, { ...blocks[index].value, attributes: data })
                    setShowImageDetailModal(false)
                    setImageDetailData({})
                }}
            />
        </>
    )
}

export default ContentSection