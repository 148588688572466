import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/courses'

export function getCourses(title = null, categoryId = null, mentorUserId = null, status = null, page = 1, rows = 10, sortBy = null, types = null) {
    return axios.get(`${API_URL}`, {
        params: {
            title,
            category_id: categoryId,
            mentor_user_id: mentorUserId,
            status,
            types,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getCourse(courseId) {
    return axios.get(`${API_URL}/${courseId}`)
}

export function saveCourse(data) {
    return axios.post(`${API_URL}`, data)
}

export function updateCourse(courseId, data) {
    return axios.post(`${API_URL}/${courseId}`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateCourseBasic(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/basic`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateCoursePricing(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/pricing`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateCourseRequirements(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/requirements`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateCourseOutcomes(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/outcomes`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateCourseOccupationUnits(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/occupation_units`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateCourseCompetencyUnits(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/competency_units`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateCourseCertificates(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/certificates`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateCourseMedia(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/media`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateCourseTanyaAI(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/tanya-ai`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function deleteCourse(courseId) {
    return axios.delete(`${API_URL}/${courseId}`)
}

export function updateCourseHighlight(courseId, highlight) {
    return axios.post(`${API_URL}/${courseId}/highlight`, {
        highlight
    }, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateTopCourse(courseId, isTopCourse) {
    return axios.post(`${API_URL}/${courseId}/top_course`, {
        is_top_course: isTopCourse
    }, {
        params: {
            _method: 'PUT'
        }
    })
}

export function getDummyCertificates(courseId) {
    return axios.get(`${API_URL}/${courseId}/certificates/dummy`)
}

export function generateDummyCertificate(courseId, certificateId) {
    return axios.post(`${API_URL}/${courseId}/certificates/${certificateId}`)
}

export function getSections(courseId, withLessons = false) {
    return axios.get(`${API_URL}/${courseId}/sections`, {
        params: {
            with_lessons: withLessons
        }
    })
}

export function saveSection(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/sections`, data)
}

export function updateSection(courseId, sectionId, data) {
    return axios.put(`${API_URL}/${courseId}/sections/${sectionId}`, data)
}

export function updateSectionSort(courseId, sections) {
    return axios.put(`${API_URL}/${courseId}/sections/sort`, {
        sections
    })
}

export function deleteSection(courseId, sectionId) {
    return axios.delete(`${API_URL}/${courseId}/sections/${sectionId}`)
}

export function getLessons({ courseId, title = null, types = null }) {
    return axios.get(`${API_URL}/${courseId}/lessons`, {
        params: {
            title,
            types
        }
    })
}

export function saveLesson(courseId, sectionId, data) {
    return axios.post(`${API_URL}/${courseId}/sections/${sectionId}/lessons`, data)
}

export function updateLesson(courseId, sectionId, lessonId, data) {
    return axios.post(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function updateLessonSort(courseId, sectionId, lessons) {
    return axios.put(`${API_URL}/${courseId}/sections/${sectionId}/lessons/sort`, {
        lessons
    })
}

export function deleteLesson(courseId, sectionId, lessonId) {
    return axios.delete(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}`)
}

export function getLessonQuestions({ courseId, sectionId, lessonId, packageId = null }) {
    return axios.get(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/quiz`, {
        params: {
            package_id: packageId
        }
    })
}

export function saveQuestion(courseId, sectionId, lessonId, data) {
    return axios.post(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/quiz`, data)
}

export function updateQuestion(courseId, sectionId, lessonId, questionId, data) {
    return axios.put(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/quiz/${questionId}`, data)
}

export function deleteQuestion(courseId, sectionId, lessonId, questionId) {
    return axios.delete(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/quiz/${questionId}`)
}

export function updateQuestionSort(courseId, sectionId, lessonId, questions) {
    return axios.put(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/quiz/sort`, {
        questions
    })
}

export function copyPreTestToPostTest(courseId, sectionId, lessonId) {
    return axios.post(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/quiz/copy-pretest-to-posttest`)
}

export function getLiveSessions({ courseId, title = null, sessionType = null, status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/${courseId}/live_sessions`, {
        params: {
            title,
            session_type: sessionType,
            status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function saveLiveSession(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/live_sessions`, data)
}

export function updateLiveSession(courseId, liveSessionId, data) {
    return axios.post(`${API_URL}/${courseId}/live_sessions/${liveSessionId}`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function deleteLiveSession(courseId, liveSessionId) {
    return axios.delete(`${API_URL}/${courseId}/live_sessions/${liveSessionId}`)
}

export function getCourseMerchants({ courseId, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/${courseId}/merchants`, {
        params: {
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function saveCourseMerchant(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/merchants`, data)
}

export function updateCourseMerchant(courseId, merchantId, data) {
    return axios.post(`${API_URL}/${courseId}/merchants/${merchantId}`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function deleteCourseMerchant(courseId, merchantId) {
    return axios.delete(`${API_URL}/${courseId}/merchants/${merchantId}`)
}

export function getCourseSchedules({ courseId, name = null, status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/${courseId}/schedules`, {
        params: {
            name,
            status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function saveCourseSchedule(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/schedules`, data)
}

export function updateCourseSchedule(courseId, scheduleId, data) {
    return axios.post(`${API_URL}/${courseId}/schedules/${scheduleId}`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function deleteCourseSchedule(courseId, scheduleId) {
    return axios.delete(`${API_URL}/${courseId}/schedules/${scheduleId}`)
}

export function saveCourseScheduleWithSessions(courseId, data) {
    return axios.post(`${API_URL}/${courseId}/schedules/create-with-sessions`, data)
}

export function getCourseSessions({ courseId, scheduleId, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/${courseId}/schedules/${scheduleId}/sessions`, {
        params: {
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function saveCourseSession(courseId, scheduleId, data) {
    return axios.post(`${API_URL}/${courseId}/schedules/${scheduleId}/sessions`, data)
}

export function updateCourseSession(courseId, scheduleId, sessionId, data) {
    return axios.post(`${API_URL}/${courseId}/schedules/${scheduleId}/sessions/${sessionId}`, data, {
        params: {
            _method: 'PUT'
        }
    })
}

export function deleteCourseSession(courseId, scheduleId, sessionId) {
    return axios.delete(`${API_URL}/${courseId}/schedules/${scheduleId}/sessions/${sessionId}`)
}

export function getCourseSessionAllUsers({ courseId, scheduleId, sessionId, keyword = null, status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/${courseId}/schedules/${scheduleId}/sessions/${sessionId}/users/all`, {
        params: {
            keyword,
            status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function updateCourseSessionUsers(courseId, scheduleId, sessionId, data) {
    return axios.post(`${API_URL}/${courseId}/schedules/${scheduleId}/sessions/${sessionId}/users`, data)
}

export function getCourseAllSessions({ courseId = null, scheduleId = null, userId = null, startAt = null, status = null, haveScheduleCode = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/sessions`, {
        params: {
            course_id: courseId,
            schedule_id: scheduleId,
            mentor_user_id: userId,
            start_at: startAt,
            status,
            have_schedule_code: haveScheduleCode ? 1 : 0,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getCourseAllSession(sessionId) {
    return axios.get(`${API_URL}/sessions/${sessionId}`)
}

export function getCourseAllSessionAllUsers({ sessionId, keyword = null, status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/sessions/${sessionId}/users/all`, {
        params: {
            keyword,
            status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function updateCourseAllSessionUsers(sessionId, data) {
    return axios.post(`${API_URL}/sessions/${sessionId}/users`, data)
}

export function getRJQuestions({ courseId, sectionId, lessonId }) {
    return axios.get(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/reflective-journal`)
}
export function saveRJQuestion(courseId, sectionId, lessonId, data) {
    return axios.post(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/reflective-journal`, data)
}
export function updateRJQuestion(courseId, sectionId, lessonId, questionId, data) {
    return axios.put(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/reflective-journal/${questionId}`, data)
}
export function deleteRJQuestion(courseId, sectionId, lessonId, questionId) {
    return axios.delete(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/reflective-journal/${questionId}`)
}
export function updateRJQuestionSort(courseId, sectionId, lessonId, questions) {
    return axios.put(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/reflective-journal/sort`, {
        questions
    })
}

export function getLessonPopupQuizzes({ courseId, sectionId, lessonId }) {
    return axios.get(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/popup-quiz`)
}
export function saveLessonPopupQuiz(courseId, sectionId, lessonId, data) {
    return axios.post(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/popup-quiz`, data)
}
export function updateLessonPopupQuiz(courseId, sectionId, lessonId, popupQuizId, data) {
    return axios.put(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/popup-quiz/${popupQuizId}`, data)
}
export function deleteLessonPopupQuiz(courseId, sectionId, lessonId, popupQuizId) {
    return axios.delete(`${API_URL}/${courseId}/sections/${sectionId}/lessons/${lessonId}/popup-quiz/${popupQuizId}`)
}