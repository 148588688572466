import { Field, FormikProvider, useFormik } from "formik"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useOutletContext } from "react-router-dom"
import { toast } from "react-toastify"
import * as Yup from "yup"

import PageError from "../../../../components/Error/PageError"
import PageSpinner from "../../../../components/Spinner/PageSpinner"
import Spinner from "../../../../components/Spinner/Spinner"

import { getCertificates } from "../../../../models/certificates"
import { updateCourseCertificates } from "../../../../models/courses/courses"


const schema = Yup.object().shape({})

const Certificates = () => {
    const { course } = useOutletContext()
    
    const formik = useFormik({
        initialValues: {
            certificate_ids: course.certificates.map(certificate => certificate.id)
        },
        validationSchema: schema,
        onSubmit: values => mutate(values)
    })
    
    const queryClient = useQueryClient()
    const { isLoading, data: { data: certificates } = {}, isError, error } = useQuery(['certificates'], () => getCertificates())

    const { mutate, isLoading: submitIsLoading } = useMutation(data => updateCourseCertificates(course.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            queryClient.invalidateQueries([`course-${course.id}`])
            toast.success('Success')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <div className="card">
            <div className="card-body">
                <h3 className="mb-8">Certificates</h3>
                {isLoading ? (
                    <PageSpinner height={300} />
                ) : isError ? (
                    <PageError height={300}>{error.message}</PageError>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <FormikProvider value={formik}>
                            <div className="row mb-10">
                                {certificates.map(certificate => (
                                    <div key={certificate.id} className="col-12 col-md-3">
                                        <div className="form-check form-check-custom form-check-solid">
                                            <Field name="certificate_ids" className="form-check-input" type="checkbox" value={certificate.id} id={`certificate-${certificate.id}`} />
                                            <label className="form-check-label" htmlFor={`certificate-${certificate.id}`}>
                                                {certificate.name}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <button type="submit" className="btn btn-primary w-100" disabled={submitIsLoading || !formik.isValid}>
                                Save Changes {submitIsLoading && <Spinner size="sm" className="ms-2" />}
                            </button>
                        </FormikProvider>
                    </form>
                )}
            </div>
        </div>
    )
}

export default Certificates