import { useState } from "react"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { PageTitle } from "../../../../_metronic/layout/core"
import PageError from "../../../components/Error/PageError"
import PageSpinner from "../../../components/Spinner/PageSpinner"
import usePrompt from "../../../hooks/usePrompt"

import { getPost, updatePost } from "../../../models/blog/posts"
import PostForm from "./components/PostForm/PostForm"


const breadcrumbs = [
    {
        title: 'Posts',
        path: '/blog/posts',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const EditPost = () => {
    const { postId } = useParams()

    const [isDirty, setIsDirty] = useState(false)

    const queryClient = useQueryClient()
    const { isLoading, data: { data: post } = {}, isError, error} = useQuery([`blog-post-${postId}`], () => getPost(postId))
    const { mutate, isLoading: submitLoading } = useMutation(data => updatePost(postId, data), {
        onSuccess: () => {
            queryClient.invalidateQueries([`blog-post-${postId}`])
            setIsDirty(false)
            toast.success('Success')
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })

    usePrompt("Are you sure want to leave? Change you made may not be saved.", isDirty)

    return (
        <>
            <div className="min-h-500px">
                <PageTitle breadcrumbs={breadcrumbs}>Edit Post</PageTitle>
                {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : (
                    <PostForm
                        data={post}
                        submitLoading={submitLoading}
                        onSubmit={data => mutate(data)}
                        onDirty={() => setIsDirty(true)}
                    />
                )}
            </div>
        </>
    )
}

export default EditPost