import { Modal } from "react-bootstrap"
import { useQuery, useQueryClient } from "react-query"
import AsyncSelect from "react-select/async"

import PageError from "../../../../../components/Error/PageError"
import PageSpinner from "../../../../../components/Spinner/PageSpinner"
import { getLessonQuestions } from "../../../../../models/courses/courses"
import { getPackages } from "../../../../../models/packages"
import { useEffect, useState } from "react"


const CourseQuestionModal = ({ lesson, show, onHide, onQuestionOrders, onCreateQuestion, onEditQuestion, onDeleteQuestion }) => {
    const [filters, setFilters] = useState({
        package: null
    })
    const queryClient = useQueryClient()

    const { isLoading, isError, error, data: { data: questions } = {} } = useQuery(`lessons-${lesson.id}-questions`, () => getLessonQuestions({
        courseId: lesson.course_id,
        sectionId: lesson.section_id,
        lessonId: lesson.id,
        packageId: filters.package?.value
    }), {
        enabled: !!show
    })

    const searchPackage = async search => queryClient.fetchQuery('packages', () => getPackages({ name: search })).then(res => res.data.map(item => ({
        label: item.name,
        value: item.id,
    })))

    useEffect(() => {
        queryClient.fetchQuery([`lessons-${lesson.id}-questions`])
    }, [filters])

    const createMarkUp = html => ({
        __html: html
    })
    
    const hideModal = () => {
        setFilters({
            package: null
        })
        onHide()
    }

    return (
        <Modal show={show} onHide={hideModal} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Lessons Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between align-items-center mb-5">
                    <h3 className="mb-0 w-50">{lesson?.title}</h3>
                    <div>
                        <div>
                            <button type="button" className="btn btn-sm btn-light-primary me-3" disabled={lesson.quiz_type !== 'general' || isLoading} onClick={() => onQuestionOrders(questions)}>
                                <i className="fa fa-sort"></i> Set Question Orders
                            </button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={onCreateQuestion}>Create Question</button>
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <PageSpinner height={300} />
                ) : isError ? (
                    <PageError height={300}>{error.message}</PageError>
                ) : (
                    <>
                        <div className="form-group mb-2">
                            <AsyncSelect
                                isClearable
                                cacheOptions
                                defaultOptions
                                loadOptions={searchPackage}
                                name="package"
                                placeholder="Filter by Package"
                                onChange={option => setFilters(filters => ({
                                    ...filters,
                                    package: option
                                }))}
                                value={filters.package}
                            />
                        </div>
                        <p className="mb-2 text-muted text-end">{questions?.length} results</p>
                        {questions?.length > 0 ? questions.map((question, i) => (
                            <div key={question.id} className="border rounded px-6 py-3 mb-3">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <span className="badge badge-square badge-primary p-3 me-3">Question {i+1}</span>
                                        {question.package && (
                                            <span className="badge badge-square badge-light-primary p-3 text-capitalize me-3">Package {question.package.name}</span>
                                        )}
                                        <span className="badge badge-square badge-light p-3 text-capitalize">{question.question_type} Choice</span>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-sm px-2 me-3" onClick={() => onEditQuestion(question)}>
                                            <i className="fa fa-pencil text-muted p-0"></i>
                                        </button>
                                        <button type="button" className="btn btn-sm px-2" onClick={() => onDeleteQuestion({ lesson, question })}>
                                            <i className="fa fa-trash text-danger p-0"></i>
                                        </button>
                                    </div>
                                </div>
                                <div dangerouslySetInnerHTML={createMarkUp(question.question)}></div>
                                <div className="row mt-6">
                                    {question.answers?.length > 0 && question.answers.map(answer => (
                                        <div key={answer.id} className="col-12 col-md-6 mb-3 mb-md-3">
                                            <div className={"rounded border p-3 " + (answer.is_correct_answer ? 'bg-light-success' : '')}>
                                                <div dangerouslySetInnerHTML={createMarkUp(answer.answer)}></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )) : null}
                    </>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default CourseQuestionModal