import { useFormik } from "formik"
import * as Yup from "yup"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

import Spinner from "../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    question: Yup.string().required('Question is required').min(5, 'Question must be at least 5 characters')
})

const RJQuestionForm = ({ data, onSubmit, submitLoading }) => {
    const formik = useFormik({
        initialValues: {
            question: data?.question ? data.question : '',
        },
        validationSchema: schema,
        onSubmit
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Question</label>
                <ReactQuill theme='snow' name="question" value={formik.values.question} onChange={value => formik.setFieldValue('question', value)} />
                {formik.errors.question ? <small className="form-text text-danger">{formik.errors.question}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                {data ? 'Update' : 'Create'} Question {submitLoading && <Spinner size="sm" />}
            </button>
        </form>
    )
}

export default RJQuestionForm