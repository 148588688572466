import { useFormik } from "formik"
import AsyncSelect from "react-select/async"
import Collapse from "react-bootstrap/Collapse"

import { getCourses } from "../../../../../models/courses/courses"
import { getMerchants } from "../../../../../models/merchants"
import { getBatches } from "../../../../../models/courses/vouchers"


const VoucherFilter = ({ show, queryClient, initialValues, onSubmit }) => {
    const searchCourse = async search => queryClient.fetchQuery('courses', () => getCourses(search, null, null, null, 1, 100)).then(res => res.data.map(course => ({
        label: course.title,
        value: course.id,
    })))

    const searchMerchant = async search => queryClient.fetchQuery('merchants', () => getMerchants({ name: search, rows: 100 })).then(res => res.data.map(merchant => ({
        label: merchant.name,
        value: merchant.id,
    })))

    const searchBatch = async search => queryClient.fetchQuery('batches', () => getBatches({ title: search, rows: 100 })).then(res => res.data.map(batch => ({
        label: batch.title,
        value: batch.id,
    })))

    const formik = useFormik({
        initialValues: {
            code: '',
            prakerjaOwner: '',
            course: '',
            merchant: '',
            batch: initialValues?.batch ? initialValues.batch : '',
            status: ''
        },
        onSubmit: values => {
            values.courseId = values?.course?.value
            values.merchantId = values?.merchant?.value
            values.batchId = values?.batch?.value

            onSubmit(values)
        }
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-4">
                        <div className="form-group mb-4">
                            <label className="form-label"><small>Voucher Code</small></label>
                            <input type="text" name="code" className="form-control form-control-sm" placeholder="Voucher Code" onChange={formik.handleChange} value={formik.values.code} />
                        </div>
                        <div className="form-group">
                            <label className="form-label"><small>Course</small></label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={searchCourse}
                                name="course"
                                className="form-control-sm p-0"
                                onChange={option => formik.setFieldValue('course', option)}
                                value={formik.values.course}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group mb-4">
                            <label className="form-label"><small>Prakerja Name</small></label>
                            <input type="text" name="prakerjaOwner" className="form-control form-control-sm" placeholder="Prakerja Name" onChange={formik.handleChange} value={formik.values.prakerjaOwner} />
                        </div>
                        <div className="form-group">
                            <label className="form-label"><small>Merchant</small></label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={searchMerchant}
                                name="merchant"
                                className="form-control-sm p-0"
                                onChange={option => formik.setFieldValue('merchant', option)}
                                value={formik.values.merchant}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group mb-4">
                            <label className="form-label"><small>Status</small></label>
                            <select name="status" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.status}>
                                <option value="">All</option>
                                <option value="available">Available</option>
                                <option value="used">Used</option>
                                <option value="expired">Expired</option>
                                <option value="burned">Burned</option>
                                <option value="void">Void</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="form-label"><small>Batch</small></label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={searchBatch}
                                name="batch"
                                className="form-control-sm p-0"
                                onChange={option => formik.setFieldValue('batch', option)}
                                value={formik.values.batch}
                            />
                        </div>
                    </div>
                    <div className="col-12 text-end mt-6">
                        <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                            formik.resetForm()
                            onSubmit({})
                        }}>Clear</button>
                        <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}

export default VoucherFilter