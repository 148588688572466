import { useFormik } from "formik"
import * as Yup from "yup"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import AsyncSelect from "react-select/async"

import Spinner from "../../../../../../components/Spinner/Spinner"
import { getZoomUsers } from "../../../../../../models/zoom"


const schema = Yup.object().shape({
    name: Yup.string().required().min(5).max(255).label('Name'),
    schedule_start: Yup.date().required().nullable().label('Start Date'),
    schedule_end: Yup.date().required().nullable().label('End Date'),
    whatsapp_group_url: Yup.string().url().label('WhatsApp Group URL')
})

const CourseScheduleForm = ({ queryClient, data = null, onSubmit, submitLoading }) => {
    const formik = useFormik({
        initialValues: {
            name: data?.name ? data.name : '',
            schedule_code: data?.schedule_code ? data.schedule_code : '',
            schedule_start: data?.schedule_start ? moment(data.schedule_start).toDate() : '',
            schedule_end: data?.schedule_end ? moment(data.schedule_end).toDate() : '',
            zoom_user: data?.zoom_user ? {
                value: data.zoom_user.id,
                label: `${data.zoom_user.email} - ${data.zoom_user.full_name}`
            } : '',
            whatsapp_group_url: data?.whatsapp_group_url ? data.whatsapp_group_url : ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.zoom_user_id = data.zoom_user?.value || null
            data.schedule_start = moment(values.schedule_start).format('YYYY-MM-DD')
            data.schedule_end = moment(values.schedule_end).format('YYYY-MM-DD')

            onSubmit(data)
        }
    })

    const searchZoomUser = async search => queryClient.fetchQuery("zoom-users", () => getZoomUsers({ email: search })).then(res => res.data.map(user => {
        return {
            label: `${user.email} - ${user.full_name}`,
            value: user.id
        }
    }))

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Schedule Name</label>
                <input type="text" name="name" className="form-control" placeholder="Schedule Name" onChange={formik.handleChange} value={formik.values.name} />
                {formik.errors.name ? <small className="form-text text-danger">{formik.errors.name}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Schedule Code</label>
                <input type="text" name="schedule_code" className="form-control" placeholder="Schedule Code" onChange={formik.handleChange} value={formik.values.schedule_code} />
                {formik.errors.schedule_code ? <small className="form-text text-danger">{formik.errors.schedule_code}</small> : null}
            </div>
            <div className="row mb-4">
                <div className="col-6 form-group">
                    <label className="form-label required">Start Date</label>
                    <ReactDatePicker
                        name="schedule_start"
                        className="form-control"
                        placeholderText="Start Date"
                        onChange={date => formik.setFieldValue('schedule_start', date)}
                        selected={formik.values.schedule_start}
                        startDate={formik.values.schedule_start}
                        endDate={formik.values.schedule_end}
                        dateFormat="yyyy-MM-dd"
                        selectsStart
                        autoComplete="off"
                    />
                    {formik.errors.schedule_start ? <small className="form-text text-danger">{formik.errors.schedule_start}</small> : null}
                </div>
                <div className="col-6 form-group">
                    <label className="form-label required">End Date</label>
                    <ReactDatePicker
                        name="schedule_end"
                        className="form-control"
                        placeholderText="End Date"
                        onChange={date => formik.setFieldValue('schedule_end', date)}
                        selected={formik.values.schedule_end}
                        startDate={formik.values.schedule_start}
                        endDate={formik.values.schedule_end}
                        minDate={formik.values.schedule_start}
                        dateFormat="yyyy-MM-dd"
                        selectsEnd
                        autoComplete="off"
                    />
                    {formik.errors.schedule_end ? <small className="form-text text-danger">{formik.errors.schedule_end}</small> : null}
                </div>
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Zoom Host</label>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={searchZoomUser}
                    name="zoom_user"
                    className="form-control-sm p-0"
                    onChange={option => formik.setFieldValue('zoom_user', option)}
                    value={formik.values.zoom_user}
                    isClearable
                />
                {formik.errors.zoom_user ? <small className="form-text text-danger">{formik.errors.zoom_user}</small> : null}
            </div>
            <div className="form-group mb-8">
                <label className="form-label">WhatsApp Group URL</label>
                <input type="text" name="whatsapp_group_url" className="form-control" placeholder="Whatsapp Group URL" onChange={formik.handleChange} value={formik.values.whatsapp_group_url} />
                {formik.errors.whatsapp_group_url ? <small className="form-text text-danger">{formik.errors.whatsapp_group_url}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                {data ? 'Update' : 'Create'} Schedule {submitLoading && <Spinner size="sm" />}
            </button>
        </form>
    )
}

export default CourseScheduleForm