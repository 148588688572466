import { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useFormik } from "formik"
import * as Yup from 'yup'

import Spinner from "../../../../../components/Spinner/Spinner"
import { useAuth } from "../../../../../modules/auth"

import BasicSection from "./Sections/BasicSection"
import ContentSection from "./Sections/ContentSection"


const schema = Yup.object().shape({
    author: Yup.object().required().nullable().label('Author'),
    category: Yup.object().required().nullable().label('Category'),
})

const PostForm = ({ data = null, submitLoading = false, onSubmit, onDirty }) => {
    const { currentUser: user } = useAuth()
    
    const [blocks, setBlocks] = useState([
        {
            id: 'title',
            type: 'heading',
            value: data?.title ? {
                text: data.title
            } : {}
        }, {
            id: 'thumbnail',
            type: 'image',
            value: data?.thumbnail ? data.thumbnail : null
        }
    ])
    const [collectiveDeleteFile, setCollectiveDeleteFile] = useState([])

    const formik = useFormik({
        initialValues: {
            author: data?.user ? {
                label: data.user.name,
                value: data.user.id
            } : '',
            category: data?.category ? {
                label: data.category.name,
                value: data.category.id
            } : '',
            tags: data?.tags ? data.tags.map(tag => ({
                label: tag.name,
                value: tag.id
            })) : '',
            status: data?.status ? data.status : 'draft'
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {
                user_id: values.author.value,
                category_id: values.category.value,
                status: values.status,
                tag_ids: values.tags ? values.tags.filter(tag => !tag.__isNew__).map(tag => tag.value) : null,
                new_tags: values.tags ? values.tags.filter(tag => tag.__isNew__).map(tag => tag.value) : null,
                title: blocks[0].value?.text,
                thumbnail: blocks[1].value,
                collective_delete_file_ids: collectiveDeleteFile
            }
            data.contents = blocks.slice(2)

            onSubmit(data)
        }
    })

    const queryClient = useQueryClient()

    const generateBlockId = blockType => `${blockType}-${Date.now()}-${Math.floor(Math.random() * 9999) + 1000}`

    const addBlock = blockType => setBlocks(blocks => [...blocks, {
        id: generateBlockId(blockType),
        type: blockType,
        value: {}
    }])

    const removeBlock = blockId => setBlocks(blocks => blocks.filter(block => block.id !== blockId))

    const changeBlockValue = (blockId, value) => setBlocks(blocks => {
        const blocksClone = [...blocks]
        const index = blocksClone.findIndex(block => block.id === blockId)
        if (index < 0) return blocksClone

        if (Object.keys(value) < 1) blocksClone[index].value = {}
        else blocksClone[index].value = {...blocksClone[index].value, ...value}

        return blocksClone
    })

    useEffect(() => {
        if (!data || !data.contents) return () => true

        setBlocks(blocks => {
            const newBlocks = [...blocks]
            data.contents.map(content => {
                if (newBlocks.findIndex(block => block.id === content.attributes.block_id) >= 0) return true

                let value = {}
                switch (content.type) {
                    case 'heading':
                    case 'paragraph':
                        value = {
                            text: content.body,
                            attributes: content.attributes
                        }
                        break
                    case 'image':
                        value = {
                            id: content.attributes.id,
                            file_url: content.body,
                            attributes: content.attributes
                        }
                        break
                    case 'embed_tweet':
                        value = {
                            html: content.body,
                            attributes: content.attributes
                        }
                        break
                }

                return newBlocks.push({
                    id: content.attributes.block_id,
                    type: content.type,
                    value
                })
            })

            return newBlocks
        })
    }, [data])

    useEffect(() => {
        if (blocks[0].value.text || blocks.length > 2 || formik.dirty) onDirty()
    }, [blocks, formik.dirty])

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-md-8">
                <div className="card">
                    <div className="card-body">
                        <ContentSection
                            blocks={blocks}
                            onAddBlock={blockType => addBlock(blockType)}
                            onRemoveBlock={blockId => removeBlock(blockId)}
                            onChangeBlock={setBlocks}
                            onChangeBlockValue={(blockId, value) => changeBlockValue(blockId, value)}
                            onCollectiveDeleteFile={fileId => setCollectiveDeleteFile(prev => [...prev, fileId])}
                            isUpdateMode={!!data}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                            <BasicSection
                                user={user}
                                formik={formik} 
                                queryClient={queryClient}
                            />
                            <div className="separator my-8"></div>
                            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                                {data ? 'Update' : 'Save'} Post {submitLoading ? <Spinner size="sm" className="ms-2" /> : null}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostForm