import { toRupiah } from "../../../helpers/common"


const PriceCell = ({ price, discountedPrice = null }) => {
    if (discountedPrice) {
        return (
            <>
                <p className="mb-0">{toRupiah(discountedPrice)}</p>
                <small className="text-muted"><strike>{toRupiah(price)}</strike></small>
            </>
        )
    }

    return <p className="mb-0">{toRupiah(price)}</p>
}

export default PriceCell