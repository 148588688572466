import NumberCell from "../../../../../../components/Table/cells/Number"
import MerchantCell from "../../../../../../components/Table/cells/Merchant"
import ExternalUrlCell from "../../../../../../components/Table/cells/ExternalUrl"
import ActionsCell from "./ActionsCell"


const courseMerchantColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Merchant',
        accessor: 'merchant',
        Cell: ({ ...props }) => <MerchantCell merchant={props.data[props.row.index].merchant} />
    }, {
        Header: 'Product Code',
        accessor: 'product_code',
        Cell: ({ ...props }) => props.data[props.row.index].product_code ?? '-'
    }, {
        Header: 'Merchant Product ID',
        accessor: 'product_id',
        Cell: ({ ...props }) => props.data[props.row.index].product_id ?? '-'
    }, {
        Header: 'Product URL',
        accessor: 'product_url',
        Cell: ({ ...props }) => <ExternalUrlCell url={props.data[props.row.index].product_url} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        className: 'text-end',
        Cell: ({ ...props }) => <ActionsCell merchant={props.data[props.row.index]} onEdit={props.onEdit} onDelete={props.onDelete} />
    }
]

const columnAliases = {
    number: 'id'
}

export { courseMerchantColumns, columnAliases }