import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { saveQuestion } from "../../../../../models/courses/courses"
import QuestionForm from "./QuestionForm"



const CreateQuestionModal = ({ queryClient, lesson, show, onHide }) => {
    const { mutate, isLoading } = useMutation(data => saveQuestion(lesson.course_id, lesson.section_id, lesson.id, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`lessons-${lesson.id}-questions`])
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="rounded border p-3 bg-light-primary">
                    <p className="mb-0 fw-semibold">{lesson?.title}</p>
                </div>
                <div className="separator my-6"></div>
                <QuestionForm queryClient={queryClient} lesson={lesson} onSubmit={data => mutate(data)} submitLoading={isLoading} />
            </Modal.Body>
        </Modal>
    )
}

export default CreateQuestionModal