import { useRef, useEffect, useState } from "react"
import clsx from "clsx"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import ReactQuill from "react-quill"


const ParagraphBlock = ({ blockId, placeholder = 'Type something...', value, onChange, onRemove }) => {
    const Editor = {
        modules: {
            toolbar: {
                container: `#toolbox-${blockId}`,
            },
            clipboard: {
                matchVisual: false,
            },
        },
        formats: [
            "bold",
            "italic",
            "underline",
            "strike",
            "align",
            "link",
            "list",
            "clean"
        ]
    }

    const contentBlock = useRef(null)

    const [showToolbox, setShowToolbox] = useState(false)

    const {
        attributes: sortAttributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: blockId })

    const style = {
        transform: CSS.Translate.toString(transform),
        transition
    }

    const hideToolbox = e => {
        if (contentBlock.current && !contentBlock.current.contains(e.target)) setShowToolbox(false)
    }

    useEffect(() => {
        document.addEventListener('click', hideToolbox)

        return () => document.removeEventListener('click', hideToolbox)
    }, [])

    return (
        <div className="content-block" ref={setNodeRef} style={style}>
            <div ref={contentBlock}>
                <div id={`toolbox-${blockId}`} className={clsx(
                    "content-toolbox",
                    {
                        "hidden": !showToolbox
                    }
                )}>
                    <button type="button" className="content-toolbox-item" {...sortAttributes} {...listeners}>
                        <i className="fa fa-up-down-left-right"></i>
                    </button>
                    <button type="button" className="content-toolbox-item divider ql-bold">
                        <i className="fa fa-bold"></i>
                    </button>
                    <button type="button" className="content-toolbox-item ql-italic">
                        <i className="fa fa-italic"></i>
                    </button>
                    <button type="button" className="content-toolbox-item ql-underline">
                        <i className="fa fa-underline"></i>
                    </button>
                    <button type="button" className="content-toolbox-item ql-strike">
                        <i className="fa fa-strikethrough"></i>
                    </button>
                    <button type="button" className="content-toolbox-item ql-link">
                        <i className="fa fa-link"></i>
                    </button>
                    <button type="button" className="content-toolbox-item divider ql-align" value="center">
                        <i className="fa fa-align-center"></i>
                    </button>
                    <button type="button" className="content-toolbox-item ql-align" value="right">
                        <i className="fa fa-align-right"></i>
                    </button>
                    <button type="button" className="content-toolbox-item ql-align" value="justify">
                        <i className="fa fa-align-justify"></i>
                    </button>
                    <button type="button" className="content-toolbox-item divider ql-list" value="ordered">
                        <i className="fa fa-list-ol"></i>
                    </button>
                    <button type="button" className="content-toolbox-item ql-list" value="bullet">
                        <i className="fa fa-list"></i>
                    </button>
                    <button type="button" className="content-toolbox-item divider ql-clean" value="bullet">
                        <i className="fa fa-text-slash"></i>
                    </button>
                    <button type="button" className="content-toolbox-item divider" title="Remove block" onClick={onRemove}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
                <ReactQuill
                    modules={Editor.modules}
                    formats={Editor.formats}
                    theme={false}
                    className="form-content"
                    placeholder={placeholder}
                    value={value?.text}
                    onFocus={() => setShowToolbox(true)}
                    onChange={value => onChange(value)}
                />
            </div>
        </div>
    )
}

export default ParagraphBlock