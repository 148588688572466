import { Link } from "react-router-dom"
import { merchantPicture } from "../../../../helpers/pictures"


const MerchantCell = ({ merchant }) => (
    <Link to={'/merchants/' + merchant.id + '/edit'}>
        <div className="d-flex align-items-center text-dark text-hover-primary">
            <img className="rounded w-30px" src={merchantPicture(merchant.picture_url)} alt="Thumbnail" />
            <div className="ms-3">
                <p className="mb-0 fw-bold">{merchant.name}</p>
            </div>
        </div>
    </Link>
)

export default MerchantCell