import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { PageTitle } from "../../../../_metronic/layout/core"
import Authorization from "../../../routing/Authorization"
import DeleteConfirmModal from "../../../components/DeleteConfirmModal"
import GeneralPagination from "../../../components/Pagination/GeneralPagination"

import { getCourses, deleteCourse, updateCourse, updateCourseHighlight, updateTopCourse } from "../../../models/courses/courses"
import CourseTable from "./components/CourseTable/Table"
import { columnAliases } from "./components/CourseTable/_columns"
import CourseFilter from "./components/CourseTable/CourseFilter"
import CreateSimpleCourseModal from "./components/CreateSimpleCourseModal"
import DummyCertificateModal from "./components/DummyCertificateModal"


const Courses = () => {
    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showCreateCourseModal, setShowCreateCourseModal] = useState(false)
    const [showDummyCertificateModal, setShowDummyCertificateModal] = useState(false)
    const [dummyCertificateData, setDummyCertificateData] = useState({})
    const [showDeleteCourseModal, setShowDeleteCourseModal] = useState()
    const [deleteCourseTitle, setDeleteCourseTitle] = useState()

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery(['courses'], () => getCourses(filters?.title, filters?.category_id, filters?.mentor_user_id, filters?.status, page, pageSize, sortBy))
    const { mutate: deleteCourseMutate, isLoading: isDeleteCourseLoading } = useMutation(courseId => deleteCourse(courseId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries(['courses'])
        },
        onError: error => toast.error(error.message)
    })
    const { mutate: highlightCourseMutate } = useMutation(data => updateCourseHighlight(data.courseId, data.highlight), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries(['courses'])
        },
        onError: error => toast.error(error.message)
    })
    const { mutate: topCourseMutate } = useMutation(data => updateTopCourse(data.courseId, data.is_top_course), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries(['courses'])
        },
        onError: error => toast.error(error.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(['courses'])
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <div className="min-h-500px">
                <PageTitle>Courses</PageTitle>
                <div className="card">
                    <div className="card-header p-6 d-block">
                        <div className="d-flex justify-content-between">
                            <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowCreateCourseModal(true)}>Create Course</button>
                        </div>
                        <CourseFilter
                            show={showFilter}
                            queryClient={queryClient}
                            onSubmit={values => {
                                setPage(1)
                                setFilters(values)
                            }}
                        />
                    </div>
                    <div className="card-body p-6">
                        <CourseTable
                            isLoading={isFetching}
                            isError={isError}
                            error={error}
                            data={!isLoading ? data.data : []}
                            currentPage={page}
                            pageSize={pageSize}
                            onSortByChange={sortBy => setSortBy(() => {
                                if (sortBy.length) return {
                                    id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                    direction: sortBy[0].desc ? 'DESC' : 'ASC'
                                }
                        
                                return {}
                            })}
                            onHighlight={(courseId, highlight) => highlightCourseMutate({
                                courseId, highlight: highlight ? 0 : 1
                            })}
                            onToggleTopCourse={(courseId, isTopCourse) => topCourseMutate({
                                courseId, is_top_course: isTopCourse ? 0 : 1
                            })}
                            onDummyCertificate={course => {
                                setDummyCertificateData(course)
                                setShowDummyCertificateModal(true)
                            }}
                            onDelete={(courseId, courseTitle) => {
                                setShowDeleteCourseModal(courseId)
                                setDeleteCourseTitle(courseTitle)
                            }}
                        />
                        {isSuccess && <GeneralPagination
                            onPageChange={page => setPage(page)}
                            totalCount={data?.meta.total}
                            currentPage={page}
                            pageSize={pageSize}
                            from={data?.meta.from}
                            to={data?.meta.to}
                        />}
                    </div>
                </div>
            </div>

            <CreateSimpleCourseModal
                show={showCreateCourseModal}
                onHide={() => setShowCreateCourseModal(false)}
            />

            <DummyCertificateModal
                course={dummyCertificateData}
                show={showDummyCertificateModal}
                onHide={() => {
                    setShowDummyCertificateModal(false)
                }}
            />

            <DeleteConfirmModal
                show={showDeleteCourseModal}
                handleClose={() => setShowDeleteCourseModal(false)}
                onConfirm={() => {
                    deleteCourseMutate(showDeleteCourseModal)
                    setShowDeleteCourseModal(false)
                }}
                isConfirmLoading={isDeleteCourseLoading}
                name={deleteCourseTitle}
            />
        </>
    )
}

export default Authorization(Courses, ['admin', 'officer', 'user'])