import { useState } from "react"

import { PageTitle } from "../../../../_metronic/layout/core"
import Authorization from "../../../routing/Authorization"

import RevenueFilter from "./components/RevenueFilter"
import RevenueChart from "./components/RevenueChart"
import RevenueTable from "./components/RevenueTable/RevenueTable"


const RevenueStatistic = () => {
    const [filters, setFilters] = useState({})

    return (
        <>
            <PageTitle>Revenue Statistic</PageTitle>
            <RevenueFilter onSubmit={values => setFilters(values)} />
            <div className="my-6"></div>
            <RevenueChart filters={filters} />
            <div className="my-6"></div>
            <RevenueTable filters={filters} />
        </>
    )
}

export default Authorization(RevenueStatistic, ['admin', 'user'])