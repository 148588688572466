import NumberCell from "../../../../../components/Table/cells/Number"
import OwnerCell from "./OwnerCell"
import CategoryCell from "./CategoryCell"
import PriceCell from "../../../../../components/Table/cells/Price"
import StatusCell from "./StatusCell"
import ActionsCell from "./ActionsCell"


const productColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Product',
        accessor: 'product',
        Cell: ({ ...props }) => props.data[props.row.index].title
    }, {
        Header: 'Owner',
        accessor: 'owner',
        Cell: ({ ...props }) => <OwnerCell user={props.data[props.row.index].user} />
    }, {
        Header: 'Category',
        accessor: 'category',
        Cell: ({ ...props }) => <CategoryCell category={props.data[props.row.index].category} />
    }, {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ ...props }) => <PriceCell price={props.data[props.row.index].price} discountedPrice={props.data[props.row.index].discounted_price} />
    }, {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        className: 'text-end',
        Cell: ({ ...props }) => <ActionsCell product={props.data[props.row.index]} onEdit={props.onEdit} onDelete={props.onDelete} />
    }
]

const columnAliases = {
    number: 'id',
    product: 'title'
}

export { productColumns, columnAliases }