import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/products'

export function getProducts({ title = null, categoryId = null, status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}`, {
        params: {
            title,
            category_id: categoryId,
            status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getProduct(productId) {
    return axios.get(`${API_URL}/${productId}`)
}


export function saveProduct(data) {
    return axios.post(`${API_URL}`, data)
}

export function updateProduct(productId, data) {
    return axios.put(`${API_URL}/${productId}`, data)
}

export function deleteProduct(productId) {
    return axios.delete(`${API_URL}/${productId}`)
}