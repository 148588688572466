import { useEffect } from "react"
import { Link } from "react-router-dom"
import { MenuComponent } from "../../../../../../_metronic/assets/ts/components"


const ActionsCell = ({ post, onPreview, onDelete }) => {
    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                <i className="fa fa-ellipsis-vertical pe-0"></i>
            </a>
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-4'
                data-kt-menu='true'
            >
                <div className="menu-item px-3">
                    <span className="menu-link px-3" onClick={() => onPreview(post.id)}>
                        Preview HTML
                    </span>
                </div>
                <div className="menu-item px-3">
                    <Link to={`/blog/posts/${post.id}`} className="menu-link px-3">
                        Edit
                    </Link>
                </div>
                <div className="menu-item px-3">
                    <span className="menu-link px-3" onClick={() => onDelete(post)}>
                        Delete
                    </span>
                </div>
            </div>
        </>
    )
}

export default ActionsCell