import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { PageTitle } from "../../../_metronic/layout/core"
import DeleteConfirmModal from "../../components/DeleteConfirmModal"
import GeneralPagination from "../../components/Pagination/GeneralPagination"
import { deleteMerchant, getMerchants } from "../../models/merchants"
import Authorization from "../../routing/Authorization"
import MerchantsTable from "./components/MerchantTable/Table"
import { columnAliases } from "./components/MerchantTable/_columns"

const Merchants = () => {
    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showDeleteMerchantModal, setShowDeleteMerchantModal] = useState()
    const [deleteCourseTitle, setDeleteCourseTitle] = useState()

    const queryClient = useQueryClient()
    const { isLoading, isError, error, data, isSuccess } = useQuery(['merchants'], () => getMerchants({page, rows: pageSize, sortBy}))
    const { mutate: deleteMerchantMutate, isLoading: isDeleteMerchantLoading } = useMutation(merchantId => deleteMerchant(merchantId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries(['merchants'])
        },
        onError: error => toast.error(error.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(['merchants'])
    }, [queryClient, filters, page, sortBy])


    return (
        <>
            <div className="min-h-500px">
                <PageTitle>Merchant</PageTitle>
                <div className="card">
                    <div className="card-header p-6 d-block">
                        <div className="d-flex justify-content-end">
                            <Link to="/merchants/create">
                                <button className="btn btn-sm btn-primary">Create Merchant</button>
                            </Link>
                        </div>
                    </div>
                    <div className="card-body p-6">
                        <MerchantsTable
                            isLoading={isLoading}
                            isError={isError}
                            error={error}
                            data={!isLoading ? data.data : []}
                            currentPage={page}
                            pageSize={pageSize}
                            onSortByChange={sortBy => setSortBy(() => {
                                if (sortBy.length) return {
                                    id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                    direction: sortBy[0].desc ? 'DESC' : 'ASC'
                                }
                        
                                return {}
                            })}
                            onDelete={(merchantId, courseTitle) => {
                                setShowDeleteMerchantModal(merchantId)
                                setDeleteCourseTitle(courseTitle)
                            }}
                        />
                        {isSuccess && <GeneralPagination
                            onPageChange={page => setPage(page)}
                            totalCount={data?.meta.total}
                            currentPage={page}
                            pageSize={pageSize}
                            from={data?.meta.from}
                            to={data?.meta.to}
                        />}
                    </div>
                </div>
            </div>

            <DeleteConfirmModal
                show={showDeleteMerchantModal}
                handleClose={() => setShowDeleteMerchantModal(false)}
                onConfirm={() => {
                    deleteMerchantMutate(showDeleteMerchantModal)
                    setShowDeleteMerchantModal(false)
                }}
                isConfirmLoading={isDeleteMerchantLoading}
                name={deleteCourseTitle}
            />
        </>
    )
}

export default Authorization(Merchants, ['admin', 'officer'])