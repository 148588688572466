import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"

import { PageTitle } from "../../../../_metronic/layout/core"
import Authorization from "../../../routing/Authorization"

import { getCategories, deleteCategory, updateCategoriesSort } from "../../../models/blog/categories"
import DeleteConfirmModal from "../../../components/DeleteConfirmModal"
import PageSpinner from "../../../components/Spinner/PageSpinner"
import PageError from "../../../components/Error/PageError"
import SortModal from "../../../components/SortModal"
import CreateCategoryModal from "./components/CreateCategoryModal"
import EditCategoryModal from "./components/EditCategoryModal"


const Categories = () => {
    const queryKey = 'blog-categories'

    const [showActionButton, setShowActionButton] = useState()

    const [showCategoryOrderModal, setShowCategoryOrderModal] = useState()

    const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false)
    const [createCategoryData, setCreateCategoryData] = useState({})

    const [showEditCategoryModal, setShowEditCategoryModal] = useState(false)
    const [editCategoryData, setEditCategoryData] = useState({})

    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false)
    const [deleteCategoryData, setDeleteCategoryData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isError, data: { data: categories } = {}, error } = useQuery([queryKey], () => getCategories())
    const { mutate: categoryOrderMutate, isLoading: categoryOrderLoading } = useMutation(categories => {
        if (!categories) return true

        const data = {}
        categories.map((category, i) => data[category.id] = i + 1)

        return updateCategoriesSort(data)
    }, {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([queryKey])
            setShowCategoryOrderModal(false)
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })
    const { mutate: deleteCategoryMutate, isLoading: isDeleteCategoryLoading } = useMutation(categoryId => deleteCategory(categoryId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([queryKey])

            setShowDeleteCategoryModal(false)
            setDeleteCategoryData({})
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })

    const getSortModalProps = () => {
        let initialData = categories;
        if (showCategoryOrderModal && showCategoryOrderModal !== true) {
            const selectedCategoryIndex = categories.findIndex(category => category.id === showCategoryOrderModal)
            if (selectedCategoryIndex < 0) initialData = []

            initialData = categories[selectedCategoryIndex].sub_category
        }

        return {
            show: showCategoryOrderModal,
            modalTitle: 'Set Category Orders',
            initialData,
            titleKey: 'name',
            isLoading,
            submitLoading: categoryOrderLoading,
            onSubmit: data => categoryOrderMutate(data),
            onHide: () => setShowCategoryOrderModal(false)
        }
    }

    return (
        <>
            <PageTitle>Blog Categories</PageTitle>
            <div className="card">
                <div className="card-body p-6 d-flex justify-content-between">
                    <button type="button" className="btn btn-sm btn-light-primary" onClick={() => setShowCategoryOrderModal(true)}><i className="fa fa-sort"></i> Set Category Orders</button>
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowCreateCategoryModal(true)}>Create Category</button>
                </div>
            </div>
            <div className="row min-h-450px">
                {isLoading ? (
                    <PageSpinner height={300} color='primary' />
                ) : isError ? (
                    <div className="h-300px">
                        <PageError height={300}>{error.message}</PageError>
                    </div>
                ) : categories.map(category => ((
                    <div className="col-12 col-md-4 mt-5" key={category.id}>
                        <div className="card">
                            <div className="card-body p-6" onMouseOver={() => setShowActionButton(category.id)} onMouseOut={() => setShowActionButton(false)}>
                                <h3>{category.name}</h3>
                                <p className="mb-0 text-muted">{category.sub_category.length} Sub Categories</p>

                                {showActionButton === category.id && (
                                    <div className="position-absolute top-0 end-0 py-4">
                                        <span className="cursor-pointer px-2" onClick={() => {
                                            setCreateCategoryData(category)
                                            setShowCreateCategoryModal(true)
                                        }}><i className="fa fa-plus text-primary"></i></span>
                                        {category.sub_category.length > 0 && (
                                            <span className="cursor-pointer px-2" onClick={() => setShowCategoryOrderModal(category.id)}><i className="fa fa-sort"></i></span>
                                        )}
                                        <span className="cursor-pointer px-2" onClick={() => {
                                            setEditCategoryData(category)
                                            setShowEditCategoryModal(true)
                                        }}><i className="fa fa-pencil"></i></span>
                                        <span className="cursor-pointer ps-2 pe-4" onClick={() => {
                                            setShowDeleteCategoryModal(true)
                                            setDeleteCategoryData(category)
                                        }}><i className="fa fa-trash text-danger"></i></span>
                                    </div>
                                )}
                            </div>
                            <div>
                                {category.sub_category.map(subCategory => (
                                    <div key={subCategory.id} className="py-4 px-6 border-top position-relative" onMouseOver={() => setShowActionButton(subCategory.id)} onMouseOut={() => setShowActionButton(false)}>
                                        <p className="mb-0">{subCategory.name}</p>
                                        {showActionButton === subCategory.id && (
                                            <div className="position-absolute top-0 end-0 py-4">
                                                <span className="cursor-pointer px-2" onClick={() => {
                                                    setEditCategoryData(subCategory)
                                                    setShowEditCategoryModal(true)
                                                }}><i className="fa fa-pencil"></i></span>
                                                <span className="cursor-pointer ps-2 pe-4" onClick={() => {
                                                    setShowDeleteCategoryModal(true)
                                                    setDeleteCategoryData(subCategory)
                                                }}><i className="fa fa-trash text-danger"></i></span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )))}
            </div>

            <SortModal {...getSortModalProps()} />
            <CreateCategoryModal
                queryClient={queryClient}
                queryKey={queryKey}
                parentCategory={createCategoryData}
                show={showCreateCategoryModal}
                onHide={() => {
                    setShowCreateCategoryModal(false)
                    setCreateCategoryData({})
                }}
            />
            <EditCategoryModal
                queryClient={queryClient}
                queryKey={queryKey}
                category={editCategoryData}
                show={showEditCategoryModal}
                onHide={() => {
                    setShowEditCategoryModal(false)
                    setEditCategoryData({})
                }}
            />
            <DeleteConfirmModal
                show={showDeleteCategoryModal}
                handleClose={() => {
                    setShowDeleteCategoryModal(false)
                    setDeleteCategoryData({})
                }}
                onConfirm={() => deleteCategoryMutate(deleteCategoryData.id)}
                isConfirmLoading={isDeleteCategoryLoading}
                name={deleteCategoryData?.name}
            />
        </>
    )
}

export default Authorization(Categories, ['admin', 'officer'])