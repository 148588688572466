import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

export function getSettings(page = 1, rows = 10, sortBy = null) {
    return axios.get(`${API_URL}/settings`, {
        params: {
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getSetting(settingId) {
    return axios.get(`${API_URL}/settings/${settingId}`)
}

export function saveSetting(data) {
    return axios.post(`${API_URL}/settings`, data)
}

export function updateSetting(settingId, data) {
    return axios.put(`${API_URL}/settings/${settingId}`, data)
}

export function deleteSetting(settingId) {
    return axios.delete(`${API_URL}/settings/${settingId}`)
}
