import { useFormik } from "formik"
import * as Yup from "yup"
import AsyncSelect from "react-select/async"
import { useQueryClient } from "react-query"

import Spinner from "../../../../../components/Spinner/Spinner"

import { getMerchants } from "../../../../../models/merchants"


const schema = Yup.object().shape({
    merchant: Yup.object().required().label('Merchant'),
    product_url: Yup.string().required().url().label('Product URL')
})

const CourseMerchantForm = ({ courseMerchant, onSubmit, submitLoading = false }) => {
    const queryClient = useQueryClient()

    const searchMerchant = async search => queryClient.fetchQuery('merchants', () => getMerchants({ name: search, rows: 100 })).then(res => res.data.map(merchant => ({
        label: merchant.name,
        value: merchant.id,
    })))

    const formik = useFormik({
        initialValues: {
            merchant: courseMerchant?.merchant ? {
                label: courseMerchant.merchant.name,
                value: courseMerchant.merchant.id
            } : '',
            product_code: courseMerchant?.product_code ? courseMerchant.product_code : '',
            product_id: courseMerchant?.product_id ? courseMerchant.product_id : '',
            product_url: courseMerchant?.product_url ? courseMerchant.product_url : ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.merchant_id = data.merchant.value

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required"><small>Merchant</small></label>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={searchMerchant}
                    name="merchant"
                    className="form-control-sm p-0"
                    onChange={option => formik.setFieldValue('merchant', option)}
                    value={formik.values.merchant}
                    isDisabled={!!courseMerchant}
                />
                {formik.errors.merchant ? <small className="form-text text-danger">{formik.errors.merchant}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Product Code</label>
                <input type="text" name="product_code" className="form-control" placeholder="Product Code" value={formik.values.product_code} onChange={formik.handleChange} />
                {formik.errors.product_code ? <small className="form-text text-danger">{formik.errors.product_code}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Merchant Product ID</label>
                <input type="text" name="product_id" className="form-control" placeholder="Merchant Product ID" value={formik.values.product_id} onChange={formik.handleChange} />
                {formik.errors.product_id ? <small className="form-text text-danger">{formik.errors.product_id}</small> : null}
            </div>
            <div className="form-group mb-8">
                <label className="form-label required">Product URL</label>
                <input type="text" name="product_url" className="form-control" placeholder="Product URL" value={formik.values.product_url} onChange={formik.handleChange} />
                {formik.errors.product_url ? <small className="form-text text-danger">{formik.errors.product_url}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                {courseMerchant ? 'Update' : 'Add'} Merchant {submitLoading ? <Spinner size="sm" className="ms-2" /> : null}
            </button>
        </form>
    )
}

export default CourseMerchantForm