import Spinner from './Spinner'

const PageSpinner = ({ size = 'lg', color = 'primary', height = null, className }, props) => (
    <div className="d-flex justify-content-center align-items-center" style={{
        height: height ? `${height}px` : 0
    }}>
        <Spinner size={size} color={color} className={className} {...props} />
    </div>
)

export default PageSpinner