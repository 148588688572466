import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/coupons'

export function getBatches({ title = null, merchantId = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/batches`, {
        params: {
            title,
            merchant_id: merchantId,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function generateCoupons(data) {
    return axios.post(`${API_URL}/batches`, data)
}

export function getCoupons({ code = null, courseId = null, merchantId = null, batchId = null, status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}`, {
        params: {
            code,
            course_id: courseId,
            merchant_id: merchantId,
            batch_id: batchId,
            status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getCoupon(couponId) {
    return axios.get(`${API_URL}/${couponId}`)
}

export function saveCoupon(data) {
    return axios.post(`${API_URL}`, data)
}

export function updateCoupon(couponId, data) {
    return axios.put(`${API_URL}/${couponId}`, data)
}

export function deleteCoupon(couponId) {
    return axios.delete(`${API_URL}/${couponId}`)
}
