import clsx from "clsx"
import { useFormik } from "formik"
import { useMutation, useQueryClient } from "react-query"
import { useOutletContext } from "react-router-dom"
import Select from "react-select"
import SelectCreatable from "react-select/creatable"
import AsyncSelect from "react-select/async"
import { toast } from "react-toastify"
import ReactQuill from 'react-quill'
import * as Yup from "yup"
import 'react-quill/dist/quill.snow.css'

import Spinner from "../../../../components/Spinner/Spinner"
import { useAuth } from "../../../../modules/auth"

import { getCategories } from "../../../../models/courses/categories"
import { updateCourseBasic } from "../../../../models/courses/courses"
import { getUsers } from "../../../../models/users"
import usePrompt from "../../../../hooks/usePrompt"


const schema = Yup.object().shape({
    course_type: Yup.string().required().label('Course type'),
    course_method: Yup.string().required().label('Course method'),
    title: Yup.string().required().label('Title'),
    category: Yup.object().required().nullable().label('Category'),
    mentor: Yup.object().required().nullable().label('Main Mentor'),
})

const CourseTypeItem = ({ active, children, onClick }) => (
    <div className={clsx("position-relative border border-gray-400 rounded px-3 py-4 cursor-pointer", active ? 'bg-light-primary' : 'bg-hover-light-primary')} onClick={onClick}>
        {active ? <div className="position-absolute w-20px h-20px bg-primary rounded-circle d-flex justify-content-center align-items-center top-0 end-0" style={{
            marginTop: -5,
            marginRight: -5
        }}><i className="fa fa-check text-white"></i></div> : null}
        <p className="text-gray-700 fw-semibold mb-0 fs-6 text-center">{children}</p>
    </div>
)

const BasicInfo = () => {
    const { currentUser: user } = useAuth()
    const { course } = useOutletContext()
    
    const formik = useFormik({
        initialValues: {
            course_type: course.is_prakerja ? 'prakerja' : 'general',
            course_method: course.type,
            title: course.title,
            category: {
                label: course.category.name,
                value: course.category.id
            },
            mentor: {
                label: course.main_mentor?.name,
                value: course.main_mentor?.id
            },
            other_mentor: course.other_mentor.map(mentor => ({
                label: mentor.name,
                value: mentor.id
            })),
            level: {
                label: course.level.charAt(0).toUpperCase() + course.level.slice(1),
                value: course.level
            },
            short_description: course.short_description,
            description: course.description,
            tags: course.tags.map(tag => ({
                label: tag,
                value: tag
            }))
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {
                is_prakerja: values.course_type === 'prakerja' ? 1 : 0,
                type: values.course_method,
                title: values.title,
                category_id: values.category.value,
                mentor_user_ids: {},
                level: values.level.value,
                short_description: values.short_description,
                description: values.description,
                tags: values.tags ? values.tags.map(tag => tag.value) : []
            }

            if (user.role_id === 'user') data.mentor_user_ids[user.id] = 1
            else {
                data.mentor_user_ids = {
                    [values.mentor.value]: 1
                }
                if (values.other_mentor) values.other_mentor.map(mentor => {
                    if (!(mentor.value in data.mentor_user_ids)) data.mentor_user_ids[mentor.value] = 0

                    return mentor
                })
            }

            mutate(data)
        }
    })
    
    const queryClient = useQueryClient()

    const searchCategory = async search => queryClient.fetchQuery('all-categories', () => getCategories(true, search)).then(res => res.data.map(category => ({
        label: category.name,
        value: category.id,
    })))
    
    const searchMentor = async search => queryClient.fetchQuery('mentors', () => getUsers({ isMentor: true, name: search })).then(res => res.data.map(mentor => ({
        label: mentor.name,
        value: mentor.id,
    })))

    const { mutate, isLoading } = useMutation(data => updateCourseBasic(course.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            queryClient.invalidateQueries([`course-${course.id}`])
            toast.success('Success')
            formik.resetForm({ values: formik.values })
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    usePrompt("Are you sure want to leave? Change you made may not be saved.", formik.dirty)

    return (
        <div className="card">
            <div className="card-body">
                <h3 className="mb-8">Basic Info</h3>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-5">
                        <label className="form-label required">Course Type</label>
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <CourseTypeItem active={formik.values.course_type === 'general'} onClick={() => formik.setFieldValue('course_type', 'general')}>General</CourseTypeItem>
                            </div>
                            <div className="col-12 col-md-6">
                                <CourseTypeItem active={formik.values.course_type === 'prakerja'} onClick={() => formik.setFieldValue('course_type', 'prakerja')}>Kartu Prakerja</CourseTypeItem>
                            </div>
                        </div>
                        {formik.errors.course_method ? <small className="form-text text-danger">{formik.errors.course_method}</small> : null}
                    </div>
                    <div className="form-group mb-5">
                        <label className="form-label required">Course Method</label>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <CourseTypeItem active={formik.values.course_method === 'video'} onClick={() => formik.setFieldValue('course_method', 'video')}>Video</CourseTypeItem>
                            </div>
                            <div className="col-12 col-md-3">
                                <CourseTypeItem active={formik.values.course_method === 'video_spl'} onClick={() => formik.setFieldValue('course_method', 'video_spl')}>Video SPL</CourseTypeItem>
                            </div>
                            <div className="col-12 col-md-3">
                                <CourseTypeItem active={formik.values.course_method === 'webinar'} onClick={() => formik.setFieldValue('course_method', 'webinar')}>Webinar</CourseTypeItem>
                            </div>
                            <div className="col-12 col-md-3">
                                <CourseTypeItem active={formik.values.course_method === 'offline'} onClick={() => formik.setFieldValue('course_method', 'offline')}>Offline</CourseTypeItem>
                            </div>
                        </div>
                        {formik.errors.course_method ? <small className="form-text text-danger">{formik.errors.course_method}</small> : null}
                    </div>
                    <div className="form-group mb-5">
                        <label className="form-label required">Title</label>
                        <input type="text" name="title" className="form-control" placeholder="Title" value={formik.values.title} onChange={formik.handleChange} />
                        {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
                    </div>
                    <div className="form-group mb-5">
                        <label className="form-label required">Category</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={searchCategory}
                            name="category"
                            onChange={option => formik.setFieldValue('category', option)}
                            value={formik.values.category}
                        />
                        {formik.errors.category ? <small className="form-text text-danger">{formik.errors.category}</small> : null}
                    </div>
                    <div className="form-group mb-5">
                        <label className="form-label required">Main Mentor</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={searchMentor}
                            name="mentor"
                            onChange={option => formik.setFieldValue('mentor', option)}
                            value={formik.values.mentor}
                            isDisabled={user.role_id === 'user'}
                        />
                        {formik.errors.mentor ? <small className="form-text text-danger">{formik.errors.mentor}</small> : null}
                    </div>
                    {['admin', 'officer'].includes(user.role_id) ? (
                        <div className="form-group mb-5">
                            <label className="form-label">Other Mentor</label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                isMulti
                                loadOptions={searchMentor}
                                name="other_mentor"
                                onChange={option => formik.setFieldValue('other_mentor', option)}
                                value={formik.values.other_mentor}
                            />
                            {formik.errors.other_mentor ? <small className="form-text text-danger">{formik.errors.other_mentor}</small> : null}
                        </div>
                    ) : null}
                    <div className="form-group mb-5">
                        <label className="form-label">Level</label>
                        <Select
                            isClearable
                            isSearchable={false}
                            name="level"
                            onChange={option => formik.setFieldValue('level', option)}
                            value={formik.values.level}
                            options={[
                                { label: 'Beginner', value: 'beginner' },
                                { label: 'Intermediate', value: 'intermediate' },
                                { label: 'Advanced', value: 'advanced' },
                            ]}
                        />
                        {formik.errors.level ? <small className="form-text text-danger">{formik.errors.level}</small> : null}
                    </div>
                    <div className="form-group mb-5">
                        <label className="form-label">Short Description</label>
                        <textarea rows={4} name="short_description" className="form-control" placeholder="Short Description" onChange={formik.handleChange} value={formik.values.short_description} />
                        {formik.errors.short_description ? <small className="form-text text-danger">{formik.errors.short_description}</small> : null}
                    </div>
                    <div className="form-group mb-5">
                        <label className="form-label">Description</label>
                        <ReactQuill theme='snow' name="description" value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                        {formik.errors.description ? <small className="form-text text-danger">{formik.errors.description}</small> : null}
                    </div>
                    <div className="form-group mb-10">
                        <label className="form-label">Tags</label>
                        <SelectCreatable
                            isMulti
                            isClearable
                            name="tags"
                            onChange={option => formik.setFieldValue('tags', option)}
                            value={formik.values.tags}
                        />
                        {formik.errors.tags ? <small className="form-text text-danger">{formik.errors.tags}</small> : null}
                    </div>

                    <button type="submit" className="btn btn-primary w-100" disabled={isLoading || !formik.isValid}>
                        Save Changes {isLoading && <Spinner size="sm" className="ms-2" />}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default BasicInfo