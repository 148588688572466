import { useEffect } from "react"
import { Link } from "react-router-dom"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { MenuComponent } from "../../../../../../../../../_metronic/assets/ts/components"
import { toast } from "react-toastify"


const MenuItem = ({ actionType = 'link', href, onClick, className, children }) => (
    <div className={`menu-item px-3 ` + className}>
        {actionType === 'link' ? (
            <Link to={href} className='menu-link px-3'>
                {children}
            </Link>
        ) : actionType === 'onClick' ? (
            <span className="menu-link px-3" onClick={onClick}>
                {children}
            </span>
        ) : ''}
    </div>
)

const MenuCell = ({ user, onLoginAsUser, onShowZoomLogs }) => {
    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                <i className="fa fa-ellipsis-vertical pe-0"></i>
            </a>
            <div
                className={'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 py-4 ' + (user.personal_meeting_url ? 'w-250px' : 'w-175px')}
                data-kt-menu='true'
            >
                <MenuItem actionType="onClick" onClick={() => onLoginAsUser(user.id)}>Login as User</MenuItem>
                {user.personal_meeting_url && (
                    <CopyToClipboard text={`${user.name} - ${user.personal_meeting_url}`} onCopy={() => toast.success('Copied!')}>
                        <MenuItem actionType="onClick" onClick={() => console.log('copy')}>
                            Copy Personal Meeting URL
                        </MenuItem>
                    </CopyToClipboard>
                )}
                {user.registrant_id && <MenuItem actionType="onClick" onClick={() => onShowZoomLogs(user.registrant_id)}>Show Zoom Logs</MenuItem>}
            </div>
        </>
    )
}

export default MenuCell
