import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useQueryClient, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { PageTitle } from "../../../../../_metronic/layout/core"
import Table from "../../../../components/Table/Table"
import GeneralPagination from "../../../../components/Pagination/GeneralPagination"
import DeleteConfirmModal from "../../../../components/DeleteConfirmModal"

import { deleteLiveSession, getLiveSessions } from "../../../../models/courses/courses"
import { liveSessionColumns, columnAliases } from "./components/CourseLiveSessionsTable/_columns"
import CourseLiveSessionsFilter from "./components/CourseLiveSessionsFilter"
import CreateLiveSessionModal from "./components/CreateLiveSessionModal"
import EditLiveSessionModal from "./components/EditLiveSessionModal"
import LiveSessionsViewVideoModal from "./components/LiveSessionsViewVideoModal"
import Authorization from "../../../../routing/Authorization"


const breadcumbs = [
    {
        title: 'Courses',
        path: '/courses/manage',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CourseLiveSessions = () => {
    const { courseId } = useParams()

    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showLiveSessionViewVideoModal, setShowLiveSessionViewVideoModal] = useState(false)
    const [liveSessionViewVideoData, setLiveSessionViewVideoData] = useState({})
    const [showCreateLiveSessionModal, setShowCreateLiveSessionModal] = useState(false)
    const [showEditLiveSessionModal, setShowEditLiveSessionModal] = useState(false)
    const [editLiveSessionData, setEditLiveSessionData] = useState({})
    const [showDeleteLiveSessionModal, setShowDeleteLiveSessionModal] = useState()
    const [deleteLiveSessionData, setDeleteLiveSessionData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery(`course-${courseId}-live-sessions`, () => getLiveSessions({
        courseId,
        title: filters?.title,
        sessionType: filters?.session_type,
        status: filters?.status,
        page,
        rows: pageSize,
        sortBy
    }))
    const { mutate: deleteLiveSessionMutate, isLoading: isDeleteLiveSessionLoading } = useMutation(liveSessionId => deleteLiveSession(courseId, liveSessionId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`course-${courseId}-live-sessions`])
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(`course-${courseId}-live-sessions`)
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <PageTitle breadcrumbs={breadcumbs}>Course Live Sessions</PageTitle>
            <div className="card">
                <div className="card-header p-6 d-block">
                    <div className="d-flex justify-content-between">
                        <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowCreateLiveSessionModal(true)}>Create Live Session</button>
                    </div>
                    <CourseLiveSessionsFilter
                        show={showFilter}
                        onSubmit={values => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className="card-body p-6">
                    <Table
                        columns={liveSessionColumns}
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        onViewVideo={liveSession => {
                            setShowLiveSessionViewVideoModal(true)
                            setLiveSessionViewVideoData(liveSession)
                        }}
                        onEdit={liveSession => {
                            setShowEditLiveSessionModal(true)
                            setEditLiveSessionData(liveSession)
                        }}
                        onDelete={liveSession => {
                            setShowDeleteLiveSessionModal(true)
                            setDeleteLiveSessionData(liveSession)
                        }}
                    />
                    
                    {isSuccess && <GeneralPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>
            
            <LiveSessionsViewVideoModal
                liveSession={liveSessionViewVideoData}
                show={showLiveSessionViewVideoModal}
                onHide={() => {
                    setShowLiveSessionViewVideoModal(false)
                    setLiveSessionViewVideoData({})
                }}
            />
            <CreateLiveSessionModal
                courseId={courseId}
                show={showCreateLiveSessionModal}
                onHide={() => setShowCreateLiveSessionModal(false)}
            />
            <EditLiveSessionModal
                courseId={courseId}
                liveSession={editLiveSessionData}
                show={showEditLiveSessionModal}
                onHide={() => {
                    setShowEditLiveSessionModal(false)
                    setEditLiveSessionData({})
                }}
            />
            <DeleteConfirmModal
                show={showDeleteLiveSessionModal}
                handleClose={() => setShowDeleteLiveSessionModal(false)}
                onConfirm={() => {
                    deleteLiveSessionMutate(deleteLiveSessionData.id)
                    setShowDeleteLiveSessionModal(false)
                    setDeleteLiveSessionData({})
                }}
                isConfirmLoading={isDeleteLiveSessionLoading}
                name={deleteLiveSessionData.title}
            />
        </>
    )
}

export default Authorization(CourseLiveSessions, ['admin', 'officer', 'user'])