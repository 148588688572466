import { useQuery } from "react-query"
import { getTotalEnrol } from "../../../models/statistics"
import SummaryCard from "./SummaryCard"


const TotalEnrol = () => {
    const { isLoading, isError, error, data: { data } = {} } = useQuery('statistic-total-enrol', getTotalEnrol)

    return (
        <SummaryCard
            label="Total Enrolment"
            value={data?.total_enrol ? data.total_enrol.toLocaleString('id-ID') : 0}
            icon="/media/icons/duotune/art/art002.svg"
            isLoading={isLoading}
            isError={isError}
            error={error}
        />
    )
}

export default TotalEnrol