import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { toast } from "react-toastify"

import { useAuth } from "../../../modules/auth"
import { checkIsActive } from "../../../../_metronic/helpers"
import PageSpinner from "../../../components/Spinner/PageSpinner"
import PageError from "../../../components/Error/PageError"

import { getCourse } from "../../../models/courses/courses"
import { courseThumbnail } from "../../../helpers/pictures"


const Navigation = ({ href, isLastItem = false, children }) => {
    const { pathname } = useLocation()
    const isActive = checkIsActive(pathname, href)

    return (
        <Link to={href}>
            <div className={"border-bottom px-6 py-4 d-flex justify-content-between align-items-center " + (isActive ? 'bg-light' : 'bg-hover-light') + ' ' + (isLastItem ? 'rounded-bottom' : '')}>
                <p className={"mb-0 fw-semibold " + (isActive ? 'text-primary' : 'text-gray-700')}>{children}</p>
                <i className={"fa fa-chevron-right " + (isActive ? 'text-primary' : 'text-gray-700')}></i>
            </div>
        </Link>
    )
}

const Course = () => {
    const { currentUser: { role_id: roleId } } = useAuth()
    const { courseId } = useParams()
    const navigate = useNavigate()

    const { isLoading: courseIsLoading, isError: courseIsError, error: courseError, data: { data: course } = {} } = useQuery(`course-${courseId}`, () => getCourse(courseId), {
        retry: 0,
        onError: error => {
            if (error.response.status === 404) {
                toast.error('Course not found')
                navigate('/courses/manage')
            }
        }
    })

    return (
        <>
            {courseIsLoading ? <PageSpinner height={300} /> : courseIsError ? (
                <PageError height={300}>{courseError.message}</PageError>
            ) : (
                <div className="row min-h-500px">
                    <div className="col-12 col-md-3">
                        <div className="card sticky-none sticky-md-top" style={{
                            top: 80
                        }}>
                            <div className="card-body p-6">
                                <img src={courseThumbnail(course.thumbnail_url)} alt="Course Thumbnail" className="img-fluid rounded mb-4" />
                                <h4 className="mb-0">{course.title}</h4>
                            </div>
                            <div className="card-footer p-0">
                                <Navigation href={`/courses/manage/${courseId}/curriculum`}>Curriculum</Navigation>
                                <Navigation href={`/courses/manage/${courseId}/schedules`}>Schedules</Navigation>
                                <Navigation href={`/courses/manage/${courseId}/tasks`}>User Tasks</Navigation>
                                <Navigation href={`/courses/manage/${courseId}/live_sessions`}>Live Sessions</Navigation>
                                {['admin', 'officer'].includes(roleId) ? (
                                    <Navigation href={`/courses/manage/${courseId}/merchants`}>Merchants</Navigation>
                                ) : null}
                                <Navigation href={`/courses/manage/${courseId}/edit/basic`} isLastItem>Edit Details</Navigation>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-9">
                        <Outlet context={{ course }} />
                    </div>
                </div>
            )}
        </>
    )
}

export default Course