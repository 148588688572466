import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { updateLesson } from "../../../../../models/courses/courses"
import LessonTypeDocument from "./LessonTypeDocument"
import LessonTypeOfflineActivity from "./LessonTypeOfflineActivity"
import LessonTypePracticalTask from "./LessonTypePracticalTask"
import LessonTypeQuiz from "./LessonTypeQuiz"
import LessonTypeVideo from "./LessonTypeVideo"
import LessonTypeWebinar from "./LessonTypeWebinar"
import LessonTypeReflectiveJournal from "./LessonTypeReflectiveJournal"


const EditLessonModal = ({ queryClient, section, lesson, show, onHide }) => {
    const { mutate, isLoading } = useMutation(data => updateLesson(section.course_id, section.id, lesson.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries([`course-${section.course_id}-sections`])
            toast.success('Success')
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Lesson</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="rounded border p-3 bg-light-primary">
                    <p className="mb-0 fw-semibold">Section: {section?.title}</p>
                </div>
                <div className="separator my-6"></div>
                    {lesson?.type === 'video' ? (
                        <LessonTypeVideo
                            lesson={lesson}
                            onSubmit={data => mutate(data)}
                            isSubmitLoading={isLoading}
                        />
                    ) : lesson?.type === 'document' ? (
                        <LessonTypeDocument
                            lesson={lesson}
                            onSubmit={data => mutate(data)}
                            isSubmitLoading={isLoading}
                        />
                    ) : lesson?.type === 'quiz' ? (
                        <LessonTypeQuiz
                            lesson={lesson}
                            onSubmit={data => mutate(data)}
                            isSubmitLoading={isLoading}
                        />
                    ) : lesson?.type === 'practical_task' ? (
                        <LessonTypePracticalTask
                            lesson={lesson}
                            onSubmit={data => mutate(data)}
                            isSubmitLoading={isLoading}
                        />
                    ) : lesson?.type === 'webinar' ? (
                        <LessonTypeWebinar
                            lesson={lesson}
                            onSubmit={data => mutate(data)}
                            isSubmitLoading={isLoading}
                        />
                    ) : lesson?.type === 'offline_activity' ? (
                        <LessonTypeOfflineActivity
                            lesson={lesson}
                            onSubmit={data => mutate(data)}
                            isSubmitLoading={isLoading}
                        />
                    ) : lesson?.type === 'reflective_journal' ? (
                        <LessonTypeReflectiveJournal
                            lesson={lesson}
                            onSubmit={data => mutate(data)}
                            isSubmitLoading={isLoading}
                        />
                    ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default EditLessonModal