import { useMutation, useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import clsx from "clsx"

import PageSpinner from "../../../../../../components/Spinner/PageSpinner"
import PageError from "../../../../../../components/Error/PageError"
import { getScheduleStudent, getScheduleStudentLessonProgress } from "../../../../../../models/courses/schedules"
import { userProfilePicture } from "../../../../../../helpers/pictures"
import { loginAsUser } from "../../../../../../models/users"
import Spinner from "../../../../../../components/Spinner/Spinner"
import SkillTestScoreModal from "../components/SkillTestScoreModal"
import { useState } from "react"
import { formatDate } from "../../../../../../helpers/common"


const Items = ({ title, className, children }) => (
    <div className={clsx("d-flex justify-content-between gap-3", className)}>
        <p className="mb-0 fw-bold">{title}</p>
        <p className="mb-0 text-end">{children}</p>
    </div>
)

const Profile = ({ scheduleId, student, queryKey }) => {
    const [showSkillTestScoreModal, setShowSkillTestScoreModal] = useState(false)

    const { isLoading: loginAsUserLoading, mutate: loginAsUserMutate } = useMutation(userId => loginAsUser({ userId }), {
        onSuccess: data => window.open(data.data, '_newtab'),
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <>
            <div className="card">
                <div className="card-body p-6">
                    <div className="d-flex align-items-center gap-6">
                        <img src={userProfilePicture(student.profile_picture_url)} className="rounded-circle w-75px" />
                        <div>
                            <p className="fw-bold mb-1">{student.name}</p>
                            <p className="text-muted mb-0">{student.email}</p>
                            <p className="text-muted mb-0">{student.phone_number}</p>
                        </div>
                    </div>
                    <div className="separator my-6"></div>
                    <Items className="mb-4" title="Merchant">{student.dp_platform}</Items>
                    <Items className="mb-4" title="Voucher Code">{student.voucher_code}</Items>
                    <Items className="mb-4" title="Redeem Code">{student.redeem_code}</Items>
                    <div className="separator my-6"></div>
                    <Items className="mb-4" title="Progress">{student.progress?.user_progress || 0}%</Items>
                    <Items className="mb-4" title="Pre test score">{(student.pretest_score || student.pretest_score === 0) ? student.pretest_score : '-'}</Items>
                    <Items className="mb-4" title="Post test score">{(student.score || student.score === 0) ? student.score : '-'}</Items>
                    <Items title="Skill test score">{(student.skill_test_score || student.skill_test_score === 0) ? student.skill_test_score : '-'}</Items>
                    {student.completed_at && <Items className="mt-4" title="Completed at">{formatDate(student.completed_at)}</Items>}
                    <div className="separator my-6"></div>
                    <button type="button" className="btn btn-light-primary w-100" disabled={loginAsUserLoading} onClick={() => loginAsUserMutate(student.id)}>
                        Login as User {loginAsUserLoading && <Spinner size="sm" />}
                    </button>
                    {student.completed_at && <button type="button" className="btn btn-light-primary w-100 mt-3" onClick={() => setShowSkillTestScoreModal(true)}>Set Skill Test Score</button>}
                </div>
            </div>

            <SkillTestScoreModal
                show={showSkillTestScoreModal}
                onHide={() => setShowSkillTestScoreModal(false)}
                queryKey={queryKey}
                scheduleId={scheduleId}
                student={student}
            />
        </>
    )
}

const Progress = ({ scheduleId, student }) => {
    const { isLoading, isError, error, data: { data: sections } = {} } = useQuery(`schedule-${scheduleId}-students-${student.id}-lesson-progress`, () => getScheduleStudentLessonProgress(scheduleId, student.id))

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">Lesson Progress</h4>
            </div>
            <div className="card-body d-flex flex-column gap-6">
                {isLoading ? <PageSpinner height={100} /> : isError ? <PageError height={100}>{error.message}</PageError> : sections.map((section, i) => (
                    <div key={i}>
                        <div className="d-flex gap-3 align-items-center">
                            <span class={clsx("bullet", section.completed && 'bg-success')}></span>
                            <div className="flex-fill">
                                <p className="mb-0 fw-semibold">{section.title}</p>
                            </div>
                        </div>
                        <div className="ms-6 mt-4 d-flex flex-column gap-2">
                            {section.lessons.map((lesson, ii) => (
                                <div key={ii} className="d-flex align-items-center gap-3">
                                    <span class={clsx("bullet", lesson.completed && 'bg-success', lesson.completed === 'not_present' && 'bg-danger')}></span>
                                    <div className="flex-fill">
                                        <p className="mb-0 fw-semibold">{lesson.title}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default function Student() {
    const { scheduleId, studentId } = useParams()
    const navigate = useNavigate()
    
    const studentQueryKey = `schedule-${scheduleId}-student-${studentId}`
    const { isLoading, isError, error, data: { data: student } = {} } = useQuery(studentQueryKey, () => getScheduleStudent(scheduleId, studentId), {
        retry: 0,
        onError: error => {
            if (error.response.status === 404) {
                toast.error('Student not found')
                navigate(`/courses/schedules/${scheduleId}/students`)
            }
        }
    })

    return isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : (
        <>
            <div className="row min-h-500px">
                <div className="col-12 col-md-4">
                    <Profile scheduleId={scheduleId} student={student} queryKey={studentQueryKey} />
                </div>
                <div className="col-12 col-md-8">
                    <Progress scheduleId={scheduleId} student={student} />
                </div>
            </div>
        </>
    )
}