import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import PopupQuizDragTheWords from "./PopupQuizDragTheWords"
import { updateLessonPopupQuiz } from "../../../../../models/courses/courses"
import PopupQuizWordSearch from "./PopupQuizWordSearch"
import PopupQuizProblemSolving from "./PopupQuizProblemSolving"
import PopupQuizTrueOrFalse from "./PopupQuizTrueOrFalse"
import PopupQuizSequence from "./PopupQuizSequence"
import PopupQuizFillTheBlank from "./PopupQuizFillTheBlank"

const EditPopupQuizModal = ({ queryClient, lesson, popupQuiz, show, onHide }) => {
    const { mutate, isLoading } = useMutation(data => updateLessonPopupQuiz(lesson.course_id, lesson.section_id, lesson.id, popupQuiz.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries([`lesson-${lesson.id}-popup-quiz`])
            toast.success('Success')
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Popup Quiz</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {popupQuiz?.type === 'drag_the_words' ? (
                    <PopupQuizDragTheWords
                        lesson={lesson}
                        popupQuiz={popupQuiz}
                        onSubmit={lesson => mutate(lesson)}
                        isSubmitLoading={isLoading}
                    />
                ) : popupQuiz?.type === 'word_search' ? (
                    <PopupQuizWordSearch
                        lesson={lesson}
                        popupQuiz={popupQuiz}
                        onSubmit={lesson => mutate(lesson)}
                        isSubmitLoading={isLoading}
                    />
                ) : popupQuiz?.type === 'problem_solving' ? (
                    <PopupQuizProblemSolving
                        lesson={lesson}
                        popupQuiz={popupQuiz}
                        onSubmit={lesson => mutate(lesson)}
                        isSubmitLoading={isLoading}
                    />
                ) : popupQuiz?.type === 'true_or_false' ? (
                    <PopupQuizTrueOrFalse
                        lesson={lesson}
                        popupQuiz={popupQuiz}
                        onSubmit={lesson => mutate(lesson)}
                        isSubmitLoading={isLoading}
                    />
                ) : popupQuiz?.type === 'sequence' ? (
                    <PopupQuizSequence
                        lesson={lesson}
                        popupQuiz={popupQuiz}
                        onSubmit={lesson => mutate(lesson)}
                        isSubmitLoading={isLoading}
                    />
                ) : popupQuiz?.type === 'fill_the_blank' ? (
                    <PopupQuizFillTheBlank
                        lesson={lesson}
                        popupQuiz={popupQuiz}
                        onSubmit={lesson => mutate(lesson)}
                        isSubmitLoading={isLoading}
                    />
                ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default EditPopupQuizModal