import { useQuery } from "react-query"
import { toRupiah } from "../../../helpers/common"
import { getTotalSales } from "../../../models/statistics"
import SummaryCard from "./SummaryCard"


const TotalSales = () => {
    const { isLoading, isError, error, data: { data } = {} } = useQuery('statistic-total-sales', getTotalSales)

    return (
        <SummaryCard
            label="Total Sales"
            value={data?.total_sales ? toRupiah(data.total_sales) : 0}
            icon="/media/icons/duotune/finance/fin008.svg"
            labelClassName="fs-4"
            isLoading={isLoading}
            isError={isError}
            error={error}
        />
    )
}

export default TotalSales