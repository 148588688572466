import { userProfilePicture } from "../../../../../helpers/pictures"


const MentorCell = ({ mainMentor, otherMentor }) => (
    <>
        <div className="d-flex align-items-center">
            <img src={userProfilePicture(mainMentor.profile_picture_url)} className="me-3 rounded-circle h-30px" alt="Profile" />
            <div>
                <p className="mb-0">{mainMentor.name}</p>
                {otherMentor.length > 0 && (
                    <small className="text-muted"><strong>With</strong>: {otherMentor.map((mentor, i) => (i > 0 ? ', ' : '') + mentor.name)}</small>
                )}
            </div>
        </div>
    </>
)

export default MentorCell