import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

export function getPackages({ name = null }) {
    return axios.get(`${API_URL}/packages`, {
        params: {
            name,
        }
    })
}
