import { Field, FieldArray, FormikProvider, useFormik } from "formik"
import { useMutation, useQueryClient } from "react-query"
import { useOutletContext } from "react-router-dom"
import { toast } from "react-toastify"
import * as Yup from "yup"

import Spinner from "../../../../components/Spinner/Spinner"

import { updateCourseRequirements } from "../../../../models/courses/courses"


const schema = Yup.object().shape({
    requirements: Yup.array().of(Yup.string().required('Requirement is required'))
})

const Requirements = () => {
    const { course } = useOutletContext()
    
    const formik = useFormik({
        initialValues: {
            requirements: course.requirements
        },
        validationSchema: schema,
        onSubmit: values => mutate(values)
    })
    
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(data => updateCourseRequirements(course.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            queryClient.invalidateQueries([`course-${course.id}`])
            toast.success('Success')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <div className="card">
            <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        <FieldArray name="requirements">
                            {({ push, remove }) => (
                                <>
                                    <div className="d-flex justify-content-between align-items-center mb-8">
                                        <h3 className="mb-0">Requirements</h3>
                                        <button type="button" className="btn btn-sm btn-light-primary" onClick={() => push()}>Add Requirements</button>
                                    </div>
                                    {formik.values.requirements.length > 0 && formik.values.requirements.map((_, i) => (
                                        <div key={i} className="mb-4">
                                            <div className="d-flex align-items-center">
                                                <Field name={`requirements.${i}`} className="form-control flex-fill me-4" placeholder={`Requirement #${i + 1}`} />
                                                <button type="button" className="btn btn-sm btn-light-danger" onClick={() => remove(i)}>Remove</button>
                                            </div>
                                            {formik.errors.requirements && Array.isArray(formik.errors.requirements) ? <small className="form-text text-danger mb-3">{formik.errors.requirements[i]}</small> : null}
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                        
                        <div className="mt-10">
                            <button type="submit" className="btn btn-primary w-100" disabled={isLoading || !formik.isValid}>
                                Save Changes {isLoading && <Spinner size="sm" className="ms-2" />}
                            </button>
                        </div>
                    </FormikProvider>
                </form>
            </div>
        </div>
    )
}

export default Requirements