import { useOutletContext } from "react-router-dom"
import { useAuth } from "../../../../modules/auth"
import Authorization from "../../../../routing/Authorization"
import TasksComponent from "../../tasks/components/TasksComponent"


const Tasks = () => {
    const { schedule } = useOutletContext()
    const { auth } = useAuth()

    return (
        <div className="min-h-500px">
            <TasksComponent schedule={schedule} />
        </div>
    )
}

export default Authorization(Tasks, ['admin', 'officer', 'host', 'customer_service', 'user'])