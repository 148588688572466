import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

export function getUsers({ isMentor = null, name = null, roleId = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/users`, {
        params: {
            is_mentor: isMentor,
            name: name,
            role_id: roleId,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function loginAsUser({ userId }) {
    return axios.post(`${API_URL}/users/${userId}/login`)
}