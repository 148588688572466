import PrakerjaOwnerPagination from "./components/PrakerjaOwnerPagination"


const PrakerjaOwner = ({ data, page, pageSize, onPageChange, onView }) => {
    if (!data.data.length) return <></>
    return (
        <>
            <p className="fw-bold mt-6 mb-4">List of Voucher</p>
            <div className="min-h-300px">
                {data.data.map(voucher => (
                    <div className="card mb-3">
                        <div className="card-body p-6">
                            <div className="row">
                                <div className="col-12 col-md-2">
                                    <p className="fw-bold mb-2">Voucher Code</p>
                                    <p className="mb-0">{voucher.code}</p>
                                </div>
                                <div className="col-12 col-md-2">
                                    <p className="fw-bold mb-2">Merchant</p>
                                    <p className="mb-0">{voucher.merchant?.name}</p>
                                </div>
                                <div className="col-12 col-md-4">
                                    <p className="fw-bold mb-2">Course</p>
                                    <p className="mb-0">{voucher.course?.title}</p>
                                </div>
                                <div className="col-12 col-md-3">
                                    <p className="fw-bold mb-2">Prakerja Owner</p>
                                    <p className="mb-0">{voucher.prakerja_owner}</p>
                                </div>
                                <div className="col-12 col-md-1 align-self-center">
                                    <button type="button" className="btn btn-sm btn-primary w-100" onClick={() => onView(voucher.code)}>View</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <PrakerjaOwnerPagination
                onPageChange={onPageChange}
                totalCount={data.meta.total}
                currentPage={page}
                pageSize={pageSize}
                from={data.meta.from}
                to={data.meta.to}
            />
        </>
    )
}

export default PrakerjaOwner