import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { PageTitle } from "../../../_metronic/layout/core"
import { saveMerchant } from "../../models/merchants"
import Authorization from "../../routing/Authorization"
import MerchantForm from "./components/MerchantForm/MerchantForm"

const breadcumbs = [
    {
        title: 'Merchant',
        path: '/merchants',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CreateMerchant = () => {
    const navigate = useNavigate()

    const queryClient = useQueryClient()
    const { mutate, isLoading: isSubmitLoading } = useMutation(data => saveMerchant(data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['merchants'])
            toast.success('Success')
            navigate('/merchants')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    const onSubmit = values => {
        const data = new FormData()
        data.append('name', values.name)
        if (values.picture) {
            data.append('picture', values.picture)
        }
        mutate(data)
    }

    return (
        <>
            <PageTitle breadcrumbs={breadcumbs}>Create Merchant</PageTitle>
            <div className="card">
                <div className="card-body">
                    <MerchantForm
                        queryClient={queryClient}
                        onSubmit={onSubmit}
                        isSubmitLoading={isSubmitLoading}
                    />
                </div>
            </div>
        </>
    )
}

export default Authorization(CreateMerchant, ['admin', 'officer'])