import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { PageTitle } from "../../../../../_metronic/layout/core"
import { saveVoucher } from "../../../../models/courses/vouchers"
import Authorization from "../../../../routing/Authorization"
import VoucherForm from "./components/VoucherForm"


const breadcumbs = [
    {
        title: 'Manage Vouchers',
        path: '/courses/vouchers/manage',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CreateVoucher = () => {
    const navigate = useNavigate()

    const queryClient = useQueryClient()
    const { mutate, isLoading } = useMutation(voucher => saveVoucher(voucher), {
        onSuccess: data => {
            queryClient.invalidateQueries(['vouchers'])
            toast.success('Success')
            navigate(`/courses/vouchers/manage`)
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <>
            <PageTitle breadcrumbs={breadcumbs}>Create Voucher</PageTitle>
            <div className="card">
                <div className="card-body">
                    <VoucherForm
                        queryClient={queryClient}
                        onSubmit={data => mutate(data)}
                        isSubmitLoading={isLoading}
                    />
                </div>
            </div>
        </>
    )
}

export default Authorization(CreateVoucher, ['admin', 'officer'])