import { useQuery } from "react-query"
import moment from "moment"

import { getTotalCompletion } from "../../../models/statistics"
import SummaryCard from "./SummaryCard"


const TotalCompletion = () => {
    const currentYear = moment().format('YYYY')
    const { isLoading, isError, error, data } = useQuery('statistic-total-completion', () => getTotalCompletion({ pubYear: currentYear }))

    return (
        <SummaryCard
            label={`Completion ${currentYear}`}
            value={data}
            icon="/media/icons/duotune/technology/teh002.svg"
            iconClassName="svg-icon-success"
            labelClassName="fs-4"
            isLoading={isLoading}
            isError={isError}
            error={error}
        />
    )
}

export default TotalCompletion