import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { saveSection } from "../../../../../models/courses/courses"
import CourseSectionForm from "./CourseSectionForm"



const CreateSectionModal = ({ queryClient, courseId, show, onHide }) => {
    const { mutate, isLoading } = useMutation(section => {
        const data = {
            title: section.title,
            sequence: section.sequence
        }

        return saveSection(courseId, data)
    }, {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`course-${courseId}-sections`])
            onHide()
        },
        onError: error => {
            toast.error(error.message)
        }
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Section</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CourseSectionForm onSubmit={data => mutate(data)} submitLoading={isLoading} />
            </Modal.Body>
        </Modal>
    )
}

export default CreateSectionModal