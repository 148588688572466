import { useState } from "react"
import { KTSVG, toAbsoluteUrl } from "../../../../../../_metronic/helpers"


const LiveSessionTypeItem = ({ icon, isSelected, children, onClick }) => (
    <div className={"border border-dashed border-gray-400 rounded p-3 d-flex align-items-center cursor-pointer " + (isSelected ? 'bg-light-primary' : 'bg-hover-light-primary')} onClick={onClick}>
        <div className="w-40px">
            <KTSVG path={toAbsoluteUrl(icon)} className="svg-icon-primary svg-icon-2x" />
        </div>
        <p className="text-gray-700 fw-semibold mb-0 fs-6">{children}</p>
    </div>
)


const ChooseLiveSessionType = ({ onContinue }) => {
    const [selectedLiveSessionType, setSelectedLiveSessionType] = useState()

    return (
        <>
            <h5 className="mb-5 text-gray-800">Choose Live Session Type</h5>
            <div className="row">
                <div className="col-6">
                    <LiveSessionTypeItem icon={"/media/icons/duotune/abstract/abs032.svg"} isSelected={selectedLiveSessionType === 'recorded'} onClick={() => setSelectedLiveSessionType('recorded')}>Video Recorded</LiveSessionTypeItem>
                </div>
                <div className="col-6">
                    <LiveSessionTypeItem icon={"/media/icons/duotune/electronics/elc004.svg"} isSelected={selectedLiveSessionType === 'webinar'} onClick={() => setSelectedLiveSessionType('webinar')}>Webinar</LiveSessionTypeItem>
                </div>
            </div>
            <button className="btn w-100 mt-8 btn-primary" disabled={!selectedLiveSessionType} onClick={() => onContinue(selectedLiveSessionType)}>Continue</button>
        </>
    )
}

export default ChooseLiveSessionType