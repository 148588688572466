import clsx from "clsx"
import { useEffect, useMemo } from "react"
import { useSortBy, useTable } from "react-table"


const Table = ({ columns, isLoading, isErorr, error, data, currentPage, pageSize, onSortByChange, isClickableRow = false, onRowClick = null, ...props }) => {
    const columnsMemo = useMemo(() => columns, [])

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: { sortBy } } = useTable({ 
        columns: columnsMemo,
        data,
        initialState: { currentPage, pageSize, sortBy: props?.defaultSortBy ? [props.defaultSortBy] : [] },
        manualPagination: true,
        manualSortBy: true
    }, useSortBy)

    useEffect(() => onSortByChange(sortBy), [sortBy])

    return (
        <div className="table-responsive min-h-350px">
            <table className={clsx("table table-row-bordered table-row-gray-300 gy-4", {
                "table-hover": isClickableRow
            })} {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="fw-bold text-muted">
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? <i className="fa fa-chevron-down ms-2"></i> : <i className="fa fa-chevron-up ms-2"></i>) : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {isLoading ? (
                        <tr>
                            <td colSpan={columns.length} className="text-center bg-light">Loading...</td>
                        </tr>
                    ) : rows.length < 1 ? (
                        <tr>
                            <td colSpan={columns.length} className="text-center bg-light">No Result</td>
                        </tr>
                    ) : null}

                    {rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className={clsx("align-items-center", {
                                "cursor-pointer": isClickableRow
                            })} onClick={() => onRowClick ? onRowClick(row.original) : false}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()} className={clsx("align-middle", cell.column.className)}>
                                            {cell.render('Cell', props)}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Table