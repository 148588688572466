import { FormikProvider, useFormik } from "formik"
import { useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { settingSchema } from "./_schema"

const SettingForm = ({ data = null, queryClient, onSubmit, isSubmitLoading = false }) => {
    const formik = useFormik({
        initialValues: {
            key: data?.key,
            value: data?.value
        },
        validationSchema: settingSchema,
        onSubmit: values => onSubmit(values),
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <div className="form-group mb-4">
                    <label className="form-label required">Setting Key</label>
                    <input type="text" name="key" className="form-control" placeholder="Setting Key" onChange={formik.handleChange} value={formik.values.key} />
                    {formik.errors.key ? <small className="form-text text-danger">{formik.errors.key}</small> : null}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label">Value</label>
                    <textarea rows={8} name="value" className="form-control" placeholder="Value" onChange={formik.handleChange} value={formik.values.value} />
                    {formik.errors.value ? <small className="form-text text-danger">{formik.errors.value}</small> : null}
                </div>  
            </FormikProvider>

            <div className="separator my-8"></div>
            <p className="text-end mb-0">
                <button type="submit" className="btn btn-primary" disabled={isSubmitLoading || !formik.isValid}>{data ? 'Update' : 'Create'} Setting {isSubmitLoading && <Spinner size="sm" className="ms-2" />}</button>
            </p>
        </form>
    )
}

export default SettingForm