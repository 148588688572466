import { useFormik } from "formik"
import * as Yup from "yup"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

import Spinner from "../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    title: Yup.string().required().min(5).max(255).label('Title'),
    session_url: Yup.string().required().url().label('Webinar URL'),
    start_date: Yup.string().required().label('Start Date').nullable(),
})

const LiveSessionTypeWebinar = ({ liveSession, onSubmit, submitLoading = false }) => {
    const formik = useFormik({
        initialValues: {
            title: liveSession?.title ? liveSession.title : '',
            session_url: liveSession?.session_url ? liveSession.session_url : '',
            start_date: liveSession?.start_date ? moment.utc(liveSession.start_date).toDate() : '',
            end_date: liveSession?.end_date ? moment.utc(liveSession.end_date).toDate() : ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.session_type = 'webinar'

            if (values.start_date) data.start_date = moment(values.start_date).utc().format('YYYY-MM-DD HH:mm:ss')
            if (values.end_date) data.end_date = moment(values.end_date).utc().format('YYYY-MM-DD HH:mm:ss')

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Title</label>
                <input type="text" name="title" className="form-control" placeholder="Title" value={formik.values.title} onChange={formik.handleChange} />
                {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Webinar URL</label>
                <input type="text" name="session_url" className="form-control" placeholder="Webinar URL" value={formik.values.session_url} onChange={formik.handleChange} />
                {formik.errors.session_url ? <small className="form-text text-danger">{formik.errors.session_url}</small> : null}
            </div>
            <div className="row mb-8">
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <label className="form-label required">Start Date</label>
                        <ReactDatePicker
                            name="start_date"
                            className="form-control"
                            placeholderText="Start date"
                            onChange={date => formik.setFieldValue('start_date', date)}
                            selected={formik.values.start_date}
                            startDate={formik.values.start_date}
                            endDate={formik.values.end_date}
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeSelect
                            selectsStart
                            autoComplete="off"
                        />
                        {formik.errors.start_date ? <small className="form-text text-danger">{formik.errors.start_date}</small> : null}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <label className="form-label">End Date</label>
                        <ReactDatePicker
                            name="end_date"
                            className="form-control"
                            placeholderText="End date"
                            onChange={date => formik.setFieldValue('end_date', date)}
                            selected={formik.values.end_date}
                            startDate={formik.values.start_date}
                            endDate={formik.values.end_date}
                            minDate={formik.values.start_date}
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeSelect
                            selectsEnd
                            autoComplete="off"
                        />
                        {formik.errors.end_date ? <small className="form-text text-danger">{formik.errors.end_date}</small> : null}
                    </div>
                </div>
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                {liveSession ? 'Update' : 'Create'} Live Session {submitLoading ? <Spinner size="sm" className="ms-2" /> : null}
            </button>
        </form>
    )
}

export default LiveSessionTypeWebinar