import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import ChoosePopupQuizType from "./ChoosePopupQuizType"
import PopupQuizDragTheWords from "./PopupQuizDragTheWords"
import { saveLessonPopupQuiz } from "../../../../../models/courses/courses"

import { popupQuizTypes } from "../../../../../helpers/conts"
import PopupQuizWordSearch from "./PopupQuizWordSearch"
import PopupQuizProblemSolving from "./PopupQuizProblemSolving"
import PopupQuizTrueOrFalse from "./PopupQuizTrueOrFalse"
import PopupQuizSequence from "./PopupQuizSequence"
import PopupQuizFillTheBlank from "./PopupQuizFillTheBlank"

const CreatePopupQuizModal = ({ queryClient, lesson, show, onHide }) => {
    const [step, setStep] = useState('popup_quiz_type')
    const [type, setType] = useState()

    const { mutate, isLoading } = useMutation(data => saveLessonPopupQuiz(lesson.course_id, lesson.section_id, lesson.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries([`lesson-${lesson.id}-popup-quiz`])
            toast.success('Success')
            hideModal()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        if (step === 'popup_quiz_type') {
            setType()
        }
    }, [step])

    const hideModal = () => {
        setStep('popup_quiz_type')
        setType()
        onHide()
    }

    return (
        <Modal show={show} onHide={hideModal} backdrop="static" keyboard={false} centered size={type === 'fill_the_blank' ? 'lg' : 'md'}>
            <Modal.Header closeButton>
                <Modal.Title>Create Popup Quiz</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {step === 'popup_quiz_type' ? (
                    <ChoosePopupQuizType onContinue={type => {
                        setType(type)
                        setStep('content')
                    }} />
                ) : step === 'content' ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-6">
                            <div>
                                <p className="mb-1 fw-bold">Popup Quiz Type</p>
                                <p className="mb-0 fw-semibold text-primary text-capitalize">{popupQuizTypes[type]}</p>
                            </div>
                            <button type="button" className="btn btn-sm btn-light text-primary" onClick={() => setStep('popup_quiz_type')}>Change</button>
                        </div>
                        <div className="separator my-6"></div>
                        {type === 'drag_the_words' ? (
                            <PopupQuizDragTheWords
                                lesson={lesson}
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : type === 'word_search' ? (
                            <PopupQuizWordSearch
                                lesson={lesson}
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : type === 'problem_solving' ? (
                            <PopupQuizProblemSolving
                                lesson={lesson}
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : type === 'true_or_false' ? (
                            <PopupQuizTrueOrFalse
                                lesson={lesson}
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : type === 'sequence' ? (
                            <PopupQuizSequence
                                lesson={lesson}
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : type === 'fill_the_blank' ? (
                            <PopupQuizFillTheBlank
                                lesson={lesson}
                                onSubmit={lesson => mutate(lesson)}
                                isSubmitLoading={isLoading}
                            />
                        ) : null}
                    </>
                ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default CreatePopupQuizModal