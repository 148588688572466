import { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useLocation } from "react-router-dom"

import { useAuth } from "../../../../modules/auth"
import { PageTitle } from "../../../../../_metronic/layout/core"
import Authorization from "../../../../routing/Authorization"
import GeneralPagination from "../../../../components/Pagination/GeneralPagination"

import { getBatches } from "../../../../models/courses/coupons"
import Table from "../../../../components/Table/Table"
import CouponsBatchFilter from "./components/CouponsBatchFilter"
import GenerateCouponModal from "./components/GenerateCouponModal"
import { couponBatchColumns } from "./components/CouponsBatchTable/_columns"


const CouponsBatch = () => {
    const { auth } = useAuth()
    const location = useLocation()

    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({
        id: 'created_at',
        direction: 'DESC'
    })

    const [showGenerateCouponModal, setShowGenerateCouponModal] = useState(location.state?.showGenerateCouponModal)

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery(['coupon-batches'], () => getBatches({
        title: filters?.title,
        merchantId: filters?.merchantId,
        page,
        pageSize,
        sortBy
    }))

    useEffect(() => {
        queryClient.fetchQuery(['coupon-batches'])
    }, [queryClient, filters, page, sortBy])

    const exportBatch = downloadUrl => {
        window.open(downloadUrl + '?_token=' + auth.access_token, "_blank")
    }

    return (
        <>
            <PageTitle>Generate History</PageTitle>
            <div className="card">
                <div className="card-header p-6 d-block">
                    <div className="d-flex justify-content-between">
                        <button type="button" className={"btn btn-sm " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowGenerateCouponModal(true)}>Generate Coupons</button>
                    </div>
                    <CouponsBatchFilter
                        show={showFilter}
                        queryClient={queryClient}
                        onSubmit={values => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className="card-body p-6">
                    <Table
                        columns={couponBatchColumns}
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        onDownload={downloadUrl => exportBatch(downloadUrl)}
                    />
                    {isSuccess && <GeneralPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>

            <GenerateCouponModal
                show={showGenerateCouponModal}
                onHide={() => setShowGenerateCouponModal(false)}
            />
        </>
    )
}

export default Authorization(CouponsBatch, ['admin', 'officer'])