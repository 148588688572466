import NumberCell from "../../../../../../components/Table/cells/Number"
import ContentCell from "./ContentCell"
import StatusCell from "./StatusCell"
import ActionsCell from "./ActionsCell"
import DateTimeCell from "../../../../../../components/Table/cells/DateTime"


const liveSessionColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Title',
        accessor: 'title'
    }, {
        Header: 'Type',
        accessor: 'session_type',
        className: 'text-capitalize'
    }, {
        Header: 'Content',
        accessor: 'content',
        disableSortBy: true,
        Cell: ({ ...props }) => <ContentCell liveSession={props.data[props.row.index]} onViewVideo={props.onViewVideo} />
    }, {
        Header: 'Start Date',
        accessor: 'start_date',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].start_date} />
    }, {
        Header: 'End Date',
        accessor: 'end_date',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].end_date} />
    }, {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        className: 'text-end',
        Cell: ({ ...props }) => <ActionsCell liveSession={props.data[props.row.index]} onEdit={props.onEdit} onDelete={props.onDelete} />
    }
]

const columnAliases = {
    number: 'id'
}

export { liveSessionColumns, columnAliases }