import { Field, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

import Spinner from "../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    task_type: Yup.string().required().label('Task Type'),
    title: Yup.string().required('Title is required').min(5, 'Title must be at least 5 characters').max(255, 'Title must be at most 255 characters'),
    description: Yup.string().required('Description is required').min(5, 'Description must be at least 5 characters'),
    allowed_file_types: Yup.array().min(1)
})


const LessonTypePracticalTask = ({ lesson, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            task_type: lesson?.task_type ? lesson.task_type : 'regular',
            title: lesson?.title ? lesson.title : '',
            description: lesson?.description ? lesson.description : '',
            allowed_file_types: lesson?.allowed_file_types ? lesson.allowed_file_types : ['document', 'video', 'image'],
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = new FormData()
            data.append('type', 'practical_task')
            data.append('task_type', values.task_type)
            data.append('title', values.title)
            data.append('description', values.description)
            data.append('allowed_file_types', JSON.stringify(values.allowed_file_types))

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <div className="form-group mb-4">
                    <label className="form-label required">Task Type</label>
                    <select name="task_type" className="form-select" onChange={formik.handleChange} value={formik.values.task_type}>
                        <option value="regular">Regular Task</option>
                        <option value="final">Final Task</option>
                    </select>
                    {formik.errors.task_type ? <small className="form-text text-danger">{formik.errors.task_type}</small> : null}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label required">Title</label>
                    <input type="text" name="title" className="form-control" placeholder="Practical Task Title" onChange={formik.handleChange} value={formik.values.title} />
                    {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label required">Description</label>
                    <ReactQuill theme='snow' name="description" value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                    {formik.errors.description ? <small className="form-text text-danger">{formik.errors.description}</small> : null}
                </div>
                <div className="form-group mb-6">
                    <label className="form-label required">Allowed File Type</label>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-check form-check-custom form-check-solid">
                                <Field type="checkbox" name="allowed_file_types" className="form-check-input" value="document" id="file-type-document" />
                                <label className="form-check-label" for="file-type-document">
                                    Document (.pdf)
                                </label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-custom form-check-solid">
                                <Field type="checkbox" name="allowed_file_types" className="form-check-input" value="image" id="file-type-image" />
                                <label className="form-check-label" for="file-type-image">
                                    Image (.jpg, .jpeg, .png)
                                </label>
                            </div>
                        </div>
                        <div className="col-6 mt-3">
                            <div className="form-check form-check-custom form-check-solid">
                                <Field type="checkbox" name="allowed_file_types" className="form-check-input" value="video" id="file-type-video" />
                                <label className="form-check-label" for="file-type-video">
                                    Video (.mp4)
                                </label>
                            </div>
                        </div>
                    </div>
                    {formik.errors.allowed_file_types ? <small className="form-text text-danger">{formik.errors.allowed_file_types}</small> : null}
                </div>
                <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                    {!lesson ? 'Create' : 'Edit'} Lesson {isSubmitLoading ? <Spinner size="sm" /> : null}
                </button>
            </FormikProvider>
        </form>
    )
}

export default LessonTypePracticalTask