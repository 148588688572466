import { useFormik } from "formik"
import AsyncSelect from "react-select/async"
import Collapse from "react-bootstrap/Collapse"

import { getCategories } from "../../../../models/products/categories"


const ProductFilter = ({ show, queryClient, onSubmit }) => {
    const searchCategory = async search => queryClient.fetchQuery('all-product-categories', () => getCategories(true, search)).then(res => res.data.map(category => ({
        label: category.name,
        value: category.id,
    })))

    const formik = useFormik({
        initialValues: {
            title: '',
            category: '',
            status: ''
        },
        onSubmit: values => {
            const data = {...values}
            data.category_id = values?.category?.value

            onSubmit(data)
        }
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Product Title</small></label>
                            <input type="text" name="title" className="form-control form-control-sm" placeholder="Product Title" onChange={formik.handleChange} value={formik.values.title} />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Category</small></label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={searchCategory}
                                name="category"
                                className="form-control-sm p-0"
                                onChange={option => formik.setFieldValue('category', option)}
                                value={formik.values.category}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Status</small></label>
                            <select name="status" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.status}>
                                <option value="">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 text-end mt-6">
                        <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                            formik.resetForm()
                            onSubmit({})
                        }}>Clear</button>
                        <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}

export default ProductFilter