import { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"

import { PageTitle } from "../../../../../_metronic/layout/core"
import { getReconciliations } from "../../../../models/courses/vouchers"
import Authorization from "../../../../routing/Authorization"
import ReconciliationFilter from "./components/ReconciliationFilter"
import ReconciliationHistoryTable from "./components/ReconciliationHistoryTable/ReconciliationHistoryTable"
import UploadReconciliationForm from "./components/UploadReconciliationForm"


const ImportReconciliation = () => {
    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({
        id: 'created_at',
        direction: 'DESC'
    })

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery(['voucher-reconciliations'], () => getReconciliations({
        merchantId: filters?.merchantId,
        page,
        pageSize,
        sortBy
    }))

    useEffect(() => {
        queryClient.fetchQuery(['voucher-reconciliations'])
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <PageTitle>Import Reconciliation</PageTitle>
            <div className="card">
                <div className="card-body p-6">
                    <UploadReconciliationForm />
                </div>
            </div>
            <h3 class="page-heading text-dark fw-bold fs-3 mt-10 mb-5">Import History</h3>
            <div className="card">
                <div className="card-header p-6 d-block">
                    <div className="d-flex justify-content-between">
                        <button type="button" className={"btn btn-sm " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                    </div>
                    <ReconciliationFilter
                        show={showFilter}
                        queryClient={queryClient}
                        onSubmit={values => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className="card-body p-6">
                    <ReconciliationHistoryTable
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                    />
                    {/* {isSuccess && <VoucherBatchPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />} */}
                </div>
            </div>
        </>
    )
}

export default Authorization(ImportReconciliation, ['admin', 'officer'])