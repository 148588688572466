import { useFormik } from "formik"


const SessionParticipantFilter = ({ onSubmit }) => {
    const formik = useFormik({
        initialValues: {
            keyword: '',
            status: '',
        },
        onSubmit: values => onSubmit(values)
    })

    return (
        <form className="row my-4" onSubmit={formik.handleSubmit}>
            <div className="col-12 col-md-5">
                <div className="form-group">
                    <input type="text" name="keyword" className="form-control form-control-solid form-control-sm" placeholder="Name or Email" value={formik.values.keyword} onChange={formik.handleChange} />
                </div>
            </div>
            <div className="col-12 col-md-5">
                <div className="form-group">
                    <select name="status" className="form-select form-select-solid form-select-sm" value={formik.values.status} onChange={formik.handleChange}>
                        <option value="">All Status</option>
                        <option value="present">Present</option>
                        <option value="not_present">Not Present</option>
                    </select>
                </div>
            </div>
            <div className="col-12 col-md-2">
                <button type="submit" className="btn btn-sm btn-primary w-100">Filter</button>
            </div>
        </form>
    )
}

export default SessionParticipantFilter