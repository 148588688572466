import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { PageTitle } from "../../../_metronic/layout/core"
import PageSpinner from "../../components/Spinner/PageSpinner"
import { getMerchant, updateMerchant } from "../../models/merchants"
import Authorization from "../../routing/Authorization"
import MerchantForm from "./components/MerchantForm/MerchantForm"

const breadcumbs = [
    {
        title: 'Merchant',
        path: '/merchants',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const EditMerchant = () => {
    const navigate = useNavigate()
    const { merchantId } = useParams()

    const queryClient = useQueryClient()
    const { isLoading, data: merchant } = useQuery(['merchant'], () => getMerchant(merchantId), {
        cacheTime: 0,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    })

    console.log(merchant)
    const { mutate, isLoading: isSubmitLoading } = useMutation(data => updateMerchant(merchantId, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            toast.success('Success')
            navigate('/merchants')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <>
            <PageTitle breadcrumbs={breadcumbs}>Edit Merchant</PageTitle>
            <div className="card">
                <div className="card-body">
                    {isLoading ? <PageSpinner height={300} /> : (
                        <MerchantForm
                            data={merchant.data}
                            queryClient={queryClient}
                            onSubmit={values => mutate(values)}
                            isSubmitLoading={isSubmitLoading}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

export default Authorization(EditMerchant, ['admin', 'officer'])