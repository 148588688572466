import { Button } from "react-bootstrap"
import ActionsCell from "./ActionCell"

const settingColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <strong className="text-muted">{((props.initialState.currentPage - 1) * props.initialState.pageSize) + props.row.index + 1}</strong>
    }, {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ ...props }) => <p> {props.data[props.row.index].key} </p>
    }, {
        Header: 'Value',
        accessor: 'value',
        Cell: ({ ...props }) => <p> {props.data[props.row.index].value} </p>
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell setting={props.data[props.row.index]} onDelete={props.onDelete} />
    }
]

const columnAliases = {
    number: 'id',
}

export { settingColumns, columnAliases }