import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { saveCourseSchedule } from "../../../../../../models/courses/courses"
import CourseScheduleForm from "./CourseScheduleForm"


const CreateScheduleModal = ({ queryClient, scheduleQueryKey, courseId, show, onHide }) => {
    const { mutate, isLoading } = useMutation(data => saveCourseSchedule(courseId, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([scheduleQueryKey])
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CourseScheduleForm queryClient={queryClient} onSubmit={data => mutate(data)} submitLoading={isLoading} />
            </Modal.Body>
        </Modal>
    )
}

export default CreateScheduleModal