import { useEffect, useMemo } from "react"
import { useSortBy, useTable } from "react-table"

import { voucherBatchColumns } from "./_columns"


const VoucherBatchTable = ({ isLoading, isErorr, error, data, currentPage, pageSize, onSortByChange, onDownload }) => {
    const columns = useMemo(() => voucherBatchColumns, [])

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state: { sortBy } } = useTable({ 
        columns,
        data,
        initialState: {
            currentPage,
            pageSize,
            sortBy: [
                {
                    id: 'created_at',
                    desc: true
                }
            ]
        },
        manualPagination: true,
        manualSortBy: true
    }, useSortBy)

    useEffect(() => onSortByChange(sortBy), [sortBy])

    return (
        <div className="table-responsive min-h-350px">
            <table className="table table-row-bordered table-row-gray-300 gy-4" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="fw-bold text-muted">
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? <i className="fa fa-chevron-down ms-2"></i> : <i className="fa fa-chevron-up ms-2"></i>) : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {isLoading ? (
                        <tr>
                            <td colSpan={voucherBatchColumns.length} className="text-center bg-light">Loading...</td>
                        </tr>
                    ) : rows.length < 1 ? (
                        <tr>
                            <td colSpan={voucherBatchColumns.length} className="text-center bg-light">No Result</td>
                        </tr>
                    ) : null}

                    {rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()} className="align-middle">
                                            {cell.render('Cell', { onDownload })}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default VoucherBatchTable