import { useFormik } from "formik"
import { useMutation, useQueryClient } from "react-query"
import { useOutletContext } from "react-router-dom"
import { toast } from "react-toastify"
import * as Yup from "yup"

import Spinner from "../../../../components/Spinner/Spinner"

import { updateCoursePricing } from "../../../../models/courses/courses"


const schema = Yup.object().shape({
    price: Yup.number().required().min(0).label('Price'),
    discounted_price: Yup.number().min(0).label('Discounted Price'),
})

const Pricing = () => {
    const { course } = useOutletContext()
    
    const formik = useFormik({
        initialValues: {
            price: course.price,
            discounted_price: course.discounted_price,
        },
        validationSchema: schema,
        onSubmit: values => mutate(values)
    })
    
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(data => updateCoursePricing(course.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            queryClient.invalidateQueries([`course-${course.id}`])
            toast.success('Success')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <div className="card">
            <div className="card-body">
                <h3 className="mb-8">Pricing</h3>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-5">
                        <label className="form-label d-flex justify-content-between">
                            <span className="required">Price</span>
                            <small className="text-muted"><i className="fa fa-info-circle fa-fw"></i> Set 0 if this is a free course</small>
                        </label>
                        <input type="number" name="price" className="form-control" placeholder="Price" onChange={formik.handleChange} value={formik.values.price} />
                        {formik.errors.price ? <small className="form-text text-danger">{formik.errors.price}</small> : null}
                    </div>
                    <div className="form-group mb-10">
                        <label className="form-label d-flex justify-content-between">
                            <span>Discounted Price</span>
                            <small className="text-muted"><i className="fa fa-info-circle fa-fw"></i> Set 0 if no discount</small>
                        </label>
                        <input type="number" min={0} name="discounted_price" className="form-control" placeholder="Discounted Price" onChange={formik.handleChange} value={formik.values.discounted_price} />
                        {formik.errors.discounted_price ? <small className="form-text text-danger">{formik.errors.discounted_price}</small> : null}
                    </div>

                    <button type="submit" className="btn btn-primary w-100" disabled={isLoading || !formik.isValid}>
                        Save Changes {isLoading && <Spinner size="sm" className="ms-2" />}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Pricing