import { Modal } from "react-bootstrap"

import { eventDate, unSlug } from "../../../../../../helpers/common"
import SessionParticipantTable from "./SessionParticipantTable"


const SessionDetailModal = ({ queryClient, schedule, session, courseId, show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} size="xl" backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Session Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {schedule && session ? (
                    <>
                        <div className={session.status !== 'inactive' ? "border rounded p-6" : null}>
                            <span className="badge badge-square badge-light p-3 text-capitalize mb-3">
                                {unSlug(session.lesson.type)}
                            </span>
                            {session.status === 'active' ? <span className="badge badge-square badge-success p-3 ms-3">On Going</span> : null}
                            {session.status === 'finished' ? <span className="badge badge-square badge-light-danger p-3 ms-3">Finished</span> : null}
                            <p className="fw-bold fs-4">{session.lesson.title}</p>
                            <div className="row mt-6">
                                <div className="col-12 col-md-4">
                                    <p className="fw-bold fs-6 mb-0">Date Time</p>
                                    <p className="fs-6 text-muted mb-0">{eventDate(session.start_datetime)}</p>
                                </div>
                                <div className="col-12 col-md-4">
                                    <p className="fw-bold fs-6 mb-0">Meeting URL</p>
                                    <p className="fs-6 text-muted mb-0">
                                        {session.meeting_url ? <a href={session.meeting_url} target="_blank" rel="noreferrer">{session.meeting_url}</a> : '-'}
                                    </p>
                                </div>
                                <div className="col-12 col-md-4">
                                    <p className="fw-bold fs-6 mb-0">Absent Code</p>
                                    <p className="fs-6 text-muted mb-0">{session.absent_code}</p>
                                </div>
                                <div className="col-12 mt-3">
                                    <p className="fw-bold fs-6 mb-0">Description</p>
                                    {session.description ? (
                                        <div className="mt-3" dangerouslySetInnerHTML={{
                                            __html: session.description
                                        }}></div>
                                    ) : (
                                        <p className="fs-6 text-muted mb-0">No description provided</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        {session.status !== 'inactive' ? (
                            <SessionParticipantTable
                                queryClient={queryClient}
                                courseId={courseId}
                                schedule={schedule}
                                session={session}
                            />
                        ) : null}
                    </>
                ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default SessionDetailModal