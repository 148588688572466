import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Field, FormikProvider, useFormik } from "formik"
import * as Yup from 'yup'
import Select from "react-select"
import AsyncSelect from "react-select/async"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Spinner from "../../../../../components/Spinner/Spinner"

import { getCourses } from "../../../../../models/courses/courses"
import { getMerchants } from "../../../../../models/merchants"
import { formatDate } from "../../../../../helpers/common"


const schema = Yup.object().shape({
    showSchedule: Yup.boolean(),
    type: Yup.string().required().label('Voucher type'),
    merchant: Yup.object().required('Merchant is required').nullable(),
    course: Yup.object().required('Course is required').nullable(),
    price: Yup.number().required('Price is required').min(0),
    quota: Yup.number().required('Quota is required').min(0),
    code: Yup.string().min(4).max(20)
})

const VoucherForm = ({ data, queryClient, onSubmit, isSubmitLoading }) => {
    const [courses, setCourses] = useState()

    const searchCourse = async search => queryClient.fetchQuery('courses', () => getCourses(search, null, null, null, 1, 100)).then(res => {
        setCourses(items => {
            const tempItems = {...items}
            res.data.map(course => {
                if (course.id in tempItems) return true

                tempItems[course.id] = course
            })

            return tempItems
        })

        return res.data.map(course => ({
            label: course.title,
            value: course.id,
        }))
    })

    const searchMerchant = async search => queryClient.fetchQuery('merchants', () => getMerchants({ name: search, rows: 100 })).then(res => res.data.map(merchant => ({
        label: merchant.name,
        value: merchant.id,
    })))

    const formik = useFormik({
        initialValues: {
            showSchedule: false,
            type: data?.type ? data.type : 'live',
            merchant: data?.merchant ? {
                label: data.merchant.name,
                value: data.merchant.id
            } : '',
            course: data?.course ? {
                label: data.course.title,
                value: data.course.id
            } : '',
            schedule: data?.schedule ? {
                label: data.schedule.name,
                value: data.schedule.id
            } : '',
            price: data?.price ? data.price : 0,
            quota: data?.quota ? data.quota : 1,
            expires_at: data?.expires_at ? new Date(data.expires_at) : '',
            require_redeem_code: data ? data.require_redeem_code : false,
            random_code: true
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.merchant_id = data.merchant.value
            data.course_id = data.course.value
            data.schedule_id = data.schedule?.value

            onSubmit(data)
        }
    })

    useEffect(() => {
        if (!formik.values.course) return () => true
        if (!courses) return () => true
        if (!courses[formik.values.course.value]) return () => true
        
        formik.setFieldValue('price', courses[formik.values.course.value].price)
        formik.setFieldValue('schedule', '')
        formik.setFieldValue('showSchedule', courses[formik.values.course.value].schedules?.length > 0)
    }, [formik.values.course])

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                {data ? (
                    <div className="form-group mb-5">
                        <label className="form-label">Code</label>
                        <input type="text" className="form-control" value={data.code} disabled />
                    </div>
                ) : null}
                <div className="form-group mb-5">
                    <label className="form-label required">Type</label>
                    <select name="type" className="form-select" onChange={formik.handleChange} value={formik.values.type}>
                        <option value="live">Live</option>
                        <option value="dummy">Dummy</option>
                    </select>
                    {formik.errors.type ? <small className="form-text text-danger">{formik.errors.type}</small> : null}
                </div>
                <div className="form-group mb-5">
                    <label className="form-label required">Merchant</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={searchMerchant}
                        name="merchant"
                        className="form-control-sm p-0"
                        onChange={option => formik.setFieldValue('merchant', option)}
                        value={formik.values.merchant}
                    />
                    {formik.errors.merchant ? <small className="form-text text-danger">{formik.errors.merchant}</small> : null}
                </div>
                <div className="form-group mb-5">
                    <label className="form-label required">Course</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={searchCourse}
                        name="course"
                        className="form-control-sm p-0"
                        onChange={option => formik.setFieldValue('course', option)}
                        value={formik.values.course}
                    />
                    {formik.errors.course ? <small className="form-text text-danger">{formik.errors.course}</small> : null}
                </div>
                {formik.values.showSchedule && (
                    <div className="form-group mb-5">
                        <label className="form-label">Schedule</label>
                        <Select
                            name="schedule"
                            className="form-control-sm p-0"
                            options={courses[formik.values.course.value]?.schedules.map(schedule => ({
                                label: `[${schedule.name}] ${formatDate(schedule.schedule_start, false)} - ${formatDate(schedule.schedule_end, false)}`,
                                value: schedule.id
                            }))}
                            value={formik.values.schedule}
                            onChange={option => formik.setFieldValue('schedule', option)}
                        />
                        {formik.errors.schedule ? <small className="form-text text-danger">{formik.errors.schedule}</small> : null}
                    </div>
                )}
                <div className="form-group mb-5">
                    <label className="form-label required">Price</label>
                    <input type="number" name="price" className="form-control" placeholder="Price" min={1} onChange={formik.handleChange} value={formik.values.price} />
                    {formik.errors.price ? <small className="form-text text-danger">{formik.errors.price}</small> : null}
                </div>
                <div className="form-group mb-5">
                    <label className="form-label required">Quota</label>
                    <input type="number" name="quota" className="form-control" placeholder="Quota" min={1} onChange={formik.handleChange} value={formik.values.quota} />
                    {formik.errors.quota ? <small className="form-text text-danger">{formik.errors.quota}</small> : null}
                </div>
                <div className="form-group mb-5">
                    <label className="form-label">Expires at</label>
                    <ReactDatePicker
                        name="expires_at"
                        className="form-control"
                        placeholderText="Expires at"
                        onChange={date => formik.setFieldValue('expires_at', date)}
                        selected={formik.values.expires_at}
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                    />
                    {formik.errors.expires_at ? <small className="form-text text-danger">{formik.errors.expires_at}</small> : null}
                </div>
                <div className="form-check form-check-custom form-check-solid mb-5">
                    <Field type="checkbox" name="require_redeem_code" className="form-check-input" id="require_redeem_code" onChange={e => formik.setFieldValue('require_redeem_code', e.target.checked)} />
                    <label className="form-check-label" htmlFor="require_redeem_code">
                        Require Redeem Code
                    </label>
                </div>
                {!data ? (
                    <>
                        <div className="form-check form-check-custom form-check-solid mb-5">
                            <Field type="checkbox" name="random_code" className="form-check-input" id="random_code" onChange={e => formik.setFieldValue('random_code', e.target.checked)} />
                            <label className="form-check-label" htmlFor="random_code">
                                Generate Random Voucher Code
                            </label>
                        </div>
                        {!formik.values.random_code ? (
                            <div className="form-group">
                                <label className="form-label required">Code</label>
                                <input type="text" name="code" className="form-control" placeholder="Code" onChange={formik.handleChange} value={formik.values.code} />
                                {formik.errors.code ? <small className="form-text text-danger">{formik.errors.code}</small> : null}
                            </div>
                        ) : null}
                    </>
                ) : null}
                <div className="separator my-6"></div>
                <div className="d-flex justify-content-between">
                    <Link to='/courses/vouchers/manage'>
                        <button type="button" className="btn btn-secondary">Cancel</button>
                    </Link>
                    <button className="btn btn-primary" onClick={formik.submitForm} disabled={isSubmitLoading || !formik.isValid}>
                        {data ? 'Update' : 'Create'} Voucher {isSubmitLoading ? <Spinner size="sm" color="white" className="ms-2" /> : null}
                    </button>
                </div>
            </FormikProvider>
        </form>
    )
}

export default VoucherForm