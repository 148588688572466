import { FormikProvider, useFormik, Field } from "formik"
import * as Yup from "yup"
import AsyncSelect from "react-select/async"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { getCourses } from "../../../../../models/courses/courses"
import { getMerchants } from "../../../../../models/merchants"
import Spinner from "../../../../../components/Spinner/Spinner"

const schema = Yup.object().shape({
    merchant: Yup.object().required().nullable().label('Merchant'),
    price: Yup.number().when('type', {
        is: 'fixed',
        then: Yup.number().required().min(0)
    }).label('Price'),
    discount: Yup.number().when('type', {
        is: 'discount',
        then: Yup.number().required().min(0).max(100)
    }).label('Discount'),
    coupon_discount_max_amount: Yup.number().min(0).label('Coupon Discount Max Amount'),
    quota: Yup.number().required().min(1).label('Quota'),
    code: Yup.string().when('random_code', {
        is: '0',
        then: Yup.string().required()
    }).label('Coupon Code')
})

const CouponForm = ({ queryClient, data, onSubmit, isSubmitLoading }) => {
    const searchCourse = async search => queryClient.fetchQuery('courses', () => getCourses(search, null, null, null, 1, 100)).then(res => res.data.map(course => ({
        label: course.title,
        value: course.id,
    })))
    const searchMerchant = async search => queryClient.fetchQuery('merchants', () => getMerchants({ name: search, rows: 100 })).then(res => res.data.map(merchant => ({
        label: merchant.name,
        value: merchant.id,
    })))

    const formik = useFormik({
        initialValues: {
            merchant: data?.merchant ? {
                label: data.merchant.name,
                value: data.merchant.id
            } : '',
            course: data?.course ? {
                label: data.course.title,
                value: data.course.id
            } : '',
            type: data?.type ? data.type : 'discount',
            price: data?.price ? data.price : '',
            discount: data?.discount ? data.discount : '',
            discount_max_amount: data?.discount_max_amount ? data.discount_max_amount : '',
            quota: data?.quota ? data.quota : 1,
            expires_at: data?.expires_at ? new Date(data.expires_at) : '',
            user_fees: '1',
            random_code: '1',
            code: data?.code ? data.code : ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.merchant_id = data.merchant.value
            data.course_id = data.course.value

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <div className="form-group mb-4">
                    <label className="form-label required">Merchant</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={searchMerchant}
                        name="merchant"
                        className="form-control-sm p-0"
                        onChange={option => formik.setFieldValue('merchant', option)}
                        value={formik.values.merchant}
                    />
                    {formik.errors.merchant ? <small className="form-text text-danger">{formik.errors.merchant}</small> : null}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label">Course</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        isClearable
                        loadOptions={searchCourse}
                        name="course"
                        className="form-control-sm p-0"
                        onChange={option => formik.setFieldValue('course', option)}
                        value={formik.values.course}
                        isDisabled={!!data}
                    />
                    {formik.errors.course ? <small className="form-text text-danger">{formik.errors.course}</small> : null}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label required">Type</label>
                    <select name="type" className="form-select" onChange={e => {
                        formik.handleChange(e)
                        formik.setFieldValue('price', '')
                        formik.setFieldValue('discount', '')
                        formik.setFieldValue('discount_max_amount', '')
                    }} value={formik.values.type} disabled={!!data}>
                        <option value="fixed">Fixed Price</option>
                        <option value="discount">Discount</option>
                    </select>
                    {formik.errors.course ? <small className="form-text text-danger">{formik.errors.course}</small> : null}
                </div>
                {formik.values.type === 'discount' ? (
                    <>
                        <div className="form-group mb-4">
                            <label className="form-label required">Discount (%)</label>
                            <input type="number" name="discount" className="form-control" placeholder="Discount" min={1} onChange={formik.handleChange} value={formik.values.discount} disabled={!!data} />
                            {formik.errors.discount ? <small className="form-text text-danger">{formik.errors.discount}</small> : null}
                        </div>
                        <div className="form-group mb-4">
                            <label className="form-label">Discount Max Amount</label>
                            <input type="number" name="discount_max_amount" className="form-control" placeholder="Discount Max Amount" min={1} onChange={formik.handleChange} value={formik.values.discount_max_amount} />
                            {formik.errors.discount_max_amount ? <small className="form-text text-danger">{formik.errors.discount_max_amount}</small> : null}
                        </div>
                    </>
                ) : (
                    <div className="form-group mb-4">
                        <label className="form-label required">Price</label>
                        <input type="number" name="price" className="form-control" placeholder="Price" min={0} onChange={formik.handleChange} value={formik.values.price} disabled={!!data} />
                        {formik.errors.price ? <small className="form-text text-danger">{formik.errors.price}</small> : null}
                    </div>
                )}
                <div className="form-group mb-4">
                    <label className="form-label required">Quota</label>
                    <input type="number" name="quota" className="form-control" placeholder="Quota" min={1} onChange={formik.handleChange} value={formik.values.quota} disabled={!!data} />
                    {formik.errors.quota ? <small className="form-text text-danger">{formik.errors.quota}</small> : null}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label">Expires at</label>
                    <ReactDatePicker
                        name="expires_at"
                        className="form-control"
                        placeholderText="Expires at"
                        onChange={date => formik.setFieldValue('expires_at', date)}
                        selected={formik.values.expires_at}
                        minDate={new Date()}
                        dateFormat="yyyy-MM-dd"
                    />
                    {formik.errors.expires_at ? <small className="form-text text-danger">{formik.errors.expires_at}</small> : null}
                </div>
                <div className="form-check form-check-custom form-check-solid mb-4">
                    <Field type="checkbox" name="user_fees" className="form-check-input" id="user_fees" onChange={e => {
                        formik.setFieldValue('user_fees', e.target.checked)
                    }} />
                    <label className="form-check-label" htmlFor="user_fees">
                        Fees are charged to user
                    </label>
                </div>
                {!data && (
                    <>
                        <div className="form-check form-check-custom form-check-solid mb-4">
                            <Field type="checkbox" name="random_code" className="form-check-input" id="random_code" onChange={e => {
                                formik.setFieldValue('random_code', e.target.checked)
                                formik.setFieldValue('code', '')
                            }} />
                            <label className="form-check-label" htmlFor="random_code">
                                Generate random coupon code
                            </label>
                        </div>
                        {!formik.values.random_code ? (
                            <div className="form-group mb-4">
                                <label className="form-label required">Coupon Code</label>
                                <input type="text" name="code" className="form-control" placeholder="Code" onChange={formik.handleChange} value={formik.values.code} />
                                {formik.errors.code ? <small className="form-text text-danger">{formik.errors.code}</small> : null}
                            </div>
                        ) : null}
                    </>
                )}
                <button type="submit" className="btn btn-primary w-100 mt-4" disabled={!formik.isValid || isSubmitLoading}>
                    {!!data ? 'Save Changes' : 'Create'} {isSubmitLoading ? <Spinner size="sm" color="white" className="ms-2" /> : null}
                </button>
            </FormikProvider>
        </form>
    )
}

export default CouponForm