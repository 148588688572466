import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { updateCourseSession } from "../../../../../../models/courses/courses"
import CourseSessionForm from "./CourseSessionForm"


const EditSessionModal = ({ queryClient, scheduleQueryKey, schedule, courseId, session, show, onHide }) => {
    const { mutate, isLoading } = useMutation(data => updateCourseSession(courseId, schedule.id, session.id, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([scheduleQueryKey])
            onHide()
        },
        onError: error => {
            toast.error(error.message)
        }
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Session</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="rounded border p-3 bg-light-primary">
                    <p className="mb-0 fw-semibold">Schedule: {schedule?.name}</p>
                </div>
                <div className="separator my-6"></div>
                <CourseSessionForm
                    schedule={schedule}
                    courseId={courseId}
                    data={session}
                    onSubmit={data => mutate(data)}
                    submitLoading={isLoading}
                />
            </Modal.Body>
        </Modal>
    )
}

export default EditSessionModal