import * as Yup from 'yup'


export const courseSchema = Yup.object().shape({
    title: Yup.string().required('Course title is required').min(5, 'Course title must be at least 5 characters').max(255, 'Course title must be at most 255 characters'),
    category_id: Yup.object().required('Category is required'),
    mentor_id: Yup.object().required('Mentor is required'),
    other_mentor_ids: Yup.array(),
    short_description: Yup.string().min(10, 'Short description must be at least 10 characters'),
    level: Yup.string().required('Level is required').oneOf(['beginner', 'intermediate', 'advanced']),
    price: Yup.number().min(0).required('Price is required'),
    discounted_price: Yup.number().min(0),
    trailer_source: Yup.string().required('Trailer source is required').oneOf(['youtube', 'system'])
})