import { useFormik } from "formik"
import AsyncSelect from "react-select/async"
import ReactDatePicker from "react-datepicker"
import * as Yup from "yup"
import moment from "moment"

import { useAuth } from "../../../../modules/auth"


const schema = Yup.object().shape({
    merchant: Yup.object().required('Merchant is required'),
    start_date: Yup.string().required('Start Date is required').nullable(),
    end_date: Yup.string().required('End Date is required').nullable(),
})

const CompletionReport = ({ onSearchMerchant }) => {
    const { auth } = useAuth()

    const formik = useFormik({
        initialValues: {
            merchant: '',
            start_date: new Date(moment().add(-1, 'day').hour(14).minute(0).toString()),
            end_date: new Date(moment().hour(14).minute(0).toString())
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {
                merchant_id: values.merchant.value,
                start_date: moment(values.start_date).unix(),
                end_date: moment(values.end_date).unix(),
                _token: auth.access_token
            }
            const downloadUrl = process.env.REACT_APP_API_URL + '/reports/prakerja/completion?' + new URLSearchParams(data).toString()

            window.open(downloadUrl, "_blank")
        }
    })

    return (
        <div className="card">
            <div className="card-body p-6">
                <h3 className="mb-8">Completion Report</h3>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-5">
                        <label className="form-label required">Merchant</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={onSearchMerchant}
                            name="merchant"
                            className="form-control-sm p-0"
                            onChange={option => formik.setFieldValue('merchant', option)}
                            value={formik.values.merchant}
                        />
                        {formik.errors.merchant ? <small className="form-text text-danger">{formik.errors.merchant}</small> : null}
                    </div>
                    <div className="row mb-10">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label required">Start Date</label>
                                <ReactDatePicker
                                    name="start_date"
                                    className="form-control form-control-sm"
                                    placeholderText="Start date"
                                    onChange={date => formik.setFieldValue('start_date', date)}
                                    selected={formik.values.start_date}
                                    startDate={formik.values.start_date}
                                    endDate={formik.values.end_date}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    showTimeSelect
                                    selectsStart
                                />
                                {formik.errors.start_date ? <small className="form-text text-danger">{formik.errors.start_date}</small> : null}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label required">End Date</label>
                                <ReactDatePicker
                                    name="end_date"
                                    className="form-control form-control-sm"
                                    placeholderText="End date"
                                    onChange={date => formik.setFieldValue('end_date', date)}
                                    selected={formik.values.end_date}
                                    startDate={formik.values.start_date}
                                    endDate={formik.values.end_date}
                                    minDate={formik.values.start_date}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    showTimeSelect
                                    selectsEnd
                                />
                                {formik.errors.end_date ? <small className="form-text text-danger">{formik.errors.end_date}</small> : null}
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid}>Download Completion Report</button>
                </form>
            </div>
        </div>
    )
}

export default CompletionReport