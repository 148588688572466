import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/settings/template-messages'

export function getTemplates() {
    return axios.get(API_URL)
}

export function saveTemplate(data) {
    return axios.post(API_URL, data)
}

export function updateTemplate(templateId, data) {
    return axios.put(`${API_URL}/${templateId}`, data)
}

export function deleteTemplate(templateId) {
    return axios.delete(`${API_URL}/${templateId}`)
}

export function buildMessage(templateId, data) {
    return axios.post(`${API_URL}/${templateId}/build`, data)
}