import { useFormik } from "formik"
import { useRef } from "react"
import * as Yup from "yup"

import Spinner from "../../../../../../../components/Spinner/Spinner"

const templates = [
    {
        id: 'SCHEDULE_CODE',
        title: 'Schedule Code'
    }, {
        id: 'SCHEDULE_START_DATE',
        title: 'Schedule Start Date'
    }, {
        id: 'SCHEDULE_END_DATE',
        title: 'Schedule End Date'
    }, {
        id: 'SCHEDULE_START_TIME',
        title: 'Schedule Start Time'
    }, {
        id: 'SCHEDULE_END_TIME',
        title: 'Schedule End Time'
    }, {
        id: 'FR_START_TIME',
        title: 'FR Start Time'
    }, {
        id: 'FR_END_TIME',
        title: 'FR End Time'
    }, {
        id: 'COURSE_TITLE',
        title: 'Course Title'
    }, {
        id: 'USER_NAME',
        title: 'User Name'
    }, {
        id: 'GROUP_CHAT_URL',
        title: 'Group Chat URL'
    }
]

const schema = Yup.object().shape({
    title: Yup.string().required().max(255).label('Title'),
    message: Yup.string().required().label('Message')
})

const TemplateForm = ({ onSubmit, isLoading, data = null }) => {
    const messageRef = useRef(null)

    const formik = useFormik({
        initialValues: {
            title: data?.title ? data.title : '',
            message: data?.message ? data.message : ''
        },
        validationSchema: schema,
        onSubmit
    })

    const insertVariable = variableId => {
        const firstPart = formik.values.message.substring(0, messageRef.current?.selectionStart)
        const lastPart = formik.values.message.substring(messageRef.current?.selectionEnd)
        let newMessage = firstPart + `<${variableId}>` + lastPart

        formik.setFieldValue('message', newMessage)
    }

    return (
        <form className="d-flex flex-column gap-6" onSubmit={formik.handleSubmit}>
            <div className="form-group">
                <label className="form-label">Title</label>
                <input type="text" name="title" className="form-control" placeholder="Title" value={formik.values.title} onChange={formik.handleChange} />
                {formik.errors.title && <small className="form-text text-danger">{formik.errors.title}</small>}
            </div>
            <div className="form-group">
                <label className="form-label">Message</label>
                <textarea ref={messageRef} name="message" className="form-control" rows={8} value={formik.values.message} onChange={formik.handleChange}></textarea>
                {formik.errors.message && <small className="form-text text-danger">{formik.errors.message}</small>}
                <div className="mt-2 text-center">
                    {templates.map(template => <button key={template.id} type="button" className="btn btn-sm btn-light flex-shrink-0 me-2 mb-2" onClick={() => insertVariable(template.id)}>{template.title}</button>)}
                </div>
            </div>
            <button type="submit" className="btn btn-success w-full" disabled={!formik.isValid || isLoading}>
                {data ? 'Update' : 'Save'} Template {isLoading && <Spinner size="sm" />}
            </button>
        </form>
    )
}

export default TemplateForm