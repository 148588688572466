import { Link } from "react-router-dom";

const CodeCell =  ({ voucher }) => (
    <Link to={`/reports/progress`} state={{
        search: {
            type: 'voucher',
            input: voucher.code
        }
    }}>
        <p className="mb-0 text-dark text-hover-primary fw-bold">{voucher.code}</p>
    </Link>
)

export default CodeCell