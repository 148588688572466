import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { updateCategory } from "../../../../models/blog/categories"
import CategoryForm from "./CategoryForm"


const EditCategoryModal = ({ queryClient, queryKey, show, onHide, category }) => {
    const { mutate, isLoading } = useMutation(data => updateCategory(category.id, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([queryKey])
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CategoryForm data={category} onSubmit={data => mutate(data)} submitLoading={isLoading} />
            </Modal.Body>
        </Modal>
    )
}

export default EditCategoryModal