import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useOutletContext } from "react-router-dom"
import { toast } from "react-toastify"
import * as Yup from "yup"

import Spinner from "../../../../components/Spinner/Spinner"

import { updateCourseMedia } from "../../../../models/courses/courses"


const schema = Yup.object().shape({})

const Media = () => {
    const { course } = useOutletContext()

    const [thumbnailPreview, setThumbnailPreview] = useState(course.thumbnail_url)
    
    const formik = useFormik({
        initialValues: {
            trailer_source: course.trailer_source,
            trailer_url: course.trailer_url,
            trailer_file: null,
            thumbnail: null,
            remove_thumbnail: 0
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = new FormData()

            data.append('trailer_source', values.trailer_source)
            if (values.trailer_source === 'youtube' && values.trailer_url) data.append('trailer', values.trailer_url)
            else if (values.trailer_source === 'system' && values.trailer_file) data.append('trailer', values.trailer_file)

            if (values.thumbnail) data.append('thumbnail', values.thumbnail)
            if (values.remove_thumbnail) data.append('remove_thumbnail', values.remove_thumbnail)

            mutate(data)
        }
    })
    
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(data => updateCourseMedia(course.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            queryClient.invalidateQueries([`course-${course.id}`])
            toast.success('Success')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        if (!formik.values.thumbnail) return

        const objectUrl = URL.createObjectURL(formik.values.thumbnail)
        setThumbnailPreview(objectUrl)
        formik.setFieldValue('remove_thumbnail', 0)

        return () => URL.revokeObjectURL(objectUrl)
    }, [formik.values.thumbnail])

    return (
        <div className="card">
            <div className="card-body">
                <h3 className="mb-8">Media</h3>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row align-items-center mb-5">
                        <div className="col-3">
                            <label className="form-label mb-0">Trailer Source</label>
                        </div>
                        <div className="col-9">
                            <select name="trailer_source" className="form-select" onChange={e => {
                                formik.setFieldValue('trailer', undefined)
                                formik.handleChange(e)
                            }} value={formik.values.trailer_source}>
                                <option value="youtube">Youtube</option>
                                <option value="system">System</option>
                            </select>
                        </div>
                    </div>
                    <div className="row align-items-center mb-5">
                        <div className="col-3">
                            <label className="form-label mb-0">{formik.values.trailer_source === 'youtube' ? 'Trailer Url' : 'Trailer File'}</label>
                        </div>
                        <div className="col-9">
                            {formik.values.trailer_source === 'youtube' ? (
                                <input type="text" name="trailer_url" className="form-control" placeholder="Trailer Url" onChange={formik.handleChange} value={formik.values.trailer_url} />
                            ) : (
                                <input type="file" name="trailer_file" className="form-control" placeholder="Trailer File" onChange={e => formik.setFieldValue('trailer_file', e.currentTarget.files[0])} />
                            )}
                        </div>
                    </div>
                    <div className="row align-items-center mb-10">
                        <div className="col-3">
                            <label className="form-label mb-0">Thumbnail</label>
                            <small className="text-muted ms-2">(900x600)</small>
                        </div>
                        <div className="col-9">
                            {thumbnailPreview ? (
                                <div className="d-flex align-items-center mb-3">
                                    <div className="symbol symbol-150px symbol-2by3">
                                        <div className="symbol-label" style={{
                                            backgroundImage: `url('${thumbnailPreview}')`
                                        }}></div>
                                    </div>
                                    <div className="ms-3">
                                        <button type="button" className="btn btn-sm btn-outline btn-outline-danger" onClick={() => {
                                            if (course.thumbnail_url) formik.setFieldValue('remove_thumbnail', 1)
                                            formik.setFieldValue('thumbnail', '')
                                            setThumbnailPreview(null)
                                        }}><i className="fa fa-times p-0"></i></button>
                                    </div>
                                </div>
                            ) : null}
                            <input type="file" name="thumbnail" className="form-control" onChange={e => formik.setFieldValue('thumbnail', e.currentTarget.files[0])}  />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-100" disabled={isLoading || !formik.isValid}>
                        Save Changes {isLoading && <Spinner size="sm" className="ms-2" />}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Media