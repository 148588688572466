import { Field, FieldArray } from "formik"


const CompetencyUnitsSection = ({ formik }) => (
    <>
        <FieldArray name="competency_units">
            {({ push, remove }) => (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-8">
                        <h3 className="mb-0">Competency Units</h3>
                        <button type="button" className="btn btn-sm btn-light-primary" onClick={() => push()}>Add Competency Unit</button>
                    </div>
                    {formik.values.competency_units.length > 0 && formik.values.competency_units.map((_, i) => (
                        <div key={i} className="d-flex align-items-center mb-4">
                            <Field name={`competency_units.${i}`} className="form-control flex-fill me-4" placeholder="Competency" />
                            <div>
                                <button type="button" className="btn btn-sm btn-light-danger" onClick={() => remove(i)}>Remove</button>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </FieldArray>
    </>
)

export default CompetencyUnitsSection