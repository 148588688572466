import { useFormik } from "formik"
import * as Yup from "yup"

import Spinner from "../../../../../components/Spinner/Spinner"
import BasicSection from "./BasicSection"
import clsx from "clsx"
import { useEffect } from "react"


const schema = Yup.object().shape({
    title: Yup.string().required().min(5).max(255).label('Title'),
    word: Yup.string().required().min(5).max(80).label('Word')
})

const PopupQuizFillTheBlank = ({ lesson, popupQuiz, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            title: popupQuiz ? popupQuiz.title : '',
            position: popupQuiz ? popupQuiz.position : 'end',
            description: popupQuiz?.description ? popupQuiz.description : '',
            position_duration: popupQuiz?.position_duration ? popupQuiz.position_duration : lesson.duration,
            word: popupQuiz?.attributes ? popupQuiz.attributes.word : '',
            displayed_char: popupQuiz?.attributes ? popupQuiz.attributes.displayed_char : []
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.type = 'fill_the_blank'
            if (data.position !== 'custom') delete data.position_duration

            onSubmit(data)
        }
    })

    useEffect(() => {
        if (!popupQuiz || popupQuiz.attributes.word !== formik.values.word) formik.setFieldValue('displayed_char', [])
    }, [formik.values.word])

    return (
        <form onSubmit={formik.handleSubmit}>
            <BasicSection formik={formik} lesson={lesson} />
            <div className="form-group mb-4">
                <label className="form-label required">Word</label>
                <input type="text" name="word" className="form-control" placeholder="Word" onChange={formik.handleChange} value={formik.values.word} />
                {formik.errors.word ? <small className="form-text text-danger">{formik.errors.word}</small> : null}
            </div>
            {!!formik.values.word && (
                <div className="d-flex justify-content-center gap-3 mb-8 scroll-x">
                    {formik.values.word.split('').map((w, i) => (
                        <div key={i} className={clsx("w-40px h-40px border rounded d-flex justify-content-center align-items-center flex-shrink-0 cursor-pointer bg-hover-light-primary text-uppercase", formik.values.displayed_char.includes(i) && 'bg-light-primary')} onClick={() => {
                            let oldValue = formik.values.displayed_char
                            let newValue;
                            if (formik.values.displayed_char.includes(i)) {
                                let index = formik.values.displayed_char.findIndex(ci => ci === i)
                                oldValue.splice(index, 1)

                                newValue = oldValue
                            } else {
                                newValue = [...formik.values.displayed_char, i]
                            }

                            formik.setFieldValue('displayed_char', newValue)
                        }}>{w}</div>
                    ))}
                </div>
            )}
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                {!popupQuiz ? 'Create' : 'Edit'} Popup Quiz {isSubmitLoading ? <Spinner size="sm" /> : null}
            </button>
        </form>
    )
}

export default PopupQuizFillTheBlank