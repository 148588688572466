import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { loginAsUser } from "../../../../../../models/users"
import Spinner from "../../../../../../components/Spinner/Spinner"


const LoginAsUser = ({ user }) => {
    const { isLoading, mutate } = useMutation(userId => loginAsUser({ userId }), {
        onSuccess: data => window.open(data.data, '_newtab'),
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => mutate(user.id)} disabled={isLoading}>Login as User {isLoading ? <Spinner size="sm" color="white" className="ms-2" /> : null}</button>
    )
}

export default LoginAsUser