import { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { PageTitle } from "../../../../_metronic/layout/core"
import Authorization from "../../../routing/Authorization"
import PageSpinner from "../../../components/Spinner/PageSpinner"

import { getProgress } from "../../../models/reports"
import CheckProgressForm from "./components/CheckProgressForm"
import ResultVoucher from "./components/Results/Voucher"
import ResultEmail from "./components/Results/Email"
import PageError from "../../../components/Error/PageError"
import PrakerjaOwner from "./components/Results/PrakerjaOwner"
import ResultCoupon from "./components/Results/Coupon"
import { useLocation } from "react-router-dom"


const CheckProgress = () => {
    const location = useLocation()

    const pageSize = 10
    const [search, setSearch] = useState({
        type: location.state?.search?.type,
        input: location.state?.search?.input
    })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [page, setPage] = useState(1)

    const queryClient = useQueryClient()
    const { isFetching: isLoading, isError, error, isFetched, data } = useQuery('check-progress', () => getProgress(search.type, search.input, page, pageSize), {
        enabled: false,
        cacheTime: 0,
        retry: 0
    })

    useEffect(() => {
        if (!search.type) return () => false
        if (search.type !== 'prakerja_owner' && page !== 1) setPage(1)

        queryClient.invalidateQueries('check-progress')
        queryClient.fetchQuery('check-progress')
    }, [isSubmitting, search, page])

    return (
        <div className="min-h-500px">
            <PageTitle>Check Progress</PageTitle>
            <div className="card">
                <div className="card-body p-6">
                    <CheckProgressForm
                        initialValues={{
                            type: location.state?.search?.type,
                            input: location.state?.search?.input
                        }}
                        submitLoading={isLoading}
                        onSubmit={data => {
                            setIsSubmitting(item => !item)
                            setSearch(data)
                        }}
                    />
                </div>
            </div>
            {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : isFetched ? (
                search.type === 'voucher' ? (
                    <ResultVoucher data={data.data} />
                ) : search.type === 'email' ? (
                    <ResultEmail data={data.data} />
                ) : search.type === 'prakerja_owner' ? (
                    <PrakerjaOwner data={data} page={page} pageSize={pageSize} onPageChange={page => setPage(page)} onView={code => {
                        setIsSubmitting(item => !item)
                        setSearch({
                            type: 'voucher',
                            input: code
                        })
                    }} />
                ) : search.type === 'coupon' ? (
                    <ResultCoupon data={data.data} />
                ) : null
            ) : null}
        </div>
    )
}

export default Authorization(CheckProgress, ['admin', 'officer', 'customer_service'])