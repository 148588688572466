import { useRef, useState } from "react"
import { useMutation } from "react-query"

import { compareSiva } from "../../../../../../models/courses/schedules"
import Spinner from "../../../../../../components/Spinner/Spinner"
import { Modal } from "react-bootstrap"
import { toast } from "react-toastify"

const CompareSiva = ({ scheduleId }) => {
    const compareSivaFileRef = useRef(null)
    const [show, setShow] = useState(false)
    const [students, setStudents] = useState({})

    const { isLoading, mutate } = useMutation(data => compareSiva(scheduleId, data), {
        onError: () => toast.error('Failed to compare with SIVA'),
        onSuccess: data => {
            setStudents(data)
            setShow(true)
        }
    })

    const handleChange = event => {
        if (!event.target.files[0]) return true

        const data = new FormData()
        data.append('file', event.target.files[0])

        mutate(data)
        event.target.value = null;
    }

    return (
        <>
            <input type="file" ref={compareSivaFileRef} className="d-none" onChange={handleChange} />
            <button type="button" className="btn btn-sm btn-light-primary me-2" onClick={() => compareSivaFileRef.current?.click()} disabled={isLoading}>
                {isLoading && <Spinner size="sm" className="me-1" color="primary" />} Compare with SIVA
            </button>

            <Modal show={show} onHide={() => setShow(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Compare with SIVA</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table table-bordered table-row-bordered table-row-gray-300 gy-4 border-bottom">
                            <thead className="fw-bold text-muted">
                                <tr>
                                    <th className="ps-2">Name</th>
                                    <th>Invoice Code</th>
                                    <th className="text-center">Digital Platform</th>
                                    <th className="text-center">Imported</th>
                                    <th className="text-center pe-2">Void</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(students).map(invoiceCode => (
                                    <tr>
                                        <td className="ps-2">{students[invoiceCode].name}</td>
                                        <td>{invoiceCode}</td>
                                        <td className="text-center">{students[invoiceCode].digital_platform}</td>
                                        <td className="text-center">{students[invoiceCode].imported ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-danger"></i>}</td>
                                        <td className="text-center pe-2">{students[invoiceCode].void ? <i className="fa fa-check text-danger"></i> : ''}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CompareSiva