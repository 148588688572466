import LessonCell from "./LessonCell"
import UserCell from "./UserCell"
import FilesCell from "./FilesCell"
import DateTimeCell from "../../../../../components/Table/cells/DateTime"


const taskColumns = [
    {
        Header: 'Lesson',
        accessor: 'lesson',
        Cell: ({ ...props }) => <LessonCell lesson={props.data[props.row.index]} />
    }, {
        Header: 'User',
        accessor: 'user',
        Cell: ({ ...props }) => <UserCell user={props.data[props.row.index]} />
    }, {
        Header: 'Files',
        accessor: 'files',
        Cell: ({ ...props }) => <FilesCell files={props.data[props.row.index].files} />
    }, {
        Header: 'Score',
        accessor: 'score',
        Cell: ({ ...props }) => props.data[props.row.index].score !== null ? props.data[props.row.index].score : '-'
    }, {
        Header: 'Last Update',
        accessor: 'updated_at',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].updated_at} />
    }
]

const columnAliases = {
}

export { taskColumns, columnAliases }