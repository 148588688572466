import { Modal } from "react-bootstrap"
import VideoPlayer from "../../../../components/VideoPlayer"


const LessonViewVideoModal = ({ lesson, show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{lesson?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                {lesson ? (
                    <VideoPlayer
                        options={{
                            autoplay: true,
                            controls: true,
                            responsive: true,
                            fluid: true,
                            sources: [{
                                src: lesson.content_url,
                                type: "video/mp4",
                                label: "1080P",
                                selected: true,
                            }, ...[lesson.videos?.map(v => ({
                                src: v.video_url,
                                type: "video/mp4",
                                label: v.quality + "P",
                            }))]]
                        }}
                    />
                ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default LessonViewVideoModal