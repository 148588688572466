import { useEffect, useRef, useState } from "react"
import { useMutation } from "react-query"
import clsx from "clsx"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { toast } from "react-toastify"

import { uploadFile, deleteFile } from "../../../../../../../../models/files"


const ImageBlock = ({ blockId, value = null, isThumbnail = false, onChange, onRemove, onShowImageDetailModal, onCollectiveDeleteImage, isUpdateMode = false }) => {
    const contentBlock = useRef(null)

    const [showToolbox, setShowToolbox] = useState(false)
    const [imageFile, setImageFile] = useState()
    const [imagePreview, setImagePreview] = useState(value ? value?.file_url : null)

    const { mutate, isLoading } = useMutation(file => {
        const data = new FormData()
        data.append('source', 'blog_post_contents')
        data.append('file_type', 'image')
        data.append('file', file)

        return uploadFile(data)
    }, {
        onSuccess: data => onChange({
            id: data.data.id,
            file_url: data.data.file_url
        }),
        onError: error => {
            toast.error(error?.response?.data?.message || error.message)
            setImageFile(null)
            setImagePreview(null)
            onChange({})
        }
    })

    const {
        attributes: sortAttributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: blockId })

    const style = {
        transform: CSS.Translate.toString(transform),
        transition
    }

    const hideToolbox = e => {
        if (contentBlock.current && !contentBlock.current.contains(e.target)) setShowToolbox(false)
    }

    const removeBlock = () => {
        if (value) deleteImage(value.id)

        onRemove()
    }

    const deleteImage = imageId => {
        if (isUpdateMode) onCollectiveDeleteImage(imageId)
        else deleteFile(imageId)
    }

    useEffect(() => {
        document.addEventListener('click', hideToolbox)

        return () => document.removeEventListener('click', hideToolbox)
    }, [])

    useEffect(() => {
        if (!imageFile) return

        const objectUrl = URL.createObjectURL(imageFile)
        setImagePreview(objectUrl)

        mutate(imageFile)

        return () => URL.revokeObjectURL(objectUrl)
    }, [imageFile])

    return (
        <div className="content-block" ref={setNodeRef} style={style}>
            <div ref={contentBlock}>
                <div className={clsx(
                    "content-toolbox",
                    {
                        "hidden": !showToolbox || (isThumbnail && !value?.id)
                    }
                )}>
                    {!isThumbnail ? (
                        <button type="button" className="content-toolbox-item" {...sortAttributes} {...listeners}>
                            <i className="fa fa-up-down-left-right"></i>
                        </button>
                    ) : null}
                    {value?.id ? (
                        <>
                            <button type="button" className={clsx(
                                "content-toolbox-item",
                                {
                                    "divider": !isThumbnail
                                }
                            )} onClick={() => onShowImageDetailModal()}>
                                <i className="fa fa-note-sticky"></i>
                            </button>
                            <button type="button" className="content-toolbox-item divider" onClick={() => {
                                deleteImage(value.id)
                                setImageFile(null)
                                setImagePreview(null)
                                onChange({})
                            }}>
                                <i className="fa fa-shuffle"></i>
                            </button>
                        </>
                    ) : null}
                    {!isThumbnail ? (
                        <button type="button" className="content-toolbox-item divider" title="Remove block" onClick={() => removeBlock()}>
                            <i className="fa fa-trash"></i>
                        </button>
                    ) : null}
                </div>
                <div onClick={() => setShowToolbox(true)}>
                    {!imagePreview ? (
                        <div className={clsx(
                            "rounded border-3 border-dashed min-h-200px d-flex flex-column justify-content-center p-6",
                            {
                                "bg-light": showToolbox
                            }
                        )}>
                            <h2 className="text-capitalize mb-4">
                                <i className="fa fa-image fs-2 me-2 text-primary"></i>{isThumbnail ? 'Thumbnail' : 'Image'}
                            </h2>
                            <p>Upload an image file or add one with a URL</p>
                            <p className="mb-0">
                                <input type="file" name="thumbnail" className="form-control" accept="image/png, image/jpeg" onChange={e => setImageFile(e.currentTarget.files[0])} />
                            </p>
                        </div>
                    ) : (
                        <div>
                            {isLoading ? (
                                <div className="overlay overlay-block card-rounded">
                                    <div className="overlay-wrapper">
                                        <img src={imagePreview} className="img-fluid rounded" />
                                    </div>
                                    <div className="overlay-layer card-rounded bg-dark bg-opacity-25">
                                        <div className="spinner-border text-white" style={{
                                            width: '3rem',
                                            height: '3rem'
                                        }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <img src={value.file_url} className="img-fluid rounded" />
                                    {value.attributes?.caption ? (
                                        <p className="text-center text-muted mb-0">{value.attributes.caption}</p>
                                    ) : null}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ImageBlock 