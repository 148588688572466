import { usePagination, DOTS } from '../../hooks/usePagination'


const Pagination = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }) => {
    const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize })

    if (currentPage === 0) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <ul className="pagination">
            <li className={"page-item previous " + (currentPage === 1 ? 'disabled' : '')} onClick={currentPage > 1 ? onPrevious : undefined}>
                <span className="page-link cursor-pointer">
                    <i className="previous"></i>
                </span>
            </li>
            {paginationRange.map((pageNumber, i) => {
                if (pageNumber === DOTS) {
                    return <li className="page-item" key={i}>&#8230;</li>
                }

                return (
                    <li key={i} className={"page-item " + (pageNumber === currentPage ? 'active' : '')} onClick={() => onPageChange(pageNumber)}>
                        <span className="page-link cursor-pointer">
                            {pageNumber}
                        </span>
                    </li>
                )
            })}
            <li className={"page-item next " + (currentPage === lastPage ? 'disabled' : '')} onClick={currentPage < lastPage ? onNext : undefined}>
                <span className="page-link cursor-pointer">
                    <i className="next"></i>
                </span>
            </li>
        </ul>
    )

};

export default Pagination;
