import { courseThumbnail } from "../../../../../helpers/pictures"

const CourseCell = ({ course }) => {
    if (!course) return <></>

    return (
        <div className="d-flex align-items-center">
            <div className="symbol symbol-40px symbol-2by3">
                <div className="symbol-label" style={{
                    backgroundImage: `url('${courseThumbnail(course.thumbnail_url)}')`
                }}></div>
            </div>
            <div className="ms-3">
                <p className="mb-0 fw-bold">{course.title}</p>
                <p className="mb-0">
                    <small>{course.main_mentor?.name}</small>
                </p>
            </div>
        </div>
    )
}

export default CourseCell