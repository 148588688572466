import clsx from "clsx"

const LessonCell = ({ lesson }) => {
    return (
        <>
        <p className={clsx("text-xs fw-semibold mb-1", lesson.task_type === 'final' ? 'text-danger' : 'text-primary')}>{lesson.section_title}</p>
        <p className="mb-2">{lesson.title}</p>
        <p className="text-xs text-muted mb-0">{lesson.course_title}</p>
        </>
    )
}

export default LessonCell