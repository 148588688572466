import { useEffect, useState } from "react"
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Modal } from "react-bootstrap"

import Spinner from "./Spinner/Spinner"
import PageSpinner from "./Spinner/PageSpinner"
import PageError from "./Error/PageError"


const SortableItem = ({ id, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <div className="border bg-light p-5 rounded my-3" ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <p className="mb-0 fw-bold">{children}</p>
        </div>
    )
}

const SortModal = ({ show, modalTitle, initialData = [], primaryKey = 'id', titleKey = 'title', withNumbering = false, isLoading, isError, error, submitLoading, onSubmit, onHide }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        setData(initialData)
    }, [initialData])

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    function handleDragEnd(event) {
        const { active, over } = event

        if (active[primaryKey] !== over[primaryKey]) {
            setData(data => {
                const oldIndex = data.findIndex(v => v[primaryKey] === active[primaryKey])
                const newIndex = data.findIndex(v => v[primaryKey] === over[primaryKey])

                return arrayMove(data, oldIndex, newIndex)
            });
        }
    }

    return (
        <>
            <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? (
                        <PageSpinner
                            height={300}
                            color="primary"
                        />
                    ) : isError ? (
                        <PageError height={300}>{error.message}</PageError>
                    ) : (
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={data}
                                strategy={verticalListSortingStrategy}
                            >
                                {data.map((v, i) => (
                                    <SortableItem key={v[primaryKey]} id={v[primaryKey]}>{withNumbering ? `${i + 1}. ` : null}{v[titleKey]}</SortableItem>
                                ))}
                            </SortableContext>
                        </DndContext>
                    )}
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <button className="btn btn-secondary" onClick={onHide}>Cancel</button>
                    <button className="btn btn-primary" onClick={() => onSubmit(data)} disabled={submitLoading}>
                        Save Changes {submitLoading && <Spinner size="sm" />}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SortModal