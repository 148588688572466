import { useFormik } from "formik"
import { Modal } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import * as Yup from "yup"
import Spinner from "../../../../../../components/Spinner/Spinner"
import { updateScheduleStudentSkillTestScore } from "../../../../../../models/courses/schedules"


const schema = Yup.object().shape({
    skill_test_score: Yup.number().required().min(0).max(100).label('Skill Test Score')
})

const SkillTestScoreForm = ({ data, onSubmit, submitLoading }) => {
    const formik = useFormik({
        initialValues: {
            skill_test_score: data?.skill_test_score
        },
        validationSchema: schema,
        onSubmit: values => onSubmit(values)
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-8">
                <label className="form-label">Skill Test Score</label>
                <input type="text" name="skill_test_score" className="form-control" placeholder="Skill Test Score" onChange={formik.handleChange} value={formik.values.skill_test_score} />
                {formik.errors.skill_test_score ? <small className="form-text text-danger">{formik.errors.skill_test_score}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                Save Score {submitLoading && <Spinner size="sm" />}
            </button>
        </form>
    )
}

const SkillTestScoreModal = ({ show, onHide, queryKey, scheduleId, student }) => {
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(data => updateScheduleStudentSkillTestScore({ scheduleId, userId: student?.id, skillTestScore: data.skill_test_score}), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([queryKey])
            onHide()
        },
        onError: error => {
            toast.error(error.message)
        }
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Set Skill Test Score</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {student ? (
                    <>
                        <p className="fw-bold fs-4 mb-0">{student.name}</p>
                        <p className="text-muted mb-0">{student.email}</p>
                        <p className="text-muted mb-0">{student.phone_number}</p>
                        <div className="separator my-3"></div>
                        <SkillTestScoreForm
                            data={student}
                            onSubmit={data => mutate(data)}
                            submitLoading={isLoading}
                        />
                    </>
                ) : ''}
            </Modal.Body>
        </Modal>
    )
}

export default SkillTestScoreModal