import clsx from "clsx"
import { useOutletContext } from "react-router-dom"
import { useQuery } from "react-query"

import { formatDate } from "../../../../helpers/common"
import SummaryCard from "../../../dashboard/components/SummaryCard"
import { getScheduleStatistic } from "../../../../models/courses/schedules"


const Items = ({ title, value, className }) => (
    <div className={clsx("d-flex justify-content-between gap-3", className)}>
        <p className="mb-0 fw-bold">{title}</p>
        <p className="mb-0 text-end">{value}</p>
    </div>
)

const Statistic = ({ schedule }) => {
    const { isLoading, isError, error, data: { data } = {} } = useQuery('schedule-statistic', () => getScheduleStatistic(schedule.id))

    return (
        <div className="row mt-6 mt-md-0">
            <div className="col-12">
                <SummaryCard
                    label="Total Students"
                    value={schedule.total_students}
                    icon="/media/icons/duotune/communication/com006.svg"
                />
            </div>
            <div className="col-6 col-md-6 mt-6">
                <SummaryCard
                    label="Total Redeemed"
                    value={data?.total_redeemed ? data.total_redeemed : 0}
                    icon="/media/icons/duotune/communication/com006.svg"
                    iconClassName="svg-icon-success"
                    isLoading={isLoading}
                    isError={isError}
                    error={error}
                />
            </div>
            <div className="col-6 col-md-6 mt-6">
                <SummaryCard
                    label="Total Not Redeem"
                    value={data?.total_not_redeem ? data.total_not_redeem : 0}
                    icon="/media/icons/duotune/communication/com006.svg"
                    iconClassName="svg-icon-danger"
                    isLoading={isLoading}
                    isError={isError}
                    error={error}
                />
            </div>
        </div>
    )
}

export default function Overview() {
    const { schedule } = useOutletContext()

    return (
        <div className="row min-h-500px">
            <div className="col-12 col-md-6">
                <div className="card">
                    <div className="card-body">
                        <Items className="mb-4" title="Schedule" value={schedule.name} />
                        <Items className="mb-4" title="Schedule Code" value={schedule.schedule_code} />
                        <Items className="mb-4" title="Start Date" value={formatDate(schedule.schedule_start, false)} />
                        <Items className="mb-4" title="End Date" value={formatDate(schedule.schedule_end, false)} />
                        <Items className="mb-4" title="Course" value={schedule.course?.title} />
                        <Items className="mb-0" title="Mentor" value={schedule.course?.main_mentor?.name} />
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <Statistic schedule={schedule} />
            </div>
        </div>
    )
}
