import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { PageTitle } from "../../../_metronic/layout/core"
import Authorization from "../../routing/Authorization"
import DeleteConfirmModal from "../../components/DeleteConfirmModal"

import { getRoles, deleteRole } from "../../models/roles"
import RoleFilter from "./components/RoleFilter"
import RoleTable from "./components/RoleTable/RoleTable"
import { columnAliases } from "./components/RoleTable/_columns"
import RolePagination from "./components/RolePagination"


const ManageRoles = () => {
    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState()
    const [deleteRoleData, setDeleteRoleData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isError, error, data, isSuccess } = useQuery(['roles'], () => getRoles({
        name: filters?.name,
        page,
        rows: pageSize,
        sortBy
    }))
    const { mutate: deleteRoleMutate, isLoading: deleteRoleLoading } = useMutation(roleId => deleteRole(roleId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries(['roles'])
        },
        onError: error => toast.error(error.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(['roles'])
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <PageTitle>Manage Roles</PageTitle>
            <div className="card">
                <div className="card-header p-6 d-block">
                    <div className="d-flex justify-content-between">
                        <button type="button" className={"btn btn-sm " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        {/* <Link to="/roles/create">
                            <button className="btn btn-sm btn-primary">Create Role</button>
                        </Link> */}
                    </div>
                    <RoleFilter
                        show={showFilter}
                        queryClient={queryClient}
                        onSubmit={values => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className="card-body p-6">
                    <RoleTable
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        onDelete={role => {
                            setShowDeleteRoleModal(true)
                            setDeleteRoleData(role)
                        }}
                    />
                    {isSuccess && <RolePagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>

            <DeleteConfirmModal
                show={showDeleteRoleModal}
                handleClose={() => setShowDeleteRoleModal(false)}
                onConfirm={() => {
                    deleteRoleMutate(deleteRoleData.id)
                    setShowDeleteRoleModal(false)
                    setDeleteRoleData({})
                }}
                isConfirmLoading={deleteRoleLoading}
                name={deleteRoleData?.code}
            />
        </>
    )
}

export default Authorization(ManageRoles, ['admin'])