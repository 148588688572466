import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { PageTitle } from "../../../../_metronic/layout/core"
import Authorization from "../../../routing/Authorization"
import DeleteConfirmModal from "../../../components/DeleteConfirmModal"
import GeneralPagination from "../../../components/Pagination/GeneralPagination"

import { getProducts, deleteProduct } from "../../../models/products/products"
import ProductFilter from "./components/ProductFilter"
import { productColumns, columnAliases } from "./components/ProductTable/_columns"
import Table from "../../../components/Table/Table"
import CreateProductModal from "./components/CreateProductModal"
import EditProductModal from "./components/EditProductModal"


const Products = () => {
    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showCreateProductModal, setShowCreateProductModal] = useState(false)
    const [showEditProductModal, setShowEditProductModal] = useState(false)
    const [editProductData, setEditProductData] = useState({})
    const [showDeleteProductModal, setShowDeleteProductModal] = useState()
    const [deleteProductData, setDeleteProductData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery(['products'], () => getProducts({
        title: filters?.title,
        categoryId: filters?.category_id,
        status: filters?.status,
        page,
        rows: pageSize,
        sortBy
    }))
    const { mutate: deleteProductMutate, isLoading: isDeleteProductLoading } = useMutation(productId => deleteProduct(productId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries(['products'])
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(['products'])
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <div className="min-h-500px">
                <PageTitle>Products</PageTitle>
                <div className="card">
                    <div className="card-header p-6 d-block">
                        <div className="d-flex justify-content-between">
                            <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowCreateProductModal(true)}>Create Product</button>
                        </div>
                        <ProductFilter
                            show={showFilter}
                            queryClient={queryClient}
                            onSubmit={values => {
                                setPage(1)
                                setFilters(values)
                            }}
                        />
                    </div>
                    <div className="card-body p-6">
                        <Table
                            columns={productColumns}
                            isLoading={isFetching}
                            isError={isError}
                            error={error}
                            data={!isLoading ? data.data : []}
                            currentPage={page}
                            pageSize={pageSize}
                            onSortByChange={sortBy => setSortBy(() => {
                                if (sortBy.length) return {
                                    id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                    direction: sortBy[0].desc ? 'DESC' : 'ASC'
                                }
                        
                                return {}
                            })}
                            onEdit={product => {
                                setShowEditProductModal(true)
                                setEditProductData(product)
                            }}
                            onDelete={product => {
                                setShowDeleteProductModal(true)
                                setDeleteProductData(product)
                            }}
                        />
                        
                        {isSuccess && <GeneralPagination
                            onPageChange={page => setPage(page)}
                            totalCount={data?.meta.total}
                            currentPage={page}
                            pageSize={pageSize}
                            from={data?.meta.from}
                            to={data?.meta.to}
                        />}
                    </div>
                </div>
            </div>
            
            <CreateProductModal
                show={showCreateProductModal}
                onHide={() => setShowCreateProductModal(false)}
            />
            <EditProductModal
                product={editProductData}
                show={showEditProductModal}
                onHide={() => {
                    setShowEditProductModal(false)
                    setEditProductData({})
                }}
            />
            <DeleteConfirmModal
                show={showDeleteProductModal}
                handleClose={() => setShowDeleteProductModal(false)}
                onConfirm={() => {
                    deleteProductMutate(deleteProductData.id)
                    setShowDeleteProductModal(false)
                    setDeleteProductData({})
                }}
                isConfirmLoading={isDeleteProductLoading}
                name={deleteProductData.title}
            />
        </>
    )
}

export default Authorization(Products, ['admin', 'officer', 'user'])