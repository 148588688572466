import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/blog/posts'

export function getPosts({ title = null, status = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}`, {
        params: {
            title,
            status,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getPost(postId) {
    return axios.get(`${API_URL}/${postId}`)
}

export function savePost(data) {
    return axios.post(`${API_URL}`, data)
}

export function updatePost(postId, data) {
    return axios.put(`${API_URL}/${postId}`, data)
}

export function deletePost(postId) {
    return axios.delete(`${API_URL}/${postId}`)
}

export function getPostPreview(postId) {
    return axios.get(`${API_URL}/${postId}/preview`)
}