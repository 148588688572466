import CouponDetail from './components/CouponDetail'
import Enrolment from './components/Enrolment'


const ResultCoupon = ({ data }) => (
    <>
        <p className="fw-bold mt-6 mb-4">Coupon Detail</p>
        <CouponDetail coupon={data.coupons ? data.coupons[0] : {}} />
        {data.enrols?.length > 0 ? (
            <>
                <p className="fw-bold mt-6 mb-4">Used by</p>
                {data.enrols.map(enrol => (
                    <Enrolment showLoginAsUser enrol={enrol} />
                ))}
            </>
        ) : null}
    </>
)

export default ResultCoupon 