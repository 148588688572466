import { Modal } from "react-bootstrap"
import Spinner from "./Spinner/Spinner"

const DeleteConfirmModal = ({ show, handleClose, onConfirm, isConfirmLoading = false, name = null }) => {
    return (
        <Modal show={show} onHide={handleClose} centered size="sm">
            <Modal.Header>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-0">Are you sure want to delete {name ? <><strong>{name}</strong>?</> : 'this data?' }</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-secondary" onClick={handleClose}>Cancel</button>
                <button className="btn btn-sm btn-danger" onClick={onConfirm} disabled={isConfirmLoading}>
                    {isConfirmLoading ? (
                        <>
                            Deleting...
                            <Spinner
                                size="sm"
                                color="white"
                                className="ms-2"
                            />
                        </>
                    ) : 'Delete'}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirmModal