import { useState } from "react"
import { KTSVG, toAbsoluteUrl } from "../../../../../../_metronic/helpers"


const LessonTypeItem = ({ icon, isSelected, children, onClick }) => (
    <div className={"border border-dashed border-gray-400 rounded p-3 d-flex align-items-center cursor-pointer " + (isSelected ? 'bg-light-primary' : 'bg-hover-light-primary')} onClick={onClick}>
        <div className="w-40px">
            <KTSVG path={toAbsoluteUrl(icon)} className="svg-icon-primary svg-icon-2x" />
        </div>
        <p className="text-gray-700 fw-semibold mb-0 fs-6">{children}</p>
    </div>
)


const ChooseLessonType = ({ onContinue }) => {
    const [selectedLessonType, setSelectedLessonType] = useState()

    return (
        <>
            <h4 className="mb-5 text-gray-800">Choose Lesson Type</h4>
            <div className="row">
                <div className="col-6">
                    <LessonTypeItem icon={"/media/icons/duotune/abstract/abs032.svg"} isSelected={selectedLessonType === 'video'} onClick={() => setSelectedLessonType('video')}>Video</LessonTypeItem>
                </div>
                <div className="col-6">
                    <LessonTypeItem icon={"/media/icons/duotune/general/gen005.svg"} isSelected={selectedLessonType === 'document'} onClick={() => setSelectedLessonType('document')}>Document</LessonTypeItem>
                </div>
                <div className="col-6 mt-4">
                    <LessonTypeItem icon={"/media/icons/duotune/abstract/abs029.svg"} isSelected={selectedLessonType === 'quiz'} onClick={() => setSelectedLessonType('quiz')}>Quiz</LessonTypeItem>
                </div>
                <div className="col-6 mt-4">
                    <LessonTypeItem icon={"/media/icons/duotune/medicine/med005.svg"} isSelected={selectedLessonType === 'practical_task'} onClick={() => setSelectedLessonType('practical_task')}>Practical Task</LessonTypeItem>
                </div>
                <div className="col-6 mt-4">
                    <LessonTypeItem icon={"/media/icons/duotune/electronics/elc004.svg"} isSelected={selectedLessonType === 'webinar'} onClick={() => setSelectedLessonType('webinar')}>Webinar</LessonTypeItem>
                </div>
                <div className="col-6 mt-4">
                    <LessonTypeItem icon={"/media/icons/duotune/abstract/abs047.svg"} isSelected={selectedLessonType === 'offline_activity'} onClick={() => setSelectedLessonType('offline_activity')}>Offline Activity</LessonTypeItem>
                </div>
                <div className="col-6 mt-4">
                    <LessonTypeItem icon={"/media/icons/duotune/general/gen055.svg"} isSelected={selectedLessonType === 'reflective_journal'} onClick={() => setSelectedLessonType('reflective_journal')}>Reflective Journal</LessonTypeItem>
                </div>
            </div>
            <button className="btn w-100 mt-8 btn-primary" disabled={!selectedLessonType} onClick={() => onContinue(selectedLessonType)}>Continue</button>
        </>
    )
}

export default ChooseLessonType