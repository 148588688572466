import { userProfilePicture } from "../../../../../helpers/pictures"


const OwnerCell = ({ user }) => (
    <div className="d-flex align-items-center">
        <img src={userProfilePicture(user.profile_picture_url)} className="me-3 rounded-circle h-30px" alt="Profile" />
        <p className="mb-0">{user.name}</p>
    </div>
)

export default OwnerCell