import { Link } from "react-router-dom"
import { courseThumbnail } from "../../../../../helpers/pictures"


const CourseCell = ({ course }) => (
    <Link to={'/courses/manage/' + course.id + '/curriculum'}>
        <div className="d-flex align-items-center text-dark text-hover-primary">
            <div className="symbol symbol-40px symbol-2by3">
                <div className="symbol-label" style={{
                    backgroundImage: `url('${courseThumbnail(course.thumbnail_url)}')`
                }}></div>
            </div>
            <div className="ms-3">
                <p className="mb-1 fs-6">
                    {!!course.is_prakerja && <span className="badge badge-light-primary me-1">Prakerja</span>}
                    <span className="badge badge-light text-capitalize">{course.type}</span>
                    {!!course.is_top_course && <span className="badge badge-light-success text-capitalize ms-1">Top Course</span>}
                </p>
                <p className="mb-0 fw-bold">{course.title} {!!course.highlight && <i className="fa fa-star text-warning"></i>}</p>
            </div>
        </div>
    </Link>
)

export default CourseCell