import moment from 'moment'


export const toRupiah = value => Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0
}).format(value)


export const formatDate = (value, withTime = true) => value ? moment.utc(value).local().format(withTime ? 'DD MMMM YYYY HH:mm' : 'DD MMMM YYYY') : '-'


export const calculatePercentage = (value, valueOf) => (100 * value) / valueOf


export const unSlug = slug => {
    const words = slug.split('_')
    for (var i = 0; i < words.length; i++) {
        const word = words[i]
        words[i] = word.charAt(0).toUpperCase() + word.slice(1)
    }

    return words.join(' ')
}


export const eventDate = value => value ? moment.utc(value).local().format('dddd, DD MMMM YYYY - HH:mm') : '-'


export const parseBlogContent = content => {
    const tempContent = {...content}
    tempContent.body = tempContent.body.replace("ql-align-center", "text-center")
    tempContent.body = tempContent.body.replace("ql-align-right", "text-end")
    tempContent.body = tempContent.body.replace("ql-align-justify", "text-end")

    return tempContent
}


export const secondsToHoursAndMinutes = totalSeconds => {
    const totalMinutes = Math.floor(totalSeconds / 60)
  
    const seconds = totalSeconds % 60
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
  
    return { hours, minutes, seconds }
}


export const durationToText = duration =>  {
    if (!duration) duration = 0

    const formattedSeconds = secondsToHoursAndMinutes(duration)
    let formattedDuration = ''

    if (formattedSeconds.hours > 0) formattedDuration = formattedSeconds.hours + ' Jam'
    if (formattedSeconds.minutes > 0) formattedDuration += ' ' + formattedSeconds.minutes + ' Menit'

    return formattedDuration
}

export const getDateRange = (firstDate, lastDate) => {
    if (moment(firstDate, 'YYYY-MM-DD').isSame(moment(lastDate, 'YYYY-MM-DD'), 'day')) return [lastDate]

    let date = firstDate
    const dates = [date]

    do {
        date = moment(date).add(1, 'day')
        dates.push(date.format('YYYY-MM-DD'))
    } while (moment(date).isBefore(lastDate))
    
    return dates
}