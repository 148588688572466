import { Modal } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import { FormikProvider, useFormik, Field } from "formik"
import * as Yup from "yup"
import AsyncSelect from "react-select/async"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { toast } from "react-toastify"

import { useAuth } from "../../../../../modules/auth"
import { getCourses } from "../../../../../models/courses/courses"
import { getMerchants } from "../../../../../models/merchants"
import Spinner from "../../../../../components/Spinner/Spinner"
import { generateCoupons } from "../../../../../models/courses/coupons"


const schema = Yup.object().shape({
    title: Yup.string().required().label('Title'),
    total: Yup.number().required().min(1).max(50000).label('Total'),
    merchant: Yup.object().required().nullable().label('Merchant'),
    price: Yup.number().when('type', {
        is: 'fixed',
        then: Yup.number().required().min(0)
    }).label('Price'),
    discount: Yup.number().when('type', {
        is: 'discount',
        then: Yup.number().required().min(0).max(100)
    }).label('Discount'),
    coupon_discount_max_amount: Yup.number().min(0).label('Coupon Discount Max Amount'),
    quota: Yup.number().required().min(1).label('Quota'),
    code: Yup.string().when('random_code', {
        is: '0',
        then: Yup.string().required()
    }).label('Coupon Code')
})

const GenerateCouponModal = ({ show, onHide }) => {
    const { auth } = useAuth()

    const queryClient = useQueryClient()
    const searchCourse = async search => queryClient.fetchQuery('courses', () => getCourses(search, null, null, null, 1, 100)).then(res => res.data.map(course => ({
        label: course.title,
        value: course.id,
    })))
    const searchMerchant = async search => queryClient.fetchQuery('merchants', () => getMerchants({ name: search, rows: 100 })).then(res => res.data.map(merchant => ({
        label: merchant.name,
        value: merchant.id,
    })))

    const { mutate, isLoading } = useMutation(data => generateCoupons(data), {
        onSuccess: data => {
            queryClient.invalidateQueries(['coupon-batches'])
            toast.success('Success')
            hideModal()
            window.open(data.data.download_url + '?_token=' + auth.access_token, "_blank")
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    const formik = useFormik({
        initialValues: {
            title: '',
            total: 1,
            merchant: '',
            course: '',
            type: 'discount',
            price: '',
            discount: '',
            discount_max_amount: '',
            quota: 1,
            user_fees: '1',
            expires_at: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.merchant_id = data.merchant.value
            data.course_id = data.course.value

            mutate(data)
        }
    })

    const hideModal = () => {
        formik.resetForm()
        onHide()
    }

    return (
        <Modal show={show} onHide={hideModal} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Generate Coupons</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
                <FormikProvider value={formik}>
                    <div className="form-group mb-4">
                        <label className="form-label required">Title</label>
                        <input type="text" name="title" className="form-control" placeholder="Title" onChange={formik.handleChange} value={formik.values.title} />
                        {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
                    </div>
                    <div className="form-group mb-4">
                        <label className="form-label required">Total</label>
                        <input type="number" name="total" className="form-control" placeholder="Total" min={1} onChange={formik.handleChange} value={formik.values.total} />
                        {formik.errors.total ? <small className="form-text text-danger">{formik.errors.total}</small> : null}
                    </div>
                    <div className="form-group mb-4">
                        <label className="form-label required">Merchant</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={searchMerchant}
                            name="merchant"
                            className="form-control-sm p-0"
                            onChange={option => formik.setFieldValue('merchant', option)}
                            value={formik.values.merchant}
                        />
                        {formik.errors.merchant ? <small className="form-text text-danger">{formik.errors.merchant}</small> : null}
                    </div>
                    <div className="form-group mb-4">
                        <label className="form-label">Course</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            isClearable
                            loadOptions={searchCourse}
                            name="course"
                            className="form-control-sm p-0"
                            onChange={option => formik.setFieldValue('course', option)}
                            value={formik.values.course}
                        />
                        {formik.errors.course ? <small className="form-text text-danger">{formik.errors.course}</small> : null}
                    </div>
                    <div className="form-group mb-4">
                        <label className="form-label required">Type</label>
                        <select name="type" className="form-select" onChange={e => {
                            formik.handleChange(e)
                            formik.setFieldValue('price', '')
                            formik.setFieldValue('discount', '')
                            formik.setFieldValue('discount_max_amount', '')
                        }} value={formik.values.type}>
                            <option value="fixed">Fixed Price</option>
                            <option value="discount">Discount</option>
                        </select>
                        {formik.errors.course ? <small className="form-text text-danger">{formik.errors.course}</small> : null}
                    </div>
                    {formik.values.type === 'discount' ? (
                        <>
                            <div className="form-group mb-4">
                                <label className="form-label required">Discount (%)</label>
                                <input type="number" name="discount" className="form-control" placeholder="Discount" min={1} onChange={formik.handleChange} value={formik.values.discount} />
                                {formik.errors.discount ? <small className="form-text text-danger">{formik.errors.discount}</small> : null}
                            </div>
                            <div className="form-group mb-4">
                                <label className="form-label">Discount Max Amount</label>
                                <input type="number" name="discount_max_amount" className="form-control" placeholder="Discount Max Amount" min={1} onChange={formik.handleChange} value={formik.values.discount_max_amount} />
                                {formik.errors.discount_max_amount ? <small className="form-text text-danger">{formik.errors.discount_max_amount}</small> : null}
                            </div>
                        </>
                    ) : (
                        <div className="form-group mb-4">
                            <label className="form-label required">Price</label>
                            <input type="number" name="price" className="form-control" placeholder="Price" min={0} onChange={formik.handleChange} value={formik.values.price} />
                            {formik.errors.price ? <small className="form-text text-danger">{formik.errors.price}</small> : null}
                        </div>
                    )}
                    <div className="form-group mb-4">
                        <label className="form-label required">Quota for each coupon</label>
                        <input type="number" name="quota" className="form-control" placeholder="Quota" min={1} onChange={formik.handleChange} value={formik.values.quota} />
                        {formik.errors.quota ? <small className="form-text text-danger">{formik.errors.quota}</small> : null}
                    </div>
                    <div className="form-group mb-4">
                        <label className="form-label">Expires at</label>
                        <ReactDatePicker
                            name="expires_at"
                            className="form-control"
                            placeholderText="Expires at"
                            onChange={date => formik.setFieldValue('expires_at', date)}
                            selected={formik.values.expires_at}
                            minDate={new Date()}
                            dateFormat="yyyy-MM-dd"
                        />
                        {formik.errors.expires_at ? <small className="form-text text-danger">{formik.errors.expires_at}</small> : null}
                    </div>
                    <div className="form-check form-check-custom form-check-solid mb-4">
                        <Field type="checkbox" name="user_fees" className="form-check-input" id="user_fees" onChange={e => {
                            formik.setFieldValue('user_fees', e.target.checked)
                        }} />
                        <label className="form-check-label" htmlFor="user_fees">
                            Fees are charged to user
                        </label>
                    </div>
                    <button type="submit" className="btn btn-primary w-100 mt-4" disabled={!formik.isValid || isLoading}>
                        Generate Coupons {isLoading ? <Spinner size="sm" color="white" className="ms-2" /> : null}
                    </button>
                </FormikProvider>
            </form>
            </Modal.Body>
        </Modal>
    )
}

export default GenerateCouponModal