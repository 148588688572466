import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth } from '../../../modules/auth'
import Authorization from "../../../routing/Authorization"
import PageSpinner from '../../../components/Spinner/PageSpinner'

import { getCourse } from '../../../models/courses/courses'
import clsx from 'clsx'
import { checkIsActive } from '../../../../_metronic/helpers'
import PageError from '../../../components/Error/PageError'


const breadcumbs = [
    {
        title: 'Courses',
        path: '/courses/manage',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Navigation = ({ href, children }) => {
    const { pathname } = useLocation()
    const isActive = checkIsActive(pathname, href)

    return (
        <Link to={href}>
            <div className={clsx("flex-grow-0 flex-shrink-0 p-6 text-center min-w-175px", isActive ? "text-primary border-bottom border-2 border-primary" : 'text-dark')}>
                <p className="mb-0 fw-semibold">{children}</p>
            </div>
        </Link>
    )
}

const EditCourse = () => {
    const { courseId } = useParams()

    const { isLoading, data: { data: course} = {}, isError, error } = useQuery([`course-${courseId}`], () => getCourse(courseId), {
        cacheTime: 0,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    })

    return (
        <>
            <div className="card mb-4 rounded-bottom-0">
                <div className="card-body p-0 d-flex overflow-scroll">
                    <Navigation href={`/courses/manage/${courseId}/edit/basic`}>Basic Info</Navigation>
                    <Navigation href={`/courses/manage/${courseId}/edit/pricing`}>Pricing</Navigation>
                    <Navigation href={`/courses/manage/${courseId}/edit/requirements`}>Requirements</Navigation>
                    <Navigation href={`/courses/manage/${courseId}/edit/outcomes`}>Outcomes</Navigation>
                    <Navigation href={`/courses/manage/${courseId}/edit/occupation_units`}>Occupation Units</Navigation>
                    <Navigation href={`/courses/manage/${courseId}/edit/competency_units`}>Competency Units</Navigation>
                    <Navigation href={`/courses/manage/${courseId}/edit/certificates`}>Certificates</Navigation>
                    <Navigation href={`/courses/manage/${courseId}/edit/media`}>Media</Navigation>
                    <Navigation href={`/courses/manage/${courseId}/edit/tanya-ai`}>TanyaAI</Navigation>
                </div>
            </div>
            {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : <Outlet context={{ course }} />}
        </>
    )
}

export default Authorization(EditCourse, ['admin', 'officer', 'user'])