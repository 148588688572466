import { FormikProvider, useFormik } from 'formik'

import Spinner from '../../../../../components/Spinner/Spinner'

import { courseSchema } from './_schema'
import BasicSection from './BasicSection'
import PricingSection from './PricingSection'
import RequirementsSection from './RequirementsSection'
import OutcomesSection from './OutcomesSection'
import CompetencyUnitsSection from './CompetencyUnitsSection'
import OccupationUnitsSection from './OccupationUnitsSection'
import MediaSection from './MediaSection'
import CertificatesSections from './CertificatesSection'


const CourseForm = ({ data = null, user, queryClient, onSubmit, isSubmitLoading = false }) => {
    const formik = useFormik({
        initialValues: {
            type: data?.type ? data.type : 'video',
            title: data ? data.title : '',
            is_prakerja: data ? data.is_prakerja : false,
            category_id: data && data.category ? {
                value: data?.category?.id,
                label: data?.category?.name
            } : '',
            mentor_id: data && data.main_mentor ? {
                value: data?.main_mentor?.id,
                label: data?.main_mentor?.name
            } : user.role_id === 'user' ? {
                value: user.id,
                label: user.name
            } : '',
            other_mentor_ids: data && data.other_mentor ? data.other_mentor.map(mentor => ({
                value: mentor.id,
                label: mentor.name
            })) : '',
            short_description: data && data.short_description ? data.short_description : '',
            description: data ? data.description : '',
            level: data ? data.level : 'beginner',
            price: data ? data.price : 0,
            discounted_price: data ? data.discounted_price : 0,
            requirements: data ? data.requirements : [],
            outcomes: data ? data.outcomes : [],
            competency_units: data ? data.competency_units : [],
            occupation_units: data ? data.occupation_units : [],
            certificate_ids: data ? data.certificates.map(certificate => certificate.id) : [],
            thumbnail: null,
            trailer_source: data ? data.trailer_source : 'youtube',
            trailer_url: data?.trailer_source === 'youtube' && data.trailer ? data.trailer : '',
            trailer_file: null,
            remove_thumbnail: false
        },
        validationSchema: courseSchema,
        onSubmit: values => {
            const data = new FormData()

            let mentorUserIds = {}
            if (user.role_id === 'user') mentorUserIds[user.id] = 1
            else {
                mentorUserIds = {
                    [values.mentor_id.value]: 1
                }
                if (values.other_mentor_ids) values.other_mentor_ids.map(mentor => {
                    if (!(mentor.value in mentorUserIds)) mentorUserIds[mentor.value] = 0

                    return mentor
                })
            }

            data.append('is_prakerja', values.is_prakerja ? 1 : 0)
            data.append('type', values.is_prakerja ? values.type : null)
            data.append('title', values.title)
            data.append('category_id', values.category_id.value)
            data.append('mentor_user_ids', JSON.stringify(mentorUserIds))
            data.append('short_description', values.short_description)
            data.append('description', values.description)
            data.append('level', values.level)
            data.append('price', values.price || 0)
            data.append('discounted_price', values.discounted_price || 0)
            data.append('requirements', JSON.stringify(values.requirements))
            data.append('outcomes', JSON.stringify(values.outcomes))
            data.append('competency_units', JSON.stringify(values.competency_units))
            data.append('occupation_units', JSON.stringify(values.occupation_units))
            data.append('certificate_ids', JSON.stringify(values.certificate_ids))
            if (values.thumbnail) data.append('thumbnail', values.thumbnail)
            if (values.remove_thumbnail) data.append('remove_thumbnail', values.remove_thumbnail)
            data.append('trailer_source', values.trailer_source)
            if (values.trailer_source === 'youtube' && values.trailer_url) data.append('trailer', values.trailer_url)
            else if (values.trailer_source === 'system' && values.trailer_file) data.append('trailer', values.trailer_file)

            onSubmit(formik, data)
        },
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <BasicSection
                    user={user}
                    formik={formik} 
                    queryClient={queryClient}
                />
                <div className="separator my-8"></div>
                <PricingSection
                    formik={formik}
                />
                <div className="separator my-8"></div>
                <RequirementsSection
                    formik={formik}
                />
                <div className="separator my-8"></div>
                <OutcomesSection
                    formik={formik}
                />
                
                {!!formik.values.is_prakerja && (
                    <>
                        <div className="separator my-8"></div>
                        <CompetencyUnitsSection
                            formik={formik}
                        />
                        <div className="separator my-8"></div>
                        <OccupationUnitsSection
                            formik={formik}
                        />
                    </>
                )}
                
                <div className="separator my-8"></div>
                <CertificatesSections />
                <div className="separator my-8"></div>
                <MediaSection
                    formik={formik}
                    thumbnailUrl={data?.thumbnail_url}
                    onRemoveThumbnail={() => formik.setFieldValue('remove_thumbnail', 1)}
                />
            </FormikProvider>

            <div className="separator my-8"></div>
            <p className="text-end mb-0">
                <button type="submit" className="btn btn-primary" disabled={isSubmitLoading || !formik.isValid}>{data ? 'Update' : 'Create'} Course {isSubmitLoading && <Spinner size="sm" className="ms-2" />}</button>
            </p>
        </form>
    )
}

export default CourseForm