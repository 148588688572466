import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { PageTitle } from "../../../../_metronic/layout/core"
import PageSpinner from "../../../components/Spinner/PageSpinner"
import { getCoupon, saveCoupon, updateCoupon } from "../../../models/courses/coupons"
import Authorization from "../../../routing/Authorization"
import CouponForm from "./components/CouponForm/CouponForm"

const breadcumbs = [
    {
        title: 'Coupon',
        path: '/coupon',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const EditCoupon = () => {
    const navigate = useNavigate()
    const { couponId } = useParams()

    const queryClient = useQueryClient()
    const { isLoading, data: coupon } = useQuery(['coupon'], () => getCoupon(couponId), {
        cacheTime: 0,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    })

    const { mutate, isLoading: isSubmitLoading } = useMutation(data => updateCoupon(couponId, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            toast.success('Success')
            navigate('/courses/coupons')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    const onSubmit = values => {
        // const formData = new FormData()
        // formData.append('course_id', values.course_id.value)
        // formData.append('code', values.code)
        // formData.append('discount_percentage', values.discount_percentage)
        // formData.append('max_amount', values.max_amount)
        // formData.append('quota', values.quota)
        // formData.append('status', values.status)
        // formData.append('expire_at', values.expire_at)
        mutate({
            course_id: values.course_id.value,
            code: values.code,
            discount_percentage: values.discount_percentage,
            max_amount: values.max_amount,
            quota: values.quota,
            status: values.status,
            expire_at: values.expire_at,
        })
    }

    return (
        <>
            <PageTitle breadcrumbs={breadcumbs}>Edit Merchant</PageTitle>
            <div className="card">
                <div className="card-body">
                    {isLoading ? <PageSpinner height={300} /> : (
                        <CouponForm
                            data={coupon.data}
                            queryClient={queryClient}
                            onSubmit={onSubmit}
                            isSubmitLoading={isSubmitLoading}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

export default Authorization(EditCoupon, ['admin', 'officer'])