import { merchantPicture } from "../../../helpers/pictures"


const MerchantCell = ({ merchant }) => {
    if (!merchant) return '-'

    return (
        <div className="d-flex align-items-center">
            <div className="symbol symbol-30px symbol-2by3">
                <div className="symbol-label" style={{
                    backgroundImage: `url('${merchantPicture(merchant.picture_url)}')`
                }}></div>
            </div>
            <div className="ms-3">
                <p className="mb-0 fw-bold">{merchant.name}</p>
            </div>
        </div>
    )
}

export default MerchantCell