import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { toast } from "react-toastify"

import { getCourseSessionAllUsers, updateCourseSessionUsers } from "../../../../../../models/courses/courses"
import Table from "../../../../../../components/Table/Table"
import GeneralPagination from "../../../../../../components/Pagination/GeneralPagination"

import { participantColumns, columnAliases } from "./ParticipantTable/_columns"
import SessionParticipantFilter from "./ParticipantTable/SessionParticipantFilter"


const SessionParticipantTable = ({ queryClient, courseId, schedule, session }) => {
    const participantQueryKey = `course-${courseId}-schedule-${schedule.id}-sessions-${session.id}-participants`
    const pageSize = 100
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [filters, setFilters] = useState({})
    
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery(participantQueryKey, () => getCourseSessionAllUsers({
        courseId,
        scheduleId: schedule.id,
        sessionId: session.id,
        keyword: filters?.keyword,
        status: filters?.status,
        page,
        rows: pageSize,
        sortBy
    }))

    const { mutate: mutateSessionUser } = useMutation(data => updateCourseSessionUsers(courseId, schedule.id, session.id, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([participantQueryKey])
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(participantQueryKey)
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <p className="fw-bold fs-4 mt-6 mb-0">Participants</p>
            <SessionParticipantFilter onSubmit={values => {
                setPage(1)
                setFilters(values)
            }} />
            <Table
                columns={participantColumns}
                isLoading={isFetching}
                isError={isError}
                error={error}
                data={!isLoading ? data.data : []}
                currentPage={page}
                pageSize={pageSize}
                onSortByChange={sortBy => setSortBy(() => {
                    if (sortBy.length) return {
                        id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                        direction: sortBy[0].desc ? 'DESC' : 'ASC'
                    }
            
                    return {}
                })}
                session={session}
                onStatusChange={data => mutateSessionUser(data)}
            />
            
            {isSuccess && <GeneralPagination
                onPageChange={page => setPage(page)}
                totalCount={data?.meta.total}
                currentPage={page}
                pageSize={pageSize}
                from={data?.meta.from}
                to={data?.meta.to}
            />}
        </>
    )
}

export default SessionParticipantTable