import { useMutation, useQueryClient } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { PageTitle } from "../../../../_metronic/layout/core"
import Authorization from "../../../routing/Authorization"

import { saveCategory } from "../../../models/courses/categories"
import CategoryForm from "./components/CategoryForm"


const CreateCategory = () => {
    const breadcumbs = [
        {
            title: 'Categories',
            path: '/courses/categories',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    const navigate = useNavigate()
    const { state } = useLocation()

    const queryClient = useQueryClient()
    const { mutate, isLoading: isSubmitLoading } = useMutation(data => saveCategory(data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['categories'])
            toast.success('Success')
            navigate('/courses/categories')
        },
        onError: error => toast.error(error.message)
    })

    return (
        <div className="min-h-500px">
            <PageTitle breadcrumbs={breadcumbs}>Create Category</PageTitle>
            <CategoryForm
                data={{
                    parent_id: state?.parentId
                }}
                onSubmit={values => mutate(values)}
                isSubmitLoading={isSubmitLoading}
            />
        </div>
    )
}

export default Authorization(CreateCategory, ['admin', 'officer'])