import { useEffect } from "react"
import { Modal } from "react-bootstrap"
import { useQuery, useQueryClient } from "react-query"
import { TwitterTweetEmbed } from "react-twitter-embed"

import PageError from "../../../../components/Error/PageError"
import PageSpinner from "../../../../components/Spinner/PageSpinner"
import { parseBlogContent } from "../../../../helpers/common"

import { getPostPreview } from "../../../../models/blog/posts"


const PostPreviewModal = ({ show, onHide, postId }) => {
    const queryKey = `blog-post-${postId}-preview`
    const queryClient = useQueryClient()
    const { isFetching, data: { data: post } = {}, isError, error } = useQuery([queryKey], () => getPostPreview(postId), {
        enabled: false
    })

    useEffect(() => {
        if (postId) queryClient.fetchQuery(queryKey)
    }, [postId])

    return (
        <Modal show={show} onHide={onHide} size="lg" backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Post Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {post ? (
                    isFetching ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : (
                        <>
                            <p className="text-center fw-bold text-primary mb-1">{post.category}</p>
                            <h1 className="text-center">{post.title}</h1>
                            <p className="text-center text-muted">28 Maret 2023 - {post.author}</p>
                            {post.thumbnail ? (
                                <>
                                    <img src={post.thumbnail.url} className="img-fluid rounded" alt={post.thumbnail.attributes?.alt ? (post.thumbnail.attributes.alt) : null} />
                                    {post.thumbnail.attributes?.caption ? <p className="text-center text-muted mt-2 mb-0">{post.thumbnail.attributes.caption}</p> : null}
                                </>
                            ) : null}
                            <div>
                                {post.contents.map(c => {
                                    const content = parseBlogContent(c)
                                    switch (content.type) {
                                        case 'heading':
                                        case 'paragraph':
                                            return <div dangerouslySetInnerHTML={{
                                                __html: content.body
                                            }}></div>
                                        case 'image':
                                            return (
                                                <div className="relative">
                                                    <img src={content.body} className="img-fluid rounded w-75 mx-auto" alt={content.attributes?.alt ? (content.attributes.alt) : null} />
                                                    {content.attributes?.caption ? <p className="text-center text-muted mt-2 mb-0">{content.attributes.caption}</p> : null}
                                                </div>
                                            )
                                        case 'embed_tweet':
                                            return <TwitterTweetEmbed
                                                tweetId={content.attributes.short_url}
                                                options={{
                                                    align: 'center'
                                                }}
                                            />
                                    }
                                })}
                            </div>
                        </>
                    )
                ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default PostPreviewModal