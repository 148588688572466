import { useEffect, useRef } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css"

require("@silvermine/videojs-quality-selector")(videojs)
require("@silvermine/videojs-quality-selector/dist/css/quality-selector.css")

const VideoPlayer = ({ options, onReady }) => {
    const videoRef = useRef(null)
    const playerRef = useRef(null)

    useEffect(() => {
        if (!playerRef.current) {
            const videoElement = document.createElement("video-js")
      
            videoElement.className = 'videojs-big-play-centered'
            videoRef.current.appendChild(videoElement)
      
            const player = playerRef.current = videojs(videoElement, options, () => {
                videojs.log('player is ready')
                onReady && onReady(player)
            })
            player.controlBar.addChild("QualitySelector")
        } else {
            const player = playerRef.current
      
            player.autoplay(options.autoplay)
            player.src(options.sources)
            player.controlBar.addChild("QualitySelector")
        }
    }, [options, videoRef])
    
    useEffect(() => {
        const player = playerRef.current

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose()
                playerRef.current = null
            }
        }
    }, [playerRef])

    return (
        <div data-vjs-player>
            <div ref={videoRef}></div>
        </div>
    )
}

export default VideoPlayer