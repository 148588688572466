const TitleBlock = ({ value, onChange }) => {
    return (
        <div className="content-block">
            <div>
                <input
                    type="text"
                    className="form-content form-content-heading form-content-heading-xl"
                    placeholder="Title"
                    value={value?.text}
                    onChange={e => onChange(e.target.value)}
                />
            </div>
        </div>
    )
}

export default TitleBlock