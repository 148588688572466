import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useQueryClient, useQuery } from "react-query"
import { useParams } from "react-router-dom"

import Authorization from "../../../../routing/Authorization"
import { PageTitle } from "../../../../../_metronic/layout/core"
import Table from "../../../../components/Table/Table"
import GeneralPagination from "../../../../components/Pagination/GeneralPagination"
import DeleteConfirmModal from "../../../../components/DeleteConfirmModal"

import { deleteCourseMerchant, getCourseMerchants } from "../../../../models/courses/courses"
import { courseMerchantColumns, columnAliases } from "./components/CourseMerchantsTable/_columns"
import AddMerchantModal from "./components/AddMerchantModal"
import EditCourseMerchantModal from "./components/EditCourseMerchantModal"


const breadcumbs = [
    {
        title: 'Courses',
        path: '/courses/manage',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CourseMerchants = () => {
    const { courseId } = useParams()

    const pageSize = 10
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showAddMerchantModal, setShowAddMerchantModal] = useState(false)
    const [showEditCourseMerchantModal, setShowEditCourseMerchantModal] = useState(false)
    const [editCourseMerchantData, setEditCourseMerchantData] = useState({})
    const [showDeleteCourseMerchantModal, setShowDeleteCourseMerchantModal] = useState()
    const [deleteCourseMerchantData, setDeleteCourseMerchantData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery(`course-${courseId}-merchants`, () => getCourseMerchants({
        courseId,
        page,
        rows: pageSize,
        sortBy
    }))
    const { mutate: deleteCourseMerchantMutate, isLoading: isDeleteCourseMerchantLoading } = useMutation(data => deleteCourseMerchant(data.course_id, data.merchant_id), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`course-${courseId}-merchants`])
        },
        onError: error => toast.error(error?.response?.data?.message)
    })
    
    useEffect(() => {
        queryClient.fetchQuery(`course-${courseId}-merchants`)
    }, [queryClient, page, sortBy])

    return (
        <>
            <PageTitle breadcrumbs={breadcumbs}>Course Merchants</PageTitle>
            <div className="card">
                <div className="card-header p-6 d-block text-end">
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowAddMerchantModal(true)}>Add Merchant</button>
                </div>
                <div className="card-body p-6">
                    <Table
                        columns={courseMerchantColumns}
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        onEdit={courseMerchant => {
                            setShowEditCourseMerchantModal(true)
                            setEditCourseMerchantData(courseMerchant)
                        }}
                        onDelete={courseMerchant => {
                            setShowDeleteCourseMerchantModal(true)
                            setDeleteCourseMerchantData(courseMerchant)
                        }}
                    />
                    
                    {isSuccess && <GeneralPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>

            <AddMerchantModal
                courseId={courseId}
                show={showAddMerchantModal}
                onHide={() => setShowAddMerchantModal(false)}
            />
            <EditCourseMerchantModal
                courseId={courseId}
                courseMerchant={editCourseMerchantData}
                show={showEditCourseMerchantModal}
                onHide={() => {
                    setShowEditCourseMerchantModal(false)
                    setEditCourseMerchantData({})
                }}
            />
            <DeleteConfirmModal
                show={showDeleteCourseMerchantModal}
                handleClose={() => setShowDeleteCourseMerchantModal(false)}
                onConfirm={() => {
                    deleteCourseMerchantMutate({
                        course_id: courseId,
                        merchant_id: deleteCourseMerchantData.merchant_id
                    })
                    setShowDeleteCourseMerchantModal(false)
                    setDeleteCourseMerchantData({})
                }}
                isConfirmLoading={isDeleteCourseMerchantLoading}
            />
        </>
    )
}

export default Authorization(CourseMerchants, ['admin', 'officer'])