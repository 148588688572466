import NumberCell from "../../../../../../../components/Table/cells/Number"
import DateTimeCell from "../../../../../../../components/Table/cells/DateTime"
import SessionCell from "./SessionCell"
import StatusCell from "./StatusCell"
import ActionsCell from "./ActionsCell"
import ExternalUrlCell from "../../../../../../../components/Table/cells/ExternalUrl"


const sessionColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        className: 'ps-2',
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Session',
        accessor: 'session',
        Cell: ({ ...props }) => <SessionCell session={props.data[props.row.index]} />
    }, {
        Header: 'Start At',
        accessor: 'start_at',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].start_datetime} />
    }, {
        Header: 'Meeting URL',
        accessor: 'meeting_url',
        Cell: ({ ...props }) => props.data[props.row.index].meeting_url ? <ExternalUrlCell url={props.data[props.row.index].meeting_url} label="Meeting URL" /> : '-'
    }, {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell session={props.data[props.row.index]} />
    }
]

const columnAliases = {
    number: 'id'
}

export { sessionColumns, columnAliases }