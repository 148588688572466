import { useQuery } from "react-query"
import moment from "moment"

import { getTotalRedemption } from "../../../models/statistics"
import SummaryCard from "./SummaryCard"


const TotalRedemption = () => {
    const currentYear = moment().format('YYYY')
    const { isLoading, isError, error, data } = useQuery('statistic-total-redemption', () => getTotalRedemption({ pubYear: currentYear }))

    return (
        <SummaryCard
            label={`Redemption ${currentYear}`}
            value={data}
            icon="/media/icons/duotune/technology/teh002.svg"
            labelClassName="fs-4"
            isLoading={isLoading}
            isError={isError}
            error={error}
        />
    )
}

export default TotalRedemption