import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/courses/tasks'


export function getTasks({ courseId = null, scheduleId = null, lessonId = null, user = null, taskType = null, assessmentStatus = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/`, {
        params: {
            course_id: courseId,
            schedule_id: scheduleId,
            lesson_id: lessonId,
            user,
            task_type: taskType,
            assessment_status: assessmentStatus,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function taskFeedback(taskId, data) {
    return axios.post(`${API_URL}/${taskId}`, data, {
        params: {
            _method: 'PUT'
        }
    })
}