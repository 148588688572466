import { Modal } from "react-bootstrap"
import { useQuery } from "react-query"
import AsyncSelect from "react-select/async"
import { useEffect } from "react"

import PageError from "../../../../../components/Error/PageError"
import PageSpinner from "../../../../../components/Spinner/PageSpinner"
import { getRJQuestions } from "../../../../../models/courses/courses"


const CourseRJQuestionModal = ({ lesson, show, onHide, onQuestionOrders, onCreateQuestion, onEditQuestion, onDeleteQuestion }) => {
    const { isLoading, isError, error, data: { data: questions } = {} } = useQuery(`lessons-${lesson.id}-questions`, () => getRJQuestions({
        courseId: lesson.course_id,
        sectionId: lesson.section_id,
        lessonId: lesson.id,
    }), {
        enabled: !!show
    })

    const createMarkUp = html => ({
        __html: html
    })

    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Lessons Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between align-items-center mb-5">
                    <h3 className="mb-0 w-50">{lesson?.title}</h3>
                    <div>
                        <div>
                            <button type="button" className="btn btn-sm btn-light-primary me-3" disabled={isLoading} onClick={() => onQuestionOrders(questions)}>
                                <i className="fa fa-sort"></i> Set Question Orders
                            </button>
                            <button type="button" className="btn btn-sm btn-primary" onClick={onCreateQuestion}>Create Question</button>
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <PageSpinner height={300} />
                ) : isError ? (
                    <PageError height={300}>{error.message}</PageError>
                ) : questions?.length > 0 ? questions.map((question, i) => (
                    <div key={question.id} className="border rounded px-6 py-3 mb-3">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                                <span className="badge badge-square badge-primary p-3 me-3">Question {i+1}</span>
                            </div>
                            <div>
                                <button type="button" className="btn btn-sm px-2 me-3" onClick={() => onEditQuestion(question)}>
                                    <i className="fa fa-pencil text-muted p-0"></i>
                                </button>
                                <button type="button" className="btn btn-sm px-2" onClick={() => onDeleteQuestion({ lesson, question })}>
                                    <i className="fa fa-trash text-danger p-0"></i>
                                </button>
                            </div>
                        </div>
                        <div dangerouslySetInnerHTML={createMarkUp(question.question)}></div>
                    </div>
                )) : null}
            </Modal.Body>
        </Modal>
    )
}

export default CourseRJQuestionModal