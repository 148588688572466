import ActionsCell from "./ActionsCell"


const roleColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <strong className="text-muted">{((props.initialState.currentPage - 1) * props.initialState.pageSize) + props.row.index + 1}</strong>
    }, {
        Header: 'Name',
        accessor: 'name',
        width: 10000
    }
]

const columnAliases = {
    number: 'id'
}

export { roleColumns, columnAliases }