import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import { calculatePercentage, formatDate } from '../../../../../../helpers/common'
import { voucherStatusVariant } from '../../../../../../helpers/conts'


const VoucherDetail = ({ voucher }) => (
    <>
        <div className="row">
            <div className="col-12 col-md-10">
                <div className="card">
                    <div className="card-body p-6">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="fw-bold mb-2">Voucher Code</p>
                                <p className="mb-0">{voucher.code}</p>
                            </div>
                            <div className="col-12 col-md-3">
                                <p className="fw-bold mb-2">Merchant</p>
                                <p className="mb-0">{voucher.merchant?.name}</p>
                            </div>
                            <div className="col-12 col-md-3">
                                <p className="fw-bold mb-2">Expire at</p>
                                <p className="mb-0">{voucher.expire_at ? formatDate(voucher.expire_at) : '-'}</p>
                            </div>
                            <div className="col-12 col-md-3">
                                <p className="fw-bold mb-2">Status</p>
                                <p className={"mb-0 text-capitalize text-" + voucherStatusVariant[voucher.status]}>{voucher.status}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-2">
                    <div className="card-body p-6">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <p className="fw-bold mb-2">Course</p>
                                <p className="mb-0">{voucher.course?.title}</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <p className="fw-bold mb-2">Prakerja Owner</p>
                                <p className="mb-0">{voucher.prakerja_owner || '-'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-2">
                <div className="card">
                    <div className="card-body p-6">
                        <p className="fw-bold mb-6">Voucher Usage</p>
                        <div className="w-75 mx-auto">
                            <CircularProgressbar value={calculatePercentage(voucher.users?.length, voucher.quota)} text={`${voucher.users?.length} of ${voucher.quota}`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)

export default VoucherDetail