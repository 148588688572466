import { useEffect, useRef, useState } from "react"
import { useMutation } from "react-query"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { toast } from "react-toastify"
import { useFormik } from "formik"
import clsx from "clsx"
import * as Yup from "yup"

import Spinner from "../../../../../../../components/Spinner/Spinner"
import { generateEmbedHtml } from "../../../../../../../models/embeds"
import { TwitterTweetEmbed } from "react-twitter-embed"


const schema = Yup.object().shape({
    embed_url: Yup.string().required().url().label('Tweet URL')
})

const EmbedTweetBlock = ({ blockId, value = null, onChange, onRemove }) => {
    const contentBlock = useRef(null)

    const [showToolbox, setShowToolbox] = useState(false)

    const formik = useFormik({
        initialValues: {
            embed_url: ''
        },
        validationSchema: schema,
        onSubmit: values => mutate(values.embed_url)
    })

    const { mutate, isLoading } = useMutation(embedUrl => generateEmbedHtml('tweet', embedUrl), {
        onSuccess: data => onChange({
            html: data.data.embed_html,
            attributes: {
                short_url: data.data.short_url,
                url: data.data.url
            }
        }),
        onError: error => {
            toast.error(error?.response?.data?.message || error.message)
            onChange({})
        }
    })

    const {
        attributes: sortAttributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: blockId })

    const style = {
        transform: CSS.Translate.toString(transform),
        transition
    }

    const hideToolbox = e => {
        if (contentBlock.current && !contentBlock.current.contains(e.target)) setShowToolbox(false)
    }

    useEffect(() => {
        document.addEventListener('click', hideToolbox)

        return () => document.removeEventListener('click', hideToolbox)
    }, [])

    return (
        <div className="content-block" ref={setNodeRef} style={style}>
            <div ref={contentBlock}>
                <div className={clsx(
                    "content-toolbox",
                    {
                        "hidden": !showToolbox
                    }
                )}>
                    <button type="button" className="content-toolbox-item" {...sortAttributes} {...listeners}>
                        <i className="fa fa-up-down-left-right"></i>
                    </button>
                    {value?.id ? (
                        <>
                            <button type="button" className="content-toolbox-item divider" onClick={() => {
                                onChange({})
                            }}>
                                <i className="fa fa-shuffle"></i>
                            </button>
                        </>
                    ) : null}
                    <button type="button" className="content-toolbox-item divider" title="Remove block" onClick={() => onRemove()}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
                <div onClick={() => setShowToolbox(true)}>
                    <div className={clsx(
                        "rounded border-3 border-dashed min-h-200px d-flex flex-column justify-content-center p-6",
                        {
                            "bg-light": showToolbox
                        }
                        )}>
                        {!value?.html ? (
                            <>
                                <h2 className="text-capitalize mb-4">
                                    <i className="fa-brands fa-twitter fs-2 me-2 text-primary"></i>Tweet
                                </h2>
                                <p>Insert Tweet URL and press Embed.</p>
                                <form className="d-flex gap-3" onSubmit={formik.handleSubmit}>
                                    <input type="text" name="embed_url" className="form-control" placeholder="Insert Tweet URL" value={formik.values.embed_url} onChange={formik.handleChange} />
                                    <button type="submit" className="btn btn-primary w-25" disabled={!formik.isValid || isLoading}>
                                        Embed {isLoading && <Spinner size="sm" />}
                                    </button>
                                </form>
                                {formik.errors.embed_url ? <small className="form-text text-danger">{formik.errors.embed_url}</small> : null}
                            </>
                        ) : (
                            <TwitterTweetEmbed
                                tweetId={value.attributes.short_url}
                                options={{
                                    align: 'center'
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmbedTweetBlock 