import DateTimeCell from "../../../../../components/Table/cells/DateTime"
import NumberCell from "../../../../../components/Table/cells/Number"
import UserCell from "../../../../../components/Table/cells/User"
import StatusCell from "../../../../products/manage/components/ProductTable/StatusCell"
import ActionsCell from "./ActionsCell"
import TitleCell from "./TitleCell"


const postColumns = [
    {
        Header: '#',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ ...props }) => <TitleCell post={props.data[props.row.index]} />
    }, {
        Header: 'Author',
        accessor: 'author',
        Cell: ({ ...props }) => <UserCell user={props.data[props.row.index].user} />
    }, {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />
    }, {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].created_at} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        className: 'text-end',
        Cell: ({ ...props }) => <ActionsCell post={props.data[props.row.index]} onPreview={props.onPreview} onDelete={props.onDelete} />
    }
]

const columnAliases = {
    number: 'id'
}

export { postColumns, columnAliases }