import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { saveCategory } from "../../../../models/blog/categories"
import CategoryForm from "./CategoryForm"


const CreateCategoryModal = ({ queryClient, queryKey, show, onHide, parentCategory = null }) => {
    const { mutate, isLoading } = useMutation(data => {
        if (parentCategory) data.parent_id = parentCategory.id
        saveCategory(data)
    }, {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([queryKey])
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {parentCategory.id ? (
                    <>
                        <div className="rounded border p-3 bg-light-primary">
                            <p className="mb-0 fw-semibold">Parent Category: {parentCategory.name}</p>
                        </div>
                        <div className="separator my-6"></div>
                    </>
                ) : null}
                <CategoryForm onSubmit={data => mutate(data)} submitLoading={isLoading} />
            </Modal.Body>
        </Modal>
    )
}

export default CreateCategoryModal