import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { updateSection } from "../../../../../models/courses/courses"
import CourseSectionForm from "./CourseSectionForm"



const EditSectionModal = ({ queryClient, courseId, section, show, onHide }) => {
    const { mutate, isLoading } = useMutation(data => updateSection(courseId, section.id, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`course-${courseId}-sections`])
            onHide()
        },
        onError: error => {
            toast.error(error.message)
        }
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Section</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CourseSectionForm data={section} onSubmit={data => mutate(data)} submitLoading={isLoading} />
            </Modal.Body>
        </Modal>
    )
}

export default EditSectionModal