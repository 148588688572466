import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

export function generateEmbedHtml(embedType, embedUrl) {
    return axios.post(`${API_URL}/embeds`, {
        embed_type: embedType,
        embed_url: embedUrl
    })
}
