import { Field, FieldArray, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"

import Spinner from "../../../../../components/Spinner/Spinner"
import BasicSection from "./BasicSection"


const schema = Yup.object().shape({
    title: Yup.string().required().min(5).max(255).label('Title'),
    words: Yup.array().of(Yup.string().required().max(16).matches(/^[a-zA-Z0-9]+$/, 'Word cannot contain white space and special character').label('Word')).required().min(3).label('Words')
})

const PopupQuizWordSearch = ({ lesson, popupQuiz, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            title: popupQuiz ? popupQuiz.title : '',
            position: popupQuiz ? popupQuiz.position : 'end',
            description: popupQuiz?.description ? popupQuiz.description : '',
            position_duration: popupQuiz?.position_duration ? popupQuiz.position_duration : lesson.duration,
            words: popupQuiz?.attributes ? popupQuiz.attributes : []
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.type = 'word_search'
            if (data.position !== 'custom') delete data.position_duration

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <BasicSection formik={formik} lesson={lesson} />
            <FormikProvider value={formik}>
                <FieldArray name="words">
                    {({ push, remove }) => (
                        <>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <p className="form-label required mb-0">Words</p>
                                <button type="button" className="btn btn-sm btn-light-primary" onClick={() => push()}>Add Word</button>
                            </div>
                            <div className="mb-8">
                                {formik.values.words?.length > 0 && formik.values.words.map((_, i) => (
                                    <div key={i} className="mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="w-100 me-3">
                                                <Field name={`words.${i}`} className="form-control" placeholder="Word" />
                                            </div>
                                            <div className="flex-shrink-1">
                                                <button type="button" className="btn btn-sm btn-light-danger" onClick={() => remove(i)}><i className="fa fa-trash p-0"></i></button>
                                            </div>
                                        </div>
                                        {formik.errors.words && Array.isArray(formik.errors.words) ? <small className="form-text text-danger">{formik.errors.words[i]}</small> : null}
                                    </div>
                                ))}
                                {formik.errors.words && !Array.isArray(formik.errors.words) ? <small className="form-text text-danger">{formik.errors.words}</small> : null}
                            </div>
                        </>
                    )}
                </FieldArray>
            </FormikProvider>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                {!popupQuiz ? 'Create' : 'Edit'} Popup Quiz {isSubmitLoading ? <Spinner size="sm" /> : null}
            </button>
        </form>
    )
}

export default PopupQuizWordSearch