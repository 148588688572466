import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

export function getZoomUsers({ email = null, page = 1, rows = 10 }) {
    return axios.get(`${API_URL}/zoom/users`, {
        params: {
            email,
            page,
            rows,
        }
    })
}