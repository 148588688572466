import moment from 'moment'
import { useQuery } from 'react-query'

import Spinner from '../../../../components/Spinner/Spinner'
import { toRupiah } from '../../../../helpers/common'
import { getTotalSales } from '../../../../models/statistics'

import MonthlySalesChart from "./MonthlySalesChart"


const MonthlySalesCard = () => {
    const { isLoading, isError, data: { data } = {} } = useQuery('statistic-total-sales-current-month', () => getTotalSales({
        startPubDate: moment().startOf('month').format('YYYYMMDD'),
        endPubDate: moment().format('YYYYMMDD')
    }))

    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <p className="mb-0 fs-3 fw-bold text-gray-800">Sales</p>
                        <p className="mb-0 text-gray-400 fw-semibold">{moment().startOf('month').format('MMM DD')} - {moment().format('MMM DD YYYY')}</p>
                    </div>
                    {isLoading ? <Spinner color="primary" /> : !isError ? (
                        <p className="mb-0 fw-bold fs-3 text-primary">+{toRupiah(data.total_sales)}</p>
                    ) : null}
                </div>
            </div>
            <div className="h-200px">
                <MonthlySalesChart />
            </div>
        </div>
    )
}

export default MonthlySalesCard