import { ProgressBar } from 'react-bootstrap'

import { formatDate } from '../../../../../../helpers/common'
import { userProfilePicture } from '../../../../../../helpers/pictures'

import LoginAsUser from './LoginAsUser'


const Enrolment = ({ enrol, header = 'user', showLoginAsUser = false }) => (
    <div className="card mb-3">
        <div className="card-body p-6">
            <div className="d-flex justify-content-between align-items-center">
                {header === 'user' ? (
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-40px symbol-circle me-6">
                            <div className="symbol-label" style={{
                                backgroundImage: `url('${userProfilePicture(enrol.user.profile_picture_url)}')`
                            }}></div>
                        </div>
                        <div>
                            <p className="fw-semibold mb-0">{enrol.user.name}</p>
                            <small className="text-muted">{enrol.user.email}</small>
                        </div>
                    </div>
                ) : header === 'course' ? (
                    <p className="fw-bold mb-0">{enrol.course.title}</p>
                ) : null}
                <div>
                    {showLoginAsUser ? <LoginAsUser user={enrol.user} /> : null}
                    {enrol.user_progress.course_completed ? <button type="button" className="btn btn-sm btn-light-primary">Download Certificates</button> : null}
                </div>
            </div>
            <div className="separator my-4"></div>
            <div>
                <div className="d-flex justify-content-between mb-2">
                    <p className="fw-bold mb-0">Course Progress</p>
                    <p className="fw-bold mb-0">{Math.round(enrol.user_progress.user_progress)}%</p>
                </div>
                <ProgressBar variant={enrol.user_progress.course_completed ? 'success' : 'primary'} now={enrol.user_progress.user_progress} />
            </div>
            <div className="row mt-8">
                <div className="col-12 col-md-3 text-center">
                    <p className="fw-bold mb-2">Started at</p>
                    <p className="mb-0">{formatDate(enrol.created_at)}</p>
                </div>
                <div className="col-12 col-md-3 text-center">
                    <p className="fw-bold mb-2">Completed at</p>
                    <p className="mb-0">{enrol.completed_at ? formatDate(enrol.completed_at) : '-'}</p>
                </div>
                <div className="col-12 col-md-3 text-center">
                    <p className="fw-bold mb-2">Exported at</p>
                    <p className="mb-0">{enrol.exported_at ? formatDate(enrol.exported_at) : '-'}</p>
                </div>
                <div className="col-12 col-md-3 text-center">
                    <p className="fw-bold mb-2">Total Duration</p>
                    <p className="mb-0">{enrol.user_progress.total_duration || '-'}</p>
                </div>
                <div className="col-12 col-md-3 text-center mt-8">
                    <p className="fw-bold mb-2">Pre Test Score</p>
                    <p className="mb-0">{enrol.pretest_score !== null ? enrol.pretest_score : '-'}</p>
                </div>
                <div className="col-12 col-md-3 text-center mt-8">
                    <p className="fw-bold mb-2">Post Test Score</p>
                    <p className="mb-0">{enrol.score !== null ? enrol.score : '-'}</p>
                </div>
                <div className="col-12 col-md-3 text-center mt-8">
                    <p className="fw-bold mb-2">Feedback Status</p>
                    <p className="mb-0">{enrol.feedback_status || '-'}</p>
                </div>
                <div className="col-12 col-md-3 text-center mt-8">
                    <p className="fw-bold mb-2">Feedback Message</p>
                    <p className="mb-0">{enrol.feedback_message || '-'}</p>
                </div>
            </div>
        </div>
    </div>
)

export default Enrolment