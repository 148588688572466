import { Field, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

import Spinner from "../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    title: Yup.string().required('Title is required').min(5, 'Title must be at least 5 characters').max(255, 'Title must be at most 255 characters'),
    description: Yup.string().required('Description is required').min(5, 'Description must be at least 5 characters'),
    location_url: Yup.string().required('Location URL is required').url('Location URL must be a valid URL')
})

const LessonTypeOfflineActivity = ({ lesson, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            title: lesson?.title ? lesson.title : '',
            description: lesson?.description ? lesson.description : '',
            location_url: lesson?.location_url ? lesson.location_url : '',
            start_date: lesson?.start_date ? new Date(lesson.start_date) : '',
            end_date: lesson?.end_date ? new Date(lesson.end_date) : '',
            must_be_completed: lesson?.must_be_completed ? lesson.must_be_completed : ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = new FormData()
            data.append('type', 'offline_activity')
            data.append('title', values.title)
            data.append('description', values.description)
            data.append('location_url', values.location_url)
            data.append('start_date', values.start_date ? moment(values.start_date).format('YYYY-MM-DD HH:mm:ss') : null)
            data.append('end_date',  values.end_date ? moment(values.end_date).format('YYYY-MM-DD HH:mm:ss') : null)
            data.append('must_be_completed', values.must_be_completed ? 1 : 0)

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <div className="form-group mb-4">
                    <label className="form-label required">Title</label>
                    <input type="text" name="title" className="form-control" placeholder="Title" onChange={formik.handleChange} value={formik.values.title} />
                    {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label required">Description</label>
                    <ReactQuill theme='snow' name="description" value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                    {formik.errors.description ? <small className="form-text text-danger">{formik.errors.description}</small> : null}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label required">Location URL</label>
                    <input type="text" name="location_url" className="form-control" placeholder="Location URL" onChange={formik.handleChange} value={formik.values.location_url} />
                    {formik.errors.location_url ? <small className="form-text text-danger">{formik.errors.location_url}</small> : null}
                </div>
                <div className="row mb-2">
                    <div className="col-12 col-md-6 form-group mb-4">
                        <label className="form-label">Start Date</label>
                        <ReactDatePicker
                            name="start_date"
                            className="form-control"
                            placeholderText="Start date"
                            onChange={date => formik.setFieldValue('start_date', date)}
                            selected={formik.values.start_date}
                            startDate={formik.values.start_date}
                            endDate={formik.values.end_date}
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeSelect
                            selectsStart
                        />
                        {formik.errors.start_date ? <small className="form-text text-danger">{formik.errors.start_date}</small> : null}
                    </div>
                    <div className="col-12 col-md-6 form-group mb-4">
                        <label className="form-label">End Date</label>
                        <ReactDatePicker
                            name="end_date"
                            className="form-control"
                            placeholderText="End date"
                            onChange={date => formik.setFieldValue('end_date', date)}
                            selected={formik.values.end_date}
                            startDate={formik.values.start_date}
                            endDate={formik.values.end_date}
                            minDate={formik.values.start_date}
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeSelect
                            selectsEnd
                        />
                        {formik.errors.end_date ? <small className="form-text text-danger">{formik.errors.end_date}</small> : null}
                    </div>
                </div>
                <div className="form-group mb-8">
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <Field name="must_be_completed" className="form-check-input" type="checkbox" onChange={e => formik.setFieldValue('must_be_completed', e.target.checked)} />
                        <label className="form-check-label fw-semibold">
                            Must be Completed
                        </label>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                    {!lesson ? 'Create' : 'Edit'} Lesson {isSubmitLoading ? <Spinner size="sm" /> : null}
                </button>
            </FormikProvider>
        </form>
    )
}

export default LessonTypeOfflineActivity