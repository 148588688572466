import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useMutation } from "react-query"
import { toast } from "react-toastify"

import { PageTitle } from "../../../../_metronic/layout/core"
import usePrompt from "../../../hooks/usePrompt"

import { savePost } from "../../../models/blog/posts"
import PostForm from "./components/PostForm/PostForm"


const breadcrumbs = [
    {
        title: 'Posts',
        path: '/blog/posts',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CreatePost = () => {
    const navigate = useNavigate()

    const [isDirty, setIsDirty] = useState(false)

    const { mutate, isLoading } = useMutation(data => savePost(data), {
        onSuccess: () => {
            setIsDirty(false)
            toast.success('Success')
            setTimeout(() => {
                navigate('/blog/posts')
            }, 100);
        },
        onError: error => toast.error(error?.response?.data?.message || error.message)
    })

    usePrompt("Are you sure want to leave? Change you made may not be saved.", isDirty)

    return (
        <>
            <div className="min-h-500px">
                <PageTitle breadcrumbs={breadcrumbs}>Create Post</PageTitle>
                <PostForm
                    submitLoading={isLoading}
                    onSubmit={data => mutate(data)}
                    onDirty={() => setIsDirty(true)}
                />
            </div>
        </>
    )
}

export default CreatePost