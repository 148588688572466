const StatusCell = ({ status }) => {
    const statusClassNames = {
        active: 'badge-success',
        inactive: 'badge-secondary',
        past: 'badge-danger'
    }

    return (
        <span className={"text-capitalize badge " + statusClassNames[status]}>{status}</span>
    )
}

export default StatusCell