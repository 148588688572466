import { Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import DuplicateScheduleForm from "./DuplicateScheduleForm"
import { useMutation } from "react-query"
import { saveCourseScheduleWithSessions } from "../../../../../../models/courses/courses"

const DuplicateScheduleModal = ({ queryClient, scheduleQueryKey, courseId, schedule, show, onHide }) => {
    const { mutate, isLoading } = useMutation(data => saveCourseScheduleWithSessions(courseId, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([scheduleQueryKey])
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} size="xl" backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Schedule</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DuplicateScheduleForm queryClient={queryClient} courseId={courseId} schedule={schedule} onSubmit={data => mutate(data)} submitLoading={isLoading} />
            </Modal.Body>
        </Modal>
    )
}

export default DuplicateScheduleModal