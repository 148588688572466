import CodeCell from "./CodeCell"
import CourseCell from "./CourseCell"
import StatusCell from "./StatusCell"
import ActionsCell from "./ActionsCell"
import UsedCell from "./UsedCell"
import NumberCell from "../../../../../../components/Table/cells/Number"

const couponsColumn = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Code',
        accessor: 'code',
        Cell: ({ ...props }) => <CodeCell coupon={props.data[props.row.index]} />
    }, {
        Header: 'Course',
        accessor: 'course',
        disableSortBy: true,
        Cell: ({ ...props }) => <CourseCell course={props.data[props.row.index].course} />
    }, {
        Header: 'Merchant',
        accessor: 'merchant',
        disableSortBy: true,
        Cell: ({ ...props }) => props.data[props.row.index].merchant?.name
    }, {
        Header: 'Used',
        accessor: 'used',
        Cell: ({ ...props }) => <UsedCell coupon={props.data[props.row.index]} />
    }, {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell coupon={props.data[props.row.index]} onEdit={props.onEdit} onDelete={props.onDelete} />
    }
]

const columnAliases = {
    number: 'id',
    used: 'quota'
}

export { couponsColumn, columnAliases }