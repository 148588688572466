import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

export function getMerchants({ name = null, isDigitalPlatform = false, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/merchants`, {
        params: {
            name,
            is_digital_platform: isDigitalPlatform,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getMerchant(merchantId) {
    return axios.get(`${API_URL}/merchants/${merchantId}`)
}

export function saveMerchant(data) {
    return axios.post(`${API_URL}/merchants`, data)
}

export function updateMerchant(merchantId, data) {
    return axios.put(`${API_URL}/merchants/${merchantId}`, data)
}

export function deleteMerchant(merchantId) {
    return axios.delete(`${API_URL}/merchants/${merchantId}`)
}
