import { Modal } from "react-bootstrap"
import { useMutation } from "react-query"
import TemplateForm from "./TemplateForm"
import { saveTemplate } from "../../../../../../../models/settings/template-messages"
import { toast } from "react-toastify"


const CreateTemplateModal = ({ queryClient, show, onHide, queryKey }) => {
    const { isLoading, mutate } = useMutation(data => saveTemplate(data), {
        onError: () => toast.error('Failed to create new template'),
        onSuccess: () => {
            toast.success('Template saved')
            queryClient.invalidateQueries([queryKey])
            onHide()
        }
    })

    return (
        <Modal show={show} onHide={onHide} centered keyboard={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Create Template Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TemplateForm onSubmit={values => mutate(values)} isLoading={isLoading} />
            </Modal.Body>
        </Modal>
    )
}

export default CreateTemplateModal