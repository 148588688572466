import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"

import { getCategories } from "../../../../../../models/blog/categories"
import { getTags } from "../../../../../../models/blog/tags"
import { getUsers } from "../../../../../../models/users"


const BasicSection = ({ user, formik, queryClient }) => {
    const searchAuthor = async search => queryClient.fetchQuery('users-writer', () => getUsers({ roleId: 'writer', name: search })).then(res => res.data.map(mentor => ({
        label: mentor.name,
        value: mentor.id,
    })))

    const searchCategory = async search => queryClient.fetchQuery('all-blog-categories', () => getCategories(true, search)).then(res => res.data.map(category => ({
        label: category.name,
        value: category.id,
    })))

    const searchTags = async search => queryClient.fetchQuery('blog-tags', () => getTags({ name: search })).then(res => res.data.map(tag => ({
        label: tag.name,
        value: tag.id,
    })))

    return (
        <>
            {['admin', 'officer'].includes(user.role_id) ? (
                <div className="form-group mb-4">
                    <label className="form-label required">Author</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={searchAuthor}
                        name="author"
                        onChange={option => formik.setFieldValue('author', option)}
                        value={formik.values.author}
                    />
                    {formik.errors.author ? <small className="form-text text-danger">{formik.errors.author}</small> : null}
                </div>
            ) : null}
            <div className="form-group mb-4">
                <label className="form-label required">Category</label>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={searchCategory}
                    name="category"
                    onChange={option => formik.setFieldValue('category', option)}
                    value={formik.values.category}
                />
                {formik.errors.category ? <small className="form-text text-danger">{formik.errors.category}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Tags</label>
                <AsyncCreatableSelect
                    cacheOptions
                    defaultOptions
                    isMulti
                    loadOptions={searchTags}
                    name="tags"
                    onChange={option => formik.setFieldValue('tags', option)}
                    value={formik.values.tags}
                />
                {formik.errors.tags ? <small className="form-text text-danger">{formik.errors.tags}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Status</label>
                <select name="status" className="form-select" value={formik.values.status} onChange={formik.handleChange}>
                    <option value="draft">Draft</option>
                    <option value="published">Publish</option>
                </select>
            </div>
        </>
    )
}

export default BasicSection