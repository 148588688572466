import { useState } from "react"
import Collapse from "react-bootstrap/Collapse"

import { userProfilePicture } from "../../../../../helpers/pictures"

import Enrolment from "./components/Enrolment"
import VoucherDetail from "./components/VoucherDetail"
import LoginAsUser from "./components/LoginAsUser"


const UserDetail = ({ user, totalEnrol, totalVoucher }) => (
    <div className="card">
        <div className="card-body p-6">
            <div className="d-flex">
                <div className="symbol symbol-100px">
                    <div className="symbol-label" style={{
                        backgroundImage: `url('${userProfilePicture(user.profile_picture_url)}')`
                    }}></div>
                </div>
                <div className="row flex-fill ms-4">
                    <div className="col-12 col-md-3">
                        <p className="fw-bold mb-1">Name</p>
                        <p className="mb-0">{user.name}</p>
                    </div>
                    <div className="col-12 col-md-3">
                        <p className="fw-bold mb-1">Total Enrolment</p>
                        <p className="mb-0">{totalEnrol}</p>
                    </div>
                    <div className="col-12 col-md-3">
                        <p className="fw-bold mb-1">Total Voucher Used</p>
                        <p className="mb-0">{totalVoucher}</p>
                    </div>
                </div>
            </div>
            <div className="mt-6 text-end">
                <LoginAsUser user={user} />
                <button className="btn btn-sm btn-primary">Edit User</button>
            </div>
        </div>
    </div>
)

const Email = ({ data }) => {
    const [showEnrolment, setShowEnrolment] = useState(true)
    const [showVoucherUsed, setShowVoucherUsed] = useState(true)

    return (
        <>
            <p className="fw-bold mt-6 mb-4">User Detail</p>
            <UserDetail user={data.users ? data.users[0] : {}} totalEnrol={data.enrols?.length} totalVoucher={data.vouchers?.length} />
            <div className="d-flex justify-content-between align-items-center mt-6 mb-4">
                <p className="fw-bold mb-0">Enrolment</p>
                <button type="button" className="btn btn-sm btn-white" onClick={() => setShowEnrolment(item => !item)}>{showEnrolment ? 'Hide' : 'Show'} Enrolment</button>
            </div>
            <Collapse in={showEnrolment}>
                <div>
                    {data.enrols?.map((enrol, i) => !!enrol.course && (
                        <div key={i}>
                            <Enrolment header="course" enrol={enrol} />
                            {i + 1 < data.enrols.length ? <div className="my-6 separator"></div> : null}
                        </div>
                    ))}
                </div>
            </Collapse>
            <div className="d-flex justify-content-between align-items-center mt-6 mb-4">
                <p className="fw-bold mb-0">Voucher Used</p>
                <button type="button" className="btn btn-sm btn-white" onClick={() => setShowVoucherUsed(item => !item)}>{showVoucherUsed ? 'Hide' : 'Show'} Voucher Used</button>
            </div>
            <Collapse in={showVoucherUsed}>
                <div>
                    {data.vouchers?.map((voucher, i) => (
                        <div key={i}>
                            <VoucherDetail voucher={voucher} />
                            {i + 1 < data.vouchers.length ? <div className="my-6 separator"></div> : null}
                        </div>
                    ))}
                </div>
            </Collapse>
        </>
    )
}

export default Email