import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL  + '/vouchers'

export function getBatches({ title = null, merchantId = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/batches`, {
        params: {
            title,
            merchant_id: merchantId,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function generateVoucher(data) {
    return axios.post(`${API_URL}/batches`, data)
}

export function getVouchers({ code = null, courseId = null, merchantId = null, batchId = null, status = null, prakerjaOwner = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}`, {
        params: {
            code,
            course_id: courseId,
            merchant_id: merchantId,
            batch_id: batchId,
            status,
            prakerja_owner: prakerjaOwner,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getVoucher(voucherId) {
    return axios.get(`${API_URL}/${voucherId}`)
}

export function saveVoucher(data) {
    return axios.post(`${API_URL}`, data)
}

export function updateVoucher(voucherId, data) {
    return axios.put(`${API_URL}/${voucherId}`, data)
}

export function deleteVoucher(voucherId) {
    return axios.delete(`${API_URL}/${voucherId}`)
}

export function uploadReconciliation(data) {
    return axios.post(`${API_URL}/reconciliation`, data)
}

export function getReconciliations({ merchantId, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/reconciliation`, {
        params: {
            merchant_id: merchantId,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function uploadFeedback(data) {
    return axios.post(`${API_URL}/feedback`, data)
}