import { useFormik } from "formik"
import * as Yup from "yup"
import Spinner from "../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    input: Yup.string().required('Search is required'),
})

const CheckProgressForm = ({ initialValues, onSubmit, submitLoading }) => {
    const formik = useFormik({
        initialValues: {
            type: initialValues?.type ? initialValues.type : 'voucher',
            input: initialValues?.input ? initialValues.input : ''
        },
        validationSchema: schema,
        onSubmit: data => onSubmit(data)
    })

    return (
        <form className="row align-items-end" onSubmit={formik.handleSubmit}>
            <div className="form-group col-12 col-md-5">
                <div className="d-flex justify-content-between align-items-start">
                    <label className="form-label required">Type</label>
                    {formik.errors.type ? <small className="form-text text-danger m-0">{formik.errors.type}</small> : null}
                </div>
                <select name="type" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.type}>
                    <option value="voucher">Voucher</option>
                    <option value="email">Email</option>
                    <option value="prakerja_owner">Prakerja Owner</option>
                    <option value="prakerja_voucher">Prakerja Voucher</option>
                    <option value="coupon">Coupon</option>
                </select>
                {formik.errors.type ? <small className="form-text text-danger">{formik.errors.type}</small> : null}
            </div>
            <div className="form-group col-12 col-md-5">
                <div className="d-flex justify-content-between align-items-start">
                    <label className="form-label required">Search</label>
                    {formik.errors.input ? <small className="form-text text-danger m-0">{formik.errors.input}</small> : null}
                </div>
                <input type="text" name="input" className="form-control form-control-sm" placeholder="Search" onChange={formik.handleChange} value={formik.values.input} />
            </div>
            <div className="col-12 col-md-2">
                <button type="submit" className="btn btn-sm btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                    Check {submitLoading ? <Spinner size="sm" color="white" className="ms-2" /> : null}
                </button>
            </div>
        </form>
    )
}

export default CheckProgressForm