import { Field } from "formik"
import { useQuery } from "react-query"
import PageError from "../../../../../components/Error/PageError"
import PageSpinner from "../../../../../components/Spinner/PageSpinner"

import { getCertificates } from "../../../../../models/certificates"


const CertificatesSections = () => {
    const { isLoading, isError, error, data } = useQuery(['certificates'], () => getCertificates())

    return (
        <>
            <h3 className="mb-0 mb-8">Certificates</h3>
            {isLoading ? (
                <PageSpinner height={100} />
            ) : isError ? (
                <PageError height={100}>{error.message}</PageError>
            ) : (
                <div className="row">
                    {data.data.map(certificate => (
                        <div key={certificate.id} className="col-12 col-md-3">
                            <div className="form-check form-check-custom form-check-solid">
                                <Field name="certificate_ids" className="form-check-input" type="checkbox" value={certificate.id} id={`certificate-${certificate.id}`} />
                                <label className="form-check-label" htmlFor={`certificate-${certificate.id}`}>
                                    {certificate.name}
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}

export default CertificatesSections