import { useFormik } from "formik"
import { useEffect } from "react"
import { Modal } from "react-bootstrap"


const PreviewModal = ({ show, onHide, recipient, message }) => {
    const formik = useFormik({
        initialValues: {
            message: message
        },
        onSubmit: values => {
            const encodedMessage = encodeURI(values.message)
            const sendUrl = recipient?.phone_number ? `https://api.whatsapp.com/send/?phone=${recipient.phone_number}&text=${encodedMessage}` : `https://api.whatsapp.com/send/?text=${encodedMessage}`

            window.open(sendUrl, "_blank")
        }
    })

    useEffect(() => {
        formik.setFieldValue('message', message)
    }, [show])

    return (
        <Modal show={show} onHide={onHide} centered keyboard={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {recipient ? (
                    <>
                        <p className="mb-0 fw-semibold fs-3">{recipient.name}</p>
                        <p className="mb-0 text-success">
                            <i className="fab fa-whatsapp text-success"></i> {recipient.phone_number}
                        </p>
                    </>
                ) : (
                    <p className="mb-0 fw-semibold fs-3">Chat Room</p>
                )}
                <form className="mt-4" onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                        <textarea name="message" className="form-control" rows={8} value={formik.values.message} onChange={formik.handleChange}></textarea>
                    </div>
                    <button type="submit" className="btn btn-success w-100 mt-4">Send</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default PreviewModal