import { Modal } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"

import { updateCoupon } from "../../../../../models/courses/coupons"
import CouponForm from "./CouponForm"

const EditCouponModal = ({ show, coupon, onHide }) => {
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(data => updateCoupon(coupon.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['coupons'])
            toast.success('Success')
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Coupon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CouponForm queryClient={queryClient} data={coupon} onSubmit={data => mutate(data)} isSubmitLoading={isLoading} />
            </Modal.Body>
        </Modal>
    )
}

export default EditCouponModal