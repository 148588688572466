import { Modal } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"

import { updateProduct } from "../../../../models/products/products"
import ProductForm from "./ProductForm"


const EditProductModal = ({ product, show, onHide }) => {
    const queryClient = useQueryClient()
    const { mutate, isLoading } = useMutation(data => updateProduct(product.id, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`products`])
            onHide()
        },
        onError: error => {
            toast.error(error.message)
        }
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProductForm product={product} onSubmit={data => mutate(data)} submitLoading={isLoading} />
            </Modal.Body>
        </Modal>
    )
}

export default EditProductModal