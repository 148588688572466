const ActionsCell = ({ session, user, onStatusChange }) => {
    const handleChange = (e) => {
        onStatusChange({
            userId: user.id,
            status: e.target.checked ? 'present' : 'not_present'
        })
    }

    if (session.status === 'inactive') {
        return '-'
    }

    if (session.status === 'finished') {
        if (user.is_present) return <span className="badge badge-square badge-success p-3">Present</span> 
        
        return <span className="badge badge-square badge-danger p-3">Not Present</span> 
    }

    // if (!user.is_verified) return '-'

    return (
        <div className="form-check form-switch form-check-custom form-check-solid">
            <input name={`is_present[${user.user_id}]`} className="form-check-input" type="checkbox" checked={user.is_present} onChange={handleChange} />
        </div>
    )
}

export default ActionsCell