import { PageTitle } from "../../../../../_metronic/layout/core"
import Authorization from "../../../../routing/Authorization"
import UploadFeedbackForm from "./components/UploadFeedbackForm"


const ImportFeedback = () => {
    return (
        <div className="min-h-500px">
            <PageTitle>Import Feedback</PageTitle>
            <div className="card">
                <div className="card-body p-6">
                    <UploadFeedbackForm />
                </div>
            </div>
        </div>
    )
}

export default Authorization(ImportFeedback, ['admin', 'officer'])