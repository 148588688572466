import { useFormik } from "formik"
import * as Yup from "yup"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import AsyncSelect from "react-select/async"
import moment from "moment"

import Spinner from "../../../../../../components/Spinner/Spinner"
import { useMutation, useQueryClient } from "react-query"
import { getLessons } from "../../../../../../models/courses/courses"
import { unSlug } from "../../../../../../helpers/common"
import { createZoomMeeting } from "../../../../../../models/courses/schedules"


const schema = Yup.object().shape({
    start_datetime: Yup.date().required().nullable().label('Start Date'),
    lesson: Yup.object().required().nullable().label('Lesson'),
})

const CourseSessionForm = ({ schedule, courseId, data = null, onSubmit, submitLoading }) => {
    const queryClient = useQueryClient()

    const formik = useFormik({
        initialValues: {
            start_datetime: data?.start_datetime ? moment.utc(data.start_datetime).toDate() : '',
            lesson: data?.lesson ? {
                label: data.lesson.title,
                value: data.lesson.id
            } : '',
            meeting_id: data?.meeting_id ? data.meeting_id : '',
            meeting_url: data?.meeting_url ? data.meeting_url : '',
            description: data?.description ? data.description : ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.start_datetime = moment(values.start_datetime).utc().format('YYYY-MM-DD HH:mm:ss')
            data.lesson_id = values.lesson.value

            delete data.lesson

            onSubmit(data)
        }
    })

    const searchLesson = async search => queryClient.fetchQuery(`course-${courseId}-lessons`, () => getLessons({ courseId, title: search, types: 'webinar,offline_activity' })).then(res => res.data.map(lesson => {
        return {
            label: `[${unSlug(lesson.type)}] ${lesson.title}`,
            value: lesson.id,
            isDisabled: schedule.sessions.filter(session => session.lesson_id === lesson.id).length > 0
        }
    }))

    const { isLoading: createZoomMeetingIsLoading, mutate: createZoomMeetingMutate } = useMutation(() => createZoomMeeting(schedule.id, data.id, schedule.zoom_user.id), {
        onSuccess: data => {
            formik.setFieldValue('meeting_id', data.id)
            formik.setFieldValue('meeting_url', data.join_url)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Date Time</label>
                <ReactDatePicker
                    name="start_datetime"
                    className="form-control"
                    placeholderText="Date Time"
                    onChange={date => formik.setFieldValue('start_datetime', date)}
                    selected={formik.values.start_datetime}
                    startDate={formik.values.start_datetime}
                    minDate={moment.utc(schedule?.schedule_start).toDate()}
                    maxDate={moment.utc(schedule?.schedule_end).toDate()}
                    timeFormat="HH:mm"
                    dateFormat="yyyy-MM-dd HH:mm"
                    autoComplete="off"
                    showTimeSelect
                />
                {formik.errors.start_datetime ? <small className="form-text text-danger">{formik.errors.start_datetime}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Lesson</label>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={searchLesson}
                    name="lesson"
                    className="form-control-sm p-0"
                    onChange={option => formik.setFieldValue('lesson', option)}
                    value={formik.values.lesson}
                />
                {formik.errors.lesson ? <small className="form-text text-danger">{formik.errors.lesson}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Description</label>
                <ReactQuill theme='snow' name="description" value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                {formik.errors.description ? <small className="form-text text-danger">{formik.errors.description}</small> : null}
            </div>
            {data && (
                <div className="border p-6 mb-8 rounded">
                    {schedule?.zoom_user && (
                        <div className="row mb-4">
                            <div className="col-12 col-md-8">
                                <h5 className="mb-1">Host</h5>
                                <p className="mb-0 text-muted">{schedule.zoom_user.email}</p>
                            </div>
                            <div className="col-12 col-md-4">
                                {(!formik.values.meeting_id && schedule?.zoom_user) && (
                                    <button type="button" className="btn btn-sm btn-primary w-100" disabled={createZoomMeetingIsLoading} onClick={() => createZoomMeetingMutate()}>Generate {createZoomMeetingIsLoading &&  <Spinner size="sm" className="text-white" />}</button>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="form-group mb-4">
                        <label className="form-label">Meeting ID</label>
                        <input type="text" name="meeting_id" className="form-control" placeholder="Meeting ID" onChange={formik.handleChange} value={formik.values.meeting_id} />
                        {formik.errors.meeting_id ? <small className="form-text text-danger">{formik.errors.meeting_id}</small> : null}
                    </div>
                    <div className="form-group">
                        <label className="form-label">Meeting Url</label>
                        <input type="text" name="meeting_url" className="form-control" placeholder="Meeting Url" onChange={formik.handleChange} value={formik.values.meeting_url} />
                        {formik.errors.meeting_url ? <small className="form-text text-danger">{formik.errors.meeting_url}</small> : null}
                    </div>
                </div>
            )}

            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                {data ? 'Update' : 'Create'} Session {submitLoading && <Spinner size="sm" />}
            </button>
        </form>
    )
}

export default CourseSessionForm