import { FormikProvider, useFormik } from "formik"
import { useEffect, useRef, useState } from "react"
import { Spinner } from "react-bootstrap"
import { merchantScema } from "./_schema"

const MerchantForm = ({ data = null, queryClient, onSubmit, isSubmitLoading = false }) => {
    const formik = useFormik({
        initialValues: {
            name: data?.name,
            picture: undefined
        },
        validationSchema: merchantScema,
        onSubmit: values => onSubmit(values),
    })

    const [picturePreview, setPicturePreview] = useState()
    const pictureInputRef = useRef(null)

    useEffect(() => {
        if (!formik.values.picture) return

        const objectUrl = URL.createObjectURL(formik.values.picture)
        setPicturePreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [formik.values.picture])

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <div className="form-group mb-4">
                    <label className="form-label required">Merchant Name</label>
                    <input type="text" name="name" className="form-control" placeholder="Merchant Name" onChange={formik.handleChange} value={formik.values.name} />
                    {formik.errors.name ? <small className="form-text text-danger">{formik.errors.name}</small> : null}
                </div>
                <div className="d-flex align-items-center">
                    <div className="w-100 mt-4">
                        <label className="form-label d-block">Picture</label>
                        <div className="w-full h-225px border rounded overlay overlay-block mb-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => pictureInputRef.current.click()}>
                            <div className="overlay-wrapper">
                                {picturePreview && <img alt="Preview" src={picturePreview} className="w-100" />}
                            </div>
                            <div className="overlay-layer" style={{
                                backgroundColor: 'rgba(0, 0, 0, .3)'
                            }}>
                                <span className="bg-white p-3 rounded">
                                    <i className="fa fa-file-upload text-muted" style={{
                                        fontSize: 2 + 'rem'
                                    }}></i>
                                </span>
                            </div>
                        </div>
                        <input type="file" name="picture" className="d-none" ref={pictureInputRef} onChange={e => formik.setFieldValue('picture', e.currentTarget.files[0])} />
                        <p className="text-muted text-center mb-0">600x600</p>
                    </div>
                </div>
            </FormikProvider>

            <div className="separator my-8"></div>
            <p className="text-end mb-0">
                <button type="submit" className="btn btn-primary" disabled={isSubmitLoading || !formik.isValid}>{data ? 'Update' : 'Create'} Course {isSubmitLoading && <Spinner size="sm" className="ms-2" />}</button>
            </p>
        </form>
    )
}

export default MerchantForm