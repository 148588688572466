import NumberCell from "../../../../../../../components/Table/cells/Number"
import UserCell from "../../../../../../../components/Table/cells/User"
import ActionsCell from "./ActionsCell"


const participantColumns = [
    {
        Header: '#',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ ...props }) => <UserCell user={props.data[props.row.index]} />
    }, {
        Header: 'Email',
        accessor: 'email'
    }, {
        Header: 'Is Present',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell session={props.session} user={props.data[props.row.index]} onStatusChange={props.onStatusChange} />
    }
]

const columnAliases = {
    number: 'id'
}

export { participantColumns, columnAliases }