import NumberCell from "../../../../../components/Table/cells/Number"
import DateTimeCell from "../../../../../components/Table/cells/DateTime"
import SessionCell from "./SessionCell"
import CourseCell from "./CourseCell"
import StatusCell from "./StatusCell"


const sessionColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        className: 'ps-2',
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Session',
        accessor: 'session',
        Cell: ({ ...props }) => <SessionCell session={props.data[props.row.index]} />
    }, {
        Header: 'Course',
        accessor: 'course',
        Cell: ({ ...props }) => <CourseCell course={props.data[props.row.index].course} />
    }, {
        Header: 'Start At',
        accessor: 'start_at',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].start_datetime} />
    }, {
        Header: 'Meeting URL',
        accessor: 'meeting_url'
    }, {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />
    }
]

const columnAliases = {
    number: 'id'
}

export { sessionColumns, columnAliases }