import { Field, FieldArray } from "formik"


const OccupationUnitsSection = ({ formik }) => (
    <>
        <FieldArray name="occupation_units">
            {({ push, remove }) => (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-8">
                        <h3 className="mb-0">Occupation Units</h3>
                        <button type="button" className="btn btn-sm btn-light-primary" onClick={() => push()}>Add Occupation Unit</button>
                    </div>
                    {formik.values.occupation_units.length > 0 && formik.values.occupation_units.map((_, i) => (
                        <div key={i} className="d-flex align-items-center mb-4">
                            <Field name={`occupation_units.${i}`} className="form-control flex-fill me-4" placeholder="Occupation" />
                            <div>
                                <button type="button" className="btn btn-sm btn-light-danger" onClick={() => remove(i)}>Remove</button>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </FieldArray>
    </>
)

export default OccupationUnitsSection