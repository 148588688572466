import { useState } from "react"
import { Modal } from "react-bootstrap"
import { useMutation, useQuery } from "react-query"
import CreateTemplateModal from "./CreateTemplateModal"
import { buildMessage, deleteTemplate, getTemplates } from "../../../../../../../models/settings/template-messages"
import PageSpinner from "../../../../../../../components/Spinner/PageSpinner"
import PageError from "../../../../../../../components/Error/PageError"
import EditTemplateModal from "./EditTemplateModal"
import Spinner from "../../../../../../../components/Spinner/Spinner"
import { toast } from "react-toastify"
import PreviewModal from "./PreviewModal"


const FollowUpModal = ({ queryClient, show, onHide, onSetShow, scheduleId, recipient = null }) => {
    const queryKey = 'followup-template-messages'

    const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
    const [buildIsLoading, setBuildIsLoading] = useState(false)

    const [showEditTemplateModal, setShowEditTemplateModal] = useState(false)
    const [editTemplateData, setEditTemplateData] = useState({})

    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [previewData, setPreviewData] = useState(null)

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const { isLoading, data: { data: templates } = {}, isError, error } = useQuery([queryKey], () => getTemplates())

    const { mutate: buildMutate } = useMutation(templateId => buildMessage(templateId, {
        schedule_id: scheduleId,
        user_id: recipient ? recipient.id : null
    }), {
        onError: () => toast.error('Failed to build message'),
        onSuccess: message => {
            onSetShow(false)
            setPreviewData(message)
            setShowPreviewModal(true)
        },
        onSettled: () => setBuildIsLoading(false)
    })

    const { isLoading: deleteIsLoading, mutate: deleteMutate } = useMutation(templateId => deleteTemplate(templateId), {
        onError: () => toast.error('Failed to delete template'),
        onSuccess: () => {
            toast.success('Template deleted')
            queryClient.invalidateQueries([queryKey])
        }
    })

    return (
        <>
            <Modal show={show} onHide={onHide} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Follow Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            {recipient ? (
                                <>
                                    <p className="mb-0 fw-semibold fs-3">{recipient.name}</p>
                                    <p className="mb-0 fw-semibold">
                                        <a href={`https://wa.me/${recipient.phone_number}`} className="text-success" target="_blank" rel="noreferrer">
                                            <i className="fab fa-whatsapp text-success"></i> {recipient.phone_number}
                                        </a>
                                    </p>
                                </>
                            ) : (
                                <p className="mb-0 fw-semibold fs-3">Chat Room</p>
                            )}
                        </div>
                        <button type="button" className="btn btn-sm btn-success" onClick={() => {
                            onSetShow(false)
                            setShowCreateTemplateModal(true)
                        }}>Create new Template</button>
                    </div>
                    {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : templates?.length < 1 ? <PageError height={300}><p className="text-muted mb-0">No result</p></PageError> : (
                        <div className="d-flex flex-column gap-3 mt-6">
                            {templates.map(template => (
                                <div key={template.id} className="border rounded p-6 d-flex align-items-center gap-6">
                                    <div className="flex-fill">
                                        <h5>{template.title}</h5>
                                        <p className="mb-0 text-muted text-justify" style={{
                                            whiteSpace: 'pre-line'
                                        }}>{template.message.substring(0, 100) + (template.message.length > 100 ? '...' : '')}</p>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        {showDeleteConfirmation === template.id ? (
                                            <>
                                                <button type="button" className="btn btn-sm btn-danger" disabled={deleteIsLoading} onClick={() => deleteMutate(template.id)}>
                                                    Delete {deleteIsLoading && <Spinner size="sm" />}
                                                </button>
                                                <button type="button" className="btn btn-sm btn-light" disabled={deleteIsLoading} onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
                                            </>
                                        ) : (
                                            <>
                                                <button type="button" className="btn btn-sm btn-success flex-shrink-0" disabled={buildIsLoading === template.id} onClick={() => {
                                                    setBuildIsLoading(template.id)
                                                    buildMutate(template.id)
                                                }}>
                                                    Send {buildIsLoading === template.id && <Spinner size="sm" className="ms-1" />}
                                                </button>
                                                <button type="button" className="btn btn-sm btn-light" onClick={() => {
                                                    onSetShow(false)
                                                    setEditTemplateData(template)
                                                    setShowEditTemplateModal(true)
                                                }}><i className="fa fa-pencil p-0"></i></button>
                                                <button type="button" className="btn btn-sm btn-light-danger" onClick={() => setShowDeleteConfirmation(template.id)}><i className="fa fa-trash p-0"></i></button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <CreateTemplateModal
                queryClient={queryClient}
                queryKey={queryKey}
                show={showCreateTemplateModal}
                onHide={() => {
                    setShowCreateTemplateModal(false)
                    onSetShow(true)
                }}
            />

            <EditTemplateModal
                queryClient={queryClient}
                queryKey={queryKey}
                show={showEditTemplateModal}
                onHide={() => {
                    setShowEditTemplateModal(false)
                    setEditTemplateData({})
                    onSetShow(true)
                }}
                template={editTemplateData}
            />

            <PreviewModal
                show={showPreviewModal}
                onHide={() => {
                    setShowPreviewModal(false)
                    setPreviewData(null)
                    onSetShow(true)
                }}
                recipient={recipient}
                message={previewData}
            />
        </>
    )
}

export default FollowUpModal