import { useState } from "react"
import { KTSVG, toAbsoluteUrl } from "../../../../../../_metronic/helpers"


const PopupQuizTypeItem = ({ icon, isSelected, children, onClick }) => (
    <div className={"border border-dashed border-gray-400 rounded p-3 d-flex align-items-center cursor-pointer " + (isSelected ? 'bg-light-primary' : 'bg-hover-light-primary')} onClick={onClick}>
        <div className="w-40px">
            <KTSVG path={toAbsoluteUrl(icon)} className="svg-icon-primary svg-icon-2x" />
        </div>
        <p className="text-gray-700 fw-semibold mb-0 fs-6">{children}</p>
    </div>
)


const ChoosePopupQuizType = ({ onContinue }) => {
    const [selectedPopupQuizType, setSelectedPopupQuizType] = useState()

    return (
        <>
            <h4 className="mb-5 text-gray-800">Choose Popup Quiz Type</h4>
            <div className="row">
                <div className="col-6">
                    <PopupQuizTypeItem icon={"/media/icons/duotune/technology/teh005.svg"} isSelected={selectedPopupQuizType === 'drag_the_words'} onClick={() => setSelectedPopupQuizType('drag_the_words')}>Drag The Words</PopupQuizTypeItem>
                </div>
                <div className="col-6">
                    <PopupQuizTypeItem icon={"/media/icons/duotune/general/gen004.svg"} isSelected={selectedPopupQuizType === 'word_search'} onClick={() => setSelectedPopupQuizType('word_search')}>Word Search</PopupQuizTypeItem>
                </div>
                <div className="col-6 mt-4">
                    <PopupQuizTypeItem icon={"/media/icons/duotune/text/txt009.svg"} isSelected={selectedPopupQuizType === 'problem_solving'} onClick={() => setSelectedPopupQuizType('problem_solving')}>Problem Solving</PopupQuizTypeItem>
                </div>
                <div className="col-6 mt-4">
                    <PopupQuizTypeItem icon={"/media/icons/duotune/general/gen009.svg"} isSelected={selectedPopupQuizType === 'true_or_false'} onClick={() => setSelectedPopupQuizType('true_or_false')}>True or False</PopupQuizTypeItem>
                </div>
                <div className="col-6 mt-4">
                    <PopupQuizTypeItem icon={"/media/icons/duotune/abstract/abs046.svg"} isSelected={selectedPopupQuizType === 'sequence'} onClick={() => setSelectedPopupQuizType('sequence')}>Sequence</PopupQuizTypeItem>
                </div>
                <div className="col-6 mt-4">
                    <PopupQuizTypeItem icon={"/media/icons/duotune/ecommerce/ecm010.svg"} isSelected={selectedPopupQuizType === 'fill_the_blank'} onClick={() => setSelectedPopupQuizType('fill_the_blank')}>Fill the Blank</PopupQuizTypeItem>
                </div>
            </div>
            <button className="btn w-100 mt-8 btn-primary" disabled={!selectedPopupQuizType} onClick={() => onContinue(selectedPopupQuizType)}>Continue</button>
        </>
    )
}

export default ChoosePopupQuizType