import { useFormik } from "formik"
import { Collapse } from "react-bootstrap"


const ParticipantFilter = ({ show, onSubmit }) => {
    const formik = useFormik({
        initialValues: {
            keyword: '',
            verified_status: '',
            status: '',
        },
        onSubmit: values => onSubmit(values)
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Name or Email</small></label>
                            <input type="text" name="keyword" className="form-control form-control-sm" placeholder="Name or Email" value={formik.values.keyword} onChange={formik.handleChange} />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Verified Status</small></label>
                            <select name="verified_status" className="form-select form-select-sm" value={formik.values.verified_status} onChange={formik.handleChange}>
                                <option value="">All</option>
                                <option value="verified">Verified</option>
                                <option value="not_verified">Not Verified</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Attendance Status</small></label>
                            <select name="status" className="form-select form-select-sm" value={formik.values.status} onChange={formik.handleChange}>
                                <option value="">All</option>
                                <option value="present">Present</option>
                                <option value="not_present">Not Present</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 mt-6 text-end">
                        {formik.dirty && <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                            formik.resetForm()
                            formik.setFieldValue('verified_status', '')
                            formik.setFieldValue('status', '')
                            onSubmit()
                        }}>Clear</button>}
                        <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}

export default ParticipantFilter