import { PageTitle } from "../../../../_metronic/layout/core"
import TasksComponent from "./components/TasksComponent"

export default function Tasks() {
    return (
        <div className='min-h-500px'>
            <PageTitle>Tasks</PageTitle>
            <TasksComponent />
        </div>
    )
}
