import VoucherDetail from './components/VoucherDetail'
import Enrolment from './components/Enrolment'


const ResultVoucher = ({ data }) => (
    <>
        <p className="fw-bold mt-6 mb-4">Voucher Detail</p>
        <VoucherDetail voucher={data.vouchers ? data.vouchers[0] : {}} />
        {data.enrols?.length > 0 ? (
            <>
                <p className="fw-bold mt-6 mb-4">Used by</p>
                {data.enrols.map(enrol => (
                    <Enrolment showLoginAsUser enrol={enrol} />
                ))}
            </>
        ) : null}
    </>
)

export default ResultVoucher 