import { useFormik } from "formik"
import * as Yup from "yup"
import AsyncSelect from "react-select/async"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"

import Spinner from "../../../../../components/Spinner/Spinner"

import { getMerchants } from "../../../../../models/merchants"
import { uploadReconciliation } from "../../../../../models/courses/vouchers"


const schema = Yup.object().shape({
    merchant: Yup.object().required('Merchant is required').nullable(),
    reconciliation_files: Yup.mixed().test('required', 'Reconciliation Files is required', file => {
        if (file) return true
        return false
    })
})

const UploadReconciliationForm = () => {
    const queryClient = useQueryClient()
    const searchMerchant = async search => queryClient.fetchQuery('merchants-digital-platform', () => getMerchants({ name: search, isDigitalPlatform: 1, rows: 100 })).then(res => res.data.map(merchant => ({
        label: merchant.name,
        value: merchant.id,
    })))

    const { mutate, isLoading } = useMutation(data => uploadReconciliation(data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['voucher-reconciliations'])
            toast.success('Success')
            formik.resetForm()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    const formik = useFormik({
        initialValues: {
            merchant: '',
            reconciliation_files: undefined,
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = new FormData()
            data.append('merchant_id', values.merchant.value)
            for (var i = 0; i < values.reconciliation_files.length; i++) data.append('reconciliation_files[]', values.reconciliation_files[i])

            mutate(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Merchant</label>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={searchMerchant}
                    name="merchant"
                    className="form-control-sm p-0"
                    onChange={option => formik.setFieldValue('merchant', option)}
                    value={formik.values.merchant}
                />
                {formik.errors.merchant ? <small className="form-text text-danger">{formik.errors.merchant}</small> : null}
            </div>
            <div className="form-group mb-8">
                <label className="form-label required">Reconciliation Files</label>
                <input type="file" className="form-control form-control-sm" multiple onChange={e => formik.setFieldValue('reconciliation_files', e.currentTarget.files)} />
                {formik.errors.reconciliation_files ? <small className="form-text text-danger">{formik.errors.reconciliation_files}</small> : null}
            </div>
            <div className="text-end">
                <button type="submit" className="btn btn-sm btn-primary" disabled={!formik.isValid || isLoading}>
                    Upload Reconciliation {isLoading ? <Spinner size="sm" color="white" className="ms-2" /> : null}
                </button>
            </div>
        </form>
    )
}

export default UploadReconciliationForm