import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useAuth } from '../../../modules/auth'
import { PageTitle } from "../../../../_metronic/layout/core"
import Authorization from "../../../routing/Authorization"

import { saveCourse } from '../../../models/courses/courses'
import CourseForm from './components/CourseForm/CourseForm'


const breadcumbs = [
    {
        title: 'Courses',
        path: '/courses/manage',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CreateCourse = () => {
    const { currentUser: user } = useAuth()
    const navigate = useNavigate()

    const queryClient = useQueryClient()
    const { mutate, isLoading: isSubmitLoading } = useMutation(data => saveCourse(data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            toast.success('Success')
            navigate('/courses/manage')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <>
            <PageTitle breadcrumbs={breadcumbs}>Create Course</PageTitle>
            <div className="card">
                <div className="card-body">
                    <CourseForm
                        user={user}
                        queryClient={queryClient}
                        onSubmit={(_, data) => mutate(data)}
                        isSubmitLoading={isSubmitLoading}
                    />
                </div>
            </div>
        </>
    )
}

export default Authorization(CreateCourse, ['admin', 'officer', 'user'])