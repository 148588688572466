import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"

import { PageTitle } from "../../../../_metronic/layout/core"
import DeleteConfirmModal from "../../../components/DeleteConfirmModal"
import Table from "../../../components/Table/Table"
import GeneralPagination from "../../../components/Pagination/GeneralPagination"

import { deletePost, getPosts } from "../../../models/blog/posts"
import PostFilter from "./components/PostFilter"
import { postColumns, columnAliases } from "./components/PostTable/_columns"
import PostPreviewModal from "./components/PostPreviewModal"


const Posts = () => {
    const queryKey = 'blog-posts'
    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})

    const [showPreviewPostModal, setShowPreviewPostModal] = useState()
    const [previewPostData, setPreviewPostData] = useState(null)
    const [showDeletePostModal, setShowDeletePostModal] = useState()
    const [deletePostData, setDeletePostData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery(queryKey, () => getPosts({
        title: filters?.title,
        status: filters?.status,
        page,
        rows: pageSize,
        sortBy
    }))
    const { mutate: deletePostMutate, isLoading: isDeletePostLoading } = useMutation(postId => deletePost(postId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([queryKey])
            setShowDeletePostModal(false)
            setDeletePostData({})
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(queryKey)
    }, [queryClient, filters, page, sortBy])

    return (
        <div className="min-h-500px">
            <PageTitle>Blog Posts</PageTitle>
            <div className="card">
                <div className="card-header p-6 d-block">
                    <div className="d-flex justify-content-between">
                        <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        <Link to="/blog/posts/create">
                            <button className="btn btn-sm btn-primary">Create Post</button>
                        </Link>
                    </div>
                    <PostFilter
                        show={showFilter}
                        queryClient={queryClient}
                        onSubmit={values => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className="card-body p-6">
                    <Table
                        columns={postColumns}
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        defaultSortBy={{
                            id: 'created_at',
                            desc: true
                        }}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        onPreview={postId => {
                            setShowPreviewPostModal(true)
                            setPreviewPostData(postId)
                        }}
                        onDelete={post => {
                            setShowDeletePostModal(true)
                            setDeletePostData(post)
                        }}
                    />
                    
                    {isSuccess && <GeneralPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>

            <PostPreviewModal
                show={showPreviewPostModal}
                onHide={() => {
                    setShowPreviewPostModal(false)
                    setPreviewPostData(null)
                }}
                postId={previewPostData}
            />

            <DeleteConfirmModal
                show={showDeletePostModal}
                name={deletePostData.title}
                handleClose={() => {
                    setShowDeletePostModal(false)
                    setDeletePostData({})
                }}
                onConfirm={() => deletePostMutate(deletePostData.id)}
                isConfirmLoading={isDeletePostLoading}
            />

        </div>
    )
}

export default Posts