import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/blog/tags'

export function getTags({ name = null }) {
    return axios.get(`${API_URL}`, {
        params: {
            name
        }
    })
}

export function getTag(tagId) {
    return axios.get(`${API_URL}/${tagId}`)
}

export function saveTag(data) {
    return axios.post(`${API_URL}`, data)
}

export function deleteTag(tagId) {
    return axios.delete(`${API_URL}/${tagId}`)
}