import { useFormik } from "formik"
import * as Yup from "yup"

import Spinner from "../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    name: Yup.string().required().min(5).max(200).label('Name'),
})

const CategoryForm = ({ data = null, onSubmit, submitLoading }) => {
    const formik = useFormik({
        initialValues: {
            name: data?.name ? data.name : '',
        },
        validationSchema: schema,
        onSubmit: values => onSubmit(values)
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-8">
                <label className="form-label required">Name</label>
                <input type="text" name="name" className="form-control" placeholder="Category Name" onChange={formik.handleChange} value={formik.values.name} />
                {formik.errors.name ? <small className="form-text text-danger">{formik.errors.name}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                {data ? 'Update' : 'Create'} Category {submitLoading && <Spinner size="sm" />}
            </button>
        </form>
    )
}

export default CategoryForm