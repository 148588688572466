import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { PageTitle } from "../../../../_metronic/layout/core"
import PageSpinner from "../../../components/Spinner/PageSpinner"
import Authorization from "../../../routing/Authorization"
import CategoryForm from "./components/CategoryForm"

import { getCategory, updateCategory } from "../../../models/courses/categories"


const EditCategory = () => {
    const breadcumbs = [
        {
            title: 'Categories',
            path: '/courses/categories',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    const navigate = useNavigate()
    const { categoryId } = useParams()

    const queryClient = useQueryClient()
    const { isLoading, data: category } = useQuery(['category'], () => getCategory(categoryId), {
        cacheTime: 0
    })
    const { mutate, isLoading: isSubmitLoading } = useMutation(data => updateCategory(categoryId, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['categories'])
            toast.success('Success')
            navigate('/courses/categories')
        },
        onError: error => toast.error(error.message)
    })

    return (
        <div className="min-h-500px">
            <PageTitle breadcrumbs={breadcumbs}>Edit Category</PageTitle>
            {isLoading ? <PageSpinner height={300} color='primary' /> : (
                <CategoryForm
                    data={category.data}
                    onSubmit={values => mutate(values)}
                    isSubmitLoading={isSubmitLoading}
                />
            )}
        </div>
    )
}

export default Authorization(EditCategory, ['admin', 'officer'])