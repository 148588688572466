const PricingSection = ({ formik }) => {
    return (
        <>
            <h3 className="mb-0 mb-8">Pricing</h3>
            <div className="row">
                <div className="col-12 col-md-6 form-group mb-4 mb-md-0">
                    <label className="form-label d-flex justify-content-between">
                        <span className="required">Price</span>
                        <small className="text-muted"><i className="fa fa-info-circle fa-fw"></i> Set 0 if this is a free course</small>
                    </label>
                    <input type="number" name="price" className="form-control" placeholder="Price" onChange={formik.handleChange} value={formik.values.price} />
                    {formik.errors.price ? <small className="form-text text-danger">{formik.errors.price}</small> : null}
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label className="form-label d-flex justify-content-between">
                        <span>Discounted Price</span>
                        <small className="text-muted"><i className="fa fa-info-circle fa-fw"></i> Set 0 if no discount</small>
                    </label>
                    <input type="number" min={0} name="discounted_price" className="form-control" placeholder="Discounted Price" onChange={formik.handleChange} value={formik.values.discounted_price} />
                    {formik.errors.discounted_price ? <small className="form-text text-danger">{formik.errors.discounted_price}</small> : null}
                </div>
            </div>
        </>
    )
}

export default PricingSection