import { useEffect } from "react"
import { Link } from "react-router-dom"
import { MenuComponent } from "../../../../../../_metronic/assets/ts/components"


const MenuItem = ({ actionType = 'link', href, onClick, className, children }) => (
    <div className={`menu-item px-3 ` + className}>
        {actionType === 'link' ? (
            <Link to={href} className='menu-link px-3'>
                {children}
            </Link>
        ) : actionType === 'onClick' ? (
            <span className="menu-link px-3" onClick={onClick}>
                {children}
            </span>
        ) : ''}
    </div>
)

const ActionsCell = ({ course, onHighlight, onToggleTopCourse, onDummyCertificate, onDelete }) => {
    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                <i className="fa fa-ellipsis-vertical pe-0"></i>
            </a>
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-4'
                data-kt-menu='true'
            >
                {course.status === 'active' ? (
                    <>
                        <MenuItem actionType="onClick" onClick={() => {
                            if (window.confirm('Are you sure?')) onHighlight(course.id, course.highlight)
                        }}>{course.highlight ? 'Remove' : 'Set'} Highlight</MenuItem>
                        <MenuItem actionType="onClick" onClick={() => {
                            if (window.confirm('Are you sure?')) onToggleTopCourse(course.id, course.is_top_course)
                        }}>{course.is_top_course ? 'Remove from Top Courses' : 'Add to Top Courses'}</MenuItem>
                    </>
                ) : null}
                <MenuItem actionType="onClick" onClick={() => onDummyCertificate(course)}>Dummy Certificate</MenuItem>
                <MenuItem href={'/courses/manage/' + course.id + '/curriculum'}>Curriculum</MenuItem>
                <MenuItem href={'/courses/manage/' + course.id + '/schedules'}>Schedules</MenuItem>
                <MenuItem href={'/courses/manage/' + course.id + '/live_sessions'}>Live Sessions</MenuItem>
                <MenuItem href={'/courses/manage/' + course.id + '/merchants'}>Merchants</MenuItem>
                <MenuItem href={'/courses/manage/' + course.id + '/edit/basic'}>Edit</MenuItem>
                <MenuItem actionType="onClick" onClick={() => onDelete(course.id, course.title)}>Delete</MenuItem>
            </div>
        </>
    )
}

export default ActionsCell