import clsx from "clsx"
import { KTSVG } from "../../../../_metronic/helpers"
import Spinner from "../../../components/Spinner/Spinner"


const SummaryCard = ({ label, value, icon, iconClassName = 'svg-icon-primary', labelClassName = "fs-2", isLoading, isError, error }) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                    {isError ? <p className="mb-0 text-danger text-center">{error.message}</p> : (
                        <>
                            <KTSVG path={icon} className={clsx("svg-icon-4x", iconClassName)} />
                            <div className="text-center text-md-end mt-6 mt-md-0">
                                {isLoading ? <Spinner color="primary" /> : (
                                    <p className={"fw-bold mb-1 text-dark " + labelClassName}>{value}</p>
                                )}
                                <p className="fw-semibold mb-0 text-muted">{label}</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SummaryCard