import { Modal } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"

import { updateLiveSession } from "../../../../../models/courses/courses"
import LiveSessionTypeRecorded from "./LiveSessionTypeRecorded"
import LiveSessionTypeWebinar from "./LiveSessionTypeWebinar"


const EditLiveSessionModal = ({ courseId, liveSession, show, onHide }) => {
    const queryClient = useQueryClient()
    const { mutate, isLoading } = useMutation(data => updateLiveSession(courseId, liveSession.id, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`course-${courseId}-live-sessions`])
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Live Session</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {liveSession.session_type === 'recorded' ? (
                    <LiveSessionTypeRecorded
                        liveSession={liveSession}
                        onSubmit={data => mutate(data)}
                        submitLoading={isLoading}
                    />
                ) : liveSession.session_type === 'webinar' ? (
                    <LiveSessionTypeWebinar
                        liveSession={liveSession}
                        onSubmit={data => mutate(data)}
                        submitLoading={isLoading}
                    />
                ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default EditLiveSessionModal