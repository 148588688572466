import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import { calculatePercentage, formatDate } from '../../../../../../helpers/common'
import { voucherStatusVariant as couponStatusVariant } from '../../../../../../helpers/conts'


const CouponDetail = ({ coupon }) => (
    <>
        <div className="row">
            <div className="col-12 col-md-10">
                <div className="card">
                    <div className="card-body p-6">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="fw-bold mb-2">Coupon Code</p>
                                <p className="mb-0">{coupon.code}</p>
                            </div>
                            <div className="col-12 col-md-3">
                                <p className="fw-bold mb-2">Merchant</p>
                                <p className="mb-0">{coupon.merchant?.name}</p>
                            </div>
                            <div className="col-12 col-md-3">
                                <p className="fw-bold mb-2">Expire at</p>
                                <p className="mb-0">{coupon.expire_at ? formatDate(coupon.expire_at) : '-'}</p>
                            </div>
                            <div className="col-12 col-md-3">
                                <p className="fw-bold mb-2">Status</p>
                                <p className={"mb-0 text-capitalize text-" + couponStatusVariant[coupon.status]}>{coupon.status}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-2">
                    <div className="card-body p-6">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <p className="fw-bold mb-2">Course</p>
                                <p className="mb-0">{coupon.course?.title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-2">
                <div className="card">
                    <div className="card-body p-6">
                        <p className="fw-bold mb-6">Coupon Usage</p>
                        <div className="w-75 mx-auto">
                            <CircularProgressbar value={calculatePercentage(coupon.users?.length, coupon.quota)} text={`${coupon.users?.length} of ${coupon.quota}`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)

export default CouponDetail