import { useState } from "react"
import { Modal } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import { toast } from "react-toastify"

import { saveLiveSession } from "../../../../../models/courses/courses"
import ChooseLiveSessionType from "./ChooseLiveSessionType"
import LiveSessionTypeRecorded from "./LiveSessionTypeRecorded"
import LiveSessionTypeWebinar from "./LiveSessionTypeWebinar"


const liveSessionTypes = {
    recorded: 'Video Recorded',
    webinar: 'Webinar'
}

const CreateLiveSessionModal = ({ courseId, show, onHide }) => {
    const [step, setStep] = useState('live_session_type')
    const [liveSessionType, setLiveSessionType] = useState()

    const queryClient = useQueryClient()
    const { mutate, isLoading } = useMutation(data => saveLiveSession(courseId, data), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([`course-${courseId}-live-sessions`])
            onHide()
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Live Session</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {step === 'live_session_type' ? (
                    <ChooseLiveSessionType onContinue={liveSessionType => {
                        setLiveSessionType(liveSessionType)
                        setStep('content')
                    }} />
                ) : step === 'content' ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-6">
                            <div>
                                <p className="mb-1 fw-bold">Live Session Type</p>
                                <p className="mb-0 fw-semibold text-primary text-capitalize">{liveSessionTypes[liveSessionType]}</p>
                            </div>
                            <button type="button" className="btn btn-sm btn-light text-primary" onClick={() => setStep('live_session_type')}>Change</button>
                        </div>
                        <div className="separator my-6"></div>
                        {liveSessionType === 'recorded' ? (
                            <LiveSessionTypeRecorded
                                onSubmit={data => mutate(data)}
                                submitLoading={isLoading}
                            />
                        ) : liveSessionType === 'webinar' ? (
                            <LiveSessionTypeWebinar
                                onSubmit={data => mutate(data)}
                                submitLoading={isLoading}
                            />
                        ) : null}
                    </>
                ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default CreateLiveSessionModal