import ActionsCell from "./ActionCell"
import MerchantCell from "./MerchantCell"

const merchantColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <strong className="text-muted">{((props.initialState.currentPage - 1) * props.initialState.pageSize) + props.row.index + 1}</strong>
    }, {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ ...props }) => <MerchantCell merchant={props.data[props.row.index]} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell merchant={props.data[props.row.index]} onDelete={props.onDelete} />
    }
]

const columnAliases = {
    number: 'id',
}

export { merchantColumns, columnAliases }