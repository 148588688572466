import { useFormik } from "formik"
import { useQueryClient } from "react-query"
import AsyncSelect from "react-select/async"
import moment from 'moment'

import { getCourses } from "../../../../models/courses/courses"
import { useEffect } from "react"


const RevenueFilter = ({ onSubmit }) => {
    const queryClient = useQueryClient()

    const searchCourse = async search => queryClient.fetchQuery('courses', () => getCourses(search, null, null, null, 1, 100)).then(res => res.data.map(course => ({
        label: course.title,
        value: course.id,
    })))

    const formik = useFormik({
        initialValues: {
            course: '',
            pub_year: '',
            pub_month: '',
            pub_date: ''
        },
        onSubmit: values => {
            const data = {...values}
            data.course_id = values.course?.value

            if (values.pub_month && values.pub_month < 10) data.pub_month = '0' + values.pub_month
            if (values.pub_date && values.pub_date < 10) data.pub_date = '0' + values.pub_date

            if (data.pub_month) data.pub_month = data.pub_year + '' + data.pub_month
            if (data.pub_date) data.pub_date = data.pub_month + '' + data.pub_date

            onSubmit(data)
        }
    })

    useEffect(() => {
        if (!formik.values.pub_year) formik.setFieldValue('pub_month', '')
        if (!formik.values.pub_month) formik.setFieldValue('pub_date', '')
    }, [formik.values.pub_year, formik.values.pub_month])

    return (
        <div className="card">
            <div className="card-body p-6">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label"><small>Course</small></label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    isClearable
                                    loadOptions={searchCourse}
                                    name="course"
                                    className="form-control-sm p-0"
                                    onChange={option => formik.setFieldValue('course', option)}
                                    value={formik.values.course}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="form-group">
                                <label className="form-label"><small>Year</small></label>
                                <select name="pub_year" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.pub_year}>
                                    <option value="">All</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="form-group">
                                <label className="form-label"><small>Month</small></label>
                                <select name="pub_month" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.pub_month} disabled={!formik.values.pub_year}>
                                    <option value="">All</option>
                                    {moment.months().map((month, i) => <option value={i + 1} key={i}>{month}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-2">
                            <div className="form-group">
                                <label className="form-label"><small>Date</small></label>
                                <select name="pub_date" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.pub_date} disabled={!formik.values.pub_month}>
                                    <option value="">All</option>
                                    {[...Array(31)].map((_, i) => <option value={i + 1} key={i}>{i + 1}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="text-end mt-4">
                        {formik.values.course || formik.values.pub_year || formik.values.pub_month || formik.values.pub_date ? (
                            <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                                formik.resetForm()
                                onSubmit({})
                            }}>Reset</button>
                        ) : null}
                        <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RevenueFilter