import { Modal } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import AsyncSelect from 'react-select/async'
import { toast } from "react-toastify"
import { useFormik } from "formik"
import clsx from "clsx"
import * as Yup from "yup"

import { getUsers } from "../../../../models/users"
import { saveCourse } from "../../../../models/courses/courses"
import Spinner from "../../../../components/Spinner/Spinner"
import { useNavigate } from "react-router-dom"
import { getCategories } from "../../../../models/courses/categories"


const schema = Yup.object().shape({
    course_type: Yup.string().required().label('Course type'),
    course_method: Yup.string().required().label('Course method'),
    title: Yup.string().required().label('Title'),
    category: Yup.object().required().nullable().label('Category'),
    mentor: Yup.object().required().nullable().label('Mentor'),
})

const CourseTypeItem = ({ active, children, onClick }) => (
    <div className={clsx("position-relative border border-gray-400 rounded px-3 py-4 cursor-pointer", active ? 'bg-light-primary' : 'bg-hover-light-primary')} onClick={onClick}>
        {active ? <div className="position-absolute w-20px h-20px bg-primary rounded-circle d-flex justify-content-center align-items-center top-0 end-0" style={{
            marginTop: -5,
            marginRight: -5
        }}><i className="fa fa-check text-white"></i></div> : null}
        <p className="text-gray-700 fw-semibold mb-0 fs-6 text-center">{children}</p>
    </div>
)

const CreateSimpleCourseModal = ({ show, onHide }) => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            course_type: '',
            course_method: '',
            title: '',
            category: '',
            mentor: ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {
                is_prakerja: values.course_type === 'prakerja' ? 1 : 0,
                type: values.course_method,
                title: values.title,
                category_id: values.category.value,
                mentor_user_id: values.mentor.value
            }

            mutate(data)
        }
    })

    const queryClient = useQueryClient()

    const searchCategory = async search => queryClient.fetchQuery('all-categories', () => getCategories(true, search)).then(res => res.data.map(category => ({
        label: category.name,
        value: category.id,
    })))

    const searchMentor = async search => queryClient.fetchQuery('mentors', () => getUsers({ isMentor: true, name: search })).then(res => res.data.map(mentor => ({
        label: mentor.name,
        value: mentor.id,
    })))

    const { mutate, isLoading } = useMutation(data => saveCourse(data), {
        onSuccess: data => {
            queryClient.invalidateQueries(['courses'])
            toast.success('Success')
            navigate(`/courses/manage/${data.data.id}/edit/basic`)
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-4">
                        <label className="form-label required">Course Type</label>
                        <div className="row">
                            <div className="col-6">
                                <CourseTypeItem active={formik.values.course_type === 'general'} onClick={() => formik.setFieldValue('course_type', 'general')}>General</CourseTypeItem>
                            </div>
                            <div className="col-6">
                                <CourseTypeItem active={formik.values.course_type === 'prakerja'} onClick={() => formik.setFieldValue('course_type', 'prakerja')}>Kartu Prakerja</CourseTypeItem>
                            </div>
                        </div>
                        {formik.errors.course_type ? <small className="form-text text-danger">{formik.errors.course_type}</small> : null}
                    </div>
                    <div className="form-group mb-4">
                        <label className="form-label required">Course Method</label>
                        <div className="row">
                            <div className="col-6 col-md-4">
                                <CourseTypeItem active={formik.values.course_method === 'video'} onClick={() => formik.setFieldValue('course_method', 'video')}>Video</CourseTypeItem>
                            </div>
                            <div className="col-6 col-md-4">
                                <CourseTypeItem active={formik.values.course_method === 'video_spl'} onClick={() => formik.setFieldValue('course_method', 'video_spl')}>Video SPL</CourseTypeItem>
                            </div>
                            <div className="col-6 col-md-4 mt-3 mt-md-0">
                                <CourseTypeItem active={formik.values.course_method === 'webinar'} onClick={() => formik.setFieldValue('course_method', 'webinar')}>Webinar</CourseTypeItem>
                            </div>
                            <div className="col-6 col-md-4 mt-3">
                                <CourseTypeItem active={formik.values.course_method === 'offline'} onClick={() => formik.setFieldValue('course_method', 'offline')}>Offline</CourseTypeItem>
                            </div>
                        </div>
                        {formik.errors.course_method ? <small className="form-text text-danger">{formik.errors.course_method}</small> : null}
                    </div>
                    <div className="form-group mb-4">
                        <label className="form-label required">Course Title</label>
                        <input type="text" name="title" className="form-control" placeholder="Course Title" value={formik.values.title} onChange={formik.handleChange} />
                        {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
                    </div>
                    <div className="form-group mb-4">
                        <label className="form-label required">Category</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={searchCategory}
                            name="category"
                            onChange={option => formik.setFieldValue('category', option)}
                            value={formik.values.category}
                        />
                        {formik.errors.category ? <small className="form-text text-danger">{formik.errors.category}</small> : null}
                    </div>
                    <div className="form-group mb-8">
                        <label className="form-label required">Mentor</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={searchMentor}
                            name="mentor"
                            onChange={option => formik.setFieldValue('mentor', option)}
                            value={formik.values.mentor}
                        />
                        {formik.errors.mentor ? <small className="form-text text-danger">{formik.errors.mentor}</small> : null}
                    </div>
                    <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isLoading}>
                        Create Course {isLoading && <Spinner size="sm" className="ms-2" />}
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateSimpleCourseModal