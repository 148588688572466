import { useEffect } from "react"
import { Link } from "react-router-dom"
import { MenuComponent } from "../../../../../../_metronic/assets/ts/components"


const MenuItem = ({ actionType = 'link', href, onClick, className, children }) => (
    <div className={`menu-item px-3 ` + className}>
        {actionType === 'link' ? (
            <Link to={href} className='menu-link px-3'>
                {children}
            </Link>
        ) : actionType === 'onClick' ? (
            <span className="menu-link px-3" onClick={onClick}>
                {children}
            </span>
        ) : ''}
    </div>
)

const ActionsCell = ({ schedule, roleId }) => {
    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                <i className="fa fa-ellipsis-vertical pe-0"></i>
            </a>
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-4'
                data-kt-menu='true'
            >
                <MenuItem href={'/courses/schedules/' + schedule.id + '/overview'}>Overview</MenuItem>
                <MenuItem href={'/courses/schedules/' + schedule.id + '/sessions'}>Sessions</MenuItem>
                <MenuItem href={'/courses/schedules/' + schedule.id + '/students'}>Students</MenuItem>
                <MenuItem href={'/courses/schedules/' + schedule.id + '/tasks'}>Tasks</MenuItem>
                {['admin', 'officer'].includes(roleId) && <MenuItem href={'/courses/schedules/' + schedule.id + '/reports'}>Reports</MenuItem>}
            </div>
        </>
    )
}

export default ActionsCell