import NumberCell from "../../../../../components/Table/cells/Number"
import DateTimeCell from "../../../../../components/Table/cells/DateTime"
import CourseCell from "./CourseCell"
import ScheduleCell from "./ScheduleCell"
import StatusCell from "./StatusCell"
import ActionsCell from "./ActionsCell"


const scheduleColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        className: 'ps-2',
        Cell: ({ ...props }) => <NumberCell {...props} />
    }, {
        Header: 'Course',
        accessor: 'course',
        Cell: ({ ...props }) => <CourseCell course={props.data[props.row.index].course} />
    }, {
        Header: 'Schedule',
        accessor: 'schedule',
        Cell: ({ ...props }) => <ScheduleCell schedule={props.data[props.row.index]} />
    }, {
        Header: 'Host',
        accessor: 'host',
        Cell: ({ ...props }) => props.data[props.row.index].zoom_user?.email || '-'
    }, {
        Header: 'Schedule Start',
        accessor: 'schedule_start',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].schedule_start} withTime={false} />
    }, {
        Header: 'Schedule End',
        accessor: 'schedule_end',
        Cell: ({ ...props }) => <DateTimeCell dateTime={props.data[props.row.index].schedule_end} withTime={false} />
    }, {
        Header: 'Total Students',
        accessor: 'total_students',
        disableSortBy: true,
        className: 'text-center'
    }, {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell schedule={props.data[props.row.index]} roleId={props.roleId} />
    }
]

const columnAliases = {
    number: 'id'
}

export { scheduleColumns, columnAliases }