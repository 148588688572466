import { useFormik, FormikProvider, FieldArray, Field } from "formik"
import * as Yup from "yup"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import AsyncSelect from 'react-select/async'

import Spinner from "../../../../../components/Spinner/Spinner"
import { getLessons } from "../../../../../models/courses/courses"
import { getPackages } from "../../../../../models/packages"


const schema = Yup.object().shape({
    question: Yup.string().required('Question is required').min(5, 'Question must be at least 5 characters'),
    question_type: Yup.string().required('Question type is required').oneOf(['single', 'multiple']),
    answers: Yup.array().of(
        Yup.object().shape({
            answer: Yup.string().required('Answer is required')
        })
    ).required('Answers is required').min(2)
})

const QuestionForm = ({ queryClient, lesson, data, onSubmit, submitLoading }) => {
    const searchPackage = async search => queryClient.fetchQuery('packages', () => getPackages({ name: search })).then(res => res.data.map(item => ({
        label: item.name,
        value: item.id,
    })))
    const searchLesson = async search => queryClient.fetchQuery(`course-${lesson.course_id}-lessons`, () => getLessons({ courseId: lesson.course_id, title: search, type: 'video,document' })).then(res => res.data.map(lesson => ({
        label: lesson.title,
        value: lesson.id,
    })))

    const formik = useFormik({
        initialValues: {
            package: data?.package ? {
                label: data.package.name,
                value: data.package.id
            } : '',
            question: data?.question ? data.question : '',
            question_type: data?.question_type ? data.question_type : 'single',
            related_lesson_id: data?.related_lesson ? {
                label: data.related_lesson.title,
                value: data.related_lesson.id
            } : '',
            answers: data?.answers ? data.answers.map(answer => ({
                answer: answer.answer,
                is_correct_answer: answer.is_correct_answer ? 1 : null
            })) : []
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.package_id = values.package.value
            data.answers = []
            data.related_lesson_id = values.related_lesson_id.value

            values.answers.map(answer => {
                const tempAnswer = { answer: answer.answer }
                if (answer.is_correct_answer) tempAnswer.is_correct_answer = true

                return data.answers.push(tempAnswer)
            })

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            {(lesson.quiz_type === 'pre_test' || lesson.quiz_type === 'post_test') && (
                <div className="form-group mb-4">
                    <label className="form-label">Package</label>
                    <AsyncSelect
                        isClearable
                        cacheOptions
                        defaultOptions
                        loadOptions={searchPackage}
                        name="package"
                        placeholder="Select Package"
                        onChange={option => formik.setFieldValue('package', option)}
                        value={formik.values.package}
                    />
                </div>
            )}
            <div className="form-group mb-4">
                <label className="form-label required">Question</label>
                <ReactQuill theme='snow' name="question" value={formik.values.question} onChange={value => formik.setFieldValue('question', value)} />
                {formik.errors.question ? <small className="form-text text-danger">{formik.errors.question}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Question Type</label>
                <select name="question_type" className="form-select">
                    <option value="single">Single Choice</option>
                    <option value="multiple">Multiple Choice</option>
                </select>
                {formik.errors.question_type ? <small className="form-text text-danger">{formik.errors.question_type}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Related Lesson</label>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    isClearable
                    loadOptions={searchLesson}
                    name="related_lesson_id"
                    onChange={option => formik.setFieldValue('related_lesson_id', option)}
                    value={formik.values.related_lesson_id}
                />
            </div>
            <FormikProvider value={formik}>
                <FieldArray name="answers">
                    {({ push, remove }) => (
                        <>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <p className="form-label required mb-0">Answers</p>
                                <button type="button" className="btn btn-sm btn-light-primary" onClick={() => push()}>Add Answer</button>
                            </div>
                            <div className="mb-8">
                                {formik.values.answers?.length > 0 && formik.values.answers.map((_, i) => (
                                    <div key={i} className="d-flex align-items-center mb-3">
                                        <div className="flex-shrink-1">
                                            <div className="form-check form-check-custom form-check-solid">
                                                <Field type="checkbox" name={`answers.${i}.is_correct_answer`} className="form-check-input" onChange={e => formik.setFieldValue(`answers.${i}.is_correct_answer`, e.target.checked)} />
                                            </div>
                                        </div>
                                        <div className="w-100 mx-3">
                                            <Field name={`answers.${i}.answer`} className="form-control" placeholder="Answer" />
                                            {formik.errors.answers && Array.isArray(formik.errors.answers) ? <small className="form-text text-danger mb-3">{formik.errors.answers[i]?.answer}</small> : null}
                                        </div>
                                        <div className="flex-shrink-1">
                                            <button type="button" className="btn btn-sm btn-light-danger" onClick={() => remove(i)}><i className="fa fa-trash p-0"></i></button>
                                        </div>
                                    </div>
                                ))}
                                {formik.errors.answers && !Array.isArray(formik.errors.answers) ? <small className="form-text text-danger mb-3">{formik.errors.answers}</small> : null}
                            </div>
                        </>
                    )}
                </FieldArray>
            </FormikProvider>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                {data ? 'Update' : 'Create'} Question {submitLoading && <Spinner size="sm" />}
            </button>
        </form>
    )
}

export default QuestionForm