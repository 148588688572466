import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"

import { durationToText, eventDate } from "../../../../../../helpers/common"
import { generateZoomLogs, getScheduleSession, getScheduleSessionParticipants, updateScheduleSessionParticipant } from "../../../../../../models/courses/schedules"
import PageSpinner from "../../../../../../components/Spinner/PageSpinner"
import PageError from "../../../../../../components/Error/PageError"
import ParticipantFilter from "./components/ParticipantFilter"
import { columnAliases, participantColumns } from "./components/ParticipantTable/_columns"
import Table from "../../../../../../components/Table/Table"
import GeneralPagination from "../../../../../../components/Pagination/GeneralPagination"
import Spinner from "../../../../../../components/Spinner/Spinner"
import ZoomLogsModal from "./components/ZoomLogsModal"
import { loginAsUser } from "../../../../../../models/users"


const Participant = ({ scheduleId, session }) => {
    const participantsQueryKey = `schedule-${scheduleId}-session-${session.id}-participants`
    const pageSize = 100
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const [showZoomLogsModal, setShowZoomLogsModal] = useState(false)
    const [zoomLogsRegistrantId, setZoomLogsRegistrantId] = useState(null)

    const queryClient = useQueryClient()

    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery([participantsQueryKey], () => getScheduleSessionParticipants({
        scheduleId,
        sessionId: session.id,
        keyword: filters?.keyword,
        status: filters?.status,
        verified_status: filters?.verified_status,
        page,
        rows: pageSize,
        sortBy
    }))

    const { mutate: mutateSessionUser } = useMutation(data => updateScheduleSessionParticipant({ scheduleId, sessionId: session.id, ...data }), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([participantsQueryKey])
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    const { mutate: generateZoomLogsMutate, isLoading: generateZoomLogsLoading } = useMutation(data => generateZoomLogs(scheduleId, session.id), {
        onSuccess: () => {
            setShowZoomLogsModal(true)
            queryClient.invalidateQueries([participantsQueryKey])
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    const { mutate: loginAsUserMutate } = useMutation(userId => loginAsUser({ userId }), {
        onSuccess: data => window.open(data.data, '_newtab'),
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        queryClient.fetchQuery([participantsQueryKey])
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <div className="card mt-6">
                <div className="card-header d-block p-6">
                    <div className="d-flex">
                        <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        {session.status === 'finished' && (
                            <button type="button" className="btn btn-sm btn-light-dark" disabled={generateZoomLogsLoading} onClick={() => {
                                setZoomLogsRegistrantId(null)
                                generateZoomLogsMutate()
                            }}>Zoom Meeting Logs {generateZoomLogsLoading && <Spinner size="sm" className="ms-1 text-white" />}</button>
                        )}
                    </div>
                    <ParticipantFilter
                        show={showFilter}
                        onSubmit={values => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className="card-body">
                    <Table
                        columns={participantColumns}
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        session={session}
                        onStatusChange={data => mutateSessionUser(data)}
                        onLoginAsUser={userId => loginAsUserMutate(userId)}
                        onShowZoomLogs={registrantId => {
                            setZoomLogsRegistrantId(registrantId)
                            generateZoomLogsMutate()
                        }}
                    />
                    
                    {isSuccess && <GeneralPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>

            <ZoomLogsModal show={showZoomLogsModal} onHide={() => setShowZoomLogsModal(false)} scheduleId={scheduleId} sessionId={session.id} registrantId={zoomLogsRegistrantId} />
        </>
    )
}

export default function Session() {
    const { scheduleId, sessionId } = useParams()
    const { schedule } = useOutletContext()
    const navigate = useNavigate()

    const { isLoading, isError, error, data: { data: session } = {} } = useQuery(`schedule-${scheduleId}-session-${sessionId}`, () => getScheduleSession(scheduleId, sessionId), {
        retry: 0,
        onError: error => {
            if (error.response.status === 404) {
                toast.error('Session not found')
                navigate(`/courses/schedules/${scheduleId}/sessions`)
            }
        }
    })

    return (
        <div className="min-h-500px">
            {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : (
                <>
                    <div className="card">
                        <div className="card-body p-6">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="mb-1 text-primary">{session.lesson?.section?.title}</p>
                                    <h3 className="mb-0">{session.lesson?.title}</h3>
                                </div>
                                <div>
                                    {session.status === 'inactive' ? <span className="badge badge-square badge-lg badge-light p-3">Upcoming</span> : null}
                                    {session.status === 'active' ? <span className="badge badge-square badge-lg badge-light-primary p-3">On Going</span> : null}
                                    {session.status === 'finished' ? <span className="badge badge-square badge-lg badge-light-primary p-3">Finished</span> : null}
                                </div>
                            </div>
                            <div className="separator my-6"></div>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <p className="fw-bold fs-6 text-muted mb-1">Date Time</p>
                                    <p class="fs-6 mb-0">{eventDate(session.start_datetime)}</p>
                                </div>
                                <div className="col-12 col-md-2 mt-4 mt-md-0">
                                    <p className="fw-bold fs-6 text-muted mb-1">Duration</p>
                                    <p class="fs-6 mb-0">{durationToText(session.lesson?.duration)}</p>
                                </div>
                                <div className="col-12 col-md-2 mt-4 mt-md-0">
                                    <p className="fw-bold fs-6 text-muted mb-1">Absent Code</p>
                                    <p class="fs-6 mb-0">{session.absent_code}</p>
                                </div>
                                <div className="col-12 col-md-4 mt-4 mt-md-0">
                                    <p className="fw-bold fs-6 text-muted mb-1">Host</p>
                                    <p class="fs-6 mb-0">{schedule?.zoom_user?.email || '-'}</p>
                                </div>
                                <div className="col-12 col-md-4 mt-4">
                                    <p className="fw-bold fs-6 text-muted mb-1">Participant Count</p>
                                    <p class="fs-6 mb-0"><span className="fw-semibold">{session.total_participants}</span> of <span className="fw-semibold">{schedule.total_students}</span></p>
                                </div>
                                <div className="col-12 col-md-8 mt-4">
                                    <p className="fw-bold fs-6 text-muted mb-1">Meeting URL</p>
                                    <p class="fs-6 mb-0">
                                        {session.meeting_url ? <a href={session.meeting_url || '#'} target="_blank" rel="noreferrer">{session.meeting_url}</a> : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Participant scheduleId={scheduleId} session={session} />
                </>
            )}
        </div>
    )
}
