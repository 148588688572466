import { Modal } from "react-bootstrap"
import VideoPlayer from "../../../../../components/VideoPlayer"


const LiveSessionsViewVideoModal = ({ liveSession, show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{liveSession?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                {liveSession ? (
                    <VideoPlayer
                        options={{
                            autoplay: true,
                            controls: true,
                            responsive: true,
                            fluid: true,
                            sources: [{
                                src: liveSession.content_url
                            }]
                        }}
                    />
                ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default LiveSessionsViewVideoModal