import VoucherTypeCell from '../../../../../../components/Table/cells/VoucherTypeCell'
import { formatDate } from '../../../../../../helpers/common'

import CourseCell from "../../../ManageVouchers/components/VoucherTable/CourseCell"
import ActionsCell from "./ActionsCell"

const voucherBatchColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <strong className="text-muted">{((props.initialState.currentPage - 1) * props.initialState.pageSize) + props.row.index + 1}</strong>
    }, {
        Header: 'Title',
        accessor: 'title'
    }, {
        Header: 'Merchant',
        accessor: 'merchant',
        disableSortBy: true,
        Cell: ({ ...props }) => props.data[props.row.index].merchant?.name
    }, {
        Header: 'Course',
        accessor: 'course',
        disableSortBy: true,
        Cell: ({ ...props }) => <CourseCell course={props.data[props.row.index].course} />
    }, {
        Header: 'Voucher Type',
        accessor: 'voucher_type',
        Cell: ({ ...props }) => <VoucherTypeCell type={props.data[props.row.index].voucher_type} />
    }, {
        Header: 'Total',
        accessor: 'total'
    }, {
        Header: 'Created at',
        accessor: 'created_at',
        Cell: ({ ...props }) => formatDate(props.data[props.row.index].created_at)
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell batch={props.data[props.row.index]} onDownload={props.onDownload} />
    }
]

export { voucherBatchColumns }