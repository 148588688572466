/* eslint-disable react/jsx-no-target-blank */
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
    const { currentUser } = useAuth()
    const userId = currentUser!.id
    const roleId = currentUser!.role_id
    const sumaUserIds = [8407, 8408, 8434, 8435, 8456, 8457, 8637, 8638, 8639, 8640]

    return (
        <>
            <SidebarMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title='Dashboard'
            />

            {['admin', 'officer', 'user', 'host'].includes(roleId) && (
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                                Courses
                            </span>
                        </div>
                    </div>
                    {['admin', 'officer'].includes(roleId) && (
                        <SidebarMenuItem
                            to='/courses/categories'
                            title='Categories'
                            icon='/media/icons/duotune/abstract/abs015.svg'
                            />
                    )}
                    {(['admin', 'officer', 'user'].includes(roleId) && !sumaUserIds.includes(userId)) && (
                        <SidebarMenuItem
                            to='/courses/manage'
                            title='Courses'
                            icon='/media/icons/duotune/abstract/abs025.svg'
                        />
                    )}
                    <SidebarMenuItem
                        to='/courses/tasks'
                        title='Tasks'
                        icon='/media/icons/duotune/general/gen024.svg'
                    />
                    <SidebarMenuItemWithSub
                        to='/courses/schedules'
                        title='Schedules'
                        icon='/media/icons/duotune/files/fil002.svg'
                    >
                        <SidebarMenuItem
                            to='/courses/schedules/all'
                            title='All Schedules'
                            hasBullet={true}
                        />
                        <SidebarMenuItem
                            to='/courses/schedules/sessions'
                            title='All Sessions'
                            hasBullet={true}
                        />
                    </SidebarMenuItemWithSub>
                    {['admin', 'officer'].includes(roleId) && (
                        <>
                            <SidebarMenuItemWithSub
                                to='/courses/vouchers'
                                title='Vouchers'
                                icon='/media/icons/duotune/abstract/abs026.svg'
                            >
                                <SidebarMenuItem
                                    to='/courses/vouchers/manage'
                                    title='Manage Vouchers'
                                    hasBullet={true}
                                />
                                <SidebarMenuItem
                                    to='/courses/vouchers/batch'
                                    title='Generate History'
                                    hasBullet={true}
                                />
                                <SidebarMenuItem
                                    to='/courses/vouchers/import/reconciliation'
                                    title='Import Reconciliation'
                                    hasBullet={true}
                                />
                                <SidebarMenuItem
                                    to='/courses/vouchers/import/feedback'
                                    title='Import Feedback'
                                    hasBullet={true}
                                />
                            </SidebarMenuItemWithSub>
                            <SidebarMenuItemWithSub
                                to='/courses/coupons'
                                title='Coupons'
                                icon='/media/icons/duotune/abstract/abs037.svg'
                            >
                                <SidebarMenuItem
                                    to='/courses/coupons/manage'
                                    title='Manage Coupons'
                                    hasBullet={true}
                                />
                                <SidebarMenuItem
                                    to='/courses/coupons/batch'
                                    title='Generate History'
                                    hasBullet={true}
                                />
                            </SidebarMenuItemWithSub>
                        </>
                    )}
                </>
            )}

            {['admin', 'officer', 'customer_service'].includes(roleId) && (
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                                Reports
                            </span>
                        </div>
                    </div>
                    <SidebarMenuItem
                        to='/reports/progress'
                        title='Check Progress'
                        icon='/media/icons/duotune/files/fil024.svg'
                    />
                    {['admin', 'officer'].includes(roleId) && (
                        <SidebarMenuItem
                            to='/reports/prakerja'
                            title='Prakerja'
                            icon='/media/icons/duotune/files/fil021.svg'
                        />
                    )}
                </>
            )}

            {['admin'].includes(roleId) && (
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                                Merchants
                            </span>
                        </div>
                    </div>
                    <SidebarMenuItem
                        to='/merchants'
                        title='Merchants'
                        icon='/media/icons/duotune/general/gen026.svg'
                    />

                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                                Users
                            </span>
                        </div>
                    </div>
                    <SidebarMenuItem
                        to='/users'
                        title='Users'
                        icon='/media/icons/duotune/communication/com006.svg'
                    />
                </>
            )}
            {['admin'].includes(roleId) && (
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                                Statistic
                            </span>
                        </div>
                    </div>
                    <SidebarMenuItem
                        to='/statistics/revenue'
                        title='Revenue Statistic'
                        icon='/media/icons/duotune/general/gen032.svg'
                    />
                </>
            )}

            {['admin'].includes(roleId) && (
                <>
                    <div className='menu-item'>
                        <div className='menu-content pt-8 pb-2'>
                            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                                Settings
                            </span>
                        </div>
                    </div>
                    <SidebarMenuItem
                        to='/settings/website'
                        title='Website Settings'
                        fontIcon='bi-archive'
                        icon='/media/icons/duotune/abstract/abs013.svg'
                    />
                </>
            )}
        </>
    )
}

export { SidebarMenuMain }
