import { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { Line } from "react-chartjs-2"

import { getRevenueChart } from "../../../../models/statistics"
import PageSpinner from "../../../../components/Spinner/PageSpinner"
import PageError from "../../../../components/Error/PageError"


const RevenueChart = ({ filters }) => {
    const [chartView, setChartView] = useState('voucher')

    const queryClient = useQueryClient()
    const { isLoading, isFetching, data: { data: revenue } = {}, isError, error, isSuccess } = useQuery('revenue-chart', () => getRevenueChart({
        courseId: filters?.course_id,
        view: chartView,
        pubYear: filters?.pub_year,
        pubMonth: filters?.pub_month,
        pubDate: filters?.pub_date
    }), {
        enabled: false
    })

    useEffect(() => {
        queryClient.fetchQuery('revenue-chart')
    }, [filters, chartView])

    return (
        <div className="card">
            <div className="card-body p-6">
                <div className="d-flex justify-content-between mb-8">
                    <p className="fw-bold fs-5 mb-0">Chart</p>
                    <div className="w-150px">
                        <select className="form-select form-select-sm" onChange={e => setChartView(e.target.value)} value={chartView}>
                            <option value="voucher">Total Voucher</option>
                            <option value="amount">Total Amount</option>
                        </select>
                    </div>
                </div>
                {isLoading || isFetching ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : isSuccess ? (
                    <Line
                        options={{
                            elements: {
                                line: {
                                    tension: 0.4
                                }
                            },
                            scales: {
                                y: {
                                    stacked: true
                                }
                            }
                        }}
                        data={{
                            labels: revenue.labels,
                            datasets: revenue.datasets
                        }}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default RevenueChart