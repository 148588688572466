import { userProfilePicture } from "../../../helpers/pictures"


const UserCell = ({ user }) => (
    <div className="d-flex align-items-center">
        <img src={userProfilePicture(user.profile_picture_url)} className="me-3 rounded-circle h-30px" alt="Profile" />
        <div>
            <p className="mb-0">{user.name}</p>
            <p className="mb-0 text-muted">{user.email}</p>
        </div>
    </div>
)

export default UserCell