import { useFormik } from "formik"
import * as Yup from "yup"

import Spinner from "../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    title: Yup.string().required().min(5).max(255).label('Title')
})

const LessonTypeReflectiveJournal = ({ lesson, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            title: lesson ? lesson.title : ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.type = 'reflective_journal'

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Title</label>
                <input type="text" name="title" className="form-control" placeholder="Lesson Title" onChange={formik.handleChange} value={formik.values.title} />
                {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                {!lesson ? 'Create' : 'Edit'} Lesson {isSubmitLoading ? <Spinner size="sm" /> : null}
            </button>
        </form>
    )
}

export default LessonTypeReflectiveJournal