import { useQuery, useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { PageTitle } from "../../../../../_metronic/layout/core"
import PageError from "../../../../components/Error/PageError"
import PageSpinner from "../../../../components/Spinner/PageSpinner"
import { getVoucher, updateVoucher } from "../../../../models/courses/vouchers"
import Authorization from "../../../../routing/Authorization"
import VoucherForm from "./components/VoucherForm"


const breadcumbs = [
    {
        title: 'Manage Vouchers',
        path: '/courses/vouchers/manage',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const EditVoucher = () => {
    const { voucherId } = useParams()

    const queryClient = useQueryClient()
    const { isLoading, data: { data: voucher } = {}, isError, error } = useQuery([`voucher-${voucherId}`], () => getVoucher(voucherId))

    const { mutate, isLoading: isSubmitLoading } = useMutation(voucher => updateVoucher(voucherId, voucher), {
        onSuccess: () => {
            queryClient.invalidateQueries([`voucher-${voucherId}`])
            queryClient.invalidateQueries(['vouchers'])
            toast.success('Success')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <>
            <PageTitle breadcrumbs={breadcumbs}>Edit Voucher</PageTitle>
            <div className="card">
                <div className="card-body">
                    {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : (
                        <VoucherForm
                            queryClient={queryClient}
                            data={voucher}
                            onSubmit={data => mutate(data)}
                            isSubmitLoading={isSubmitLoading}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

export default Authorization(EditVoucher, ['admin', 'officer'])