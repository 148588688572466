import { useState } from "react"
import { Modal } from "react-bootstrap"
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


const LessonViewDocumentModal = ({ lesson, show, onHide }) => {
    const [page, setPage] = useState(1)
    const documentUrl = process.env.REACT_APP_USER_API_URL + '/courses/' + lesson.course_id + '/lessons/' + lesson.id + '/files/download'

    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{lesson?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div>
                    {lesson ? (
                        <Document file={documentUrl}>
                            <Page pageNumber={page} />
                        </Document>
                    ) : null}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LessonViewDocumentModal