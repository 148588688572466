import { Field, FieldArray, FormikProvider, useFormik } from "formik"
import { useMutation, useQueryClient } from "react-query"
import { useOutletContext } from "react-router-dom"
import { toast } from "react-toastify"
import * as Yup from "yup"

import Spinner from "../../../../components/Spinner/Spinner"

import { updateCourseOutcomes } from "../../../../models/courses/courses"


const schema = Yup.object().shape({
    outcomes: Yup.array().of(Yup.string().required('Outcome is required'))
})

const Outcomes = () => {
    const { course } = useOutletContext()
    
    const formik = useFormik({
        initialValues: {
            outcomes: course.outcomes
        },
        validationSchema: schema,
        onSubmit: values => mutate(values)
    })
    
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(data => updateCourseOutcomes(course.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            queryClient.invalidateQueries([`course-${course.id}`])
            toast.success('Success')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <div className="card">
            <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        <FieldArray name="outcomes">
                            {({ push, remove }) => (
                                <>
                                    <div className="d-flex justify-content-between align-items-center mb-8">
                                        <h3 className="mb-0">Outcomes</h3>
                                        <button type="button" className="btn btn-sm btn-light-primary" onClick={() => push()}>Add Outcomes</button>
                                    </div>
                                    {formik.values.outcomes.length > 0 && formik.values.outcomes.map((_, i) => (
                                        <div key={i} className="mb-4">
                                            <div className="d-flex align-items-center">
                                                <Field name={`outcomes.${i}`} className="form-control flex-fill me-4" placeholder={`Outcome #${i + 1}`} />
                                                <button type="button" className="btn btn-sm btn-light-danger" onClick={() => remove(i)}>Remove</button>
                                            </div>
                                            {formik.errors.outcomes && Array.isArray(formik.errors.outcomes) ? <small className="form-text text-danger mb-3">{formik.errors.outcomes[i]}</small> : null}
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>
                        
                        <div className="mt-10">
                            <button type="submit" className="btn btn-primary w-100" disabled={isLoading || !formik.isValid}>
                                Save Changes {isLoading && <Spinner size="sm" className="ms-2" />}
                            </button>
                        </div>
                    </FormikProvider>
                </form>
            </div>
        </div>
    )
}

export default Outcomes