import AsyncSelect from 'react-select/async'
import { FormikProvider, useFormik } from "formik"
import { Spinner } from "react-bootstrap"
import * as Yup from 'yup'

import { getCourses } from "../../../../../models/courses/courses"

const schema = Yup.object().shape({
    course_id: Yup.object().required('Course is required'),
    code: Yup.string().required('Coupon code is required').min(1, 'Coupon code must be at least 1 characters').max(255, 'Coupon code must be at most 255 characters'),
    status: Yup.string().required('Status is required').oneOf(['available', 'used', 'expired', 'burned']),
})

const CouponForm = ({ data = null, queryClient, onSubmit, isSubmitLoading = false }) => {
    const searchCourse = async search => queryClient.fetchQuery('all-courses', () => getCourses(search)).then(res => res.data.map(course => ({
        label: course.title,
        value: course.id,
    })))

    const formik = useFormik({
        initialValues: {
            course_id: data && data.course ? {
                value: data?.course?.id,
                label: data?.course?.title
            } : '',
            code: data?.code,
            discount_percentage: data?.discount_percentage,
            max_amount: data?.max_amount,
            quota: data?.quota,
            status: data ? data.status : 'available'
        },
        validationSchema: schema,
        onSubmit: values => onSubmit(values),
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <div className="form-group mb-4">
                    <label className="form-label required">Course</label>
                    <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={searchCourse}
                        name="course_id"
                        onChange={option => formik.setFieldValue('course_id', option)}
                        value={formik.values.course_id}
                    />
                    {formik.errors.course_id ? <small className="form-text text-danger">{formik.errors.course_id}</small> : null}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label required">Coupon Code</label>
                    <input type="text" name="code" className="form-control" placeholder="Coupon Code" onChange={formik.handleChange} value={formik.values.code} />
                    {formik.errors.code ? <small className="form-text text-danger">{formik.errors.code}</small> : null}
                </div>
                <div className="row">
                    <div className="col-12 col-md-4 form-group mb-4">
                        <label className="form-label required">Discount %</label>
                        <input type="number" name="discount_percentage" className="form-control" placeholder="Discount" onChange={formik.handleChange} value={formik.values.discount_percentage} />
                        {formik.errors.discount_percentage ? <small className="form-text text-danger">{formik.errors.discount_percentage}</small> : null}
                    </div>
                    <div className="col-12 col-md-4 form-group mb-4">
                        <label className="form-label required">Max Amount</label>
                        <input type="number" name="max_amount" className="form-control" placeholder="Max Amount" onChange={formik.handleChange} value={formik.values.max_amount} />
                        {formik.errors.max_amount ? <small className="form-text text-danger">{formik.errors.max_amount}</small> : null}
                    </div>
                    <div className="col-12 col-md-4 form-group mb-4">
                        <label className="form-label required">Quota</label>
                        <input type="number" name="quota" className="form-control" placeholder="Quota" onChange={formik.handleChange} value={formik.values.quota} />
                        {formik.errors.quota ? <small className="form-text text-danger">{formik.errors.quota}</small> : null}
                    </div>
                </div>
                <div className="form-group mb-0">
                    <label className="form-label">Status</label>
                    <select name="status" className="form-select" onChange={formik.handleChange} value={formik.values.status}>
                        <option value="available">Available</option>
                        <option value="used">Used</option>
                        <option value="expired">Expired</option>
                    </select>
                    {formik.errors.status ? <small className="form-text text-danger">{formik.errors.status}</small> : null}
                </div>

            </FormikProvider>

            <div className="separator my-8"></div>
            <p className="text-end mb-0">
                <button type="submit" className="btn btn-primary" disabled={isSubmitLoading || !formik.isValid}>{data ? 'Update' : 'Create'} Course {isSubmitLoading && <Spinner size="sm" className="ms-2" />}</button>
            </p>
        </form>
    )
}

export default CouponForm