import ActionsCell from "./ActionsCell"
import CourseCell from "./CourseCell"
import MentorCell from "./MentorCell"
import PriceCell from "./PriceCell"
import StatusCell from "./StatusCell"

const courseColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <strong className="text-muted">{((props.initialState.currentPage - 1) * props.initialState.pageSize) + props.row.index + 1}</strong>
    }, {
        Header: 'Course',
        accessor: 'course',
        Cell: ({ ...props }) => <CourseCell course={props.data[props.row.index]} />
    }, {
        Header: 'Mentor',
        accessor: 'mentor',
        Cell: ({ ...props }) => <MentorCell mainMentor={props.data[props.row.index].main_mentor} otherMentor={props.data[props.row.index].other_mentor} />
    }, {
        Header: 'Category',
        accessor: 'category',
        Cell: ({ ...props }) => <span className="badge badge-light">{props.data[props.row.index].category.name}</span>
    }, {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ ...props }) => <PriceCell price={props.data[props.row.index].price} discountedPrice={props.data[props.row.index].discounted_price} />
    }, {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell course={props.data[props.row.index]} onHighlight={props.onHighlight} onToggleTopCourse={props.onToggleTopCourse} onDummyCertificate={props.onDummyCertificate} onDelete={props.onDelete} />
    }
]

const columnAliases = {
    number: 'id',
    course: 'title'
}

export { courseColumns, columnAliases }