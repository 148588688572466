import { Modal } from "react-bootstrap"
import { useQuery } from "react-query"
import moment from "moment"

import { getLessonPopupQuizzes, getRJQuestions } from "../../../../../models/courses/courses"
import PageSpinner from "../../../../../components/Spinner/PageSpinner"
import PageError from "../../../../../components/Error/PageError"
import { popupQuizTypes } from "../../../../../helpers/conts"

const LessonPopupQuizModal = ({ lesson, show, onHide, onCreatePopupQuiz, onEditPopupQuiz, onDeletePopupQuiz }) => {
    const { isLoading, isError, error, data: { data: popupQuizzes } = {} } = useQuery(`lessons-${lesson.id}-popup-quiz`, () => getLessonPopupQuizzes({
        courseId: lesson.course_id,
        sectionId: lesson.section_id,
        lessonId: lesson.id,
    }), {
        enabled: !!show
    })

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Lessons Popup Quiz</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between align-items-center mb-5">
                    <div className="w-50">
                        <h3 className="mb-1">{lesson?.title}</h3>
                    </div>
                    <div>
                        <button type="button" className="btn btn-sm btn-primary" onClick={onCreatePopupQuiz}>Create Popup Quiz</button>
                    </div>
                </div>
                {show && (
                    <>
                        {isLoading ? (
                            <PageSpinner height={100} />
                        ) : isError ? (
                            <PageError height={100}>{error.message}</PageError>
                        ) : popupQuizzes.length > 0 && popupQuizzes.map((popupQuiz, i) => (
                            <div key={i} className="border rounded px-6 py-3 mb-3">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <span className="badge badge-square badge-primary p-3 text-capitalize me-3">{popupQuizTypes[popupQuiz.type]}</span>
                                        <span className="badge badge-square badge-light p-3 text-capitalize">{popupQuiz.position === 'start' ? 'Start of Video' : popupQuiz.position === 'end' ? 'End of Video' : popupQuiz.position === 'custom' ? moment().startOf('day').seconds(popupQuiz.position_duration).format('H:mm:ss') : null}</span>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-sm px-2 me-3" onClick={() => onEditPopupQuiz(popupQuiz)}>
                                            <i className="fa fa-pencil text-muted p-0"></i>
                                        </button>
                                        <button type="button" className="btn btn-sm px-2" onClick={() => onDeletePopupQuiz({ lesson, popupQuiz })}>
                                            <i className="fa fa-trash text-danger p-0"></i>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <h4>{popupQuiz.title}</h4>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default LessonPopupQuizModal