import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

export function uploadFile(data) {
    return axios.post(`${API_URL}/files`, data)
}

export function deleteFile(fileId) {
    return axios.delete(`${API_URL}/files/${fileId}`)
}
