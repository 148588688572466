import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useMutation, useQueryClient } from "react-query"
import { Field, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { toast } from "react-toastify"

import { useAuth } from "../../../../../modules/auth"
import { getCourses } from "../../../../../models/courses/courses"
import { getMerchants } from "../../../../../models/merchants"
import Spinner from "../../../../../components/Spinner/Spinner"
import { generateVoucher } from "../../../../../models/courses/vouchers"
import { formatDate } from "../../../../../helpers/common"


const schema = Yup.object().shape({
    showSchedule: Yup.boolean(),
    format: Yup.string().required().label('Format'),
    voucher_type: Yup.string().required().label('Voucher type'),
    merchant: Yup.object().required('Merchant is required').nullable(),
    course: Yup.object().required('Course is required').nullable(),
    price: Yup.number().required('Price is required').min(0),
    total: Yup.number().required('Total Voucher is required').min(1)
})

const GenerateVoucherModal = ({ show, onHide }) => {
    const { auth } = useAuth()
    const [courses, setCourses] = useState()

    const queryClient = useQueryClient()
    const searchCourse = async search => queryClient.fetchQuery('courses', () => getCourses(search, null, null, null, 1, 100)).then(res => {
        setCourses(items => {
            const tempItems = {...items}
            res.data.map(course => {
                if (course.id in tempItems) return true

                tempItems[course.id] = course
            })

            return tempItems
        })

        return res.data.map(course => ({
            label: course.title,
            value: course.id,
        }))
    })
    const searchMerchant = async search => queryClient.fetchQuery('merchants', () => getMerchants({ name: search, rows: 100 })).then(res => res.data.map(merchant => ({
        label: merchant.name,
        value: merchant.id,
    })))

    const { mutate, isLoading } = useMutation(data => generateVoucher(data), {
        onSuccess: data => {
            queryClient.invalidateQueries(['voucher-batches'])
            toast.success('Success')
            hideModal()
            window.open(data.data.download_url + '?_token=' + auth.access_token + '&format=' + formik.values.format, "_blank")
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    const formik = useFormik({
        initialValues: {
            showSchedule: false,
            format: 'general',
            voucher_type: 'live',
            merchant: '',
            course: '',
            schedule: '',
            price: 0,
            total: 1,
            expires_at: '',
            require_redeem_code: false
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.merchant_id = data.merchant.value
            data.course_id = data.course.value

            data.schedule_ids = []
            if (data.schedule) data.schedule.map(schedule => data.schedule_ids.push(schedule.value))

            mutate(data)
        }
    })

    useEffect(() => {
        if (!formik.values.course) return () => true
        if (!courses) return () => true
        if (!courses[formik.values.course.value]) return () => true
        
        formik.setFieldValue('price', courses[formik.values.course.value].price)
        formik.setFieldValue('schedule', '')
        formik.setFieldValue('showSchedule', courses[formik.values.course.value].schedules?.length > 0)
    }, [formik.values.course])

    const hideModal = () => {
        formik.resetForm()
        onHide()
    }

    return (
        <Modal show={show} onHide={hideModal} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Generate Vouchers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        <div className="form-group mb-4">
                            <label className="form-label required">Format</label>
                            <select name="format" className="form-select" onChange={formik.handleChange} value={formik.values.format}>
                                <option value="general">General</option>
                                <option value="kariermu">Kariermu</option>
                                <option value="bukalapak">Bukalapak</option>
                            </select>
                            {formik.errors.format ? <small className="form-text text-danger">{formik.errors.voucher_type}</small> : null}
                        </div>
                        <div className="form-group mb-4">
                            <label className="form-label required">Title</label>
                            <input type="text" name="title" className="form-control" placeholder="Title" onChange={formik.handleChange} value={formik.values.title} />
                            {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
                        </div>
                        <div className="form-group mb-4">
                            <label className="form-label required">Type</label>
                            <select name="voucher_type" className="form-select" onChange={formik.handleChange} value={formik.values.voucher_type}>
                                <option value="live">Live</option>
                                <option value="dummy">Dummy</option>
                            </select>
                            {formik.errors.voucher_type ? <small className="form-text text-danger">{formik.errors.voucher_type}</small> : null}
                        </div>
                        <div className="form-group mb-4">
                            <label className="form-label required">Merchant</label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={searchMerchant}
                                name="merchant"
                                className="form-control-sm p-0"
                                onChange={option => formik.setFieldValue('merchant', option)}
                                value={formik.values.merchant}
                            />
                            {formik.errors.merchant ? <small className="form-text text-danger">{formik.errors.merchant}</small> : null}
                        </div>
                        <div className="form-group mb-4">
                            <label className="form-label required">Course</label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                loadOptions={searchCourse}
                                name="course"
                                className="form-control-sm p-0"
                                onChange={option => formik.setFieldValue('course', option)}
                                value={formik.values.course}
                            />
                            {formik.errors.course ? <small className="form-text text-danger">{formik.errors.course}</small> : null}
                        </div>
                        {formik.values.showSchedule && (
                            <div className="form-group mb-4">
                                <label className="form-label">Schedule</label>
                                <Select
                                    name="schedule"
                                    className="form-control-sm p-0"
                                    options={courses[formik.values.course.value]?.schedules.map(schedule => ({
                                        label: `[${schedule.name}] ${formatDate(schedule.schedule_start, false)} - ${formatDate(schedule.schedule_end, false)}`,
                                        value: schedule.id
                                    }))}
                                    value={formik.values.schedule}
                                    onChange={option => formik.setFieldValue('schedule', option)}
                                    isMulti
                                />
                                {formik.errors.schedule ? <small className="form-text text-danger">{formik.errors.schedule}</small> : null}
                            </div>
                        )}
                        <div className="form-group mb-4">
                            <label className="form-label required">Price</label>
                            <input type="number" name="price" className="form-control" placeholder="Price" min={1} onChange={formik.handleChange} value={formik.values.price} />
                            {formik.errors.price ? <small className="form-text text-danger">{formik.errors.price}</small> : null}
                        </div>
                        <div className="form-group mb-4">
                            <label className="form-label required">Total Voucher</label>
                            <input type="number" name="total" className="form-control" placeholder="Total Voucher" min={1} onChange={formik.handleChange} value={formik.values.total} />
                            {formik.errors.total ? <small className="form-text text-danger">{formik.errors.total}</small> : null}
                        </div>
                        <div className="form-group mb-4">
                            <label className="form-label">Expires at</label>
                            <ReactDatePicker
                                name="expires_at"
                                className="form-control"
                                placeholderText="Expires at"
                                onChange={date => formik.setFieldValue('expires_at', date)}
                                selected={formik.values.expires_at}
                                minDate={new Date()}
                                dateFormat="yyyy-MM-dd"
                            />
                            {formik.errors.expires_at ? <small className="form-text text-danger">{formik.errors.expires_at}</small> : null}
                        </div>
                        <div className="form-check form-check-custom form-check-solid mb-8">
                            <Field type="checkbox" name="require_redeem_code" className="form-check-input" id="require_redeem_code" onChange={e => formik.setFieldValue('require_redeem_code', e.target.checked)} />
                            <label className="form-check-label" htmlFor="require_redeem_code">
                                Require Redeem Code
                            </label>
                        </div>
                        <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isLoading}>
                            Generate {isLoading ? <Spinner size="sm" color="white" className="ms-2" /> : null}
                        </button>
                    </FormikProvider>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default GenerateVoucherModal