import { durationToText } from "../../../../../helpers/common"


const SessionCell = ({ session }) => {
    return (
        <>
            <p className="fw-bold text-primary mb-0">
                <small>{session.schedule?.name + (session.schedule?.schedule_code ? ' - ' + session.schedule.schedule_code : '')}</small>
            </p>
            <p className="fw-bold mb-1">{session.lesson?.title}</p>
            <p className="text-muted mb-0">
                <small>{durationToText(session.lesson?.duration)}</small>
            </p>
        </>
    )
}

export default SessionCell