import { useFormik, Field, FormikProvider } from "formik"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import Collapse from "react-bootstrap/Collapse"

import { useAuth } from "../../../../modules/auth"

import { getUsers } from "../../../../models/users"
import { getCourses } from "../../../../models/courses/courses"
import { getSchedules } from "../../../../models/courses/schedules"
import { formatDate } from "../../../../helpers/common"


const SessionFilter = ({ defaultValues, show, queryClient, onSubmit }) => {
    const { currentUser: { role_id: roleId } } = useAuth()

    const searchCourse = async search => queryClient.fetchQuery('courses', () => getCourses(search)).then(res => res.data.map(course => ({
        label: course.title,
        value: course.id,
    })))

    const searchSchedule = async (search, courseId) => queryClient.fetchQuery('course-schedules', () => getSchedules({
        courseId,
        name: search
    })).then(res => res.data.map(schedule => ({
        label: schedule.name + ' (' + formatDate(schedule.schedule_start, false) + ' - ' + formatDate(schedule.schedule_end, false) + ')',
        value: schedule.id,
    })))

    const searchMentor = async search => queryClient.fetchQuery('mentors', () => getUsers({ isMentor: true, name: search })).then(res => res.data.map(mentor => ({
        label: mentor.name,
        value: mentor.id,
    })))

    const formik = useFormik({
        initialValues: {
            course: defaultValues?.course ? defaultValues.course : '',
            schedule: defaultValues?.schedule ? defaultValues.schedule : '',
            mentor: defaultValues?.mentor ? defaultValues.mentor : '',
            start_at: defaultValues?.start_at ? defaultValues.start_at : '',
            status: defaultValues?.status ? defaultValues.status : '',
            have_schedule_code: defaultValues?.have_schedule_code ? defaultValues.have_schedule_code : '',
        },
        onSubmit: values => onSubmit(values)
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        <div className="col-12 col-md-4">
                            <div className="form-group mb-4">
                                <label className="form-label"><small>Course</small></label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    isClearable
                                    loadOptions={searchCourse}
                                    name="course"
                                    className="form-control-sm p-0"
                                    onChange={option => formik.setFieldValue('course', option)}
                                    value={formik.values.course}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label"><small>Status</small></label>
                                <Select
                                    isClearable
                                    isSearchable={false}
                                    name="status"
                                    onChange={option => formik.setFieldValue('status', option)}
                                    value={formik.values.status}
                                    options={[
                                        { label: 'Upcoming', value: 'inactive' },
                                        { label: 'On Going', value: 'active' },
                                        { label: 'Finished', value: 'finished' },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group mb-4">
                                <label className="form-label"><small>Schedule</small></label>
                                <AsyncSelect
                                    key={formik.values.course?.value || 0}
                                    cacheOptions
                                    defaultOptions
                                    isClearable
                                    loadOptions={search => searchSchedule(search, formik.values.course?.value)}
                                    name="schedule"
                                    className="form-control-sm p-0"
                                    onChange={option => formik.setFieldValue('schedule', option)}
                                    value={formik.values.schedule}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label"><small>Start at</small></label>
                                <Select
                                    isClearable
                                    isSearchable={false}
                                    name="start_at"
                                    onChange={option => formik.setFieldValue('start_at', option)}
                                    value={formik.values.start_at}
                                    options={[
                                        { label: 'Today', value: 'today' },
                                        { label: 'Tomorrow', value: 'tomorrow' },
                                        { label: 'In 7 Days', value: 'in_7days' },
                                        { label: 'In 30 Days', value: 'in_30days' },
                                    ]}
                                />
                            </div>
                        </div>
                        {['admin', 'officer', 'host'].includes(roleId) ? (
                            <div className="col-12 col-md-4">
                                <div className="form-group mb-4">
                                    <label className="form-label"><small>Mentor</small></label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        isClearable
                                        loadOptions={searchMentor}
                                        name="mentor"
                                        onChange={option => formik.setFieldValue('mentor', option)}
                                        value={formik.values.mentor}
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label"><small>Have Schedule Code</small></label>
                                    <div className="form-check form-check-custom form-check-solid mt-2">
                                        <Field name="have_schedule_code" className="form-check-input" type="checkbox" id="have-schedule-code" onChange={e => formik.setFieldValue('have_schedule_code', e.target.checked)} />
                                        <label className="form-check-label" htmlFor="have-schedule-code">
                                            Have Schedule Code
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className="col-12 text-end mt-6">
                            <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                                formik.resetForm()
                                formik.setFieldValue('status', '')
                                formik.setFieldValue('have_schedule_code', 1)
                                onSubmit({
                                    have_schedule_code: 1
                                })
                            }}>Clear</button>
                            <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                        </div>
                    </FormikProvider>
                </form>
            </div>
        </Collapse>
    )
}

export default SessionFilter