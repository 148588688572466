import Pagination from "../../../components/Pagination/Pagination"


const RolePagination = ({ onPageChange, totalCount, currentPage, pageSize, from, to }) => {
    return (
        <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 text-muted">Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{totalCount}</strong> entries</p>
            <Pagination
                onPageChange={onPageChange}
                totalCount={totalCount}
                currentPage={currentPage}
                pageSize={pageSize}
            />
        </div>
    )
}

export default RolePagination