import { useFormik } from "formik"
import { useMutation, useQueryClient } from "react-query"
import { useOutletContext } from "react-router-dom"
import { toast } from "react-toastify"
import Spinner from "../../../../components/Spinner/Spinner"

import { updateCourseTanyaAI } from "../../../../models/courses/courses"


const TanyaAI = () => {
    const { course } = useOutletContext()
    
    const formik = useFormik({
        initialValues: {
            tanya_ai_prompt: course?.tanya_ai_prompt || ''
        },
        onSubmit: values => mutate(values)
    })
    
    const queryClient = useQueryClient()

    const { mutate, isLoading: submitIsLoading } = useMutation(data => updateCourseTanyaAI(course.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            queryClient.invalidateQueries([`course-${course.id}`])
            toast.success('Success')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <div className="card">
            <div className="card-body">
                <h3 className="mb-8">TanyaAI</h3>
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-group mb-10">
                        <label className="form-label">Prompt</label>
                        <textarea name="tanya_ai_prompt" className="form-control" placeholder="Prompt" value={formik.values.tanya_ai_prompt} onChange={formik.handleChange} />
                        {formik.errors.tanya_ai_prompt ? <small className="form-text text-danger">{formik.errors.tanya_ai_prompt}</small> : null}
                    </div>
                    <button type="submit" className="btn btn-primary w-100" disabled={submitIsLoading || !formik.isValid}>
                        Save Changes {submitIsLoading && <Spinner size="sm" className="ms-2" />}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default TanyaAI