const StatusCell = ({ status }) => {
    const statuses = {
        inactive: {
            className: 'badge-secondary',
            name: 'Upcoming'
        }, active: {
            className: 'badge-success',
            name: 'On Going'
        }, finished: {
            className: 'badge-light-danger',
            name: 'Finished'
        }
    }

    return (
        <span className={"text-capitalize badge " + statuses[status].className}>{statuses[status].name}</span>
    )
}

export default StatusCell