import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getProgress(inputType, search, page = 1, rows = 10) {
    return axios.get(`${API_URL}/reports/progress`, {
        params: {
            type: inputType,
            search,
            page,
            rows
        }
    })
}