import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Link, useLocation } from "react-router-dom"
import { toast } from "react-toastify"

import { PageTitle } from "../../../../../_metronic/layout/core"
import Authorization from "../../../../routing/Authorization"
import DeleteConfirmModal from "../../../../components/DeleteConfirmModal"
import GeneralPagination from "../../../../components/Pagination/GeneralPagination"

import { getCoupons, deleteCoupon } from "../../../../models/courses/coupons"
import CouponFilter from "./components/CouponFilter"
import Table from "../../../../components/Table/Table"
import { couponsColumn, columnAliases } from "./components/CouponTable/_columns"
import CreateCouponModal from "./components/CreateCouponModal"
import EditCouponModal from "./components/EditCouponModal"


const ManageCoupons = () => {
    const location = useLocation()

    const pageSize = 10
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({
        batchId: location.state?.filters?.batch?.value
    })
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [showCreateCouponModal, setShowCreateCouponModal] = useState()
    const [showEditCouponModal, setShowEditCouponModal] = useState()
    const [editCouponData, setEditCouponData] = useState({})
    const [showDeleteCouponModal, setShowDeleteCouponModal] = useState()
    const [deleteCouponData, setDeleteCouponData] = useState({})

    const queryClient = useQueryClient()
    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery(['coupons'], () => getCoupons({
        code: filters?.code,
        courseId: filters?.courseId,
        merchantId: filters?.merchantId,
        batchId: filters?.batchId,
        status: filters?.status,
        page,
        pageSize,
        sortBy
    }))
    const { mutate: deleteCouponMutate, isLoading: deleteCouponLoading } = useMutation(couponId => deleteCoupon(couponId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries(['coupons'])
        },
        onError: error => toast.error(error.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(['coupons'])
    }, [queryClient, filters, page, sortBy])

    return (
        <>
            <PageTitle>Coupons</PageTitle>
            <div className="card">
                <div className="card-header p-6 d-block">
                    <div className="d-flex justify-content-between">
                        <button type="button" className={"btn btn-sm " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                        <div>
                            <Link to="/courses/coupons/batch" state={{showGenerateCouponModal: true}} className="me-3">
                                <button type="button" className="btn btn-sm btn-outline btn-outline-primary">Generate Coupons</button>
                            </Link>
                            <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowCreateCouponModal(true)}>Create Coupon</button>
                        </div>
                    </div>
                    <CouponFilter
                        show={showFilter}
                        queryClient={queryClient}
                        initialValues={{
                            batch: location.state?.filters?.batch
                        }}
                        onSubmit={values => {
                            setPage(1)
                            setFilters(values)
                        }}
                    />
                </div>
                <div className="card-body p-6">
                    <Table
                        columns={couponsColumn}
                        isLoading={isFetching}
                        isError={isError}
                        error={error}
                        data={!isLoading ? data.data : []}
                        currentPage={page}
                        pageSize={pageSize}
                        onSortByChange={sortBy => setSortBy(() => {
                            if (sortBy.length) return {
                                id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                                direction: sortBy[0].desc ? 'DESC' : 'ASC'
                            }
                    
                            return {}
                        })}
                        onEdit={coupon => {
                            setEditCouponData(coupon)
                            setShowEditCouponModal(true)
                        }}
                        onDelete={coupon => {
                            setShowDeleteCouponModal(true)
                            setDeleteCouponData(coupon)
                        }}
                    />
                    {isSuccess && <GeneralPagination
                        onPageChange={page => setPage(page)}
                        totalCount={data?.meta.total}
                        currentPage={page}
                        pageSize={pageSize}
                        from={data?.meta.from}
                        to={data?.meta.to}
                    />}
                </div>
            </div>

            <DeleteConfirmModal
                show={showDeleteCouponModal}
                handleClose={() => setShowDeleteCouponModal(false)}
                onConfirm={() => {
                    deleteCouponMutate(deleteCouponData.id)
                    setShowDeleteCouponModal(false)
                    setDeleteCouponData({})
                }}
                isConfirmLoading={deleteCouponLoading}
                name={deleteCouponData?.code}
            />
            <CreateCouponModal
                show={showCreateCouponModal}
                onHide={() => setShowCreateCouponModal(false)}
            />
            <EditCouponModal
                coupon={editCouponData}
                show={showEditCouponModal}
                onHide={() => setShowEditCouponModal(false)}
            />
        </>
    )
}

export default Authorization(ManageCoupons, ['admin', 'officer'])