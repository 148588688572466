import { useFormik } from "formik"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import Spinner from "../../../../components/Spinner/Spinner"

import { getCategories } from "../../../../models/products/categories"


const validate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Name is required'
    }

    return errors;
};

const CategoryForm = ({ data = {}, onSubmit, isSubmitLoading }) => {
    const { data: categories } = useQuery(['categories'], () => getCategories(), {
        onError: error => toast.error(error.message)
    })

    const formik = useFormik({
        initialValues: {
            parent_id: data?.parent_id,
            name: data?.name,
            icon: data?.icon
        },
        validate,
        onSubmit: values => onSubmit(values)
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="card">
                <div className="card-body">
                    <div className="form-group mb-5">
                        <label className="form-label">Parent</label>
                        <select name="parent_id" className="form-select form-select-solid" onChange={formik.handleChange} value={formik.values.parent_id}>
                            <option value="">Select Parent</option>
                            {categories && categories.data.map(category => category.id !== data.id && <option key={category.id} value={category.id}>{category.name}</option>)}
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="form-label required">Name</label>
                        <input name="name" className="form-control form-control-solid" placeholder="Category Name" onChange={formik.handleChange} value={formik.values.name} />
                        {formik.errors.name ? <small className="form-text text-danger">{formik.errors.name}</small> : null}
                    </div>
                </div>
                <div className="card-footer">
                    <div className="d-flex justify-content-between">
                        <Link to='/products/categories'>
                            <button type="button" className="btn btn-secondary">Cancel</button>
                        </Link>
                        <button className="btn btn-primary" onClick={formik.submitForm} disabled={isSubmitLoading}>
                            {isSubmitLoading ? (
                                <>
                                    Saving...
                                    <Spinner
                                        size="sm"
                                        color="white"
                                        className="ms-2"
                                    />
                                </>
                            ) : 'Save'}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default CategoryForm