import { Field, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

import Spinner from "../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    title: Yup.string().required('Title is required').min(5, 'Title must be at least 5 characters').max(255, 'Title must be at most 255 characters'),
    description: Yup.string().required('Description is required').min(5, 'Description must be at least 5 characters'),
    duration: Yup.number().required().label('Duration')
})

const LessonTypeWebinar = ({ lesson, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            title: lesson?.title ? lesson.title : '',
            description: lesson?.description ? lesson.description : '',
            duration: lesson?.duration ? lesson.duration : 0
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = new FormData()
            data.append('type', 'webinar')
            data.append('title', values.title)
            data.append('description', values.description)
            data.append('duration', values.duration)

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
                <div className="form-group mb-4">
                    <label className="form-label required">Title</label>
                    <input type="text" name="title" className="form-control" placeholder="Webinar Title" onChange={formik.handleChange} value={formik.values.title} />
                    {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
                </div>
                <div className="form-group mb-4">
                    <label className="form-label required">Description</label>
                    <ReactQuill theme='snow' name="description" value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                    {formik.errors.description ? <small className="form-text text-danger">{formik.errors.description}</small> : null}
                </div>
                <div className="form-group mb-8">
                    <label className="form-label required">Duration <small className="text-muted">in seconds</small></label>
                    <input type="number" name="duration" className="form-control" placeholder="Duration" min={0} onChange={formik.handleChange} value={formik.values.duration} />
                    {formik.errors.duration ? <small className="form-text text-danger">{formik.errors.duration}</small> : null}
                </div>
                <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                    {!lesson ? 'Create' : 'Edit'} Lesson {isSubmitLoading ? <Spinner size="sm" /> : null}
                </button>
            </FormikProvider>
        </form>
    )
}

export default LessonTypeWebinar