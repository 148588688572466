import { Field, FormikProvider, useFormik } from "formik"
import Select from "react-select"
import AsyncSelect from "react-select/async"
import Collapse from "react-bootstrap/Collapse"

import { useAuth } from "../../../../modules/auth"

import { getCourses, getLessons } from "../../../../models/courses/courses"
import { getSchedules } from "../../../../models/courses/schedules"


const TaskFilter = ({ defaultValues, show, queryClient, onSubmit, scheduleId = null }) => {
    const { currentUser: { role_id: roleId } } = useAuth()

    const searchCourse = async search => queryClient.fetchQuery('course-task-filter-courses', () => getCourses(search)).then(res => res.data.map(course => ({
        label: course.title,
        value: course.id,
    })))

    const searchLesson = async search => formik.values?.course?.value && queryClient.fetchQuery('course-task-filter-lessons', () => getLessons({
        courseId: formik.values?.course?.value,
        title: search,
        types: 'practical_task'
    })).then(res => res.data.map(lesson => ({
        label: `${lesson.section?.title} - ${lesson.title}`,
        value: lesson.id,
    })))

    const searchSchedule = async search => formik.values?.course?.value && queryClient.fetchQuery('course-task-filter-schedules', () => getSchedules({
        courseId: formik.values?.course?.value,
        name: search
    })).then(res => res.data.map(schedule => ({
        label: schedule.name,
        value: schedule.id,
    })))

    const formik = useFormik({
        initialValues: {
            course: defaultValues?.course ? defaultValues.course : '',
            schedule: defaultValues?.schedule ? defaultValues.schedule : '',
            lesson: defaultValues?.lesson ? defaultValues.lesson : '',
            user: defaultValues?.user ? defaultValues.user : '',
            taskType: defaultValues?.taskType ? defaultValues.taskType : '',
            assessmentStatus: defaultValues?.assessmentStatus ? defaultValues.assessmentStatus : '',
        },
        onSubmit: values => onSubmit(values)
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        {!scheduleId && (
                            <>
                                <div className="col-12 col-md-4 mb-4">
                                    <div className="form-group">
                                        <label className="form-label"><small>Course</small></label>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions
                                            isClearable
                                            loadOptions={searchCourse}
                                            name="course"
                                            className="form-control-sm p-0"
                                            onChange={option => {
                                                formik.setFieldValue('course', option)
                                                formik.setFieldValue('lesson', null)
                                            }}
                                            value={formik.values.course}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mb-4">
                                    <div className="form-group">
                                        <label className="form-label"><small>Schedule</small></label>
                                        <AsyncSelect
                                            key={formik.values?.course?.value || 'all-schedules'}
                                            cacheOptions
                                            defaultOptions
                                            isClearable
                                            loadOptions={searchSchedule}
                                            name="schedule"
                                            className="form-control-sm p-0"
                                            onChange={option => formik.setFieldValue('schedule', option)}
                                            value={formik.values.schedule}
                                            isDisabled={!formik.values.course}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 mb-4">
                                    <div className="form-group">
                                        <label className="form-label"><small>Lesson</small></label>
                                        <AsyncSelect
                                            key={formik.values?.course?.value || 'all-lessons'}
                                            cacheOptions
                                            defaultOptions
                                            isClearable
                                            loadOptions={searchLesson}
                                            name="lesson"
                                            className="form-control-sm p-0"
                                            onChange={option => formik.setFieldValue('lesson', option)}
                                            value={formik.values.lesson}
                                            isDisabled={!formik.values.course}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="col-12 col-md-4 mb-4">
                            <div className="form-group">
                                <label className="form-label"><small>Task Type</small></label>
                                <Select
                                    isClearable
                                    isSearchable={false}
                                    name="taskType"
                                    onChange={option => formik.setFieldValue('taskType', option)}
                                    value={formik.values.taskType}
                                    options={[
                                        { label: 'Tugas Praktik Mandiri', value: 'regular' },
                                        { label: 'Unjuk Keterampilan', value: 'final' },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-4">
                            <div className="form-group">
                                <label className="form-label"><small>Assessment Status</small></label>
                                <Select
                                    isClearable
                                    isSearchable={false}
                                    name="assessmentStatus"
                                    onChange={option => formik.setFieldValue('assessmentStatus', option)}
                                    value={formik.values.assessmentStatus}
                                    options={[
                                        { label: 'Assessed', value: 'assessed' },
                                        { label: 'Not Assessed', value: 'not_assessed' },
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-4">
                            <div className="form-group">
                                <label className="form-label"><small>Name or Email</small></label>
                                <input type="text" name="user" className="form-control form-control-sm" placeholder="Name or Email" onChange={formik.handleChange} value={formik.values.user} />
                            </div>
                        </div>
                        <div className="col-12 text-end mt-6">
                            <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                                formik.resetForm()
                                onSubmit()
                            }}>Clear</button>
                            <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                        </div>
                    </FormikProvider>
                </form>
            </div>
        </Collapse>
    )
}

export default TaskFilter