import { useFormik } from "formik"
import AsyncSelect from "react-select/async"
import Collapse from "react-bootstrap/Collapse"

import { useAuth } from "../../../../modules/auth"

import { getCategories } from "../../../../models/blog/categories"
import { getUsers } from "../../../../models/users"


const PostFilter = ({ show, queryClient, onSubmit }) => {
    const { currentUser: { role_id: roleId } } = useAuth()

    const searchCategory = async search => queryClient.fetchQuery('all-blog-categories', () => getCategories(true, search)).then(res => res.data.map(category => ({
        label: category.name,
        value: category.id,
    })))

    const searchAuthor = async search => queryClient.fetchQuery('users-writer', () => getUsers({ roleId: 'writer', name: search })).then(res => res.data.map(mentor => ({
        label: mentor.name,
        value: mentor.id,
    })))

    const formik = useFormik({
        initialValues: {
            title: '',
            category: '',
            author: '',
            status: ''
        },
        onSubmit: values => {
            const data = {...values}

            data.category_id = values?.category?.value
            data.user_id = values?.author?.value

            onSubmit(data)
        }
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-4">
                        <div className="form-group mb-4">
                            <label className="form-label"><small>Title</small></label>
                            <input type="text" name="title" className="form-control form-control-sm" placeholder="Post Title" onChange={formik.handleChange} value={formik.values.title} />
                        </div>
                        <div className="form-group">
                            <label className="form-label"><small>Status</small></label>
                            <select name="status" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.status}>
                                <option value="">All</option>
                                <option value="draft">Draft</option>
                                <option value="published">Published</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Category</small></label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={searchCategory}
                                name="category"
                                className="form-control-sm p-0"
                                onChange={option => formik.setFieldValue('category', option)}
                                value={formik.values.category}
                            />
                        </div>
                    </div>
                    {['admin', 'officer'].includes(roleId) ? (
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label"><small>Author</small></label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    isClearable
                                    loadOptions={searchAuthor}
                                    name="author"
                                    onChange={option => formik.setFieldValue('author', option)}
                                    value={formik.values.author}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="col-12 text-end mt-6">
                        <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                            formik.resetForm()
                            onSubmit({})
                        }}>Clear</button>
                        <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}

export default PostFilter