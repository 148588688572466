import { useEffect } from "react"
import { useQuery, useQueryClient } from "react-query"
import PageError from "../../../../../components/Error/PageError"
import PageSpinner from "../../../../../components/Spinner/PageSpinner"
import { toRupiah } from "../../../../../helpers/common"

import { getRevenueTable } from "../../../../../models/statistics"


const RevenueTable = ({ filters }) => {
    const queryClient = useQueryClient()
    const { isLoading, isFetching, data: { data: revenue } = {}, isError, error, isSuccess } = useQuery('revenue-table', () => getRevenueTable({
        courseId: filters?.course_id,
        pubYear: filters?.pub_year,
        pubMonth: filters?.pub_month,
        pubDate: filters?.pub_date
    }), {
        enabled: false
    })

    useEffect(() => {
        queryClient.fetchQuery('revenue-table')
    }, [filters])

    return (
        <div className="card">
            <div className="card-body p-6">
                <p className="fw-bold fs-5 mb-8">Table</p>
                {isLoading ? <PageSpinner height={300} /> : isError ? <PageError height={300}>{error.message}</PageError> : isSuccess ? (
                    <div className="table-responsive min-h-350px">
                        <table className="table table-row-gray-300 gy-4">
                            <thead className="border">
                                <tr className="fw-bold text-muted">
                                    <th className="text-center align-middle" rowSpan={2}>Date Time</th>
                                    <th className="text-center align-middle" rowSpan={2}>Course</th>
                                    <th className="text-center" colSpan={2}>Tokopedia</th>
                                    <th className="text-center" colSpan={2}>Sekolahmu</th>
                                </tr>
                                <tr className="fw-bold text-muted">
                                    <th className="text-center">Total Voucher</th>
                                    <th className="text-center">Total Revenue</th>
                                    <th className="text-center">Total Voucher</th>
                                    <th className="text-center">Total Revenue</th>
                                </tr>
                            </thead>
                            <tbody className="border">
                                {isFetching ? (
                                    <tr>
                                        <td className="text-center" colSpan={6}>Loading...</td>
                                    </tr>
                                ) : Object.keys(revenue.items).map(dateTime => revenue.items[dateTime].map((item, i) => (
                                    <tr key={i}>
                                        {i === 0 ? <td className="text-center align-middle fw-semibold" rowSpan={revenue.items[dateTime].length}>{dateTime.slice(0, -1)}</td> : null}
                                        <td className="align-middle fw-semibold ps-3">{item.name}</td>
                                        <td className="text-center align-middle">{item.tokopedia ? item.tokopedia.total_vouchers : 0}</td>
                                        <td className="align-middle">{toRupiah(item.tokopedia ? item.tokopedia.total_revenue : 0)}</td>
                                        <td className="text-center align-middle">{item.sekolahmu ? item.sekolahmu.total_vouchers : 0}</td>
                                        <td className="align-middle">{toRupiah(item.sekolahmu ? item.sekolahmu.total_revenue : 0)}</td>
                                    </tr>
                                )))}
                            </tbody>
                            <tfoot className="border">
                                <tr className="bg-light">
                                    <td className="text-center fw-semibold" colSpan={2}>Total</td>
                                    <td className="text-center fw-semibold">{revenue.summary.tokopedia ? revenue.summary.tokopedia.total_vouchers : 0}</td>
                                    <td className="fw-semibold">{toRupiah(revenue.summary.tokopedia ? revenue.summary.tokopedia.total_revenue : 0)}</td>
                                    <td className="text-center fw-semibold">{revenue.summary.sekolahmu ? revenue.summary.sekolahmu.total_vouchers : 0}</td>
                                    <td className="fw-semibold">{toRupiah(revenue.summary.sekolahmu ? revenue.summary.sekolahmu.total_revenue : 0)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default RevenueTable