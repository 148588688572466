import { useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Link, useOutletContext, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import moment from "moment"

import { PageTitle } from "../../../../../_metronic/layout/core"
import { eventDate, formatDate, unSlug } from "../../../../helpers/common"
import { deleteCourseSchedule, deleteCourseSession, getCourseSchedules } from "../../../../models/courses/courses"

import DeleteConfirmModal from "../../../../components/DeleteConfirmModal"
import PageError from "../../../../components/Error/PageError"
import PageSpinner from "../../../../components/Spinner/PageSpinner"
import CreateScheduleModal from "./components/ScheduleModal/CreateScheduleModal"
import EditScheduleModal from "./components/ScheduleModal/EditScheduleModal"
import CreateSessionModal from "./components/SessionModal/CreateSessionModal"
import EditSessionModal from "./components/SessionModal/EditSessionModal"
import SessionDetailModal from "./components/SessionModal/SessionDetailmodal"
import { Collapse } from "react-bootstrap"
import CourseScheduleFilter from "./components/CourseScheduleFilter"
import DuplicateScheduleModal from "./components/ScheduleModal/DuplicateScheduleModal"


const breadcumbs = [
    {
        title: 'Courses',
        path: '/courses/manage',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const theme = {
    inactive: {
        background: 'bg-primary'
    },
    active: {
        background: 'bg-success',
    },
    finished: {
        background: 'bg-danger'
    }
}

const CourseSchedules = () => {
    const { courseId } = useParams()
    const { course } = useOutletContext()

    const scheduleQueryKey = `course-${courseId}-schedules`

    const [schedulesCollapse, setSchedulesCollapse] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})

    const [showCreateScheduleModal, setShowCreateScheduleModal] = useState(false)
    const [showEditScheduleModal, setShowEditScheduleModal] = useState(false)
    const [editScheduleData, setEditScheduleData] = useState({})
    const [showDeleteScheduleModal, setShowDeleteScheduleModal] = useState(false)
    const [deleteScheduleData, setDeleteScheduleData] = useState({})
    const [showDuplicateScheduleModal, setShowDuplicateScheduleModal] = useState(false)
    const [duplicateScheduleData, setDuplicateScheduleData] = useState({})

    const [showCreateSessionModal, setShowCreateSessionModal] = useState(false)
    const [createSessionData, setCreateSessionData] = useState({})
    const [showEditSessionModal, setShowEditSessionModal] = useState(false)
    const [editSessionData, setEditSessionData] = useState({})
    const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false)
    const [deleteSessionData, setDeleteSessionData] = useState({})
    const [showSessionDetailModal, setShowSessionDetailModal] = useState(false)
    const [sessionDetailData, setSessionDetailData] = useState({})
    
    const queryClient = useQueryClient()
    const { isLoading: isSchedulesLoading, isError: isSchedulesError, error: schedulesError, data: { data: schedules } = {} } = useQuery(scheduleQueryKey, () => getCourseSchedules({ courseId, name: filters?.name, status: filters?.status, rows: 100000, sortBy: {
        id: 'schedule_start',
        direction: 'ASC'
    }}))

    const { mutate: deleteScheduleMutate, isLoading: deleteScheduleLoading } = useMutation(scheduleId => deleteCourseSchedule(courseId, scheduleId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([scheduleQueryKey])
            setShowDeleteScheduleModal(false)
            setDeleteScheduleData({})
        },
        error: error => toast.error(error?.response?.data?.message || error.message)
    })

    const { mutate: deleteSessionMutate, isLoading: deleteSessionLoading } = useMutation(sessionId => deleteCourseSession(courseId, deleteSessionData.schedule.id, sessionId), {
        onSuccess: () => {
            toast.success('Success')
            queryClient.invalidateQueries([scheduleQueryKey])
            setShowDeleteSessionModal(false)
            setDeleteSessionData({})
        },
        error: error => toast.error(error?.response?.data?.message || error.message)
    })

    useEffect(() => {
        queryClient.fetchQuery(scheduleQueryKey)
    }, [queryClient, filters])

    return (
        <>
            <PageTitle breadcrumbs={breadcumbs}>Course Schedules</PageTitle>
            <div className="card">
                <div className="card-body p-6">
                    <div className="d-flex justify-content-between">
                        <div>
                        <button type="button" className={"btn btn-sm me-3 " + (showFilter ? 'btn-primary' : 'btn-light-primary')} onClick={() => setShowFilter(item => !item)}><i className="fa fa-filter"></i> Filter</button>
                            <button type="button" className="btn btn-sm btn-light ms-3" onClick={() => {
                                setSchedulesCollapse(prev => {
                                    if (prev.length > 0) return []
                                    
                                    return schedules.map(schedule => schedule.id)
                                })
                            }}>{schedulesCollapse.length > 0 ? "Show" : "Hide"} all</button>
                        </div>
                        <button type="button" className="btn btn-sm btn-primary" disabled={course?.type === 'video'} onClick={() => setShowCreateScheduleModal(true)}>Create Schedule</button>
                    </div>
                    <CourseScheduleFilter
                        show={showFilter}
                        onSubmit={values => {
                            setFilters(values)
                        }}
                    />
                </div>
            </div>
            {isSchedulesLoading ? <PageSpinner height={300} /> : isSchedulesError ? (
                <PageError height={300}>{schedulesError.message}</PageError>
            ) : schedules.map((schedule, i) => (
                <div className="card mt-6" key={i}>
                    <div className="card-header p-6 d-flex justify-content-between align-items-center bg-primary">
                        <div className="w-50">
                            {schedule.status === 'active' ? <span className="badge badge-square badge-light-primary p-3 mb-2">On Going</span> : null}
                            {schedule.status === 'finished' ? <span className="badge badge-square badge-light-primary p-3 mb-2">Finished</span> : null}
                            <p className="fs-3 mb-1 fw-bold text-white">{schedule.name}</p>
                            <p className="fs-7 mb-0 fw-semibold text-white">{formatDate(schedule.schedule_start, false)} - {formatDate(schedule.schedule_end, false)}</p>
                        </div>
                        <div>
                            <button type="button" className="btn btn-sm btn-bg-light btn-color-primary me-3" onClick={() => {
                                setShowCreateSessionModal(true)
                                setCreateSessionData(schedule)
                            }}>Add Session</button>
                            <Link to={`/courses/schedules/${schedule.id}/overview`} className="btn btn-sm me-3 px-2" title="View Schedule"><i className="fa fa-magnifying-glass text-white p-0"></i></Link>
                            <button className="btn btn-sm me-3 px-2" title="Edit Session" onClick={() => {
                                setShowDuplicateScheduleModal(true)
                                setDuplicateScheduleData(schedule)
                            }}><i className="fa fa-copy text-white p-0"></i></button>
                            <button className="btn btn-sm me-3 px-2" title="Edit Session" onClick={() => {
                                setShowEditScheduleModal(true)
                                setEditScheduleData(schedule)
                            }}><i className="fa fa-pencil text-white p-0"></i></button>
                            <button className="btn btn-sm px-2" title="Delete Session" onClick={() => {
                                setShowDeleteScheduleModal(true)
                                setDeleteScheduleData(schedule)
                            }}><i className="fa fa-trash text-white p-0"></i></button>
                        </div>
                    </div>
                    <div className="card-body p-6 pb-0">
                        <Collapse in={!schedulesCollapse.includes(schedule.id)}>
                            <div className="row">
                                {schedule.sessions.map((session, ii) => (
                                    <div key={ii} className="col-12 col-md-6 my-1">
                                        <div className="border rounded px-6 py-3 mb-3 bg-hover-light">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div>
                                                    <span className="badge badge-square badge-primary p-3 me-3">Session {ii + 1}</span>
                                                    <span className="badge badge-square badge-light p-3 text-capitalize">
                                                        {unSlug(session.lesson.type)}
                                                    </span>
                                                    {session.status === 'active' ? <span className="badge badge-square badge-success p-3 ms-3">On Going</span> : null}
                                                    {session.status === 'finished' ? <span className="badge badge-square badge-light-danger p-3 ms-3">Finished</span> : null}
                                                </div>
                                                <div>
                                                    <Link to={`/courses/schedules/${schedule.id}/sessions/${session.id}`} className="btn btn-sm px-2 me-3" onClick={() => {
                                                        setShowSessionDetailModal(true)
                                                        setSessionDetailData({ schedule, session })
                                                    }}><i className="fa fa-magnifying-glass text-muted p-0"></i></Link>
                                                    <button type="button" className="btn btn-sm px-2 me-3" onClick={() => {
                                                        setShowEditSessionModal(true)
                                                        setEditSessionData({ schedule, session })
                                                    }}><i className="fa fa-pencil text-muted p-0"></i></button>
                                                    <button type="button" className="btn btn-sm px-2" onClick={() => {
                                                        setShowDeleteSessionModal(true)
                                                        setDeleteSessionData({ schedule, session })
                                                    }}><i className="fa fa-trash text-danger p-0"></i></button>
                                                </div>
                                            </div>
                                            <p className="fw-bold fs-4 mb-1">{session.lesson.title}</p>
                                            <p className="fw-semibold fs-7 mb-0 text-muted">{eventDate(session.start_datetime)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Collapse>
                        <button className="btn w-100 btn-sm btn-bg-white btn-color-muted" onClick={() => setSchedulesCollapse(prev => {
                            const newItems = [...prev]
                            if (newItems.includes(schedule.id)) return newItems.filter(item => item !== schedule.id)

                            return [...prev, schedule.id]
                        })}>
                            {schedulesCollapse.includes(schedule.id) ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-up"></i>}
                        </button>
                    </div>
                </div>
            ))}

            <CreateScheduleModal
                queryClient={queryClient}
                scheduleQueryKey={scheduleQueryKey}
                courseId={courseId}
                show={showCreateScheduleModal}
                onHide={() => setShowCreateScheduleModal(false)}
            />
            <EditScheduleModal
                queryClient={queryClient}
                scheduleQueryKey={scheduleQueryKey}
                courseId={courseId}
                schedule={editScheduleData}
                show={showEditScheduleModal}
                onHide={() => {
                    setShowEditScheduleModal(false)
                    setEditScheduleData({})
                }}
            />
            <DuplicateScheduleModal
                queryClient={queryClient}
                scheduleQueryKey={scheduleQueryKey}
                courseId={courseId}
                schedule={duplicateScheduleData}
                show={showDuplicateScheduleModal}
                onHide={() => {
                    setShowDuplicateScheduleModal(false)
                    setDuplicateScheduleData({})
                }}
            />
            <DeleteConfirmModal
                show={showDeleteScheduleModal}
                handleClose={() => {
                    setShowDeleteScheduleModal(false)
                    setDeleteScheduleData({})
                }}
                onConfirm={() => deleteScheduleMutate(deleteScheduleData.id)}
                isConfirmLoading={deleteScheduleLoading}
                name={deleteScheduleData?.name}
            />

            <CreateSessionModal
                queryClient={queryClient}
                scheduleQueryKey={scheduleQueryKey}
                schedule={createSessionData}
                courseId={courseId}
                show={showCreateSessionModal}
                onHide={() => {
                    setShowCreateSessionModal(false)
                    setCreateSessionData({})
                }}
            />
            <EditSessionModal
                queryClient={queryClient}
                scheduleQueryKey={scheduleQueryKey}
                courseId={courseId}
                schedule={editSessionData.schedule}
                session={editSessionData.session}
                show={showEditSessionModal}
                onHide={() => {
                    setShowEditSessionModal(false)
                    setEditSessionData({})
                }}
            />
            <SessionDetailModal
                queryClient={queryClient}
                courseId={courseId}
                schedule={sessionDetailData.schedule}
                session={sessionDetailData.session}
                show={showSessionDetailModal}
                onHide={() => {
                    setShowSessionDetailModal(false)
                    setSessionDetailData({})
                }}
            />
            <DeleteConfirmModal
                show={showDeleteSessionModal}
                handleClose={() => {
                    setShowDeleteSessionModal(false)
                    setDeleteSessionData({})
                }}
                onConfirm={() => deleteSessionMutate(deleteSessionData.session.id)}
                isConfirmLoading={deleteSessionLoading}
            />
        </>
    )
}

export default CourseSchedules