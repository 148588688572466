import { useFormik } from "formik"
import * as Yup from "yup"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

import Spinner from "../../../../../components/Spinner/Spinner"


const LessonTypeDocument = ({ lesson, onSubmit, isSubmitLoading }) => {
    const schema = Yup.object().shape({
        title: Yup.string().required('Title is required').min(5, 'Title must be at least 5 characters').max(255, 'Title must be at most 255 characters'),
        document: !lesson ? Yup.mixed().test('required', 'Document is required', file => {
            if (file) return true
            return false
        }) : null,
        duration: Yup.number().min(0)
    })

    const formik = useFormik({
        initialValues: {
            title: lesson ? lesson.title : '',
            document: undefined,
            duration: lesson ? lesson.duration : '',
            summary: lesson ? lesson.summary : ''
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = new FormData()
            data.append('type', 'document')
            data.append('title', values.title)
            data.append('document', values.document)
            data.append('duration', values.duration)
            data.append('summary', values.summary)

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Title</label>
                <input type="text" name="title" className="form-control" placeholder="Lesson Title" onChange={formik.handleChange} value={formik.values.title} />
                {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className={"form-label " + (!lesson ? 'required' : '')}>Document</label>
                <input type="file" name="document" className="form-control" onChange={e => formik.setFieldValue('document', e.currentTarget.files[0])} />
                {formik.errors.document ? <small className="form-text text-danger">{formik.errors.document}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Duration <small className="text-muted">in seconds</small></label>
                <input type="number" name="duration" className="form-control" placeholder="Duration" min={0} onChange={formik.handleChange} value={formik.values.duration} />
                {formik.errors.duration ? <small className="form-text text-danger">{formik.errors.duration}</small> : null}
            </div>
            <div className="form-group mb-8">
                <label className="form-label">Summary</label>
                <ReactQuill theme='snow' name="summary" value={formik.values.summary} onChange={value => formik.setFieldValue('summary', value)} />
                {formik.errors.summary ? <small className="form-text text-danger">{formik.errors.summary}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                {!lesson ? 'Create' : 'Edit'} Lesson {isSubmitLoading ? <Spinner size="sm" /> : null}
            </button>
        </form>
    )
}

export default LessonTypeDocument