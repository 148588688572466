import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"


const ImageDetailModal = ({ show, onHide, getData, onSubmit }) => {
    const data = getData()
    const [values, setValues] = useState({})

    useEffect(() => {
        setValues({
            alt: data ? data?.attributes?.alt : '',
            caption: data ? data?.attributes?.caption : ''
        })
    }, [data])

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Image Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {data ? (
                    <>
                        <img src={data.file_url} className="img-fluid rounded" />
                        <div className="form-group mt-4 mb-4">
                            <label className="form-label">Alt Image</label>
                            <input type="text" className="form-control" placeholder="Alt Image" value={values.alt} onChange={e => setValues(values => ({
                                ...values,
                                alt: e.target.value
                            }))} />
                        </div>
                        <div className="form-group mb-8">
                            <label className="form-label">Caption</label>
                            <input type="text" className="form-control" placeholder="Caption" value={values.caption} onChange={e => setValues(values => ({
                                ...values,
                                caption: e.target.value
                            }))} />
                        </div>
                        <button type="button" className="btn btn-primary w-100" onClick={() => onSubmit(values)}>Save Detail</button>
                    </>
                ) : null}
            </Modal.Body>
        </Modal>
    )
}

export default ImageDetailModal