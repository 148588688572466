import { Field, FieldArray } from "formik"


const RequirementsSection = ({ formik }) => (
    <>
        <FieldArray name="requirements">
            {({ push, remove }) => (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-8">
                        <h3 className="mb-0">Requirements</h3>
                        <button type="button" className="btn btn-sm btn-light-primary" onClick={() => push()}>Add Requirements</button>
                    </div>
                    {formik.values.requirements.length > 0 && formik.values.requirements.map((_, i) => (
                        <div key={i} className="d-flex align-items-center mb-4">
                            <Field name={`requirements.${i}`} className="form-control flex-fill me-4" placeholder="Requirement" />
                            <div>
                                <button type="button" className="btn btn-sm btn-light-danger" onClick={() => remove(i)}>Remove</button>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </FieldArray>
    </>
)

export default RequirementsSection