import { Navigate } from "react-router-dom"
import { useAuth } from "../modules/auth"


const Authorization = (WrappedComponent, allowedRoles) => {
	return function WithAuthorization(props) {
		const { currentUser } = useAuth();

		if (allowedRoles.includes(currentUser.role_id)) {
			return <WrappedComponent {...props} />
		} else {
			return <Navigate to='/error/404' />
		}
	}
}

export default Authorization