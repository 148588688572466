import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify`
export const LOGIN_URL = `${API_URL}/auth/login`

export function login(email: string, password: string, remember: boolean = false) {
    return axios.post<AuthModel>(LOGIN_URL, {
        email,
        password,
        remember
    })
}

export function getProfile(accessToken: string) {
    return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
        access_token: accessToken
    })
}
