import CodeCell from "./CodeCell"
import CourseCell from "./CourseCell"
import PriceCell from "./PriceCell"
import StatusCell from "./StatusCell"
import ActionsCell from "./ActionsCell"
import UsedCell from "./UsedCell"
import VoucherTypeCell from "../../../../../../components/Table/cells/VoucherTypeCell"

const voucherColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <strong className="text-muted">{((props.initialState.currentPage - 1) * props.initialState.pageSize) + props.row.index + 1}</strong>
    }, {
        Header: 'Code',
        accessor: 'code',
        Cell: ({ ...props }) => <CodeCell voucher={props.data[props.row.index]} />
    }, {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ ...props }) => <VoucherTypeCell type={props.data[props.row.index].type} />
    }, {
        Header: 'Course',
        accessor: 'course',
        disableSortBy: true,
        Cell: ({ ...props }) => <CourseCell course={props.data[props.row.index].course} />
    }, {
        Header: 'Merchant',
        accessor: 'merchant',
        disableSortBy: true,
        Cell: ({ ...props }) => props.data[props.row.index].merchant?.name
    }, {
        Header: 'Used',
        accessor: 'used',
        Cell: ({ ...props }) => <UsedCell voucher={props.data[props.row.index]} />
    }, {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ ...props }) => <PriceCell price={props.data[props.row.index].price} discountedPrice={props.data[props.row.index].discounted_price} />
    }, {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        Cell: ({ ...props }) => <StatusCell status={props.data[props.row.index].status} />
    }, {
        Header: 'Prakerja Name',
        accessor: 'prakerja_owner',
        Cell: ({ ...props }) => props.data[props.row.index].prakerja_owner || '-'
    }, {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: ({ ...props }) => <ActionsCell voucher={props.data[props.row.index]} onDelete={props.onDelete} />
    }
]

const columnAliases = {
    number: 'id',
    used: 'quota'
}

export { voucherColumns, columnAliases }