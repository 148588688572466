const FilesCell = ({ files }) => {
    if (!files || files.length < 1) return '-'

    return (
        <ul className="list-unstyled mb-0">
            {files.map(file => (
                <li>
                    <a href={file.file_url} target="_blank"><i className="fa fa-download fa-fw"></i> {file.file_name}</a>
                </li>
            ))}
        </ul>
    )
}

export default FilesCell