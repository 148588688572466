import { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import ReactQuill from "react-quill"


const HeadingBlock = ({ blockId, placeholder = "Heading", value, onChange, onAttributeChange, onRemove }) => {
    const Editor = {
        modules: {
            toolbar: {
                container: `#toolbox-${blockId}`,
            },
            clipboard: {
                matchVisual: false,
            }
        },
        formats: [
            "header",
            "italic",
            "underline",
            "align"
        ]
    }

    const contentBlock = useRef(null)

    const [attributes, setAttributes] = useState({
        variant: value?.attributes?.variant || 'h1'
    })
    const [showToolbox, setShowToolbox] = useState(false)

    const {
        attributes: sortAttributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: blockId })

    const style = {
        transform: CSS.Translate.toString(transform),
        transition
    }

    const hideToolbox = e => {
        if (contentBlock.current && !contentBlock.current.contains(e.target)) setShowToolbox(false)
    }

    useEffect(() => {
        document.addEventListener('click', hideToolbox)

        return () => document.removeEventListener('click', hideToolbox)
    }, [])

    useEffect(() => {
        onAttributeChange(attributes)
    }, [attributes])

    return (
        <div className="content-block" ref={setNodeRef} style={style}>
            <div ref={contentBlock}>
                <div id={`toolbox-${blockId}`} className={clsx(
                    "content-toolbox",
                    {
                        "hidden": !showToolbox
                    }
                )}>
                    <button type="button" className="content-toolbox-item" {...sortAttributes} {...listeners}>
                        <i className="fa fa-up-down-left-right"></i>
                    </button>
                    <button
                        className={clsx(
                            "content-toolbox-item divider",
                            {
                                "active": attributes.variant === "h1"
                            }
                        )}
                        onClick={() => setAttributes(attributes => ({
                            ...attributes,
                            variant: "h1"
                        }))}
                    >
                        <p className="fw-bold">H1</p>
                    </button>
                    <button
                        className={clsx(
                            "content-toolbox-item",
                            {
                                "active": attributes.variant === "h2"
                            }
                        )}
                        onClick={() => setAttributes(attributes => ({
                            ...attributes,
                            variant: "h2"
                        }))}
                    >
                        <p className="fw-bold">H2</p>
                    </button>
                    <button
                        className={clsx(
                            "content-toolbox-item",
                            {
                                "active": attributes.variant === "h3"
                            }
                        )}
                        onClick={() => setAttributes(attributes => ({
                            ...attributes,
                            variant: "h3"
                        }))}
                    >
                        <p className="fw-bold">H3</p>
                    </button>
                    <button type="button" className="content-toolbox-item divider ql-italic">
                        <i className="fa fa-italic"></i>
                    </button>
                    <button type="button" className="content-toolbox-item ql-underline">
                        <i className="fa fa-underline"></i>
                    </button>
                    <button type="button" className="content-toolbox-item divider ql-align" value="center">
                        <i className="fa fa-align-center"></i>
                    </button>
                    <button type="button" className="content-toolbox-item ql-align" value="right">
                        <i className="fa fa-align-right"></i>
                    </button>
                    <button type="button" className="content-toolbox-item ql-align" value="justify">
                        <i className="fa fa-align-justify"></i>
                    </button>
                    <button type="button" className="content-toolbox-item divider" title="Remove block" onClick={onRemove}>
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
                <ReactQuill
                    modules={Editor?.modules}
                    formats={Editor?.formats}
                    theme={false}
                    className={`form-content form-content-heading form-content-heading-${attributes.variant}`}
                    placeholder={placeholder}
                    onFocus={() => setShowToolbox(true)}
                    onChange={value => onChange(value)}
                    value={value?.text}
                />
            </div>
        </div>
    )
}

export default HeadingBlock