import clsx from "clsx"
import { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { useQueryClient, useQuery, useMutation } from "react-query"
import Select from "react-select"

import Table from "../../../../../components/Table/Table"
import GeneralPagination from "../../../../../components/Pagination/GeneralPagination"

import { getScheduleStudents, getScheduleStudentsProgress } from "../../../../../models/courses/schedules"
import { studentColumns, columnAliases } from "./components/StudentsTable/_columns"
import { loginAsUser } from "../../../../../models/users"
import { toast } from "react-toastify"
import SkillTestScoreModal from "./components/SkillTestScoreModal"
import { useAuth } from "../../../../../modules/auth"
import PageSpinner from "../../../../../components/Spinner/PageSpinner"
import PageError from "../../../../../components/Error/PageError"
import CompareSiva from "./components/CompareSiva"
import FollowUpModal from "./components/FollowUpModal/FollowUpModal"


const StudentsView = ({ queryClient, schedule }) => {
    const { auth } = useAuth()

    const studentsQueryKey = `schedule-${schedule.id}-students`
    const pageSize = 100
    const [page, setPage] = useState(1)
    const [sortBy, setSortBy] = useState({})
    const [filters, setFilters] = useState({})

    const [showSkillTestScoreModal, setShowSkillTestScoreModal] = useState(false)
    const [skillTestScoreData, setSkillTestScoreData] = useState({})

    const [showFollowUpModal, setShowFollowUpModal] = useState(false)
    const [followUpData, setFollowUpData] = useState(null)

    const { isLoading, isFetching, isError, error, data, isSuccess } = useQuery([studentsQueryKey], () => getScheduleStudents({
        scheduleId: schedule.id,
        redeemStatus: filters?.redeemStatus?.value,
        page,
        rows: pageSize,
        sortBy
    }))

    const { mutate: loginAsUserMutate } = useMutation(userId => loginAsUser({ userId }), {
        onSuccess: data => window.open(data.data, '_newtab'),
        onError: error => toast.error(error?.response?.data?.message)
    })

    useEffect(() => {
        queryClient.fetchQuery([studentsQueryKey])
    }, [queryClient, filters, page, sortBy])

    const exportContacts = () => {
        const downloadUrl = `${process.env.REACT_APP_API_URL}/courses/schedules/${schedule.id}/students/export-contacts?_token=${auth.access_token}`
        window.open(downloadUrl, "_blank")
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <Select
                    isClearable
                    isSearchable={false}
                    name="redeemStatus"
                    onChange={option => setFilters((prev) => ({
                        ...prev,
                        redeemStatus: option
                    }))}
                    value={filters?.redeemStatus}
                    options={[
                        { label: 'Redeemed', value: 'used' },
                        { label: 'Not redeem', value: 'available' },
                    ]}
                    placeholder="Redeem Status"
                />
                <div>
                    <button type="button" className="btn btn-sm btn-success me-2" onClick={() => setShowFollowUpModal(true)}><i className="fab fa-whatsapp fs-4"></i> Follow Up</button>
                    <CompareSiva scheduleId={schedule.id} />
                    <button type="button" className="btn btn-sm btn-primary" onClick={() => exportContacts()}>Export Contacts</button>
                </div>
            </div>
            <Table
                columns={studentColumns}
                isLoading={isFetching}
                isError={isError}
                error={error}
                data={!isLoading ? data.data : []}
                currentPage={page}
                pageSize={pageSize}
                onSortByChange={sortBy => setSortBy(() => {
                    if (sortBy.length) return {
                        id: sortBy[0].id in columnAliases ? columnAliases[sortBy[0].id] : sortBy[0].id,
                        direction: sortBy[0].desc ? 'DESC' : 'ASC'
                    }
            
                    return {}
                })}
                scheduleId={schedule.id}
                onLoginAsUser={userId => loginAsUserMutate(userId)}
                onSetSkillTestScore={student => {
                    setShowSkillTestScoreModal(true)
                    setSkillTestScoreData(student)
                }}
                onPhoneNumberClick={student => {
                    setShowFollowUpModal(true)
                    setFollowUpData(student)
                }}
            />
            
            {isSuccess && <GeneralPagination
                onPageChange={page => setPage(page)}
                totalCount={data?.meta.total}
                currentPage={page}
                pageSize={pageSize}
                from={data?.meta.from}
                to={data?.meta.to}
            />}

            <SkillTestScoreModal
                show={showSkillTestScoreModal}
                onHide={() => {
                    setShowSkillTestScoreModal(false)
                    setSkillTestScoreData({})
                }}
                queryKey={studentsQueryKey}
                scheduleId={schedule.id}
                student={skillTestScoreData}
            />

            <FollowUpModal
                queryClient={queryClient}
                show={showFollowUpModal}
                onHide={() => {
                    setShowFollowUpModal(false)
                    setFollowUpData(null)
                }}
                onSetShow={show => setShowFollowUpModal(show)}
                scheduleId={schedule.id}
                recipient={followUpData}
            />
        </>
    )
}

const ProgressView = ({ schedule }) => {
    const { isLoading, isFetching, isError, error, data } = useQuery([`schedule-${schedule.id}-students-progress`], () => getScheduleStudentsProgress({
        scheduleId: schedule.id
    }))

    const simpleBoolean = (condition) => condition ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-danger"></i>

    if (isLoading || isFetching) return <PageSpinner height={300} />
    if (isError) return <PageError height={300}>{error.message}</PageError>

    return (
        <div className="table-responsive h-500px">
            <table className="table table-bordered table-row-bordered table-row-gray-300 table-students-progress gy-4">
                <thead>
                    <tr className="fw-bold text-muted">
                        <th rowSpan={2} className="text-center align-middle text-nowrap"><span className="d-block min-w-150px">User</span></th>
                        <th rowSpan={2} className="text-center align-middle text-nowrap"><span className="d-block min-w-100px">Merchant</span></th>
                        <th rowSpan={2} className="text-center align-middle text-nowrap"><span className="d-block min-w-100px">Voucher Code</span></th>
                        <th rowSpan={2} className="text-center align-middle text-nowrap"><span className="d-block min-w-100px">Redeem Code</span></th>
                        {data.fields.map(field => (
                            <th colSpan={field.lessons.length} className="text-center text-nowrap">
                                <span className="d-block px-3">{field.sequence && field.sequence < 999 ? 'Sesi ' + field.sequence : field.title}</span>
                            </th>
                        ))}
                    </tr>
                    <tr className="fw-semibold text-muted">
                        {data.fields.map(field => field.lessons.map(lesson => (
                            <th className="text-center text-nowrap th-lesson text-capitalize">{lesson.type === 'practical_task' ? 'Practical Task' : lesson.type === 'reflective_journal' ? 'Reflective Journal' : lesson.type}</th>
                        )))}
                    </tr>
                </thead>
                <tbody>
                    {data.data.map(student => (
                        <tr className="align-items-center">
                            <td className="align-middle text-nowrap ps-2">
                                <p className="mb-0">{student.name}</p>
                                <p className="mb-0 text-muted text-sm">{student.prakerja_email || student.email}</p>
                            </td>
                            <td className="align-middle text-center">{student.dp_platform}</td>
                            <td className="align-middle text-center">{student.voucher_code}</td>
                            <td className="align-middle text-center">{student.redeem_code}</td>
                            {student.sections.map(section => {
                                let dom = []
                                if (section.is_verified !== null) dom.push(<td className="align-middle text-center">
                                    {simpleBoolean(section.is_verified)}
                                </td>)

                                section.lessons.map(lesson => {
                                    if (lesson.type === 'webinar') {
                                        dom.push(
                                            <td className="align-middle text-center">
                                                {simpleBoolean(lesson.is_present)}
                                            </td>
                                        )
                                    } else if (lesson.type === 'practical_task') {
                                        dom.push(
                                            <td className="align-middle text-center">
                                                <i className={clsx("fa fa-upload", lesson.is_completed ? 'text-success' : 'text-danger')}></i>
                                                <i className={clsx("fa fa-clipboard ms-3", lesson.is_assessed ? 'text-success' : lesson.is_assessed === null ? 'text-gray' : 'text-danger')}></i>
                                            </td>
                                        )
                                    } else {
                                        dom.push(
                                            <td className="align-middle text-center">
                                                {simpleBoolean(lesson.is_completed)}
                                            </td>
                                        ) 
                                    }
                                })

                                return dom
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default function Students() {
    const { schedule } = useOutletContext()
    const [view, setView] = useState('students')

    const queryClient = useQueryClient()
    
    return (
        <>
            <div className="min-h-500px">
                <div className="card">
                    <div className="card-body p-6">
                        <div className="d-flex gap-3">
                            <button type="button" className={clsx("btn btn-sm", view === 'students' ? 'btn-dark' : 'btn-light-dark')} onClick={() => setView('students')}>Students View</button>
                            <button type="button" className={clsx("btn btn-sm", view === 'progress' ? 'btn-dark' : 'btn-light-dark')} onClick={() => setView('progress')}>Progress View</button>
                        </div>
                        <div className="separator separator-dashed my-6"></div>
                        {view === 'students' ? <StudentsView queryClient={queryClient} schedule={schedule} /> : <ProgressView schedule={schedule} />}
                    </div>
                </div>
            </div>
        </>
    )
}