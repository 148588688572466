import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'


const BasicSection = ({ formik, lesson }) => {
    return (
        <>
            <div className="form-group mb-4">
                <label className="form-label required">Title</label>
                <input type="text" name="title" className="form-control" placeholder="Title" onChange={formik.handleChange} value={formik.values.title} />
                {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Position</label>
                <select name="position" className="form-select" onChange={formik.handleChange} value={formik.values.position}>
                    <option value="start">Start of Video</option>
                    <option value="end">End of Video</option>
                    <option value="custom">Custom</option>
                </select>
                {formik.errors.position ? <small className="form-text text-danger">{formik.errors.position}</small> : null}
            </div>
            {formik.values.position === 'custom' && (
                <div className="form-group mb-4">
                    <label className="form-label required">Position Duration</label>
                    <div className="d-flex gap-3">
                        <span>0</span>
                        <input type="range" name="position_duration" className="form-range" min={0} max={lesson.duration} onChange={formik.handleChange} value={formik.values.position_duration} />
                        <span>{lesson.duration}</span>
                    </div>
                </div>
            )}
            <div className="form-group mb-4">
                <label className="form-label">Description</label>
                <ReactQuill theme='snow' name="description" value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                {formik.errors.description ? <small className="form-text text-danger">{formik.errors.description}</small> : null}
            </div>
        </>
    )
}

export default BasicSection