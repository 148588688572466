import { formatDate } from '../../../../../../helpers/common'

import FilesCell from "./FilesCell"
import UserCell from '../../../../../../components/Table/cells/User'

const reconciliationColumns = [
    {
        Header: '',
        accessor: 'number',
        disableSortBy: true,
        Cell: ({ ...props }) => <strong className="text-muted">{((props.initialState.currentPage - 1) * props.initialState.pageSize) + props.row.index + 1}</strong>
    }, {
        Header: 'Merchant',
        accessor: 'merchant',
        disableSortBy: true,
        Cell: ({ ...props }) => props.data[props.row.index].merchant?.name
    }, {
        Header: 'Files',
        accessor: 'files',
        disableSortBy: true,
        Cell: ({ ...props }) => <FilesCell files={props.data[props.row.index].files} />
    }, {
        Header: 'Uploaded by',
        accessor: 'user',
        Cell: ({ ...props }) => <UserCell user={props.data[props.row.index].user} />
    }, {
        Header: 'Uploaded at',
        accessor: 'created_at',
        Cell: ({ ...props }) => formatDate(props.data[props.row.index].created_at)
    }
]

export { reconciliationColumns }