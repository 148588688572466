import moment from 'moment'
import { useQuery } from 'react-query'
import { Line } from "react-chartjs-2"
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils"

import { getSalesChart } from "../../../../models/statistics"
import PageSpinner from '../../../../components/Spinner/PageSpinner'
import PageError from '../../../../components/Error/PageError'


const YearlySalesChart = () => {
    const { isLoading, isError, error, data: { data: { labels, datasets } = {} } = {} } = useQuery('statistic-sales-chart-current-year', () => getSalesChart({
        key: 'pub_month',
        startPubMonth: moment().startOf('year').format('YYYYMM'),
        endPubMonth: moment().format('YYYYMM')
    }))

    if (isLoading) return <PageSpinner height={400} />
    if (isError) return <PageError height={400}>{error.message}</PageError>

    return (
        <Line
            options={{
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0.4
                    },
                    point: {
                        radius: 0
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        display: false
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }}
            data={{
                labels: labels,
                datasets: datasets ? datasets.map(dataset => ({
                    ...dataset,
                    fill: true,
                    backgroundColor: getCSSVariableValue('--kt-primary-light'),
                    borderColor: getCSSVariableValue('--kt-primary'),
                })) : []
            }}
        />
    )
}

export default YearlySalesChart