import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL 

export function getRoles({ name = null, page = 1, rows = 10, sortBy = null }) {
    return axios.get(`${API_URL}/roles`, {
        params: {
            name,
            page,
            rows,
            order_by: sortBy?.id,
            order_direction: sortBy?.direction
        }
    })
}

export function getRole(roleId) {
    return axios.get(`${API_URL}/roles/${roleId}`)
}

export function saveRole(data) {
    return axios.post(`${API_URL}/roles`, data)
}

export function updateRole(roleId, data) {
    return axios.put(`${API_URL}/roles/${roleId}`, data)
}

export function deleteRole(roleId) {
    return axios.delete(`${API_URL}/roles/${roleId}`)
}