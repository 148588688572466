import { useFormik } from "formik"
import AsyncSelect from "react-select/async"
import Collapse from "react-bootstrap/Collapse"

import { getMerchants } from "../../../../../models/merchants"


const VoucherBatchFilter = ({ show, queryClient, onSubmit }) => {
    const searchMerchant = async search => queryClient.fetchQuery('merchants', () => getMerchants({ name: search, rows: 100 })).then(res => res.data.map(merchant => ({
        label: merchant.name,
        value: merchant.id,
    })))

    const formik = useFormik({
        initialValues: {
            title: '',
            merchant: '',
        },
        onSubmit: values => {
            values.merchantId = values?.merchant?.value

            onSubmit(values)
        }
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Title</small></label>
                            <input type="text" name="title" className="form-control form-control-sm" placeholder="Batch Title" onChange={formik.handleChange} value={formik.values.title} />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Merchant</small></label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={searchMerchant}
                                name="merchant"
                                className="form-control-sm p-0"
                                onChange={option => formik.setFieldValue('merchant', option)}
                                value={formik.values.merchant}
                            />
                        </div>
                    </div>
                    <div className="col-12 text-end mt-6">
                        <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                            formik.resetForm()
                            onSubmit({})
                        }}>Clear</button>
                        <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}

export default VoucherBatchFilter