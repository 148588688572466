import { useOutletContext } from "react-router-dom"
import { useAuth } from "../../../../modules/auth"
import Authorization from "../../../../routing/Authorization"


const Reports = () => {
    const { schedule } = useOutletContext()
    const { auth } = useAuth()

    const download = (reportType, merchantId) => {
        const downloadUrl = `${process.env.REACT_APP_API_URL}/courses/schedules/${schedule.id}/reports/${reportType}?_token=${auth.access_token}&merchant_id=${merchantId}`
        window.open(downloadUrl, "_blank")
    }

    return (
        <div className="row min-h-500px">
             <div className="col-12 col-md-4">
                <div className="card">
                    <div className="card-body p-6">
                        <p className="fw-bold fs-4 mb-6">PMO Repository</p>
                        <button type="button" className="btn btn-light-primary w-100" onClick={() => download('attendance', 'pmo')}>Attendance Report</button>
                        <button type="button" className="btn btn-light-primary w-100 mt-2" onClick={() => download('tasks', 'pmo')}>Student Final Task</button>
                        <button type="button" className="btn btn-light-primary w-100 mt-2" onClick={() => download('tasks/feedback', 'pmo')}>Student Final Task Feedback</button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="card">
                    <div className="card-body p-6">
                        <p className="fw-bold fs-4 mb-6">Tokopedia</p>
                        <button type="button" className="btn btn-light-primary w-100" onClick={() => download('redemption', 'tokopedia')}>Redemption Report</button>
                        <button type="button" className="btn btn-light-primary w-100 mt-2" onClick={() => download('completion', 'tokopedia')}>Completion Report</button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="card">
                    <div className="card-body p-6">
                        <p className="fw-bold fs-4 mb-6">PINTAR</p>
                        <button type="button" className="btn btn-light-primary w-100" onClick={() => download('redemption', 'pintar')}>Redemption Report</button>
                        <button type="button" className="btn btn-light-primary w-100 mt-2" onClick={() => download('completion', 'pintar')}>Completion Report</button>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className="card">
                    <div className="card-body p-6">
                        <p className="fw-bold fs-4 mb-6">Pijar Mahir</p>
                        <button type="button" className="btn btn-light-primary w-100" onClick={() => download('redemption', 'pijar-mahir')}>Redemption Report</button>
                        <button type="button" className="btn btn-light-primary w-100 mt-2" onClick={() => download('completion', 'pijar-mahir')}>Completion Report</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Authorization(Reports, ['admin', 'officer'])