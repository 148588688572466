import { Field, FieldArray, FormikProvider, useFormik } from "formik"
import { useMutation, useQueryClient } from "react-query"
import { useOutletContext } from "react-router-dom"
import { toast } from "react-toastify"
import * as Yup from "yup"

import Spinner from "../../../../components/Spinner/Spinner"

import { updateCourseCompetencyUnits } from "../../../../models/courses/courses"


const schema = Yup.object().shape({
    competency_units: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required().label('Competency Group Name'),
            items: Yup.array().of(Yup.string().required().label('Competency Unit'))
        })
    )
})

const CompetencyUnits = () => {
    const { course } = useOutletContext()
    
    const formik = useFormik({
        initialValues: {
            competency_units: course.competency_units
        },
        validationSchema: schema,
        onSubmit: values => mutate(values)
    })
    
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation(data => updateCourseCompetencyUnits(course.id, data), {
        onSuccess: () => {
            queryClient.invalidateQueries(['courses'])
            queryClient.invalidateQueries([`course-${course.id}`])
            toast.success('Success')
        },
        onError: error => toast.error(error?.response?.data?.message)
    })

    return (
        <div className="card">
            <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        <FieldArray name="competency_units">
                            {({ push, remove }) => (
                                <>
                                    <div className="d-flex justify-content-between align-items-center mb-8">
                                        <h3 className="mb-0">Competency Units</h3>
                                        <button type="button" className="btn btn-sm btn-light-primary" onClick={() => push({
                                            name: '',
                                            items: []
                                        })}>Add Competency Group</button>
                                    </div>
                                    {formik.values.competency_units.length > 0 && formik.values.competency_units.map((_, i) => (
                                        <div key={i} className="mb-4">
                                            <div className="border rounded">
                                                <div className="p-6 border-bottom">
                                                    <div className="d-flex align-items-center">
                                                        <Field name={`competency_units.${i}.name`} className="form-control flex-fill me-4" placeholder={`SKKNI #${i + 1}`} />
                                                        <div className="flex-shrink-0">
                                                            <button type="button" className="btn btn-sm btn-light-danger" onClick={() => window.confirm('Are you sure?') && remove(i)}>Remove Group</button>
                                                        </div>
                                                    </div>
                                                    {formik.errors.competency_units && Array.isArray(formik.errors.competency_units) ? <small className="form-text text-danger mb-3">{formik.errors.competency_units[i]?.name}</small> : null}
                                                </div>
                                                <FieldArray name={`competency_units.${i}.items`}>
                                                    {({ push: pushCompetency, remove: removeCompetency }) => (
                                                        <div className="p-6">
                                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                                <h5 className="mb-0">Competency Units {formik.values.competency_units[i].name || `Group ${i + 1}`}</h5>
                                                                <div className="flex-shrink-0">
                                                                    <button type="button" className="btn btn-sm btn-light-primary" onClick={() => pushCompetency()}>Add Competency Unit</button>
                                                                </div>
                                                            </div>
                                                            {formik.values.competency_units[i]?.items.length > 0 && formik.values.competency_units[i]?.items.map((_, ii) => (
                                                                <div key={ii} className="mb-4">
                                                                    <div className="d-flex align-items-center">
                                                                        <Field name={`competency_units.${i}.items.${ii}`} className="form-control flex-fill me-4" placeholder={`Competency Unit  #${i + 1}.${ii + 1}`} />
                                                                        <div className="flex-shrink-0">
                                                                            <button type="button" className="btn btn-sm btn-light-danger" onClick={() => removeCompetency(ii)}>Remove Competency</button>
                                                                        </div>
                                                                    </div>
                                                                    {formik.errors.competency_units && Array.isArray(formik.errors.competency_units) && formik.errors.competency_units[i]?.items && Array.isArray(formik.errors.competency_units[i]?.items) ? <small className="form-text text-danger mb-3">{formik.errors.competency_units[i]?.items[ii]}</small> : null}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </FieldArray>

                        <div className="mt-10">
                            <button type="submit" className="btn btn-primary w-100" disabled={isLoading || !formik.isValid}>
                                Save Changes {isLoading && <Spinner size="sm" className="ms-2" />}
                            </button>
                        </div>
                    </FormikProvider>
                </form>
            </div>
        </div>
    )
}

export default CompetencyUnits