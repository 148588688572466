import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL + '/products/categories'

export function getCategories(all = false, name = null) {
    return axios.get(`${API_URL}`, {
        params: {
            all, name
        }
    })
}

export function getCategory(categoryId) {
    return axios.get(`${API_URL}/${categoryId}`)
}

export function getSubCategories(parentId) {
    return axios.get(`${API_URL}/${parentId}/sub_categories`)
}

export function saveCategory(data) {
    return axios.post(`${API_URL}`, data)
}

export function updateCategory(categoryId, data) {
    return axios.put(`${API_URL}/${categoryId}`, data)
}

export function updateCategoriesSort(categories) {
    return axios.put(`${API_URL}/sort`, {
        categories
    })
}

export function deleteCategory(categoryId) {
    return axios.delete(`${API_URL}/${categoryId}`)
}