import { useEffect, useState } from 'react'


const MediaSection = ({ formik, thumbnailUrl, onRemoveThumbnail }) => {
    const [thumbnailPreview, setThumbnailPreview] = useState(thumbnailUrl)

    useEffect(() => {
        if (!formik.values.thumbnail) return

        const objectUrl = URL.createObjectURL(formik.values.thumbnail)
        setThumbnailPreview(objectUrl)
        formik.setFieldValue('remove_thumbnail', 0)

        return () => URL.revokeObjectURL(objectUrl)
    }, [formik.values.thumbnail])

    return (
        <>
            <h3 className="mb-8">Media</h3>
            <div className="row align-items-center mb-4">
                <div className="col-3">
                    <label className="form-label mb-0">Trailer Source</label>
                </div>
                <div className="col-9">
                    <select name="trailer_source" className="form-select" onChange={e => {
                        formik.setFieldValue('trailer', undefined)
                        formik.handleChange(e)
                    }} value={formik.values.trailer_source}>
                        <option value="youtube">Youtube</option>
                        <option value="system">System</option>
                    </select>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-3">
                    <label className="form-label mb-0">{formik.values.trailer_source === 'youtube' ? 'Trailer Url' : 'Trailer File'}</label>
                </div>
                <div className="col-9">
                    {formik.values.trailer_source === 'youtube' ? (
                        <input type="text" name="trailer_url" className="form-control" placeholder="Trailer Url" onChange={formik.handleChange} value={formik.values.trailer_url} />
                    ) : (
                        <input type="file" name="trailer_file" className="form-control" placeholder="Trailer File" onChange={e => formik.setFieldValue('trailer_file', e.currentTarget.files[0])} />
                    )}
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-3">
                    <label className="form-label mb-0">Thumbnail</label>
                    <small className="text-muted ms-2">(900x600)</small>
                </div>
                <div className="col-9">
                    {thumbnailPreview ? (
                        <div className="d-flex align-items-center mb-3">
                            <div className="symbol symbol-150px symbol-2by3">
                                <div className="symbol-label" style={{
                                    backgroundImage: `url('${thumbnailPreview}')`
                                }}></div>
                            </div>
                            <div className="ms-3">
                                <button type="button" className="btn btn-sm btn-outline btn-outline-danger" onClick={() => {
                                    if (thumbnailUrl) onRemoveThumbnail()
                                    formik.setFieldValue('thumbnail', '')
                                    setThumbnailPreview(null)
                                }}><i className="fa fa-times p-0"></i></button>
                            </div>
                        </div>
                    ) : null}
                    <input type="file" name="thumbnail" className="form-control" onChange={e => formik.setFieldValue('thumbnail', e.currentTarget.files[0])}  />
                </div>
            </div>
        </>
    )
}

export default MediaSection