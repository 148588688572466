import { useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

import Spinner from "../../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    title: Yup.string().required('Title is required').min(5, 'Title must be at least 5 characters').max(255, 'Title must be at most 255 characters'),
    quiz_type: Yup.string().required('Quiz Type is required'),
    quiz_minimum_score: Yup.number().required('Minimum Score is required').min(0, 'Minimum Score must be greater than or equal to 0').max(100, 'Minimum Score must be less than or equal to 100')
})

const LessonTypeQuiz = ({ lesson, onSubmit, isSubmitLoading }) => {
    const formik = useFormik({
        initialValues: {
            title: lesson ? lesson.title : '',
            summary: lesson ? lesson.summary : '',
            quiz_type: lesson ? lesson.quiz_type : 'pre_test',
            quiz_minimum_score: lesson ? lesson.quiz_minimum_score : 0
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = new FormData()
            data.append('type', 'quiz')
            data.append('title', values.title)
            data.append('quiz_type', values.quiz_type)
            data.append('quiz_minimum_score', values.quiz_minimum_score)

            onSubmit(data)
        }
    })

    useEffect(() => {
        if (lesson) return () => true

        if (formik.values.quiz_type === 'pre_test') formik.setFieldValue('quiz_minimum_score', 0)
        else if (formik.values.quiz_type === 'general') formik.setFieldValue('quiz_minimum_score', 80)
        else if (formik.values.quiz_type === 'post_test') formik.setFieldValue('quiz_minimum_score', 60)
    }, [lesson, formik.values.quiz_type])

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Title</label>
                <input type="text" name="title" className="form-control" placeholder="Quiz Title" onChange={formik.handleChange} value={formik.values.title} />
                {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Quiz Type</label>
                <select name="quiz_type" className="form-select" onChange={formik.handleChange} value={formik.values.quiz_type}>
                    <option value="pre_test">Pre Test</option>
                    <option value="general">General</option>
                    <option value="post_test">Post Test</option>
                </select>
                {formik.errors.quiz_type ? <small className="form-text text-danger">{formik.errors.quiz_type}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Minimum Score</label>
                <input type="number" name="quiz_minimum_score" className="form-control" placeholder="Minimum Score" min={0} max={100} onChange={formik.handleChange} value={formik.values.quiz_minimum_score} />
                {formik.errors.quiz_minimum_score ? <small className="form-text text-danger">{formik.errors.quiz_minimum_score}</small> : null}
            </div>
            <div className="form-group mb-8">
                <label className="form-label">Summary</label>
                <ReactQuill theme='snow' name="summary" value={formik.values.summary} onChange={value => formik.setFieldValue('summary', value)} />
                {formik.errors.summary ? <small className="form-text text-danger">{formik.errors.summary}</small> : null}
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || isSubmitLoading}>
                {!lesson ? 'Create' : 'Edit'} Lesson {isSubmitLoading ? <Spinner size="sm" /> : null}
            </button>
        </form>
    )
}

export default LessonTypeQuiz