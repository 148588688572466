import { useFormik } from "formik"
import { useQueryClient } from "react-query"
import AsyncSelect from "react-select/async"
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as Yup from "yup"

import { useAuth } from "../../../../modules/auth"

import { getCategories } from "../../../../models/products/categories"
import { getUsers } from "../../../../models/users"
import Spinner from "../../../../components/Spinner/Spinner"


const schema = Yup.object().shape({
    title: Yup.string().required().min(5).max(255).label('Product Title'),
    category: Yup.object().required().nullable().label('Category'),
    user: Yup.object().required().nullable().label('User'),
    price: Yup.number().required().min(0).label('Price'),
    discounted_price: Yup.number().label('Discounted Price')
})

const ProductForm = ({ product, onSubmit, submitLoading = false }) => {
    const { currentUser: user } = useAuth()
    const queryClient = useQueryClient()

    const searchCategory = async search => queryClient.fetchQuery('all-product-categories', () => getCategories(true, search)).then(res => res.data.map(category => ({
        label: category.name,
        value: category.id,
    })))
    
    const searchUser = async search => queryClient.fetchQuery('users', () => getUsers({ name: search })).then(res => res.data.map(user => ({
        label: user.name + ' - ' + user.email,
        value: user.id,
    })))

    const formik = useFormik({
        initialValues: {
            title: product?.title ? product.title : '',
            category: product?.category ? {
                label: product.category.name,
                value: product.category.id
            } : '',
            user: product?.title ? {
                label: product.user.name,
                value: product.user.id
            } : user.role_id === 'user' ? {
                label: user.name,
                value: user.id
            } : '',
            description: product?.description ? product.description : '',
            price: product?.price ? product.price : 0,
            discounted_price: product?.discounted_price ? product.discounted_price : 0,
            status: 'active',
        },
        validationSchema: schema,
        onSubmit: values => {
            const data = {...values}
            data.category_id = values.category.value

            if (user.role_id === 'user') data.user_id = user.id
            else data.user_id = values.user.value

            onSubmit(data)
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4">
                <label className="form-label required">Product Title</label>
                <input type="text" name="title" className="form-control" placeholder="Product Title" value={formik.values.title} onChange={formik.handleChange} />
                {formik.errors.title ? <small className="form-text text-danger">{formik.errors.title}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">Category</label>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={searchCategory}
                    name="category"
                    className="form-control-sm p-0"
                    onChange={option => formik.setFieldValue('category', option)}
                    value={formik.values.category}
                />
                {formik.errors.category ? <small className="form-text text-danger">{formik.errors.category}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label required">User</label>
                <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={searchUser}
                    name="user"
                    className="form-control-sm p-0"
                    onChange={option => formik.setFieldValue('user', option)}
                    value={formik.values.user}
                    isDisabled={user.role_id === 'user'}
                />
                {formik.errors.user ? <small className="form-text text-danger">{formik.errors.user}</small> : null}
            </div>
            <div className="form-group mb-4">
                <label className="form-label">Description</label>
                <ReactQuill theme='snow' name="description" value={formik.values.description} onChange={value => formik.setFieldValue('description', value)} />
                {formik.errors.description ? <small className="form-text text-danger">{formik.errors.description}</small> : null}
            </div>
            <div className="row mb-8">
                <div className="col-12 col-md-6 form-group mb-4 mb-md-0">
                    <label className="form-label required">Price</label>
                    <input type="number" name="price" className="form-control" placeholder="Price" onChange={formik.handleChange} value={formik.values.price} />
                    {formik.errors.price ? <small className="form-text text-danger">{formik.errors.price}</small> : null}
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label className="form-label">Discounted Price</label>
                    <input type="number" min={0} name="discounted_price" className="form-control" placeholder="Discounted Price" onChange={formik.handleChange} value={formik.values.discounted_price} />
                    {formik.errors.discounted_price ? <small className="form-text text-danger">{formik.errors.discounted_price}</small> : null}
                </div>
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={!formik.isValid || submitLoading}>
                {product ? 'Update' : 'Create'} Product {submitLoading ? <Spinner size="sm" className="ms-2" /> : null}
            </button>
        </form>
    )
}

export default ProductForm