import { useFormik } from "formik"
import AsyncSelect from "react-select/async"
import Collapse from "react-bootstrap/Collapse"

import { useAuth } from "../../../../../modules/auth"

import { getCategories } from "../../../../../models/courses/categories"
import { getUsers } from "../../../../../models/users"


const CourseFilter = ({ show, queryClient, onSubmit }) => {
    const { currentUser: { role_id: roleId } } = useAuth()

    const searchCategory = async search => queryClient.fetchQuery('all-categories', () => getCategories(true, search)).then(res => res.data.map(category => ({
        label: category.name,
        value: category.id,
    })))

    const searchMentor = async search => queryClient.fetchQuery('mentors', () => getUsers({ isMentor: true, name: search })).then(res => res.data.map(mentor => ({
        label: mentor.name,
        value: mentor.id,
    })))

    const formik = useFormik({
        initialValues: {
            title: '',
            category: '',
            mentor: '',
            status: ''
        },
        onSubmit: values => {
            values.category_id = values?.category?.value;
            values.mentor_user_id = values?.mentor?.value;

            onSubmit(values)
        }
    })

    return (
        <Collapse in={show}>
            <div className="border rounded mt-6">
                <form className="row p-6" onSubmit={formik.handleSubmit}>
                    <div className="col-12 col-md-4">
                        <div className="form-group mb-4">
                            <label className="form-label"><small>Course Title</small></label>
                            <input type="text" name="title" className="form-control form-control-sm" placeholder="Course Title" onChange={formik.handleChange} value={formik.values.title} />
                        </div>
                        <div className="form-group">
                            <label className="form-label"><small>Status</small></label>
                            <select name="status" className="form-select form-select-sm" onChange={formik.handleChange} value={formik.values.status}>
                                <option value="">All</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="form-label"><small>Category</small></label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                isClearable
                                loadOptions={searchCategory}
                                name="category"
                                className="form-control-sm p-0"
                                onChange={option => formik.setFieldValue('category', option)}
                                value={formik.values.category}
                            />
                        </div>
                    </div>
                    {['admin', 'officer'].includes(roleId) ? (
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="form-label"><small>Mentor</small></label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    isClearable
                                    loadOptions={searchMentor}
                                    name="mentor"
                                    onChange={option => formik.setFieldValue('mentor', option)}
                                    value={formik.values.mentor}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="col-12 text-end mt-6">
                        <button type="button" className="btn btn-sm btn-outline btn-outline-danger me-3" onClick={() => {
                            formik.resetForm()
                            onSubmit({})
                        }}>Clear</button>
                        <button type="submit" className="btn btn-sm btn-primary">Apply</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}

export default CourseFilter